import React, { useEffect, useState } from "react";

import { Icon } from "@iconify/react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import { useStayUI } from "../../../hooks/useStayUI";
import { useHotels } from "../../../hooks/useHotels";
import { formatMoney } from "../../../features/utils/formatMoney";

export default function ModifyHotelBookingDateChangeConfirmation() {
  const { parseBookingDateChange } = useStayUI();
  const { processChangeBooking } = useHotels();
  const {
    checkedRoom,
    newPriceLoading,
    setNewPriceLoading,
    booking,
    priceDiff,
    formatModifyDates,
  } = useModifyBookingModalContext();
  const [newData, setNewData] = useState();

  const loadData = async () => {
    const data = parseBookingDateChange({
      body: booking?.requestBody,
      checkedRoom,
    });
    setNewPriceLoading(true);
    await processChangeBooking({
      data,
      id: booking?._id,
      callback: (updatedData) => setNewData(updatedData),
    }).finally(() => setNewPriceLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  const cancellation = ({ data }) => {
    let amount = data?.cancellationAmount;
    let percentage = Math.floor((amount / data?.grandTotal) * 100);

    if (!percentage) return 0;
    return percentage;
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row gap-6 w-full">
        <div className="border border-[#EFECEC] rounded-lg p-6 w-full">
          <div className="bg-primaryText text-white px-6 py-1.5 text-sm font-semibold rounded-lg max-w-[174px] mb-8">
            Current dates
          </div>
          <div className="mb-8">
            <span className="max-w-[300px] text-sm sm:text-base">
              {formatModifyDates(checkedRoom?.startDate, checkedRoom?.endDate)}
            </span>
          </div>
          <div>
            <p className="text-sm sm:text-lg  text-darkGray">
              Current price:
              <span className="font-bold text-primaryText">
                {" "}
                {formatMoney(booking?.grandTotal)}
              </span>
            </p>
            <div className="flex items-center gap-2 text-[#1EA994]">
              <Icon icon="material-symbols:check" className="text-xl" />
              {cancellation({ booking }) ? (
                <span className="text-xs sm:text-sm">
                  {cancellation({ booking })}% cancelation fee
                </span>
              ) : (
                <span className="text-xs sm:text-sm">Free cancelation</span>
              )}
            </div>
          </div>
        </div>
        <div className="border border-[#FAA108] rounded-lg p-6 w-full">
          <div className="bg-[#FAA108] text-white px-6 py-1.5 text-sm font-semibold rounded-lg max-w-[182px] mb-8">
            Modified dates
          </div>
          <div className="mb-8">
            <span className="max-w-[300px] text-sm sm:text-base">
              {formatModifyDates(
                checkedRoom?.newStartDate,
                checkedRoom?.newEndDate
              )}
            </span>
          </div>
          <div>
            <p className="text-sm sm:text-lg text-darkGray">
              New price:
              {newPriceLoading ? (
                <div className="primaryLoader"></div>
              ) : (
                <span className="font-bold text-primaryText">
                  {" "}
                  {formatMoney(newData?.newGrandTotal)}
                </span>
              )}
            </p>
            <div className="flex items-center gap-2 text-[#1EA994]">
              <Icon icon="material-symbols:check" className="text-xl" />
              {cancellation({ booking }) ? (
                <span className="text-xs sm:text-sm">
                  {cancellation({ booking })}% cancelation fee
                </span>
              ) : (
                <span className="text-xs sm:text-sm">Free cancelation</span>
              )}
            </div>
          </div>
        </div>
      </div>
      {newPriceLoading ? (
        <div className="w-full flex items-center justify-center py-6">
          <div className="primaryLoader"></div>
        </div>
      ) : priceDiff({ data: newData }) ? (
        <>
          <div className="my-6 border-t border-[#EFECEC]"></div>
          <div className="flex justify-between items-center text-xl sm:text-2xl font-bold mt-4">
            <div className="text-darkGray">Price Difference</div>
            <div>
              {priceDiff({ data: newData }) < 0 ? "-" : "+"}
              {formatMoney(priceDiff({ data: newData }))}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
