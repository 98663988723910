import moment from "moment";
import { URLdecode } from "../utils/url";
import { isCustomerSite } from "../utils/getsubDomain";

export function calculateBirthDate(age) {
  const currentDate = new Date();

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const birthYear = currentYear - age;

  const birthDate = new Date(birthYear, currentMonth, currentDay);

  const formattedBirthYear = birthDate.getFullYear();
  const formattedBirthMonth = String(birthDate.getMonth() + 1).padStart(2, "0");
  const formattedBirthDay = String(birthDate.getDate()).padStart(2, "0");

  return `${formattedBirthYear}-${formattedBirthMonth}-${formattedBirthDay}`;
}

export function calculateAge(dateString) {
  const birthDate = new Date(dateString);

  const currentDate = new Date();

  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDiff = currentDate.getMonth() - birthDate.getMonth();
  const dayDiff = currentDate.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
}

export const calculateStayNights = ({ hotel }) => {
  if (!hotel?.start_date || !hotel?.end_date) {
    return "0 nights";
  }

  const startDate = moment(hotel.start_date);
  const endDate = moment(hotel.end_date);

  if (
    !startDate.isValid() ||
    !endDate.isValid() ||
    endDate.isBefore(startDate)
  ) {
    return "0 nights";
  }

  const nights = endDate.diff(startDate, "days");
  return nights === 1 ? "1 night" : `${nights} nights`;
};

export const selectReservedRooms = ({ room, setReservedRooms }) => {
  const params = URLdecode();
  if (!params?.rooms) return;
  let rooms = JSON.parse(params.rooms);
  rooms = rooms?.map((rm) => {
    let guests = [];
    let children = rm?.children;

    if (rm?.adults) {
      for (let i = 0; i < rm?.adults; i++) {
        let first = i === 0;
        guests.push({
          firstName: first ? "" : `Guest ${i + 1}`,
          lastName: first ? "" : `Guest ${i + 1}`,
          title: first ? "" : `Guest ${i + 1}`,
          type: "adult",
          phoneNumber: first ? "" : `Guest ${i + 1}`,
          number: 1,
        });
      }
    }
    if (children) {
      for (let i = 0; i < children?.length; i++) {
        guests.push({
          firstName: `child ${i + 1}`,
          lastName: `child ${i + 1}`,
          title: `child ${i + 1}`,
          type: "child",
          phoneNumber: `child ${i + 1}`,
          birthdate: calculateBirthDate(children[i]),
          number: 1,
        });
      }
    }

    return {
      ...room,
      guests,
      price: room?.farePrice || room?.room,
      allowedGuests: {
        adult: rm?.adults,
        child: Number(rm?.children?.length || 0) + Number(rm?.infants || 0),
      },
      amount: 1,
    };
  });
  setReservedRooms(rooms);
};

export const chooseHotelPrice = ({ price, farePrice }) => {
  if (isCustomerSite()) {
    return farePrice || price;
  }
  return price;
};

export const parseRateComments = ({ rateComments }) => {
  if (!rateComments || typeof rateComments !== "string") return [];

  return rateComments
    .split(/(?<!\d)\.(?!\d)|(?<=\D)\.(?=\d)|(?<=\d)\.(?=\D)/) // Correct splitting logic
    .map((s) => s.trim().replace(/\s+/g, " ")) // Trim and remove extra spaces
    .filter((s) => s.length > 0); // Remove empty elements
};

export const roomFacilitiesListing = ({ room }) => {
  const facilities = room?.facilities || [];
  return [
    { title: "In your private kitchen", items: facilities },
    { title: "In your private bathroom", items: facilities },
    { title: "Facilities", items: facilities },
    { title: "Smoking", items: facilities },
  ].filter((item) => item?.items?.length > 0);
};
export const boardAcronym = (board) => {
  if (!board) return "";
  return board
    ?.split(" ")
    .filter((word) => !["and"]?.includes(word.toLowerCase()))
    .map((word) => word.charAt(0).toUpperCase())
    .join("");
};

export const hotelCheckoutGuestDetailChange = ({
  index,
  key,
  value,
  bookingData,
  setBookingData,
}) => {
  const updatedRooms = bookingData?.rooms?.map((room, roomIndex) =>
    roomIndex === index
      ? {
          ...room,
          guests: room.guests.map((guest, guestIndex) =>
            guestIndex === 0 ? { ...guest, [key]: value } : guest
          ),
        }
      : room
  );
  setBookingData({ ...bookingData, rooms: updatedRooms });
};

export const hotelCheckoutTravellerText = ({ index, bookingData }) => {
  const room = bookingData?.rooms?.[index];
  if (!room) return "";

  const adults = room.guests.filter((guest) => guest.type === "adult").length;
  const children = room.guests.filter((guest) => guest.type === "child");

  const childDescriptions = children
    .map((child) => `${calculateAge(child?.birthdate)} year old`)
    .join(", ");

  return [
    adults > 0 ? `${adults} Adult${adults > 1 ? "s" : ""}` : "",
    children.length > 0
      ? `${children.length} Children (${childDescriptions})`
      : "",
  ]
    .filter(Boolean)
    .join(", ");
};

export const hotelCheckoutGuestDetailFocusChange = ({
  index,
  key,
  bookingData,
  setBookingData,
}) => {
  const updatedRooms = bookingData?.rooms?.map((room, roomIndex) =>
    roomIndex === index
      ? {
          ...room,
          touched: { ...room?.touched, [key]: true },
          error: { ...room?.touched, [key]: false },
        }
      : room
  );
  setBookingData({ ...bookingData, rooms: updatedRooms });
};

export const hotelCheckoutGuestDetailBlurChange = ({
  index,
  key,
  bookingData,
  setBookingData,
}) => {
  if (!bookingData?.rooms?.at(index)?.touched[key]) return;
  if (bookingData?.rooms?.at(index)?.error[key]) return;
  if (bookingData?.rooms?.at(index)?.guests?.at(0)[key]) return;
  const updatedRooms = bookingData?.rooms?.map((room, roomIndex) =>
    roomIndex === index
      ? {
          ...room,
          error: { ...room?.error, [key]: true },
        }
      : room
  );
  setBookingData({ ...bookingData, rooms: updatedRooms });
};
