import { useDispatch, useSelector } from "react-redux";
import fetchServer from "../controllers/fetchServer";
import {
  setAvailabilityLoading,
  setHotelAvailability,
  setHotels,
} from "../redux/reducers/tour/hotelDataSlice";
import { URLdecode, URLencode } from "../utils/url";
import { setAdultsNo, setChildrenNo } from "../redux/reducers/tour/dataSlice";
import { useSnackbar } from "notistack";
import {
  setConfirmHotelBookingModal,
  setLoadingModal,
} from "../redux/reducers/modalSlice";
import { useLocation, useNavigate } from "react-router-dom";
import useDateFormat from "./useDateFormat";
import { useRef } from "react";
import axios from "axios";
import { useStayUI } from "./useStayUI";
import { setHotelOrders } from "../redux/reducers/orderSlice";
import { isCustomerSite } from "../utils/getsubDomain";
import moment from "moment";
import { configurations } from "../configurations";

export function useHotels() {
  const location = useLocation();
  const { getActualMinAndMaxPrice } = useStayUI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { rooms: totalRooms } = useSelector((state) => state.data);
  const { hotelAvailability } = useSelector((state) => state.hotelData);
  const { hotelOrders } = useSelector((state) => state.order);
  const { calculateAge } = useDateFormat();

  const cancelAutocompleteToken = useRef(null);
  const cancelGetHotelsToken = useRef(null);
  const cancelGetHotelsAvailabilityToken = useRef(null);

  const travellersString = (local) => {
    const params = URLdecode();
    let rooms = totalRooms;

    if (params?.rooms && !local) {
      rooms = JSON.parse(params?.rooms);
    }

    let totalAdults = 0;
    let totalChildren = 0;
    let totalInfants = 0;

    rooms.forEach((room) => {
      totalAdults += room.adults;
      totalChildren += room.children?.length;
      totalInfants += room.infants;
    });

    let parts = [];

    if (totalAdults > 0) {
      parts.push(`${totalAdults} Adult${totalAdults > 1 ? "s" : ""}`);
    }
    if (totalChildren > 0) {
      parts.push(`${totalChildren} Child${totalChildren > 1 ? "ren" : ""}`);
    }
    if (totalInfants > 0) {
      parts.push(`${totalInfants} Infant${totalInfants > 1 ? "s" : ""}`);
    }

    parts.push(`${rooms.length} Room${rooms.length > 1 ? "s" : ""}`);
    // Join the parts with " · " separator
    return parts.join(" · ");
  };

  const getHotels = async ({ setLoading, radius }) => {
    const params = URLdecode();
    const start_date = params?.start_date;
    const end_date = params?.end_date;
    const lng = params?.lng;
    const lat = params?.lat;
    let cancellationMessage = "Operation canceled due to new request.";

    if (cancelGetHotelsToken.current) {
      cancelGetHotelsToken.current.cancel(cancellationMessage);
    }

    cancelGetHotelsToken.current = axios.CancelToken.source();

    let roomsArray = JSON.parse(params?.rooms);

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    if (parseInt(params?.adult)) {
      dispatch(setAdultsNo(parseInt(params?.adult)));
    }
    if (parseInt(params?.children)) {
      dispatch(setChildrenNo(parseInt(params?.children)));
    }

    const data = {
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
      start_date,
      end_date,
      range: radius || 10,
      suppliers: configurations.hotelSuppliers,
      city: params?.location || "",
      rooms: roomsArray,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/search`,
      data,
      cancelToken: cancelGetHotelsToken.current.token,
    })
      .then((res) => {
        if (!res?.cancelled) setLoading && setLoading(false);
        if (res) {
          if (res.status === 200) {
            dispatch(setHotels(res.data.data.data));
            getActualMinAndMaxPrice(res.data.data.data);
            navigate(
              `${location.pathname}?${URLencode({
                ...params,
                proximity: false,
              })}`
            );
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });
  };

  const getHotelsByName = async (q, setLoading) => {
    let cancellationMessage = "Operation canceled due to new request.";

    if (cancelAutocompleteToken.current) {
      cancelAutocompleteToken.current.cancel(cancellationMessage);
    }

    cancelAutocompleteToken.current = axios.CancelToken.source();
    const data = {
      q,
      suppliers: configurations.hotelSuppliers,
    };

    setLoading && setLoading(true);

    const res = await fetchServer({
      method: "POST",
      url: `/product/v1//hotel/by-name`,
      data,
      cancelToken: cancelAutocompleteToken.current.token,
    })
      .then((res) => {
        if (!res?.cancelled) setLoading && setLoading(false);
        if (res) {
          if (res.status === 200) {
            return res.data.data.data || [];
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    return res || [];
  };

  const getHotelAvailability = async (setLoading, reload) => {
    const params = URLdecode();
    const availability = localStorage.getItem(
      `hotelAvailability ${params?.name}`
    );
    const hotel = localStorage.getItem(`hotel ${params?.name}`)
      ? JSON.parse(localStorage.getItem(`hotel ${params?.name}`))
      : null;

    if (!hotel) return;
    if (!reload) return;
    if (params?.recheck || params?.searchBy === "hotel") {
      await getHotelAvailabilityRecheck({
        setLoading,
        setAvailability: (availabilityData) => {
          dispatch(setHotelAvailability(availabilityData));
          localStorage.setItem(
            `hotelAvailability ${availabilityData?.name}`,
            JSON.stringify(availabilityData)
          );
        },
        hotel,
        availability,
      });
      return;
    }
    dispatch(setHotelAvailability(null));
    dispatch(setAvailabilityLoading(true));

    let roomsArray = params?.rooms ? JSON.parse(params?.rooms) : [];

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    let data = {
      start_date: params?.start_date,
      end_date: params?.end_date,
      rooms: roomsArray,
      code: params?.code || "",
      codeContext: params?.codeContext || "",
      name: params?.name || "",
      cityCode: params?.cityCode || "",
      chainCode: params?.chainCode || "",
      chainName: params?.chainName || "",
      areaId: params?.areaId || "",
      categoryCode: params?.categoryCode || "",
      address: params?.address || "",
      city: params?.city || "",
      postalCode: params?.postalCode || "",
      countryCode: params?.countryCode || "",
      units: params?.units || "",
      nights: params?.nights || "",
      supplier: params?.supplier || "",
    };

    if (hotel?.availabilities) {
      data = {
        ...data,
        availabilities: hotel?.availabilities
          ?.map((availability) => {
            return {
              code: availability?.code,
              rateKey: availability?.rateKey,
            };
          })
          ?.slice(0, 300),
      };
    }

    setLoading && setLoading(true);
    let cancellationMessage = "Operation canceled due to new request.";

    if (cancelGetHotelsAvailabilityToken.current) {
      cancelGetHotelsAvailabilityToken.current.cancel(cancellationMessage);
    }

    cancelGetHotelsAvailabilityToken.current = axios.CancelToken.source();

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/availability`,
      data,
      cancelToken: cancelGetHotelsAvailabilityToken.current.token,
    })
      .then((res) => {
        if (res) {
          if (!res?.cancelled) setLoading && setLoading(false);
          if (res.status === 200) {
            dispatch(setHotelAvailability(res.data.data));
            localStorage.setItem(
              `hotelAvailability ${res.data.data?.name}`,
              JSON.stringify(res.data.data)
            );
          } else if (availability) {
            let data = JSON.parse(availability);
            localStorage.setItem(
              `hotelAvailability ${data?.name}`,
              JSON.stringify({ ...data, rooms: [] })
            );
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(setAvailabilityLoading(false));
      });
  };

  const getSuggestedHotels = async ({ setLoading, callback }) => {
    const params = URLdecode();
    let hotel = localStorage.getItem(`hotelAvailability ${params?.name}`);
    if (!hotel) return;
    hotel = JSON.parse(hotel);

    const start_date = params?.start_date;
    const end_date = params?.end_date;
    const lng = hotel?.position?.longitude || hotel?.details?.location?.long;
    const lat = hotel?.position?.latitude || hotel?.details?.location?.lat;

    let roomsArray = JSON.parse(params?.rooms);
    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    const data = {
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
      start_date,
      end_date,
      range: 50,
      suppliers: [hotel?.supplier],
      city: hotel?.cityName || "",
      rooms: roomsArray,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/search`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            callback && callback(res.data.data.data);
          }
        }
      })
      .catch((err) => {});

    setLoading && setLoading(false);
  };

  const getSuggestedHotelsForTour = async ({ setLoading, callback, tour }) => {
    const params = URLdecode();

    if (!tour?.destinations?.length) return;

    const start_date = params?.date
      ? moment(params.date, "YYYY-MM-DD").toDate()
      : moment().add(1, "day").toDate();
    const end_date = moment(start_date).add(7, "days").toDate();
    const lng = tour?.destinations?.at(0)?.longitude;
    const lat = tour?.destinations?.at(0)?.latitude;

    const data = {
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
      start_date,
      end_date,
      range: 50,
      suppliers: configurations.hotelSuppliers,
      city: tour?.destinations?.at(0)?.destinationName || "",
      rooms: [
        {
          adults: 1,
          children: [],
          infants: 0,
          units: 1,
        },
      ],
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/search`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            callback && callback(res.data.data.data);
          }
        }
      })
      .catch((err) => {});

    setLoading && setLoading(false);
  };

  const getHotelAvailabilityFromNameSearch = async (
    setLoading,
    setAvailability
  ) => {
    const params = URLdecode();
    const hotel = localStorage.getItem("searchedHotel")
      ? JSON.parse(localStorage.getItem("searchedHotel"))
      : null;
    setAvailability && setAvailability(null);
    if (!hotel) return;

    let roomsArray = JSON.parse(params?.rooms);

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    let data = {
      start_date: params?.start_date,
      end_date: params?.end_date,
      rooms: roomsArray,
      code: hotel?.code || "",
      name: hotel?.name || "",
      supplier: hotel?.supplier || "",
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/select-by-name`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setAvailability && setAvailability(res?.data?.data);
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      })
      .finally(() => {
        setLoading && setLoading(false);
      });
  };

  const getHotelAvailabilityRecheck = async ({
    setLoading,
    setAvailability,
    hotel,
    availability,
  }) => {
    const params = URLdecode();

    setAvailability && setAvailability(null);
    if (!hotel) return;

    let roomsArray = JSON.parse(params?.rooms);

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    let data = {
      start_date: params?.start_date,
      end_date: params?.end_date,
      rooms: roomsArray,
      code: hotel?.code || "",
      name: hotel?.name || "",
      supplier: hotel?.supplier || "",
    };

    setLoading && setLoading(true);

    const dispatchOldData = () => {
      const oldAvailabilityData = availability ? JSON.parse(availability) : {};
      setAvailability && setAvailability({ ...oldAvailabilityData, rooms: [] });
    };

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/select-by-name`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setAvailability && setAvailability(res?.data?.data);
          } else {
            dispatchOldData();
          }
        }
      })
      .catch((err) => {
        dispatchOldData();
      })
      .finally(() => {
        setLoading && setLoading(false);
      });
  };

  const checkRoom = async ({
    setLoading,
    hotelCode,
    roomCode,
    bookingCode,
    supplier,
  }) => {
    setLoading && setLoading(true);

    const data = await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/check-rate`,
      data: {
        hotelCode,
        roomCode,
        bookingCode,
        supplier,
      },
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res?.data?.data;
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong while checking room availability. please try again later.",
              {
                variant: "error",
              }
            );
            return null;
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          "Something went wrong while checking room availability. please try again later.",
          {
            variant: "error",
          }
        );
      });
    setLoading && setLoading(false);

    return data;
  };

  const checkRooms = async ({
    setLoading,
    hotelCode,
    roomCodes,
    bookingCodes,
    supplier,
  }) => {
    setLoading && setLoading(true);

    const data = await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/check-rate`,
      data: {
        hotelCode,
        roomCodes,
        bookingCodes,
        supplier,
      },
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res?.data?.data;
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong while checking room availability. please try again later.",
              {
                variant: "error",
              }
            );
            return null;
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar(
          "Something went wrong while checking room availability. please try again later.",
          {
            variant: "error",
          }
        );
      });
    setLoading && setLoading(false);

    return data;
  };

  const recheckRooms = async () => {
    if (hotelAvailability) {
      if (hotelAvailability?.supplier !== "HotelBeds") return null;
      let rooms = hotelAvailability?.rooms;
      if (rooms) {
        // const roomsToRecheck = rooms?.filter(
        //   (room) => room.availabilityStatus === "RECHECK"
        // );
        const roomsToRecheck = rooms;

        let payloads = [];

        let roomCodes = [];
        let bookingCodes = [];

        for (let i = 0; i < roomsToRecheck.length; i++) {
          roomCodes.push(roomsToRecheck[i].code);
          bookingCodes.push(roomsToRecheck[i].bookingCode);
        }

        const chunkSize = 10;
        for (let i = 0; i < roomCodes.length; i += chunkSize) {
          payloads.push({
            roomCodes: roomCodes.slice(i, i + chunkSize),
            bookingCodes: bookingCodes.slice(i, i + chunkSize),
            hotelCode: hotelAvailability.code,
            supplier: hotelAvailability.supplier,
          });
        }

        const responses = await Promise.all(
          payloads.map((payload) => checkRooms(payload))
        );
        let recheckedRooms = [];

        for (let i = 0; i < responses.length; i++) {
          if (responses[i]) {
            recheckedRooms = [...recheckedRooms, ...responses[i]];
          }
        }
        let availableRooms = hotelAvailability.rooms
          ? [...hotelAvailability.rooms]
          : [];
        availableRooms = availableRooms.map((room) => {
          let tempRoom = recheckedRooms.find(
            (rm) => rm.bookingCode === room.bookingCode
          );
          if (tempRoom) {
            return tempRoom;
          } else return room;
        });

        let tempHotelAvailability = {
          ...hotelAvailability,
          rooms: availableRooms,
        };
        return tempHotelAvailability || null;
      }
    }
  };

  const bookRooms = async (setLoading) => {
    const params = URLdecode();

    let roomsArray = JSON.parse(params?.rooms);

    roomsArray = roomsArray?.map((item) => {
      return {
        ...item,
        units: 1,
      };
    });

    const data = {
      code: params?.code,
      name: params?.name,
      cityCode: params?.cityCode,
      chainCode: params?.chainCode,
      chainName: params?.chainName,
      areaId: params?.areaId,
      categoryCode: params?.categoryCode,
      address: params?.address,
      city: params?.city,
      postalCode: params?.postalCode,
      countryName: params?.countryName,
      price: params?.price,
      start_date: params?.start_date,
      end_date: params?.end_date,
      meal: params?.meal,
      units: params?.units,
      nights: parseInt(params?.nights),
      OriginalCurrency: params?.OriginalCurrency,
      OriginalPrice: params?.OriginalPrice,
      currency: params?.currency,
      EquivalentPriceUSD: params?.EquivalentPriceUSD,
      supplier: params?.supplier,
      codeContext: params?.codeContext,
      rooms: JSON.parse(params?.reservedRooms),
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/book`,
      data,
    })
      .then((res) => {
        dispatch(setConfirmHotelBookingModal(false));
        if (res) {
          if (res.status === 200) {
            enqueueSnackbar("Booking successfull", { variant: "success" });
            if (isCustomerSite()) {
              const bookingParameters = {
                ...params,
                bookingId: res?.data?.data?.bookedHotel?._id,
                bookId: res?.data?.data?.bookedHotel?.bookingId,
                bookingDate: res?.data?.data?.bookedHotel?.createdAt,
                price: res?.data?.data?.bookedHotel?.grandTotal,
              };

              // Push bookingParameters to the cart array
              const cart = JSON.parse(localStorage.getItem("cart")) || [];
              cart.push(bookingParameters);
              localStorage.setItem("cart", JSON.stringify(cart));

              // Navigate to cart page
              navigate("/cart");
            } else {
              if (params?.payOption === "now") {
                let parameters = {
                  ...params,
                  bookingId: res?.data?.data?.bookedHotel?._id,
                  bookId: res?.data?.data?.bookedHotel?.bookingId,
                  bookingDate: res?.data?.data?.bookedHotel?.createdAt,
                  price: res?.data?.data?.bookedHotel?.grandTotal,
                };
                navigate(`/accommodation/payment?${URLencode(parameters)}`);
                // if (
                //   res?.data?.data?.bookedHotel?.confirmationType === "Automatic"
                // ) {
                //   navigate(`/accommodation/payment?${URLencode(parameters)}`);
                // } else {
                // }
              } else {
                navigate("/order");
              }
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        dispatch(setConfirmHotelBookingModal(false));
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });
    setLoading && setLoading(false);
  };

  function getBaseURL() {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port;
    const baseURL = `${protocol}//${hostname}${port ? ":" + port : ""}`;
    return baseURL;
  }

  const initializePayment = async ({ setLoading, booking, callback }) => {
    const data = {
      hotelBookingId: booking?._id,
      paymentMode: "Wallet",
      type: "admin",
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/hotel`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data?.data;
            // callback(res);
            callback(bookingData || booking);
          } else {
            enqueueSnackbar(
              res?.data?.error ||
                res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const getHotelBookings = async (params) => {
    // setLoading && setLoading(true);
    const { limit = 0, offset = 0, searchBy, keyword } = params || {};

    const response = await fetchServer({
      method: "GET",
      url: `/product/v1/hotel?limit=${limit}&offset=${offset}${
        searchBy ? `&searchBy=orderId&keyword=${keyword}` : ""
      }`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res.data.data;
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    // setLoading && setLoading(false);
    return response || [];
  };

  const getParameters = (booking) => {
    let guests = booking?.holdBookingRes?.guests;
    let rooms = booking?.holdBookingRes?.bookedRooms;
    rooms = rooms?.map((room, index) => {
      return {
        name: `Room ${room?.typeCode ? room?.typeCode : ""} : ${room?.type}`,
        guests: [guests[index]],
        ...room,
      };
    });

    let travelerMix = "";
    let childrenCount = 0;
    let childrenAge = "";
    let adultsCount = 0;
    let roomCount = rooms?.length || 0;

    guests.map((guest) => {
      if (guest.type === "Adult") {
        adultsCount += 1;
      } else if (guest.type === "Child") {
        childrenCount += 1;
        childrenAge += `${childrenAge ? ", " : ""} ${calculateAge(
          guest?.birthdate
        )} years old `;
      }
    });

    if (childrenCount > 0) {
      travelerMix += `${childrenCount} child${childrenCount > 1 ? "ren" : ""}${
        childrenAge && "(" + childrenAge + ")"
      }`;
    }

    if (adultsCount > 0) {
      if (travelerMix) {
        travelerMix += ", ";
      }
      travelerMix += `${adultsCount} adult${adultsCount > 1 ? "s" : ""}`;
    }

    if (roomCount > 0) {
      if (travelerMix) {
        travelerMix += " and ";
      }
      travelerMix += `${roomCount} room${roomCount > 1 ? "s" : ""}`;
    }

    let parameters = {
      name: booking?.holdBookingRes?.name,
      supplier: booking?.holdBookingRes?.supplier,
      address: booking?.holdBookingRes?.details?.address?.content,
      phone: JSON.stringify(booking?.holdBookingRes?.details?.phone),
      start_date: booking?.holdBookingRes?.start_date,
      end_date: booking?.holdBookingRes?.end_date,
      nights: booking?.holdBookingRes?.nights,
      room: booking?.holdBookingRes?.bookedRooms?.length,
      reservedRooms: JSON.stringify(rooms),
      bookingDate: booking?.createdAt,
      bookingId: booking?._id,
      bookId: booking?.holdBookingRes?.control_number,
      status: booking?.status,
      vat: booking?.holdBookingRes?.vatNumber,
      images: JSON.stringify(booking?.holdBookingRes?.details?.image),
      accommodationType: booking?.holdBookingRes?.details?.accomodationType,
      categoryType: booking?.holdBookingRes?.details?.categoryDescription,
      travelerMix,
    };

    return parameters;
  };

  const getBooking = async (setReload) => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));

    await fetchServer({
      method: "GET",
      url: `/product/v1/hotel/${params?.bookingId}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            let parameters = {
              name: booking?.holdBookingRes?.name,
              supplier: booking?.holdBookingRes?.supplier,
              address: booking?.holdBookingRes?.details?.address?.content,
              bookingId: booking?._id,
              // bookId: booking?.holdBookingRes?.control_number || booking?._id,
              bookId: booking?._id,
              status: booking?.status,
            };
            localStorage.setItem(
              `booking/${booking?._id}`,
              JSON.stringify(booking)
            );
            let url = `/accommodation/confirmation?${URLencode(parameters)}`;
            window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        // console.log("Error: ", err);
        // enqueueSnackbar("Something went wrong. please try again later.", {
        //   variant: "error",
        // });
      })
      .finally(() => {
        setReload && setReload((prev) => !prev);
        dispatch(setLoadingModal(false));
      });
  };

  const cancelAndRefundInOrder = async ({
    booking,
    setLoading,
    callbackUrl,
  }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/cancel-confirmed/${booking?._id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data;
            callbackUrl && callbackUrl(bookingData);
            enqueueSnackbar("Ticket cancelled successfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const cancelPendingHotelOrder = async ({
    booking,
    setLoading,
    callbackUrl,
  }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/cancel-booking/${booking?._id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data;
            callbackUrl && callbackUrl(bookingData);
            enqueueSnackbar("Booking cancelled successfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const processChangeBooking = async ({ id, callback, setLoading, data }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/process-change-booking/${id}`,
      data,
    })
      .then(async (res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            callback && (await callback(booking));
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading && setLoading(true);
      });
  };

  const changeBookingReconcilation = async ({
    id,
    callback,
    setLoading,
    page,
  }) => {
    const params = URLdecode();
    setLoading && setLoading(true);

    const data = {
      hotelBookingId: id,
      paymentMode: "Card",
      callbackUrl: `${getBaseURL()}${location.pathname}?${URLencode(
        page
          ? { ...params }
          : {
              bookingId: id,
              type: "hotel",
            }
      )}`,
    };

    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/hotel-change-reconcilation`,
      data,
    })
      .then(async (res) => {
        if (res) {
          if (res.status === 200) {
            const responseData = res?.data?.data;
            callback && (await callback(responseData));
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading && setLoading(true);
      });
  };

  const confirmChangeBooking = async ({ id, callback, setLoading }) => {
    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/hotel/change-booking/${id}`,
    })
      .then(async (res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data?.bookedHotel;
            dispatch(
              setHotelOrders(
                hotelOrders?.map((order) =>
                  order?._id === id ? booking : order
                )
              )
            );
            callback && (await callback(booking));
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      })
      .finally(() => {
        setLoading && setLoading(true);
      });
  };

  const confirmHotelOrder = async ({ booking, setLoading, callbackUrl }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/admin-confirm-hotel?bookingId=${booking?._id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data;
            callbackUrl && callbackUrl(bookingData);
            enqueueSnackbar("Booking confirmed successfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  return {
    getHotels,
    getHotelAvailability,
    getSuggestedHotels,
    checkRoom,
    travellersString,
    bookRooms,
    initializePayment,
    getHotelBookings,
    getBooking,
    cancelPendingHotelOrder,
    checkRooms,
    checkRooms,
    recheckRooms,
    getHotelsByName,
    getHotelAvailabilityFromNameSearch,
    cancelAndRefundInOrder,
    processChangeBooking,
    changeBookingReconcilation,
    confirmChangeBooking,
    getSuggestedHotelsForTour,
    confirmHotelOrder,
  };
}
