import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import { ModifyHotelBookingServiceSelection } from "./ModifyHotelBookingServiceSelection";
import { ModifyHotelBookingTypeSelection } from "./ModifyHotelBookingTypeSelection";
import { ModifyHotelBookingModification } from "./ModifyHotelBookingModification";
import { ModifyHotelBookingConfirmation } from "./ModifyHotelBookingConfirmation";
import ModifyHotelBookingAddRoom from "./ModifyHotelBookingAddRoom";
import ModifyHotelBookingSelectRoom from "./ModifyHotelBookingSelectRoom";

export const ModifyHotelBookingModalContent = () => {
  const { data } = useModifyBookingModalContext();

  const renderContent = () => {
    switch (data.tab) {
      case 0:
        return <ModifyHotelBookingServiceSelection />;
      case 1:
        return data?.tabType === "room" ? (
          <ModifyHotelBookingAddRoom />
        ) : (
          <ModifyHotelBookingTypeSelection />
        );
      case 2:
        return data?.tabType === "room" ? (
          <ModifyHotelBookingSelectRoom />
        ) : (
          <ModifyHotelBookingModification />
        );
      case 3:
        return <ModifyHotelBookingConfirmation />;
      default:
        return null;
    }
  };

  return <>{renderContent()}</>;
};
