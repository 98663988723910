import { getCurrencySymbol } from "./features/utils/countires";

export const priceTimeout = 1000 * 60 * 15; // 15 minutes

export const path = {
  // api: "https://btm-backend-live.onrender.com/api",
  site: window.location.protocol + "//" + window.location.host,
};

let cur = window.localStorage.getItem("currency") || "NGN";
export const def = {
  get currency() {
    return getCurrencySymbol(cur);
  },
  get currencyCode() {
    cur = window.localStorage.getItem("currency") || "NGN";
    return cur;
  },
  get api() {
    let host = window.location.hostname;
    if (host?.includes("dev.") || host?.includes("localhost"))
      return process.env.REACT_APP_API_DEV;
    if (host?.includes("qa.")) return process.env.REACT_APP_API_QA;
    else if (host?.includes("staging."))
      return process.env.REACT_APP_API_STAGING;
    else if (host?.includes("intraverse.app"))
      return process.env.REACT_APP_API_LIVE;
    else return process.env.REACT_APP_API;
  },
  devStatus: "test",
  devTest: false,
  siteName: "miles",
  logo: require("./assets/icons/textlogo.svg"),
};
