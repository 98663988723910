import React from "react";
import AccommodationDateRangePicker from "../../accommodation/AccommodationDateRangePicker";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export const ModifyHotelBookingDateModification = () => {
  const { checkedRoom, formatModifyDates, onRoomDateChange } =
    useModifyBookingModalContext();

  return (
    <div className="text-sm sm:text-base">
      <div className="rounded-lg border border-[#EFECEC] p-6 flex justify-between items-center mb-6">
        <div>
          <div>
            {formatModifyDates(checkedRoom?.startDate, checkedRoom?.endDate)}
          </div>
        </div>
        <div>
          <button className="bg-primaryText text-white  text-xxxs sm:text-sm px-6 py-1.5 rounded-lg">
            Current dates
          </button>
        </div>
      </div>
      <div>
        <h3 className="font-bold text-lg sm:text-2xl mb-4">Modify dates</h3>
        <div>
          <AccommodationDateRangePicker
            label="From - to"
            onDateChange={onRoomDateChange}
            startDate={checkedRoom?.newStartDate}
            endDate={checkedRoom?.newEndDate}
          />
        </div>
      </div>
    </div>
  );
};
