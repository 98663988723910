import React, { useState } from "react";
import ModalContainer from "../common/ModalContainer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setTourOrders } from "../../redux/reducers/orderSlice";
import useDateFormat from "../../hooks/useDateFormat";
import { useTours } from "../../hooks/useTours";

export const CancelPendingTourBookingModal = ({ setOpen, open, booking }) => {
  const dispatch = useDispatch();
  const { capitalizeFirstLetter } = useDateFormat();
  const { tourOrders } = useSelector((state) => state.order);
  const { cancelPendingTourOrder } = useTours();
  const [loading, setLoading] = useState(false);

  const paxMix = () => {
    const pax = booking?.holdBookingRes?.items?.at(0)?.lineItems;
    return pax
      ?.map((item) => {
        return `${item?.numberOfTravelers} ${capitalizeFirstLetter(
          item?.ageBand
        )}`;
      })
      .join(", ");
  };

  const handleCancel = async () => {
    await cancelPendingTourOrder({
      booking,
      setLoading,
      callbackUrl: (data) => {
        dispatch(
          setTourOrders(
            tourOrders?.map((item) => (item?._id === data?._id ? data : item))
          )
        );
        setOpen(false);
      },
    });
  };

  return (
    <>
      <ModalContainer
        open={open}
        setOpen={setOpen}
        secondary
        padding="px-4 py-6"
        title="Cancel"
        subTitle="Check the information of the product you want to cancel"
      >
        <div className="w-full flex flex-col">
          <div className="flex items-start gap-2 w-full p-2">
            <div className="my-1">
              <img
                src="/IconTour2.svg"
                alt="service-icon"
                className="h-5 object-contain"
              />
            </div>
            <div className="flex flex-1 flex-col">
              <span className="text-sm lg:text-base">
                {booking?.extraDetails?.productInfo?.title}
              </span>
              {paxMix()}
              <span className="text-sm text-gray-400">
                {moment(booking?.travelDate).format("dddd DD MMM YYYY")}
              </span>
            </div>
          </div>

          <div className="border-[#EFECEC] mb-10"></div>

          <div className="flex gap-6 items-center">
            <button onClick={setOpen} className={ClassName.whiteButton}>
              Do not cancel
            </button>
            <button
              className="bg-primary1 flex items-center justify-center text-secondaryflex-1 text-white w-full py-4 rounded-lg text-xs sm:text-lg"
              onClick={() => handleCancel()}
            >
              {loading ? (
                <div className="buttonLoader"></div>
              ) : (
                <span>Cancel</span>
              )}
            </button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

const ClassName = {
  whiteButton: "py-4 px-6 text-xs md:text-lg font-bold w-full max-w-[174px]",
};
