import React, { useRef } from "react";
import { allStatuses } from "../OrderList";
import usePDF from "../../../hooks/flight/usePDF";
import TourConfirmationPDFSection from "../../pdf/tourPDF/TourConfirmationPDFSection";
import MenuOptions from "./MenuOptions";
import { useTours } from "../../../hooks/useTours";
import { useDispatch, useSelector } from "react-redux";
import { setTourOrders } from "../../../redux/reducers/orderSlice";

export default function TourMenu({
  order,
  mobile,
  setViewTourOrder,
  setLoading,
  setCancelPendingTourOrder,
  setPayTourOrder,
}) {
  const { confirmTourOrder } = useTours();
  const dispatch = useDispatch();
  const { tourOrders } = useSelector((state) => state.order);
  const { handlePDFDownload } = usePDF();
  const pdfRef = useRef(null);

  const handleExport = async () => {
    // dispatch(setHotelConfirmationPdfModal(true));
    if (pdfRef?.current) {
      let string = pdfRef.current.outerHTML;
      setLoading && setLoading(true);
      await handlePDFDownload(string, {
        fileName: order?.itemRef || "",
      }).finally(() => setLoading && setLoading(false));
    }
  };
  const isVisible = (type) => {
    switch (type) {
      case "View Booking":
        return true;
      // return allStatuses
      //   .filter(
      //     (status) =>
      //       !["Pending", "Paid", "Booked", "Failed"].includes(status)
      //   )
      //   ?.includes(order?.status);
      case "Pay Booking":
        return ["Booked", "Pending", "Not Paid"]?.includes(order?.status);
      case "Modify Booking":
        return (
          allStatuses?.includes(order?.status) &&
          order?.holdBookingRes?.details?.modificationPolicies?.modification
        );
      case "Cancel Booking":
        return (
          allStatuses?.includes(order?.status) &&
          order?.holdBookingRes?.details?.modificationPolicies?.cancellation
        );
      case "Cancel Pending Booking":
        return ["Not Paid", "Pending"]?.includes(order?.status);
      case "Confirm Booking":
        return ["Awaiting Admin", "AwaitingAdmin"]?.includes(order?.status);
      case "Send Invoice":
        return allStatuses?.includes(order?.status);
      case "Price Details":
        return allStatuses?.includes(order?.status);
      case "PDF":
        return true;
      case "Voucher":
        return Boolean(order?.voucherInfo?.url);
      // case "Modify Reference":
      //   return allStatuses?.includes(order?.status);
      default:
        return false;
    }
  };

  const menu = [
    {
      label: "View Booking",
      action: () => {
        setViewTourOrder && setViewTourOrder(true);
      },
      visible: isVisible("View Booking"),
    },
    {
      label: "Pay Booking",
      action: () => {
        setPayTourOrder && setPayTourOrder(true);
      },
      visible: isVisible("Pay Booking"),
    },
    // {
    //   label: "Send Invoice",
    //   action: () => {},
    //   visible: isVisible("Send Invoice"),
    // },
    // {
    //   label: "Price Details",
    //   action: () => {
    //     setHotelPriceDetailModal && setHotelPriceDetailModal(true);
    //   },
    //   visible: isVisible("Price Details"),
    // },
    // {
    //   label: "Modify Reference",
    //   action: () => {},
    //   visible: isVisible("Modify Reference"),
    // },
    {
      label: "Download PDF",
      action: () => {
        handleExport();
      },
      visible: isVisible("PDF"),
    },
    {
      label: "Confirm Booking",
      action: () => {
        confirmTourOrder({
          booking: order,
          setLoading,
          callbackUrl: (data) => {
            dispatch(
              setTourOrders(
                tourOrders?.map((item) =>
                  item?._id === data?._id ? data : item
                )
              )
            );
          },
        });
      },
      visible: isVisible("Confirm Booking"),
    },
    {
      label: "Voucher",
      action: () => {
        window.open(order?.voucherInfo?.url, "_blank");
      },
      visible: isVisible("Voucher"),
    },
    {
      label: "Cancel Booking",
      action: () => {
        setCancelPendingTourOrder && setCancelPendingTourOrder(true);
      },
      visible: isVisible("Cancel Pending Booking"),
    },
  ];

  return (
    <>
      <div className="hidden">
        <div className="" ref={pdfRef}>
          <TourConfirmationPDFSection booking={order} />
        </div>
      </div>
      <MenuOptions mobile={mobile} menu={menu} />
    </>
  );
}
// Tour menu
