import moment from "moment";
import { formatMoney } from "../../../features/utils/formatMoney";

function capitalizeFirstLetter(str, trim) {
  if (typeof str !== "string") {
    return str;
  }

  str = str.replace(/_/g, " ");

  let finalString = str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
  if (trim) {
    if (finalString?.length > 18) {
      finalString = finalString.slice(0, 18) + "...";
    }
  }

  return finalString;
}

export function templateStayOrderData(order) {
  let rooms = order?.holdBookingRes
    ? order?.holdBookingRes?.rooms
    : order?.requestBody?.rooms;
  let guests = order?.holdBookingRes
    ? order?.holdBookingRes?.guests?.filter((guest) => guest?.type === "Adult")
    : order?.requestBody?.rooms
        ?.flatMap((room) => room?.guests || [])
        ?.filter((guest) => guest?.type === "adult");

  let guestElements = [
    {
      type: "title",
      value: "Guest(s)",
    },
  ];
  let roomElements = [
    {
      type: "title",
      value: "Rooms",
    },
  ];
  let boardElements = [
    {
      type: "title",
      value: "Board",
    },
  ];

  if (guests && guests.length > 0) {
    for (let guest of guests) {
      guestElements.push({
        type: "text",
        value:
          (guest?.first_name || guest?.firstName) +
          " " +
          (guest?.last_name || guest?.lastName),
      });
    }
  }

  if (rooms && rooms.length > 0) {
    for (let room of rooms) {
      roomElements.push({
        type: "text",
        value: `${capitalizeFirstLetter(room?.description || room?.name)}`,
      });
      boardElements.push({
        type: "text",
        value: `${capitalizeFirstLetter(room.type)}`,
      });
    }
  }
  return [
    {
      elements: [
        {
          type: "title",
          value: moment(order?.createdAt)?.format("DD-MM-YYYY HH:mm [GMT]Z"),
        },
        {
          type: "hotelLink",
          value: order?.bookRef || order?.bookingId,
        },
        {
          type: "text",
          value: order?.holdBookingRes?.hotelSupplier,
        },
        {
          type: "text",
          value: (
            <span className="text-sm text-primary1">
              <span>Order by: </span>
              <span>{order?.account?.detail?.agencyName}</span>
            </span>
          ),
        },
        {
          type: "tag",
          value:
            order?.holdBookingRes?.supplier || order?.supplier || "UNKNOWN",
          color: "text-[#B12D00]",
          background: "bg-[#FDE5C0]",
        },
      ],
      width: "md:w-[25%]",
    },
    {
      elements: [
        {
          type: "title",
          value: "Stay",
        },
        {
          type: "text",
          value: order?.holdBookingRes?.name || order?.requestBody?.name,
        },
      ],
      width: "md:w-[25%]",
    },
    {
      elements: guestElements,
      width: "md:w-[25%]",
    },
    {
      elements: roomElements,
      width: "md:w-[25%]",
    },
    {
      elements: boardElements,
      width: "md:w-[25%]",
    },
    {
      elements: [
        {
          type: "title",
          value: "Agent Price",
        },
        {
          type: "text",
          value: formatMoney(order?.fareTotalPrice),
        },
      ],
      width: "md:w-[25%]",
    },
    {
      elements: [
        {
          type: "title",
          value: "Status",
        },
        {
          type: "status",
          value: order?.status,
        },
      ],
      width: "md:w-[25%]",
    },
  ];
}
