import { useDispatch, useSelector } from "react-redux";
import fetchServer from "../controllers/fetchServer";
import {
  setActivities,
  setCancelQuote,
  setTourActivity,
  setTourAvailability,
  setTourBookings,
  setTourGroupQuestions,
  setTourTouristQuestions,
} from "../redux/reducers/tour/tourDataSlice";
import { URLdecode, URLencode } from "../utils/url";
import { useSnackbar } from "notistack";
import {
  setConfirmTourBookingModal,
  setLoadingModal,
  setTourCancelModal,
} from "../redux/reducers/modalSlice";
import { useNavigate } from "react-router-dom";
import useDateFormat from "./useDateFormat";
import { useRef } from "react";
import axios from "axios";
import useLocalStorage from "./useLocalStorage";
import useToursUI from "./useToursUI";
import { isCustomerSite } from "../utils/getsubDomain";

export function useTours() {
  const { getActualMinAndMaxPrice } = useToursUI();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activities, tour, tourTouristQuestions, tourGroupQuestions } =
    useSelector((state) => state.tourData);
  const { filterData } = useSelector((state) => state.tourFilter);
  const { enqueueSnackbar } = useSnackbar();
  const { capitalizeFirstLetter } = useDateFormat();
  const cancelAutocompleteToken = useRef(null);
  const { saveTours } = useLocalStorage();
  const { parseBookingPayload } = useToursUI();

  const travellersString = () => {
    const params = URLdecode();
    const paxMix = params?.paxMix ? JSON.parse(params?.paxMix) : [];

    if (paxMix && paxMix?.length > 0) {
      let travellers = [];

      paxMix.map((mix, index) => {
        if (mix?.numberOfTravelers > 0) {
          travellers.push(
            `${mix?.numberOfTravelers} ${capitalizeFirstLetter(mix?.ageBand)}`
          );
        }
      });

      if (travellers.length === 0) {
        return "No travellers";
      }

      if (travellers.length === 1) {
        return travellers[0];
      }

      return (
        travellers.slice(0, -1).join(", ") + " and " + travellers.slice(-1)
      );
    }

    let totalAdults = parseInt(params?.adult);
    let totalChildren = parseInt(params?.child);
    let totalInfants = parseInt(params?.infant);

    let parts = [];

    if (totalAdults > 0) {
      parts.push(`${totalAdults} Adult${totalAdults > 1 ? "s" : ""}`);
    }
    if (totalChildren > 0) {
      parts.push(`${totalChildren} Child${totalChildren > 1 ? "ren" : ""}`);
    }
    if (totalInfants > 0) {
      parts.push(`${totalInfants} Infant${totalInfants > 1 ? "s" : ""}`);
    }

    return parts.join(" · ");
  };

  const getFullNameFromQuestions = (question) => {
    let name = [];
    let questions = question?.questions || [];

    let firstName = questions?.find((q) => (q.id = "FULL_NAMES_FIRST"));
    let lastName = questions?.find((q) => (q.id = "FULL_NAMES_LAST"));
    if (firstName) name.push(firstName?.answer);
    if (lastName) name.push(lastName?.answer);

    console.log(questions);
    // console.log(lastName);

    return name.join(" ");
  };

  const getIndividualDetails = () => {
    const params = URLdecode();
    let details = [];
    const questions = params?.bookingQuestionAnswers
      ? JSON.parse(params?.bookingQuestionAnswers)
      : [];
    let iQuestions = questions.filter((question) => question?.travelerNum > 0);

    let people = [];

    for (let i = 0; i < iQuestions.length; i++) {
      let personArray = people[iQuestions[i]?.travelerNum - 1]
        ? [...people[iQuestions[i]?.travelerNum - 1]]
        : [];
      personArray.push(iQuestions[i]);
      personArray = personArray?.map((person) => {
        let data = {};
        if (person?.question) {
          data = { ...data, question: capitalizeFirstLetter(person.question) };
        }
        if (person?.answer) {
          let answer = capitalizeFirstLetter(person.answer);
          if (person?.unit) {
            answer = `${answer} (${capitalizeFirstLetter(person?.unit)})`;
          }
          data = { ...data, answer: capitalizeFirstLetter(answer) };
        }

        return data;
      });
      people[iQuestions[i]?.travelerNum - 1] = personArray;
    }

    details = people;

    return details;
  };

  const getGroupDetails = () => {
    const params = URLdecode();
    let details = [];
    const questions = params?.bookingQuestionAnswers
      ? JSON.parse(params?.bookingQuestionAnswers)
      : [];
    let gQuestions = questions.filter((question) => !question?.travelerNum);

    gQuestions = gQuestions?.map((group) => {
      let data = {};
      if (group?.question) {
        data = { ...data, question: capitalizeFirstLetter(group.question) };
      }
      if (group?.answer) {
        let answer = capitalizeFirstLetter(group.answer);
        if (group?.unit) {
          answer = `${answer} (${capitalizeFirstLetter(group?.unit)})`;
        }
        data = { ...data, answer: capitalizeFirstLetter(answer) };
      }

      return data;
    });

    details = gQuestions;

    return details;
  };

  const getAutocomplete = async (q, setLoading) => {
    setLoading && setLoading(true);

    let cancellationMessage = "Operation canceled due to new request.";

    if (cancelAutocompleteToken.current) {
      cancelAutocompleteToken.current.cancel(cancellationMessage);
    }

    cancelAutocompleteToken.current = axios.CancelToken.source();

    let data = await fetchServer({
      method: "GET",
      url: `/product/v1/package/auto-complete?q=${q}`,
      cancelToken: cancelAutocompleteToken.current.token,
    })
      .then((res) => {
        if (res) {
          if (!res?.cancelled) {
            setLoading && setLoading(false);
          }
          if (res.status === 200) {
            return (
              {
                destinations: res.data?.data?.destinations,
                products: res.data?.data?.products,
              } || { destinations: [], products: [] }
            );
          } else {
            return { destinations: [], products: [] };
          }
        }
      })
      .catch((err) => {
        return [];
      });

    return data;
  };

  const getTourPackages = async (setLoading, limit, cancelToken) => {
    const params = URLdecode();

    let data = {
      destinationId: params?.destinationId,
      destinationName: params?.location,
      // suppliers: ["TourX1", "PackagePro"],
      suppliers: ["TourX1"],
      populate: false,
    };

    if (params?.tag) {
      let tag = parseInt(params?.tag);
      if (tag) {
        data = { ...data, tagId: tag };
      }
    }

    if (limit) {
      data = { ...data, limit: limit };
    }

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/search-by-destination`,
      data,
      // cancelToken: cancelToken?.token,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setActivities(res.data.data.data));
            saveTours(res.data.data.data);
            getActualMinAndMaxPrice(res.data.data.data);
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        if (axios.isCancel(err)) {
        } else {
          // enqueueSnackbar("Something went wrong. please try again later.", {
          //   variant: "error",
          // });
        }
      });
    setLoading && setLoading(false);
  };

  const getTourSuggestions = async ({ id, city, callback, setLoading }) => {
    let data = {
      destinationId: id,
      destinationName: city,
      suppliers: ["TourX1"],
      populate: false,
      limit: 30,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/search-by-destination`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            callback && callback(res.data.data.data);
          }
        }
      })
      .catch((err) => {});
    setLoading && setLoading(false);
  };

  const getTourActivity = async (setLoading) => {
    const params = URLdecode();
    const productCode = params?.productCode;
    const supplier = params?.supplier;
    const loadedTour = JSON.parse(localStorage.getItem(`tour ${productCode}`));

    if (loadedTour && loadedTour?.bookingQuestions) return;

    setLoading && setLoading(true);

    const data = {
      productCode: productCode,
      supplier: supplier,
    };

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/get-single`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            dispatch(setTourActivity(res.data.data));
            localStorage.setItem(
              `tour ${res.data.data?.productCode}`,
              JSON.stringify(res.data.data)
            );
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    setLoading && setLoading(false);
  };

  const getTourReviews = async (setLoading, limit = 20, skip = 0) => {
    const params = URLdecode();
    const productCode = params?.productCode;
    const supplier = params?.supplier;

    setLoading && setLoading(true);

    const data = {
      productCode: productCode,
      supplier: supplier,
      limit,
      skip,
    };

    const response = await fetchServer({
      method: "POST",
      url: `/product/v1/package/product-reviews`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res.data.data || [];
          }
          return [];
        }
      })
      .catch((err) => {
        return [];
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    setLoading && setLoading(false);
    return response || [];
  };

  const getTourAvailability = async (setLoading, callback) => {
    const params = URLdecode();
    const productCode = params?.productCode;
    const supplier = params?.supplier;
    const date = params?.date;

    let paxMix = params?.paxMix ? JSON.parse(params?.paxMix) : [];

    paxMix = paxMix?.map((mix) => {
      return {
        ageBand: mix.ageBand,
        numberOfTravelers: mix.numberOfTravelers,
      };
    });

    paxMix = paxMix.filter((mix) => mix.numberOfTravelers > 0);

    const data = {
      productCode: productCode,
      supplier: supplier,
      travelDate: date,
      paxMix: paxMix,
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/availability`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            localStorage.setItem(
              `tourAvailability ${productCode}`,
              JSON.stringify(res.data.data)
            );
            if (callback) callback(res.data.data);
          } else {
            localStorage.removeItem(`tourAvailability ${productCode}`);
            enqueueSnackbar(res?.data?.message, { variant: "warning" });
          }
        }
      })
      .catch((err) => {
        if (err.message === "Network Error!") {
          enqueueSnackbar("Network Error", { variant: "error" });
        }
      });
    setLoading && setLoading(false);
  };

  const bookTourOrder = async ({ setLoading, tour, payload }) => {
    const params = URLdecode();
    const data = parseBookingPayload({ tour, payload });

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/hold-booking`,
      data,
    })
      .then((res) => {
        dispatch(setConfirmTourBookingModal(false));
        if (res) {
          if (res.status === 200) {
            enqueueSnackbar("Booking successful", { variant: "success" });
            if (payload?.payOption === "now") {
              let parameters = {
                ...params,
                bookingId: res?.data?.data?.bookedPackage?._id,
                bookId: res?.data?.data?.booking?.bookingId,
                bookingDate: res?.data?.data?.booking?.createdAt,
                price: res?.data?.data?.bookedPackage?.basePrice,
                bookingQuestionAnswers: JSON.stringify(
                  res?.data?.data?.params?.items[0]?.bookingQuestionAnswers
                ),
              };
              if (isCustomerSite()) {
                // Customer site logic
              } else {
                if (isCustomerSite()) {
                  // Customer site logic
                } else {
                  navigate(`/tour/payment?${URLencode(parameters)}`, {
                    replace: true,
                  });
                }
              }
            } else {
              navigate("/order", { replace: true });
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        dispatch(setConfirmTourBookingModal(false));
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });

    setLoading && setLoading(false);
  };

  const initializePayment = async ({ setLoading, booking, callback }) => {
    const data = {
      packageBookingId: booking?._id,
      paymentMode: "Wallet",
      type: "admin",
    };

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/package`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data?.data;
            // callback(res);
            callback(bookingData || booking);
          } else {
            enqueueSnackbar(
              res?.data?.error ||
                res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const getTourBookings = async (params) => {
    const { limit = 0, offset = 0, searchBy, keyword } = params || {};

    const response = await fetchServer({
      method: "GET",
      url: `/product/v1/package?limit=${limit}&offset=${offset}${
        searchBy ? `&searchBy=orderId&keyword=${keyword}` : ""
      }`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res.data.data;
          }
        }
      })
      .catch((err) => {
        // console.log(err.message, err);
        // if (err.message === "Network Error!") {
        //   result["msg"] = "Network Error!";
        //   result["error"] = "Please check your connection.";
        // }
      });

    return response || [];
  };

  const getBooking = async () => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));

    await fetchServer({
      method: "GET",
      url: `/product/v1/package/${params?.bookingId}`,
    })
      .then((res) => {
        dispatch(setLoadingModal(false));
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            localStorage.setItem(
              `booking/${booking?._id || booking?.itemRef}`,
              JSON.stringify(booking)
            );
            // let url = `/tour/confirmation?${URLencode(parameters)}`;
            // window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    dispatch(setLoadingModal(false));
  };

  const confirmPackage = async ({ setLoading }) => {
    const params = URLdecode();

    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/payment/v1/package/confirm-booking/${params?.bookingId}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            localStorage.setItem(
              `booking/${booking?._id || booking?.itemRef}`,
              JSON.stringify(booking)
            );

            // let parameters = getParameters(booking);

            // let url = `/tour/confirmation?${URLencode(parameters)}`;
            // window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                res?.data?.error ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      })
      .finally(() => {
        // dispatch(setTourCancelModal(false));
        dispatch(setLoadingModal(false));
        setLoading && setLoading(false);
      });
  };

  const cancelAndQuote = async ({ callback, setLoading }) => {
    const params = URLdecode();

    // dispatch(setLoadingModal(true));
    const data = {
      supplier: params?.supplier,
      bookingId: params?.bookingId,
    };
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/package/cancel-quote`,
      data,
    })
      .then(async (res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            let cancelQuote = booking?.cancelQuote;
            dispatch(setCancelQuote(booking));
            dispatch(setTourCancelModal(true));
            if (cancelQuote?.status === "CANCELLABLE") {
              // await cancelAndRefund();
              callback &&
                callback({ reasons: booking?.cancelReasons, cancelQuote });
            } else {
              enqueueSnackbar("This tour is not cancellable!", {
                variant: "warning",
              });
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const cancelAndRefund = async ({ reason, booking, setLoading }) => {
    setLoading && setLoading(true);
    const data = {
      supplier: booking?.supplier,
      bookingId: booking?._id,
      cancelReason:
        reason || "Customer_Service.Unexpected_medical_circumstances",
    };

    await fetchServer({
      method: "POST",
      url: `/product/v1/package/cancel-confirmed-booking`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            // const booking = res?.data?.data;

            // let parameters = getParameters(booking);

            // let url = `/tour/confirmation?${URLencode(parameters)}`;
            // window.location.replace(url);
            window.location.reload();
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      })
      .finally(() => {
        dispatch(setTourCancelModal(false));
      });
    setLoading && setLoading(false);
  };

  const cancelPendingTourOrder = async ({
    booking,
    setLoading,
    callbackUrl,
  }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/package/cancel-booking/${booking?._id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data;
            callbackUrl && callbackUrl(bookingData);
            enqueueSnackbar("Booking cancelled successfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const confirmTourOrder = async ({ booking, setLoading, callbackUrl }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/admin-confirm-package?bookingId=${booking?._id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data;
            callbackUrl && callbackUrl(bookingData);
            enqueueSnackbar("Booking confirmed successfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  return {
    travellersString,
    getFullNameFromQuestions,
    getIndividualDetails,
    getGroupDetails,
    getAutocomplete,
    getTourPackages,
    getTourSuggestions,
    getTourActivity,
    getTourReviews,
    getTourAvailability,
    bookTourOrder,
    initializePayment,
    getTourBookings,
    getBooking,
    confirmPackage,
    cancelAndRefund,
    cancelAndQuote,
    cancelPendingTourOrder,
    confirmTourOrder,
  };
}
