import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  hotel: null,
  hotelAvailability: null,
  hotels: [],
  hotelBookings: [],
  availabilityLoading: false,
  compare: [],
  compareDisplay: false,
};

export const hotelDataSlice = createSlice({
  name: "hotelDataSlice",
  initialState,
  reducers: {
    setHotel: (state, action) => {
      state.hotel = action.payload;
    },
    setHotels: (state, action) => {
      state.hotels = action.payload;
    },
    setHotelAvailability: (state, action) => {
      state.hotelAvailability = action.payload;
    },
    setHotelBookings: (state, action) => {
      state.hotelBookings = action.payload;
    },
    setAvailabilityLoading: (state, action) => {
      state.availabilityLoading = action.payload;
    },
    setCompareDisplay: (state, action) => {
      state.compareDisplay = action.payload;
    },
    setCompare: (state, action) => {
      state.compare = action.payload;
      localStorage.setItem("compare", JSON.stringify(action.payload));
    },
  },
});

export const {
  setHotel,
  setHotels,
  setHotelAvailability,
  setHotelBookings,
  setAvailabilityLoading,
  setCompare,
  setCompareDisplay,
} = hotelDataSlice.actions;

export default hotelDataSlice.reducer;
