import React from "react";

function MenuOptions({ mobile, menu }) {
  return (
    <div
      className={`${
        !mobile ? "absolute right-2 top-full" : ""
      } p-2 flex flex-col z-10 items-start bg-white rounded-md shadow-xl min-w-[200px]`}
    >
      {/* <div className="hidden">
        <div className="" ref={pdfRef}>
          <AccommodationConfirmationPDFSection booking={order} />
        </div>
      </div> */}
      {menu
        ?.filter((item) => item?.visible)
        ?.map((item, index) => (
          <div
            key={index}
            className="w-full cursor-pointer flex items-center justify-start text-sm text-black py-1 px-2 transition-all hover:text-primary1"
            onClick={item.action}
          >
            <span className="text-start">{item.label}</span>
          </div>
        ))}
    </div>
  );
}

export default MenuOptions;
// Menu options
