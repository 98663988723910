import React, { useEffect, useState } from "react";
import useDateFormat from "../../../hooks/useDateFormat";
import moment from "moment/moment";

function InsuranceConfirmationTravellerSection({ booking }) {
  const { capitalizeFirstLetter } = useDateFormat();
  const [travellers, setTravellers] = useState([]);

  useEffect(() => {
    if (booking) {
      const travelers =
        booking?.orderData?.orderData?.requestBody?.personsInfo || [];
      setTravellers(
        travelers?.map((traveler) => {
          return { ...traveler, detail: false };
        })
      );
    }
  }, [booking]);

  if (!booking) return <></>;

  return (
    <div className="w-full flex flex-col">
      <span className="text-base font-bold lg:text-xl mb-2">Travellers</span>
      {travellers.map((traveller, travellerIndex) => {
        if (!traveller) return null;

        const travellerData = [
          { label: "Email", value: traveller?.email },
          { label: "Phone number", value: traveller?.phone },
          {
            label: "Type",
            value: capitalizeFirstLetter(traveller?.ageCategory || ""),
          },
        ];
        let travellerDetailData = [
          {
            label: "Date of birth",
            value: moment(traveller?.birthDate).format("YYYY-MM-DD"),
          },
          { label: "Nationality", value: traveller?.countryCode },
          {
            label: "Passport No",
            value: traveller?.passportNumber,
          },
          {
            label: "State",
            value: traveller?.state,
          },
          {
            label: "City",
            value: traveller?.city,
          },
          {
            label: "Gender",
            value: traveller?.gender,
          },
          {
            label: "Next of kin name",
            value: traveller?.nextOfKinName,
          },
          {
            label: "Next of kin relationship",
            value: traveller?.nextOfKinRelationship,
          },
          {
            label: "Next of kin phone",
            value: traveller?.nextOfKinContact,
          },
          {
            label: "Next of kin address",
            value: traveller?.nextOfKinAddress,
          },
        ];

        if (Array.isArray(traveller?.medical)) {
          travellerDetailData = travellerDetailData.concat(
            traveller?.medical.map((medical) => {
              return {
                label: medical?.question,
                value: String(medical?.answer),
              };
            })
          );
        }

        return (
          <div
            className="w-full rounded-md flex flex-col border border-gray-300 bg-white mb-4"
            key={travellerIndex}
          >
            <div className="w-full px-4 py-2 flex items-center gap-3 border-b border-gray-300">
              <div className="w-6 h-6 text-white bg-primary1 font-bold text-sm rounded-md flex items-center justify-center">
                {travellerIndex + 1}
              </div>
              <span className="font-bold text-primary1 text-sm lg:text-base">
                {traveller?.title} {traveller?.lastName}/{traveller?.firstName}{" "}
                {traveller?.middleName}
              </span>
              <div className="flex flex-1"></div>
              <button
                className="text-sm gap-1 items-center flex"
                onClick={() => {
                  setTravellers((prevTravellers) =>
                    prevTravellers.map((t) =>
                      t.id === traveller.id ? { ...t, detail: !t.detail } : t
                    )
                  );
                }}
              >
                <span className="text-primary1 text-sm">
                  {traveller?.detail ? "Hide details" : "See details"}
                </span>
                <img
                  src="/iconChevronUp.svg"
                  alt=""
                  className={`w-4 object-contain transition-all ${
                    traveller?.detail ? "" : "rotate-180"
                  }`}
                />
              </button>
            </div>
            <div className="flex flex-wrap justify-between gap-2 lg:gap-4 py-2 px-4">
              {travellerData.map((item, index) => (
                <div className="flex flex-col" key={index}>
                  <span className="text-sm text-gray-500">{item?.label}</span>
                  <span className="text-sm">{item?.value}</span>
                </div>
              ))}
            </div>
            {traveller?.detail && (
              <div className="flex flex-wrap justify-between gap-2 lg:gap-4 py-4 px-4 border-t bg-gray-50 border-gray-300 rounded-b-lg">
                {travellerDetailData.map((item, index) => (
                  <div
                    className="flex flex-col w-[31%] bg-white rounded-lg p-2 h-fit"
                    key={index}
                  >
                    <span className="text-sm text-gray-500">{item?.label}</span>
                    <span className="text-sm">{item?.value}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default InsuranceConfirmationTravellerSection;
