import React from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment";

function TourConfirmationBookedTourSectionPDFStatic(booking) {
  if (!booking) return "";

  // Create a static HTML for the hotel section.
  const staticHotelContent = (
    <div className="w-full flex flex-col mt-4">
      <div className="flex items-center gap-1 mb-1">
        <svg
          className="h-5 object-contain"
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.67052 10.7736C5.05941 11.2892 4.75391 11.9064 4.75391 12.6251V22.375C4.75391 23.0939 5.05941 23.7111 5.67052 24.2266C6.28168 24.7424 7.0132 25 7.86507 25H8.75391V10H7.86507C7.013 10 6.28154 10.2579 5.67052 10.7736Z"
            fill="url(#paint0_linear_8612_32744)"
          />
          <path
            d="M21.1287 7.18745C21.1287 6.85756 21.0103 6.57724 20.7733 6.34631C20.5365 6.11538 20.2486 6 19.9101 6H12.5976C12.2592 6 11.9714 6.11538 11.7344 6.34631C11.4974 6.57711 11.3789 6.85752 11.3789 7.18745V9.16657H9.75391V25H22.7539V9.16657H21.1287V7.18745ZM19.5038 9.16657H13.0039V7.58328H19.5038V9.16657Z"
            fill="url(#paint1_linear_8612_32744)"
          />
          <path
            d="M26.8373 10.7736C26.2262 10.2579 25.4946 10 24.6429 10H23.7539V25H24.6429C25.4947 25 26.2262 24.7424 26.8373 24.2266C27.4483 23.7111 27.7539 23.0939 27.7539 22.375V12.6251C27.7539 11.9064 27.4482 11.2892 26.8373 10.7736Z"
            fill="url(#paint2_linear_8612_32744)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_8612_32744"
              x1="6.75391"
              y1="10"
              x2="6.75391"
              y2="25"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#233767" />
              <stop offset="1" stop-color="#1B2B51" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_8612_32744"
              x1="16.2539"
              y1="6"
              x2="16.2539"
              y2="25"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#233767" />
              <stop offset="1" stop-color="#1B2B51" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_8612_32744"
              x1="25.7539"
              y1="10"
              x2="25.7539"
              y2="25"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#233767" />
              <stop offset="1" stop-color="#1B2B51" />
            </linearGradient>
          </defs>
        </svg>

        <span className="text-xl font-bold">
          {booking?.extraDetails?.productInfo?.title}
        </span>
      </div>
      <div className="w-full bg-blue-100 border-t-2 border-blue-600 p-4 flex flex-wrap gap-2 justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order ID</span>
          <span className="text-sm text-blue-600 font-bold">
            {booking?.itemRef}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order Date</span>
          <span className="text-sm font-bold">
            {moment(booking?.createdAt).format("DD/MM/YYYY HH:mm A")}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Start time</span>
          <span className="text-sm font-bold">
            {moment(booking?.travelDate).format("DD MMM")}
            {booking?.startTime
              ? `, ${moment(booking?.startTime, "HH:mm").format("h:mm A")}`
              : ""}
          </span>
        </div>
      </div>
    </div>
  );

  return ReactDOMServer.renderToStaticMarkup(staticHotelContent);
}

export default TourConfirmationBookedTourSectionPDFStatic;
