import React from "react";
import useOrders from "../../hooks/useOrders";
import { useSelector } from "react-redux";
import { configurations } from "../../configurations";

function OrderTypeSelection() {
  const { ticketType } = useSelector((state) => state.order);
  const { getOrdersCount, selectOrderType } = useOrders();

  const ticketTypes = [
    {
      name: "Flights",
      count: getOrdersCount().flights,
      onClick: () => selectOrderType("Flights"),
    },
    {
      name: configurations.stayKeyword,
      count: getOrdersCount().stays,
      onClick: () => selectOrderType(configurations.stayKeyword),
    },
    {
      name: configurations.tourKeyword,
      count: getOrdersCount().tour,
      onClick: () => selectOrderType(configurations.tourKeyword),
    },
    {
      name: configurations.insuranceKeyword,
      count: getOrdersCount().insurance,
      onClick: () => selectOrderType(configurations.insuranceKeyword),
    },
  ];

  return (
    <div className="w-full flex flex-col pt-10">
      <span className="font-black text-xl mb-4">Orders</span>
      <div className="flex items-center gap-4 justify-between overflow-x-scroll scroll-hide w-full">
        <div className="flex items-center gap-4">
          {ticketTypes.map(({ name, count, onClick }) => (
            <button
              key={name}
              onClick={onClick}
              className={`h-14 flex items-center justify-between gap-2 px-4 font-bold rounded-lg ${
                ticketType === name
                  ? "text-white bg-[#4E5662]"
                  : "text-[#818083] bg-[#F8F8F8]"
              }`}
            >
              <span className="text-xs md:text-base">{name}</span>
              <span
                className={`text-sm px-2 rounded-md py-1 ${
                  ticketType === name
                    ? "text-[#47A0FA] bg-[#5F6978]"
                    : "bg-[#F1F1F1]"
                }`}
              >
                {count}
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OrderTypeSelection;
