import React, { useState, useEffect } from 'react';
import SupplierSection from '../../../components/Settings/suppliers/SupplierSections';
import getSuppliersName from '../../../controllers/settings/supplier/getFlightSetting';
import getSuppliersList from '../../../controllers/settings/supplier/getSuppliersList';
import ManageHotelSuppliers from './Hotel/ManageHotelSuppliers';
import ManageTourSuppliers from './Tour/ManageTourSuppliers';
import getHotelSettings from '../../../controllers/hotelSettings/getHotelSettings';
import ManageInsuranceSuppliers from './Insurance/ManageInsuranceSuppliers';
import ToggleSwitch from '../../../components/Settings/suppliers/ToggleSwitch';
import updateFlightSetting from '../../../controllers/settings/supplier/updateFlightSetting';
import { enqueueSnackbar } from 'notistack';
import updateHotelSettings from '../../../controllers/hotelSettings/updateHotelSettings';

const Supplier = () => {
  const [loadings, setLoadings] = useState({flight: true,hotel: true});
  const [flightProviders, setFlightProviders] = useState({});
  const [data,setData] = useState([]);
  const [autoTicket,setAutoTicket] = useState(false);
  const [hotelAutoTicket,setHotelAutoTicket] = useState(false);
  const [packageAutoTicket,setPackageAutoTicket] = useState(false);

  const hotelProviders = [
    { title: 'Suppliers', providers: [{ initials: 'HB', name: 'Hotelbeds', toggle: false }, { initials: 'SA', name: 'Sabre' }] }
  ];

  const toursProviders = [
    { title: 'Suppliers', providers: [{ initials: 'HB', name: 'Hotelbeds', toggle: false }, { initials: 'SA', name: 'Sabre' }] }
  ];

  const getSuppliers = async () => {
    try {
      setLoadings(loadings => ({...loadings,flight: true})); // Set loading to true before fetching
      const res = await getSuppliersName();
      const res2 = await getSuppliersList();
      setFlightProviders({...res.data,list: res2?.data});
      setAutoTicket(res?.data?.autoTicket)
    } catch (error) {
      console.error("Error fetching suppliers", error);
    } finally {
      setLoadings(loadings => ({...loadings,flight: false})); // Set loading to false after fetching completes
    }
  };

  async function getHotelSuppliers() {
    setLoadings(loadings => ({...loadings,hotel: true}))
    const res = await getHotelSettings();
    console.log(res?.data)
    setLoadings(loadings => ({...loadings,hotel: false}))
    if(res.return) {
        setData({
          hotelSuppliers: Object.entries(res?.data?.hotelSuppliers || {})?.map(([key,obj]) => ({name: key, status: obj?.enabled, image: obj?.image})),
          insuranceSuppliers: Object.entries(res?.data?.insuranceSuppliers || {})?.map(([key,obj]) => ({name: key, status: obj?.enabled, image: obj?.image})),
          packageSuppliers: Object.entries(res?.data?.packageSuppliers || {})?.map(([key,obj]) => ({name: key, status: obj?.enabled, image: obj?.image})),
        })
        setHotelAutoTicket(res?.data?.hotelConfirmation === 'Automatic')
        setPackageAutoTicket(res?.data?.packageConfirmation === 'Automatic')
    }
  }

  useEffect(() => {
    getSuppliers();
    getHotelSuppliers();

    //eslint-disable-next-line
  }, []);

  const autoTicketingEnabledAirlines = flightProviders?.autoTicketingEnabledAirlines;

  const allSuppliers = flightProviders?.list?.map((obj) => ({
    code: obj?.value,
    autoTicketingEnabledAirlines: obj?.value === 'Intra1A' ? autoTicketingEnabledAirlines: [],
    name: obj?.name,
    icon: obj?.icon,
    enabled: flightProviders?.enabledSuppliers?.includes(obj?.value),
    autoTicketing: flightProviders?.autoTicketingEnabledSuppliers?.includes(obj?.value),
    expiration: flightProviders?.bookingExpiration[obj?.value] || null,
  }));

  const localFlights = flightProviders?.list
    ?.filter((obj) => obj?.value.startsWith('Intra1'))
    ?.map((obj) => ({
      code: obj?.value,
      autoTicketingEnabledAirlines: obj?.value === 'Intra1A' ? autoTicketingEnabledAirlines: [],
      name: obj?.name,
      icon: obj?.icon,
      enabled: flightProviders?.enabledSuppliers?.includes(obj?.value),
      autoTicketing: flightProviders?.autoTicketingEnabledSuppliers?.includes(obj?.value),
      expiration: flightProviders?.bookingExpiration[obj?.value] || null,
    }));

    
    async function handleAutoTicket(autoTicket) {
      const res = await updateFlightSetting({ autoTicket });
      setAutoTicket(autoTicket)
      if (res?.return) {
        getSuppliers();
      } else {
        enqueueSnackbar(res.msg, { variant: "error" });
        setTimeout(() => setAutoTicket(!autoTicket),500)
      }
    }
    async function handleHotelAutoTicket(autoTicket) {
      const res = await updateHotelSettings({ hotelConfirmation: autoTicket ? 'Automatic' : 'Manual' });
      setHotelAutoTicket(autoTicket)
      if (res?.return) {
        getHotelSuppliers();
      } else {
        enqueueSnackbar(res.msg, { variant: "error" });
        setTimeout(() => setHotelAutoTicket(!autoTicket),500)
      }
    }
    async function handlePackageAutoTicket(autoTicket) {
      const res = await updateHotelSettings({ packageConfirmation: autoTicket ? 'Automatic' : 'Manual' });
      setPackageAutoTicket(autoTicket)
      if (res?.return) {
        getHotelSuppliers();
      } else {
        enqueueSnackbar(res.msg, { variant: "error" });
        setTimeout(() => setPackageAutoTicket(!autoTicket),500)
      }
    }

  return (
    <div className="container mx-auto p-4">
      <div className='flex gap-4 justify-between items-center mb-6'>
        <h2 className="text-xl font-bold ">Flight</h2>
        <span className='flex items-center gap-2'>
          Auto Ticket <ToggleSwitch toggleValue={autoTicket} callback={(val) => handleAutoTicket(val)} />
        </span>
      </div>
      {/* Pass the loading state as well */}
      <SupplierSection allSuppliers={allSuppliers} localFlights={localFlights} loading={loadings.flight} 
        settings={flightProviders}
        reload={() => getSuppliers()}
      />
      
      

      <div className='flex gap-4 justify-between items-center mb-6'>
        <h2 className="text-xl font-bold">Hotel</h2>
        <span className='flex items-center gap-2'>
          Auto Confirmation <ToggleSwitch toggleValue={hotelAutoTicket} callback={(val) => handleHotelAutoTicket(val)} />
        </span>
      </div>
      
      <ManageHotelSuppliers loading={loadings?.hotel} data={data?.hotelSuppliers} reload={() => getHotelSuppliers()}/>
      
      <div className='flex gap-4 justify-between items-center mb-6'>
        <h2 className="text-xl font-bold my-6">Tours</h2>
        <span className='flex items-center gap-2'>
          Auto Confirmation <ToggleSwitch toggleValue={packageAutoTicket} callback={(val) => handlePackageAutoTicket(val)} />
        </span>
      </div>
      
      <ManageTourSuppliers loading={loadings?.hotel} data={data?.packageSuppliers} reload={() => getHotelSuppliers()}/>

      <h2 className="text-xl font-bold my-6">Insurance</h2>
      
      <ManageInsuranceSuppliers loading={loadings?.hotel} data={data?.insuranceSuppliers} reload={() => getHotelSuppliers()}/>


      {/* <hr className="hidden sm:block my-5" />
      <h2 className="text-xl font-bold mb-6">Hotels</h2>
   
      <SupplierSection allSuppliers={allSuppliers} loading={loading} /> */}
    </div>
  );
};


export default Supplier;
