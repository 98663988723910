import { createSlice } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";

let initialState = {
  policies: [],
  comparePolicies: [],
  sharePolicies: [],
  policyCompareDisplay: false,
};

export const insuranceSlice = createSlice({
  name: "insuranceSlice",
  initialState,
  reducers: {
    setPolicies: (state, action) => {
      state.policies = action.payload;
    },
    setComparePolicies: (state, action) => {
      let compareData = action?.payload;

      if (compareData?.length <= 4) {
        state.comparePolicies = compareData;
        state.policyCompareDisplay = true;
        localStorage.setItem("policyCompare", JSON.stringify(compareData));
      } else {
        enqueueSnackbar("Only 4 policies are allowed to compare", {
          variant: "warning",
        });
      }
    },
    setPolicyCompareDisplay: (state, action) => {
      state.policyCompareDisplay = action.payload;
    },
    setSharePolicies: (state, action) => {
      state.sharePolicies = action.payload;
    },
  },
});

export const {
  setPolicies,
  setSharePolicies,
  setComparePolicies,
  setPolicyCompareDisplay,
} = insuranceSlice.actions;

export default insuranceSlice.reducer;
// console.log("merge solving attempt")
