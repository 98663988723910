import React, { useRef } from "react";
import { allStatuses } from "../OrderList";
import usePDF from "../../../hooks/flight/usePDF";
import AccommodationConfirmationPDFSection from "../../pdf/accommodationPDF/AccommodationConfirmationPDFSection";
import MenuOptions from "./MenuOptions";
import { useDispatch, useSelector } from "react-redux";
import { useHotels } from "../../../hooks/useHotels";
import { setHotelOrders } from "../../../redux/reducers/orderSlice";

export default function StayMenu({
  order,
  mobile,
  setModifyHotelOrder,
  setCancelHotelOrder,
  setHotelPriceDetailModal,
  setViewHotelOrder,
  setCancelPendingHotelOrder,
  setLoading,
  setPayHotelOrder,
}) {
  const { confirmHotelOrder } = useHotels();
  const dispatch = useDispatch();
  const { hotelOrders } = useSelector((state) => state.order);
  const { handlePDFDownload } = usePDF();
  const pdfRef = useRef(null);

  const handleExport = async () => {
    // dispatch(setHotelConfirmationPdfModal(true));
    if (pdfRef?.current) {
      let string = pdfRef.current.outerHTML;
      setLoading && setLoading(true);
      await handlePDFDownload(string, {
        fileName: order?.bookingId || "",
      }).finally(() => setLoading && setLoading(false));
    }
  };

  const isVisible = (type) => {
    switch (type) {
      case "View Booking":
        return true;
      // return allStatuses
      //   .filter(
      //     (status) =>
      //       !["Pending", "Paid", "Booked", "Failed"].includes(status)
      //   )
      //   ?.includes(order?.status);
      case "PDF":
        return true;
      case "Pay Booking":
        return ["Booked", "Pending", "Not Paid"]?.includes(order?.status);
      case "Modify Booking":
        return (
          allStatuses?.includes(order?.status) &&
          order?.holdBookingRes?.details?.modificationPolicies?.modification
        );
      case "Cancel Booking":
        return (
          allStatuses?.includes(order?.status) &&
          order?.holdBookingRes?.details?.modificationPolicies?.cancellation
        );
      case "Cancel Pending Booking":
        return ["Not Paid", "Pending"]?.includes(order?.status);
      case "Confirm Booking":
        return ["Awaiting Admin", "AwaitingAdmin"]?.includes(order?.status);
      case "Send Invoice":
        return allStatuses?.includes(order?.status);
      case "Price Details":
        return allStatuses?.includes(order?.status);
      // case "Modify Reference":
      //   return allStatuses?.includes(order?.status);
      default:
        return false;
    }
  };

  const menu = [
    {
      label: "View Booking",
      action: () => {
        setViewHotelOrder && setViewHotelOrder(true);
      },
      visible: isVisible("View Booking"),
    },
    {
      label: "Pay Booking",
      action: () => {
        setPayHotelOrder && setPayHotelOrder(true);
        // let parameters = {
        //   bookingId: order?._id,
        //   bookId: order?.bookingId,
        //   bookingDate: order?.createdAt,
        //   price: order?.grandTotal,
        // };
        // navigate(`/accommodation/payment?${URLencode(parameters)}`);
      },
      visible: isVisible("Pay Booking"),
    },
    {
      label: "Modify Booking",
      action: () => {
        setModifyHotelOrder && setModifyHotelOrder(true);
      },
      visible: isVisible("Modify Booking"),
    },

    // {
    //   label: "Send Invoice",
    //   action: () => {},
    //   visible: isVisible("Send Invoice"),
    // },
    {
      label: "Price Details",
      action: () => {
        setHotelPriceDetailModal && setHotelPriceDetailModal(true);
      },
      visible: isVisible("Price Details"),
    },
    {
      label: "Download PDF",
      action: () => {
        handleExport();
      },
      visible: isVisible("PDF"),
    },
    {
      label: "Confirm Booking",
      action: () => {
        confirmHotelOrder({
          booking: order,
          setLoading,
          callbackUrl: (data) => {
            dispatch(
              setHotelOrders(
                hotelOrders?.map((item) =>
                  item?._id === data?._id ? data : item
                )
              )
            );
          },
        });
      },
      visible: isVisible("Confirm Booking"),
    },
    // {
    //   label: "Modify Reference",
    //   action: () => {},
    //   visible: isVisible("Modify Reference"),
    // },
    {
      label: "Cancel Booking",
      action: () => {
        setCancelHotelOrder && setCancelHotelOrder(true);
      },
      visible: isVisible("Cancel Booking"),
    },
    {
      label: "Cancel Booking",
      action: () => {
        setCancelPendingHotelOrder && setCancelPendingHotelOrder(true);
      },
      visible: isVisible("Cancel Pending Booking"),
    },
  ];

  return (
    <>
      <div className="hidden">
        <div className="" ref={pdfRef}>
          <AccommodationConfirmationPDFSection booking={order} />
        </div>
      </div>
      <MenuOptions mobile={mobile} menu={menu} />
    </>
  );
}
// Stay menu
