import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import ModalContainer from "../common/ModalContainer";
import moment from "moment";
import { formatMoney } from "../../features/utils/formatMoney";

export function AccommodationOrderPriceDetailModal({ open, setOpen, booking }) {
  const handleClose = () => {
    setOpen(false);
  };

  const formatDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return "";
    const start = moment(startDate).format("ddd, DD MMM");
    const end = moment(endDate).format("ddd, DD MMM YYYY");
    const nights = moment(endDate).diff(moment(startDate), "days");
    return `${start} - ${end} (${nights} Night${nights > 1 ? "s" : ""})`;
  };

  const roomDetails = booking?.requestBody?.rooms?.map((room) => ({
    roomType: room?.name || "Unknown Room Type",
    board: room?.type || "Unknown Room Type",
    price: room?.price || 0,
    adults: room?.guests?.filter((guest) => guest.type === "adult").length || 0,
    children:
      room?.guests?.filter((guest) => guest.type === "child").length || 0,
    leadTraveller:
      room?.guests?.find((guest) => guest.isLead)?.firstName +
        " " +
        room?.guests?.find((guest) => guest.isLead)?.lastName ||
      "Lead Traveller",
  }));

  const totalPrice = booking?.fareTotalPrice || 0;
  const startDate = booking?.requestBody?.start_date;
  const endDate = booking?.requestBody?.end_date;

  const getPassengerMix = () => {
    const rooms = booking?.requestBody?.rooms;
    if (!rooms || !Array.isArray(rooms)) return "";

    let totalAdults = 0;
    let totalChildren = 0;

    rooms.forEach((room) => {
      const adults =
        room?.guests?.filter((guest) => guest.type === "adult").length || 0;
      const children =
        room?.guests?.filter((guest) => guest.type === "child").length || 0;

      totalAdults += adults;
      totalChildren += children;
    });

    const adultString = `${totalAdults} Adult${totalAdults > 1 ? "s" : ""}`;
    const childString = `${totalChildren} Child${
      totalChildren > 1 ? "ren" : ""
    }`;

    return `${adultString}${totalChildren > 0 ? `, ${childString}` : ""}`;
  };

  const getAdultNames = () => {
    const rooms = booking?.requestBody?.rooms;
    if (!rooms || !Array.isArray(rooms)) return [];

    const adultNames = [];

    rooms.forEach((room) => {
      room?.guests?.forEach((guest) => {
        if (guest.type === "adult") {
          adultNames.push(`${guest.firstName} ${guest.lastName}`);
        }
      });
    });

    return adultNames;
  };

  return (
    <ModalContainer
      open={open}
      setOpen={setOpen}
      title="Price Details and Commission"
    >
      <div>
        <div className="flex items-center gap-2 mb-2">
          <img
            src="/IconHotel2.svg"
            alt="hotel"
            className="h-5 object-contain"
          />
          <span className="font-bold text-xl sm:text-2xl">
            {booking?.requestBody?.name ||
              booking?.holdBookingRes?.name ||
              "Unknown Hotel"}
          </span>
        </div>
        {booking?.holdBookingRes?.address && (
          <div className="flex items-center gap-2 -ml-1">
            <img
              src="/IconLocationPrimary.svg"
              alt="location pin"
              className="h-5 object-contain"
            />
            <span className="text-darkGray text-sm">
              {booking?.holdBookingRes?.address || "Location not specified"}
            </span>
          </div>
        )}
      </div>

      <div className="text-darkGray text-sm sm:text-base">
        {roomDetails?.map((room, index) => (
          <div key={index} className="mb-4 flex flex-col text-gray-600">
            <span className="text-primaryText space-x-1 mb-2">
              <span>{`1x ${room.roomType},`}</span>
              <span className="font-bold">{room?.board}</span>
            </span>
            <span>
              <span className="font-bold text-gray-500">
                Confirmation reference from hotel:{" "}
              </span>
              Service is confirmed, but reference is not available yet. Please
              check it later
            </span>
          </div>
        ))}
      </div>

      <span className="text-sm sm:text-base">
        {formatDateRange(startDate, endDate)}
      </span>

      <div className="text-sm sm:text-base flex flex-col">
        <span className="mb-3">{getPassengerMix()}</span>
        {getAdultNames()?.map((name, nameIndex) => (
          <div key={nameIndex} className="mb-1">
            <div className="flex items-center gap-2">
              <Icon icon="solar:user-bold" />
              <span className="text-darkGray flex">
                {name}{" "}
                {nameIndex === 0 && (
                  <span className="flex ml-1 text-gray-500">
                    (Lead traveller)
                  </span>
                )}
              </span>
            </div>
          </div>
        ))}
      </div>

      <div className="border-t border-[#EFECEC]"></div>
      {booking?.cancellable && (
        <div className="flex items-center gap-2 text-[#1EA994]">
          <Icon icon="material-symbols:check" className="text-xl" />
          <span className="text-xs sm:text-sm">Free cancellation</span>
        </div>
      )}

      <div className="flex justify-between items-center text-sm sm:text-base">
        <div className="flex flex-col">
          <span className="font-bold">Services</span>
          <span className="text-gray-400">All your products</span>
        </div>
        <span className="font-bold">Recommended Final Price</span>
      </div>

      <div className="border-t border-[#EFECEC]"></div>
      {roomDetails?.map((room, index) => (
        <div
          className="flex justify-between items-center text-sm sm:text-base"
          key={index}
        >
          <span className="text-darkGray font-bold">{room?.roomType}</span>
          <span className="font-bold">{formatMoney(room?.price)}</span>
        </div>
      ))}

      <div className="border-t border-[#EFECEC]"></div>
      <div className="flex justify-between items-center">
        <div>
          <span className="sm:text-[2rem]">Total:</span>
        </div>
        <div className="text-end text-sm sm:text-base flex flex-col items-end">
          <span className="font-bold text-xl sm:text-2xl">
            {formatMoney(totalPrice)}
          </span>
          <span className="text-sm text-gray-400">Total traveller price</span>
        </div>
      </div>

      <div className="flex gap-6 items-center">
        <button
          onClick={() => setOpen(false)}
          className="py-3 sm:py-4 px-6 sm:px-[4.6875rem] text-xs sm:text-lg font-bold"
        >
          Cancel
        </button>
        <button
          onClick={handleClose}
          className="bg-primary1 py-3 sm:py-4 grow text-secondary font-bold text-xs sm:text-lg rounded-[4px] sm:rounded-lg"
        >
          Close
        </button>
      </div>
    </ModalContainer>
  );
}
