import React, { useState } from "react";
import useDateFormat from "../../../hooks/useDateFormat";
import useToursUI from "../../../hooks/useToursUI";
import moment from "moment";
import { tourPassengerMix } from "../../../controllers/tourUIController";

function TourConfirmationTourDetailSection({ booking }) {
  if (!booking) return <></>;

  return (
    <div className="w-full flex flex-col mt-4 mb-4 lg:mb-10 gap-2">
      {/* {booking?.requestBody?.rooms?.map((room, index) => (
        <ConfirmationRoom
          room={room}
          key={index}
          index={index}
          booking={booking}
        />
      ))} */}
      <ConfirmationTour booking={booking} />
    </div>
  );
}

export default TourConfirmationTourDetailSection;

const ConfirmationTour = ({ booking }) => {
  const [details, setDetails] = useState(true);
  const { capitalizeFirstLetter } = useDateFormat();
  const { getTourDuration, getTourLocation, tourLanguages } = useToursUI();

  const detailData = [
    {
      icon: "/tourIconDate.svg",
      value: `${moment(booking?.travelDate).format("dddd, DD MMM")} ${
        booking?.startTime
          ? `, ${moment(booking?.startTime, "HH:mm").format("h:mm A")}`
          : ""
      }`,
    },
    {
      icon: "/IconTime.svg",
      value: getTourDuration({ tour: booking?.extraDetails?.productInfo }),
    },
    {
      icon: "/tourIconPerson.svg",
      value: tourPassengerMix({ item: booking?.holdBookingRes?.items?.at(0) }),
    },
  ];

  const moreDetailData = [
    {
      icon: "/IconAudio.svg",
      value:
        capitalizeFirstLetter(
          booking?.extraDetails?.languageGuide?.legacyGuide?.replace(
            "es/",
            ""
          ) || ""
        ) || "Guide not available",
    },
    {
      icon: "/tourIconGlobe.svg",
      value: tourLanguages({
        tour: { languageGuides: [booking?.extraDetails?.languageGuide] },
      }),
    },
    {
      icon: "/tourIconPerson.svg",
      value: tourPassengerMix({ item: booking?.holdBookingRes?.items?.at(0) }),
    },
  ];

  return (
    <div className="w-full p-4 bg-white border flex-wrap border-gray-300 rounded-lg items-center flex gap-2 justify-between">
      <div className="w-6 h-6 text-white bg-primary1 font-bold text-sm rounded-md flex items-center justify-center">
        1
      </div>
      <div className="flex flex-col items-start w-[350px] flex-1">
        <div className="flex items-center gap-1">
          <img src="/IconTour2.svg" alt="" className="h-5 object-contain" />
          <span className=" font-bold">
            {booking?.extraDetails?.productInfo?.title}
          </span>
        </div>
        <div className="flex items-center gap-1 -ml-1">
          <img
            src="/IconLocationPrimary.svg"
            alt=""
            className="w-5 object-contain"
          />
          <span className=" text-sm text-[#6D7C94]">
            {getTourLocation({ tour: booking?.extraDetails?.productInfo })}
          </span>
        </div>
      </div>
      <div className="flex w-full lg:w-[30%] justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Duration</span>
          <span className="text-sm font-bold">
            {getTourDuration({ tour: booking?.extraDetails?.productInfo })}
          </span>
        </div>
        <div className="flex flex-col">
          <button
            className="flex items-center"
            onClick={() => setDetails(!details)}
          >
            <span className="text-sm text-gray-500">Details</span>
            <img
              src="/IconChevronDown.svg"
              alt=""
              className={`w-4 object-contain ${details && "rotate-180"}`}
            />
          </button>
          <div></div>
        </div>
      </div>
      {details && (
        <div className="w-full flex justify-between flex-col gap-2 border-t pt-2 border-gray-300 lg:px-8">
          {detailData?.map((item, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <img src={item?.icon} alt="" className="w-4 h-4 object-contain" />
              <span className="text-sm text-gray-500">{item.value}</span>
            </div>
          ))}
        </div>
      )}
      {details && (
        <div className="w-full flex flex-wrap gap-2 lg:gap-4 border-t pt-2 border-gray-300 lg:px-8">
          {moreDetailData?.map((item, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <img src={item?.icon} alt="" className="w-4 h-4 object-contain" />
              <span className="text-sm text-gray-500">{item.value}</span>
            </div>
          ))}
        </div>
      )}
      {details && (
        <div className="w-full flex flex-wrap gap-2 lg:gap-4 pt-2 lg:px-8">
          {booking?.extraDetails?.productInfo?.cancellationPolicy && (
            <div className="flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2">
              <span className="text-base font-bold mb-1">
                Order cancellation policy
              </span>
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <img src="/IconCheckmark1.svg" alt="" />
                <span className="text-xs">
                  {
                    booking?.extraDetails?.productInfo.cancellationPolicy
                      .description
                  }
                </span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
