import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export default function ModifyHotelBookingAllocationChangeConfirmation() {
  const { checkedRoom, checkedHotel } = useModifyBookingModalContext();

  const currentGuests = checkedRoom?.room?.guests || [];
  const currentAdults = currentGuests.filter(
    (guest) => guest.type === "adult"
  ).length;
  const currentChildren = currentGuests.filter(
    (guest) => guest.type === "child"
  ).length;

  const newAdults = checkedRoom?.adults || 0;
  const newChildren = checkedRoom?.children?.length || 0;

  const formatAllocation = (adults, children) => {
    let result = [];
    if (adults > 0) result.push(`${adults} Adult${adults > 1 ? "s" : ""}`);
    if (children > 0)
      result.push(`${children} Child${children > 1 ? "ren" : ""}`);
    return result.join(", ");
  };

  return (
    <div className="flex flex-col sm:flex-row gap-6 w-full">
      <div className="border border-[#EFECEC] rounded-lg p-6 w-full">
        <div className="bg-primaryText text-white px-6 py-1.5 text-sm font-semibold rounded-lg max-w-[174px] mb-8">
          Current allocation
        </div>
        <div className="text-sm sm:text-base">
          <p className="mb-2">{checkedRoom?.label || "Room Type"}</p>
          <p className="text-darkGray">
            {formatAllocation(currentAdults, currentChildren)}
          </p>
        </div>
      </div>

      <div className="border border-[#FAA108] rounded-lg p-6 w-full">
        <div className="bg-[#FAA108] text-white px-6 py-1.5 text-sm font-semibold rounded-lg max-w-[182px] mb-8">
          Modified allocation
        </div>
        <div className="text-sm sm:text-base">
          <p className="mb-2">{checkedRoom?.label || "Room Type"}</p>
          <p className="text-darkGray">
            {formatAllocation(newAdults, newChildren)}
          </p>
        </div>
      </div>
    </div>
  );
}
