import { createSlice } from "@reduxjs/toolkit";

let defaultData = {
  filterData: {
    date: null,
    country: null,
    day: null,
    price: null,
    time: null,
    duration: null,
    destination: null,
    language: [],
    interest: null,
    service: [],
    category: [],
    attractions: [],
    cancellation: null,
    actualMaxPrice: 0,
    actualMinPrice: 0,
    priceMax: 0,
    priceMin: 0,
  },
  filterStayData: {
    date: null,
    startDate: null,
    endDate: null,
    latitude: null,
    longitude: null,
    location: null,
    propertyName: null,
    landmark: "",
    mapLocation: null,
    property: null,
    price: null,
    popular: null,
    rating: null,
    guestRating: null,
    day: null,
    payment: null,
    packaging: null,
    amenities: [],
    board: [],
    zone: [],
    accomodationType: [],
    establishmentProfile: [],
    moreAmenities: false,
    actualMaxPrice: 0,
    actualMinPrice: 0,
    priceMax: 0,
    priceMin: 0,
    proximityOn: false,
    radius: 10,
  },
  filterInsuranceData: {
    sort: null,
    supplier: null,
    flexibility: null,
    actualMaxPrice: 0,
    actualMinPrice: 0,
    priceMax: 0,
    priceMin: 0,
  },
  tourFilterActive: false,
  stayFilterActive: false,
  insuranceFilterActive: false,
  hotelSort: "suggestion",
  tourSort: "price:asc",
  tourView: "list",
};
let initialState = {
  ...defaultData,
};

export const filterSlice = createSlice({
  name: "tourFilter",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filterData = action.payload;
    },
    setStaysFilter: (state, action) => {
      state.filterStayData = { ...state.filterStayData, ...action.payload };
    },
    setToursFilter: (state, action) => {
      state.filterData = { ...state.filterData, ...action.payload };
    },
    setInsuranceFilter: (state, action) => {
      state.filterInsuranceData = {
        ...state.filterInsuranceData,
        ...action.payload,
      };
    },
    setTourFilterActive: (state, action) => {
      state.tourFilterActive = action.payload;
    },
    setStayFilterActive: (state, action) => {
      state.stayFilterActive = action.payload;
    },
    setInsuranceFilterActive: (state, action) => {
      state.insuranceFilterActive = action.payload;
    },
    clearFilter: (state, action) => {
      state.filterData = {
        ...state.filterData,
        day: null,
        price: null,
        time: null,
        duration: null,
        destination: null,
        language: [],
        interest: null,
        service: [],
        category: [],
        attractions: [],
        cancellation: null,
        priceMax: 100,
        priceMin: 0,
        radius: 10,
      };
    },
    clearFilterStays: (state, action) => {
      state.filterStayData = {
        ...state.filterStayData,
        propertyName: "",
        landmark: "",
        mapLocation: "",
        property: null,
        price: null,
        popular: null,
        rating: null,
        guestRating: null,
        day: null,
        payment: null,
        packaging: null,
        Amenities: [],
        board: [],
        zone: [],
        accomodationType: [],
        establishmentProfile: [],
        priceMax: 100,
        priceMin: 0,
      };
    },
    clearFilterInsurance: (state, action) => {
      state.filterInsuranceData = {
        ...state.filterInsuranceData,
        sort: null,
        supplier: null,
        flexibility: null,
        actualMaxPrice: 0,
        actualMinPrice: 0,
        priceMax: 0,
        priceMin: 0,
      };
    },
    setHotelSort: (state, action) => {
      state.hotelSort = action.payload;
    },
    setTourSort: (state, action) => {
      state.tourSort = action.payload;
    },
    setTourView: (state, action) => {
      state.tourView = action.payload;
    },
  },
});

export const {
  setFilter,
  clearFilter,
  setStaysFilter,
  clearFilterStays,
  clearFilterInsurance,
  setTourFilterActive,
  setStayFilterActive,
  setHotelSort,
  setTourSort,
  setTourView,
  setToursFilter,
  setInsuranceFilter,
  setInsuranceFilterActive,
} = filterSlice.actions;

export default filterSlice.reducer;
