import fetchServer from "../fetchServer";


export default async function getBookings(params) {
  const {limit=0,offset=0,searchBy,keyword} = params || {}
  var result = {return: 0,msg: 'Error',data: []}

  await fetchServer({method: "GET",url: `/product/v1/book?populate=account&populate=flightBooking&limit=${limit}&offset=${offset}&booking[]=flight${searchBy ? `&searchBy=${searchBy}&keyword=${keyword}`:''}`})
  .then((res) => {
    if(res) {
      if(res.status === 200) {
        result = {return: 1,msg: "Successfull",data: res.data.data};
      } else if (res?.data?.error) result["msg"] = res.data.error;
    } 
  })
  .catch((err) => {
    console.log(err.message,err);
    if(err.message === 'Network Error!') {
      result['msg'] = 'Network Error!';
      result['error'] = 'Please check your connection.';
    }
  })

  return result;
}