import React from "react";
import ModalContainer from "../../common/ModalContainer";
import AccomodationConfirmationBookedHotelSection from "./AccomodationConfirmationBookedHotelSection";
import AccommodationConfirmationRoomsSection from "./AccommodationConfirmationRoomsSection";
import AccommodationConfirmationTravellerSection from "./AccommodationConfirmationTravellerSection";

export function AccommodationOrderDetailsModal({ open, setOpen, booking }) {
  return (
    <ModalContainer open={open} setOpen={setOpen} title="Stay order detail">
      <AccomodationConfirmationBookedHotelSection booking={booking} />
      <AccommodationConfirmationRoomsSection booking={booking} />
      <AccommodationConfirmationTravellerSection booking={booking} />
      {/* <div className="w-full flex flex-col" >
        
      </div> */}
    </ModalContainer>
  );
}
