import React from "react";
import { generateHotelPDFCSS } from "../../../controllers/cssController";
import InsuranceConfirmationBookedPolicySectionPDFStatic from "./InsuranceConfirmationBookedPolicySectionPDFStatic";
import InsuranceConfirmationTravellerPDFStatic from "./InsuranceConfirmationTravellerPDFStatic";

function InsuranceConfirmationPDFSection({ booking }) {
  const insuranceSectionStatic =
    InsuranceConfirmationBookedPolicySectionPDFStatic(booking);
  const travellerSectionStatic =
    InsuranceConfirmationTravellerPDFStatic(booking);

  const combinedStaticMarkup = `
      <div class="flex flex-col gap-4">
        ${insuranceSectionStatic}
        ${travellerSectionStatic}
      </div>
    `;

  return (
    <div className="insurance-doc-template">
      <div dangerouslySetInnerHTML={{ __html: combinedStaticMarkup }} />
      <style>{generateHotelPDFCSS()}</style>
    </div>
  );
}

export default InsuranceConfirmationPDFSection;
