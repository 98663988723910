import React, { useRef, useState, useEffect } from "react";
import { Icon } from "@iconify/react";

const Dropdown = ({ label, options, onSelect, placeholder, selectedValue }) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    onSelect(option.value);
    setIsOpen(false);
  };

  const selectedOption = options.find(
    (option) => option.value === selectedValue
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full z-10" ref={dropdownRef}>
      {label && (
        <label className="block text-sm text-darkGray mb-1">{label}</label>
      )}

      <button
        onClick={toggleDropdown}
        className="flex items-center justify-between w-full px-4 py-2 border-2 border-gray-300 rounded-md"
      >
        <span className={selectedOption ? "text-black" : "text-gray-400"}>
          {selectedOption ? selectedOption.label : placeholder}
        </span>
        <Icon
          className={`text-lg text-gray-500 transform transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          icon="mi:caret-down"
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-2 w-full bg-white rounded-md shadow-md border border-gray-200">
          <ul
            className="max-h-[100px] overflow-y-auto"
            style={{
              transform: "translateZ(0)",
              WebkitFontSmoothing: "antialiased",
            }}
          >
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => handleOptionClick(option)}
                className="cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100"
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
