import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import { ModifyHotelBookingDateModification } from "./ModifyHotelBookingDateModification";
import { ModifyHotelBookingNameModification } from "./ModifyHotelBookingNameModification";
import { ModifyHotelBookingAllocationModification } from "./ModifyHotelBookingAllocationModification";
import { ModifyHotelBookingRequestModification } from "./ModifyHotelBookingRequestModification";

export const ModifyHotelBookingModification = () => {
  const { data } = useModifyBookingModalContext();

  return (
    <div className="flex flex-col">
      {data?.type === "traveler-names" && (
        <ModifyHotelBookingNameModification />
      )}
      {data?.type === "allocation" && (
        <ModifyHotelBookingAllocationModification />
      )}
      {data?.type === "requests" && <ModifyHotelBookingRequestModification />}
      {data?.type === "dates" && <ModifyHotelBookingDateModification />}
    </div>
  );
};
