import React, { useState, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const AccommodationDateRangePicker = ({
  startDate,
  endDate,
  onDateChange,
  placeholder = "Select dates",
  label,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [range, setRange] = useState({
    startDate: startDate ? new Date(startDate) : new Date(),
    endDate: endDate ? new Date(endDate) : addDays(new Date(), 7),
    key: "selection",
  });

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (ranges) => {
    setRange(ranges.selection);
    onDateChange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  };

  // Calculate nights
  const calculateNights = (start, end) => {
    const diffInTime = end - start;
    return diffInTime > 0 ? Math.ceil(diffInTime / (1000 * 3600 * 24)) : 0;
  };

  const nights = calculateNights(range.startDate, range.endDate);

  return (
    <div className="relative w-full flex-col" ref={dropdownRef}>
      {label && (
        <div className="flex justify-between w-full">
          {label && (
            <label className="block text-gray-400 text-sm mb-1">{label}</label>
          )}
          <label className="block text-gray-400 text-sm mb-1 w-20">
            Nights
          </label>
        </div>
      )}
      <div
        className="flex items-center gap-2 h-11 border bg-white border-gray-300 rounded-md cursor-pointer w-full"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="flex flex-1 items-center px-2 gap-2">
          <img src="/IconDate.svg" alt="Location Icon" className="h-4 w-4" />
          <span className="text-sm">
            {moment(range.startDate).format("MMM DD, YYYY")} -{" "}
            {range.endDate
              ? moment(range.endDate).format("MMM DD, YYYY")
              : placeholder}
          </span>
        </div>
        <div className="w-20 h-full px-2 border-l border-gray-300 flex items-center justify-evenly">
          <span className="text-sm">{nights}</span>
          <img
            src="/IconArrowHeadDark.svg"
            alt=""
            className="w-4 object-contain rotate-180"
          />
        </div>
      </div>

      {dropdownOpen && (
        <div className="absolute top-14 left-0 w-72 bg-white border shadow-lg rounded-md z-10">
          <DateRange
            editableDateInputs={true}
            onChange={handleSelect}
            moveRangeOnFirstSelection={false}
            ranges={[range]}
            className="date-range-picker"
            minDate={new Date()}
          />
        </div>
      )}
    </div>
  );
};

export default AccommodationDateRangePicker;
