import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedTicketsSortHeader,
  setTicketFilterType,
  setTicketsPage,
  setTicketsSortHeader,
  setTicketStatusType,
  setTicketType,
} from "../redux/reducers/orderSlice";
import { templateFlightOrderData } from "../data/order/ordersDataV2";
import useOrdersSort from "./useOrdersSort";
import sortArrayWithIndices from "../features/utils/sortWithIndice";
import { templateTicketsOrderData } from "../data/order/ordersDataTickets";
import { templateFlightOrderVoidData } from "../data/order/ordersVoidData";
import { templateFlightOrderRefundData } from "../data/order/ordersRefundData";
import { templateFlightOrderReissueData } from "../data/order/ordersReissueData";
import { templateStayOrderData } from "../data/order/stays/stayOrdersData";
import { templateTourOrderData } from "../data/order/tours/tourOrdersData";
import { configurations } from "../configurations";
import { templateInsuranceOrderData } from "../data/order/insurance/insuranceOrdersData";

export default function useOrders() {
  const { sortFlightOrders } = useOrdersSort();
  const dispatch = useDispatch();
  const {
    ticketsSortHeader,
    selectedTicketsSortHeader,
    ticketType,
    flightOrders,
    voidOrders,
    refundOrders,
    reissueOrders,
    hotelOrders,
    tourOrders,
    insuranceOrders,
    ticketFilterType,
    ticketSearchValue,
    ticketStatusType,
  } = useSelector((state) => state.order);

  const getOrdersCount = () => {
    return {
      flights: flightOrders?.length || 0,
      stays: hotelOrders?.length || 0,
      tour: tourOrders?.length || 0,
      insurance: insuranceOrders?.length || 0,
    };
  };

  const selectOrderType = (orderType) => {
    dispatch(setTicketType(orderType));
    dispatch(setTicketsPage(1));
    if (orderType === "Stays") {
      dispatch(setTicketFilterType(hotelFilterType[0]?.name));
    } else if (orderType === "Tour" || orderType === "Tours") {
      dispatch(setTicketFilterType(tourFilterType[0]?.name));
    } else if (orderType === configurations.insuranceKeyword) {
      dispatch(setTicketFilterType(insuranceFilterType[0]?.name));
    }
  };

  const ticketFilters = {
    flight: [
      {
        name: "All",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("All"));
        },
      },
      {
        name: "Pending Payment",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Pending Payment"));
        },
      },
      {
        name: "Paid",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Paid"));
        },
      },
      {
        name: "Expired",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Expired"));
        },
      },
      {
        name: "Cancelled",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Cancelled"));
        },
      },
      {
        name: "Ticketing",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Ticketing"));
        },
      },
      {
        name: "Re-issue",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Re-issue"));
        },
      },
      {
        name: "Refund",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Refund"));
        },
      },
      {
        name: "Void",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Void"));
        },
      },
      {
        name: "Completed",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Completed"));
        },
      },
    ],
    ticket: [
      {
        name: "All",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("All"));
        },
      },
      {
        name: "Pending Ticket",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Pending Ticket"));
        },
      },
      {
        name: "Completed",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Completed"));
        },
      },
      {
        name: "Rejected",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Rejected"));
        },
      },
    ],
    voidTicket: [
      {
        name: "All",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("All"));
        },
      },
      {
        name: "Pending",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Pending"));
        },
      },
      {
        name: "Rejected",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Rejected"));
        },
      },
      {
        name: "Completed",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Completed"));
        },
      },
    ],
    refundTicket: [
      {
        name: "All",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("All"));
        },
      },
      {
        name: "Quotation Request",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Quotation Request"));
        },
      },
      {
        name: "Quotation Completed",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Quotation Completed"));
        },
      },
      {
        name: "Refund Applied",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Refund Applied"));
        },
      },
      {
        name: "Refund Processing",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Refund Processing"));
        },
      },
      {
        name: "Refund Approved",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Refund Approved"));
        },
      },
      {
        name: "Refunding",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Refunding"));
        },
      },
      {
        name: "Temporarily Non-refundable",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Temporarily Non-refundable"));
        },
      },
      {
        name: "Refund Completed",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Refund Completed"));
        },
      },
      {
        name: "Rejected",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Rejected"));
        },
      },
    ],
    changeTicket: [
      {
        name: "All",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("All"));
        },
      },
      {
        name: "Pending",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Pending"));
        },
      },
      {
        name: "Rejected",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Rejected"));
        },
      },
      {
        name: "Completed",
        onClick: () => {
          dispatch(setTicketsPage(1));
          dispatch(setTicketStatusType("Completed"));
        },
      },
    ],
  };

  const headerColumns = {
    Flights: [
      {
        name: "Order Number",
        type: "desc",
        width: "w-[25%]",
      },
      {
        name: "Trip",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Travel Time",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Passenger",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Amount",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Status",
        type: "asc",
        width: "w-[25%]",
      },
    ],
    Tickets: [
      {
        name: "Order Number",
        type: "desc",
        width: "w-[25%]",
      },
      {
        name: "Trip",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Travel Time",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "PNR",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Passenger",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Amount",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Status",
        type: "asc",
        width: "w-[25%]",
      },
    ],
    Voids: [
      {
        name: "Order Number",
        type: "asc",
        width: "w-[25%]",
      },
      // {
      //   name: "Request Time",
      //   type: "desc",
      //   width: "w-[20%]",
      // },
      {
        name: "Trip",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Travel Time",
        type: "asc",
        width: "w-[15%]",
      },
      // {
      //   name: "PNR",
      //   type: "asc",
      //   width: "w-[15%]",
      // },
      {
        name: "Passenger",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Amount",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Status",
        type: "asc",
        width: "w-[25%]",
      },
    ],
    Refunds: [
      {
        name: "Order Number",
        type: "asc",
        width: "w-[25%]",
      },
      // {
      //   name: "Refund Request Time",
      //   type: "desc",
      //   width: "w-[20%]",
      // },
      {
        name: "Trip",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Travel Time",
        type: "asc",
        width: "w-[15%]",
      },
      // {
      //   name: "PNR",
      //   type: "asc",
      //   width: "w-[15%]",
      // },
      {
        name: "Passenger",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Fee",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Status",
        type: "asc",
        width: "w-[25%]",
      },
    ],
    Changes: [
      {
        name: "Order Number",
        type: "asc",
        width: "w-[25%]",
      },
      // {
      //   name: "Re-issue Request Time",
      //   type: "desc",
      //   width: "w-[20%]",
      // },
      {
        name: "Trip",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Travel Time",
        type: "asc",
        width: "w-[15%]",
      },
      // {
      //   name: "PNR",
      //   type: "asc",
      //   width: "w-[15%]",
      // },
      {
        name: "Passenger",
        type: "asc",
        width: "w-[15%]",
      },
      {
        name: "Status",
        type: "asc",
        width: "w-[25%]",
      },
    ],
  };

  const ticketFilterTypes = {
    flight: [
      {
        name: "All orders",
        onClick: () => {
          dispatch(setTicketFilterType("All orders"));
          dispatch(setTicketStatusType("All"));
          selectSortHeader(0, headerColumns.Flights);
        },
      },
      {
        name: "Tickets",
        onClick: () => {
          dispatch(setTicketFilterType("Tickets"));
          dispatch(setTicketStatusType("All"));
          selectSortHeader(0, headerColumns.Tickets);
        },
      },
      {
        name: "Re-issue Management",
        onClick: () => {
          dispatch(setTicketFilterType("Re-issue Management"));
          dispatch(setTicketStatusType("All"));
          selectSortHeader(0, headerColumns.Changes);
        },
      },
      {
        name: "Refund Management",
        onClick: () => {
          dispatch(setTicketFilterType("Refund Management"));
          dispatch(setTicketStatusType("All"));
          selectSortHeader(0, headerColumns.Refunds);
        },
      },
      {
        name: "Void Management",
        onClick: () => {
          dispatch(setTicketFilterType("Void Management"));
          dispatch(setTicketStatusType("All"));
          selectSortHeader(0, headerColumns.Voids);
        },
      },
      {
        name: "Completed",
        onClick: () => dispatch(setTicketFilterType("Completed")),
      },
    ],
  };

  const hotelFilterType = [
    {
      name: "All",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("All"));
      },
    },
    {
      name: "Paid",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Paid"));
      },
    },
    {
      name: "Pending",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Pending"));
      },
    },
    {
      name: "Canceled",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Canceled"));
      },
    },
    {
      name: "Refunded",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Refunded"));
      },
    },
    {
      name: "Completed",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Completed"));
      },
    },
  ];
  const tourFilterType = [
    {
      name: "All",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("All"));
      },
    },
    {
      name: "Paid",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Paid"));
      },
    },
    {
      name: "Pending",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Pending"));
      },
    },
    {
      name: "Canceled",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Canceled"));
      },
    },
    {
      name: "Refunded",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Refunded"));
      },
    },
    {
      name: "Completed",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Completed"));
      },
    },
  ];

  const insuranceFilterType = [
    {
      name: "All",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("All"));
      },
    },
    {
      name: "Paid",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Paid"));
      },
    },
    {
      name: "Pending",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Pending"));
      },
    },
    {
      name: "Canceled",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Canceled"));
      },
    },
    {
      name: "Refunded",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Refunded"));
      },
    },
    {
      name: "Completed",
      onClick: () => {
        dispatch(setTicketsPage(1));
        dispatch(setTicketFilterType("Completed"));
      },
    },
  ];

  const exampleOrder = [
    {
      elements: [
        { type: "title", value: "20-07-2024 17:00:47 GMT+1" },
        { type: "copy", value: "40852853048358549475" },
        {
          type: "tag",
          value: "SABRE",
          color: "text-[#BD002A]",
          background: "bg-[#BD002A]/20",
        },
      ],
      width: "md:w-[25%]",
    },
    {
      elements: [
        { type: "title", value: "One Way" },
        { type: "text", value: "LOS - NBO KQ533" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Travel Time" },
        { type: "text", value: "2024-07-30 12:25 - 19:45" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Passenger" },
        { type: "text", value: "Chinedu John Doe" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Price" },
        { type: "text", value: "1310.9 USD" },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Status" },
        { type: "status", value: "Awaiting payment" },
        { type: "time", value: "30 Mins" },
      ],
      width: "md:w-[15%]",
    },
  ];

  const getTicketFilterTypes = () => {
    switch (ticketType) {
      case configurations.stayKeyword:
        return hotelFilterType;
      case configurations.tourKeyword:
        return tourFilterType;
      case configurations.insuranceKeyword:
        return insuranceFilterType;
      default:
        return ticketFilterTypes?.flight;
    }
  };

  const getTicketStatusFilterTypes = () => {
    if (ticketType === "Flights") {
      switch (ticketFilterType) {
        case "Tickets":
          return ticketFilters?.ticket;
        case "Void Management":
          return ticketFilters?.voidTicket;
        case "Refund Management":
          return ticketFilters?.refundTicket;
        case "Re-issue Management":
          return ticketFilters?.changeTicket;
        default:
          return ticketFilters?.flight;
      }
    }
    return [];
  };

  const getSortHeaderRow = () => {
    return ticketsSortHeader || headerColumns?.Flights;
  };

  const selectSortHeader = (index, selectedHeader) => {
    let items = [...(selectedHeader || getSortHeaderRow())];
    items = items.map((itm, i) => {
      return {
        name: itm?.name,
        type: itm?.type,
        width: itm?.width,
        index: i,
      };
    });
    let item = { ...items[index] };
    if (item?.name === selectedTicketsSortHeader?.name) {
      item.type = selectedHeader
        ? item?.type
        : item.type === "asc"
        ? "desc"
        : "asc";
    }
    items[index] = item;
    dispatch(setTicketsSortHeader(items));
    dispatch(setSelectedTicketsSortHeader(item));
  };

  function getFlightOrders() {
    const emptyFills = flightOrders?.filter((obj) => !obj) || [];
    const filteredOrders = flightOrders?.filter((obj) => {
      if (!obj) return false;
      const bookingData = obj?.flightBooking?.at(0);
      let filter = true;

      // type filter
      switch (ticketFilterType) {
        case "Tickets":
          filter = [
            "Issued",
            "PendingTicketIssueApproval",
            "TicketIssueDenied",
            "PendingTicketIssue",
            "TicketCanceled",
            "TicketCancelationRequested",
          ]?.includes(bookingData?.status);
          break;
        default:
          filter = true;
      }

      // status filter
      if (filter)
        switch (ticketStatusType) {
          case "Pending Payment":
            filter = ["Pending", "Booked"]?.includes(bookingData?.status);
            break;
          case "Paid":
            filter = bookingData?.status === "Paid";
            break;
          case "Expired":
            filter = ["Expired", "AutoCanceled"]?.includes(bookingData?.status);
            break;
          case "Cancelled":
            filter = ["Canceled"]?.includes(bookingData?.status);
            break;
          case "Ticketing":
            filter = [
              "PendingTicketIssue",
              "PendingTicketIssueApproval",
            ]?.includes(bookingData?.status);
            break;
          case "Re-issue":
            filter = bookingData?.status === "Re-issued";
            break;
          case "Refund":
            filter = bookingData?.status === "Refunded";
            break;
          case "Void":
            filter = ["TicketCanceled", "TicketCancelationRequested"]?.includes(
              bookingData?.status
            );
            break;
          case "Completed":
            filter = bookingData?.status === "Issued";
            break;
          case "Pending Ticket":
            filter = [
              "PendingTicketIssue",
              "PendingTicketIssueApproval",
            ]?.includes(bookingData?.status);
            break;
          case "Rejected":
            filter = ["TicketIssueDenied"]?.includes(bookingData?.status);
            break;
          default:
            filter = true;
        }

      // search
      try {
        if (ticketSearchValue && filter)
          filter = [
            bookingData?.bookingId,
            bookingData?.payable,
            bookingData?.customerPayable,
            bookingData?.orderId,
            bookingData?.pnr,
            bookingData?.supplier,
            bookingData?.travelers
              ?.map(
                (obj) =>
                  obj?.firstName + " " + obj?.middleName + " " + obj?.lastName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.airlineName +
                  " " +
                  obj?.arrivalAirport +
                  " " +
                  obj?.arrivalLocationName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.departureAirport + " " + obj?.departureLocationName
              )
              ?.join(" "),
          ]
            ?.join(" ")
            ?.toLowerCase()
            ?.includes(ticketSearchValue?.toLowerCase());
      } catch (ex) {}

      return filter;
    });

    const templated = filteredOrders?.map((order) =>
      ticketFilterType === "Tickets"
        ? templateTicketsOrderData(order)
        : templateFlightOrderData(order)
    );

    const [sortedOrders, sortedIndices] = sortFlightOrders(
      templated,
      selectedTicketsSortHeader,
      filteredOrders
    );

    return [
      [...(sortedOrders || []), ...emptyFills],
      [
        ...(sortArrayWithIndices(filteredOrders, sortedIndices) || []),
        ...emptyFills,
      ],
    ];
  }

  function getVoidOrders() {
    const emptyFills = voidOrders?.filter((obj) => !obj) || [];

    const filteredOrders = voidOrders?.filter((obj) => {
      if (!obj) return false;

      const bookingData = obj?.flightBooking?.at(0);
      let filter = true;

      // status filter
      if (filter)
        switch (ticketStatusType) {
          case "Pending":
            filter = ["Pending", "PendingVoid", "Approved"]?.includes(
              obj?.status
            );
            break;
          case "Completed":
            filter = ["Success", "Closed", "Failed"]?.includes(obj?.status);
            break;
          case "Rejected":
            filter = ["Denied"]?.includes(obj?.status);
            break;
          default:
            filter = true;
        }

      // search
      try {
        if (ticketSearchValue && filter)
          filter = [
            bookingData?.bookingId,
            bookingData?.payable,
            bookingData?.customerPayable,
            bookingData?.orderId,
            bookingData?.pnr,
            bookingData?.supplier,
            bookingData?.travelers
              ?.map(
                (obj) =>
                  obj?.firstName + " " + obj?.middleName + " " + obj?.lastName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.airlineName +
                  " " +
                  obj?.arrivalAirport +
                  " " +
                  obj?.arrivalLocationName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.departureAirport + " " + obj?.departureLocationName
              )
              ?.join(" "),
          ]
            ?.join(" ")
            ?.toLowerCase()
            ?.includes(ticketSearchValue?.toLowerCase());
      } catch (ex) {}

      return filter;
    });

    const templated = filteredOrders?.map((order) =>
      templateFlightOrderVoidData(order)
    );

    const [sortedOrders, sortedIndices] = sortFlightOrders(
      templated,
      selectedTicketsSortHeader,
      filteredOrders
    );
    console.log(sortedOrders, "---");

    return [
      [...(sortedOrders || []), ...emptyFills],
      [
        ...(sortArrayWithIndices(filteredOrders, sortedIndices) || []),
        ...emptyFills,
      ],
    ];

    // return [sortedOrders,sortArrayWithIndices(filteredOrders,sortedIndices)||[]];
  }

  function getRefundOrders() {
    const emptyFills = refundOrders?.filter((obj) => !obj) || [];

    const filteredOrders = refundOrders?.filter((obj) => {
      if (!obj) return false;

      const bookingData = obj?.flightBooking?.at(0);
      let filter = true;

      // status filter
      // Approved = "Approved",
      //   Paid = "Paid",
      //   Denied = "Denied",
      //   Pending = "Pending",
      //   PendingPayment = "PendingPayment",
      //   Success = "Success",
      //   Failed = "Failed",
      //   Closed = "Closed",
      if (filter)
        switch (ticketStatusType) {
          case "Quotation Request":
            filter = !obj?.confirmed;
            break;
          case "Quotation Completed":
            filter = obj?.confirmed;
            break;
          case "Refunding":
          case "Refund Processing":
            filter = ["Pending"]?.includes(obj?.status);
            break;
          case "Temporarily Non-refundable":
            filter = obj?.status === "Failed";
            break;
          case "Refund Approved":
            filter = obj?.status === "Approved";
            break;
          case "Refund Applied":
          case "Refund Completed":
            filter = ["Success"]?.includes(obj?.status);
            break;
          case "Rejected":
            filter = ["Denied"]?.includes(obj?.status);
            break;
          default:
            filter = true;
        }

      // search
      try {
        if (ticketSearchValue && filter)
          filter = [
            bookingData?.bookingId,
            bookingData?.payable,
            bookingData?.customerPayable,
            bookingData?.orderId,
            bookingData?.pnr,
            bookingData?.supplier,
            bookingData?.travelers
              ?.map(
                (obj) =>
                  obj?.firstName + " " + obj?.middleName + " " + obj?.lastName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.airlineName +
                  " " +
                  obj?.arrivalAirport +
                  " " +
                  obj?.arrivalLocationName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.departureAirport + " " + obj?.departureLocationName
              )
              ?.join(" "),
          ]
            ?.join(" ")
            ?.toLowerCase()
            ?.includes(ticketSearchValue?.toLowerCase());
      } catch (ex) {}

      return filter;
    });

    const templated = filteredOrders?.map((order) =>
      templateFlightOrderRefundData(order)
    );

    const [sortedOrders, sortedIndices] = sortFlightOrders(
      templated,
      selectedTicketsSortHeader,
      filteredOrders
    );
    console.log(sortedOrders, "---");

    return [
      [...(sortedOrders || []), ...emptyFills],
      [
        ...(sortArrayWithIndices(filteredOrders, sortedIndices) || []),
        ...emptyFills,
      ],
    ];
    // return [sortedOrders,sortArrayWithIndices(filteredOrders,sortedIndices)||[]];
  }

  function getReissueOrders() {
    const emptyFills = reissueOrders?.filter((obj) => !obj) || [];

    const filteredOrders = reissueOrders?.filter((obj) => {
      if (!obj) return false;

      const bookingData = obj?.flightBooking?.at(0);
      let filter = true;

      // status filter
      // Approved = "Approved",
      //   Paid = "Paid",
      //   Denied = "Denied",
      //   Pending = "Pending",
      //   PendingPayment = "PendingPayment",
      //   Success = "Success",
      //   Failed = "Failed",
      //   Closed = "Closed",
      // status filter
      if (filter)
        switch (ticketStatusType) {
          case "Pending":
            filter = ["Pending", "PendingVoid", "Approved"]?.includes(
              obj?.status
            );
            break;
          case "Completed":
            filter = ["Success", "Closed", "Failed"]?.includes(obj?.status);
            break;
          case "Rejected":
            filter = ["Denied"]?.includes(obj?.status);
            break;
          default:
            filter = true;
        }

      // search
      try {
        if (ticketSearchValue && filter)
          filter = [
            bookingData?.bookingId,
            bookingData?.payable,
            bookingData?.customerPayable,
            bookingData?.orderId,
            bookingData?.pnr,
            bookingData?.supplier,
            bookingData?.travelers
              ?.map(
                (obj) =>
                  obj?.firstName + " " + obj?.middleName + " " + obj?.lastName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.airlineName +
                  " " +
                  obj?.arrivalAirport +
                  " " +
                  obj?.arrivalLocationName
              )
              ?.join(" "),
            bookingData?.flights
              ?.map(
                (obj) =>
                  obj?.departureAirport + " " + obj?.departureLocationName
              )
              ?.join(" "),
          ]
            ?.join(" ")
            ?.toLowerCase()
            ?.includes(ticketSearchValue?.toLowerCase());
      } catch (ex) {}

      return filter;
    });

    const templated = filteredOrders?.map((order) =>
      templateFlightOrderReissueData(order)
    );

    const [sortedOrders, sortedIndices] = sortFlightOrders(
      templated,
      selectedTicketsSortHeader,
      filteredOrders
    );
    console.log(sortedOrders, "---");

    return [
      [...(sortedOrders || []), ...emptyFills],
      [
        ...(sortArrayWithIndices(filteredOrders, sortedIndices) || []),
        ...emptyFills,
      ],
    ];
    // return [sortedOrders,sortArrayWithIndices(filteredOrders,sortedIndices)||[]];
  }

  function getStaysOrders() {
    const filteredOrders = hotelOrders?.filter((obj) => {
      // const bookingData = obj?.flightBooking?.at(0);
      let filter = true;

      // search
      try {
        if (ticketSearchValue && filter)
          filter = [
            obj?.bookingId,
            obj?.fareBasePrice,
            obj?.bookRef,
            obj?.holdBookingRes?.name,
            obj?.supplier,
            obj?.name,
          ]
            ?.join(" ")
            ?.toLowerCase()
            ?.includes(ticketSearchValue?.toLowerCase());
      } catch (ex) {}

      if (ticketFilterType && ticketFilterType !== "All") {
        switch (ticketFilterType) {
          case "Paid":
            return ["Paid"]?.includes(obj?.status);
          case "Pending":
            return ["Pending", "Booked"]?.includes(obj?.status);
          case "Canceled":
            return ["Canceled", "Cancelled"]?.includes(obj?.status);
          case "Refunded":
            return ["Refunded"]?.includes(obj?.status);
          case "Completed":
            return ["Issued", "Ticket issued", "Completed"]?.includes(
              obj?.status
            );
          default:
            return true;
        }
      }

      return filter;
    });

    const templated = filteredOrders?.map((order) =>
      templateStayOrderData(order)
    );

    const [sortedOrders, sortedIndices] = sortFlightOrders(
      templated,
      selectedTicketsSortHeader,
      filteredOrders
    );

    return [
      sortedOrders,
      sortArrayWithIndices(filteredOrders, sortedIndices) || [],
    ];
  }

  function getTourOrders() {
    const filteredOrders = tourOrders?.filter((obj) => {
      // const bookingData = obj?.flightBooking?.at(0);
      let filter = true;

      // search
      try {
        if (ticketSearchValue && filter)
          filter = [
            obj?.bookingId,
            obj?.amount,
            obj?.bookRef,
            obj?.provider,
            obj?.name,
            obj?.extraDetails?.productInfo?.title,
          ]
            ?.join(" ")
            ?.toLowerCase()
            ?.includes(ticketSearchValue?.toLowerCase());
      } catch (ex) {}

      if (ticketFilterType && ticketFilterType !== "All") {
        switch (ticketFilterType) {
          case "Paid":
            return ["Paid"]?.includes(obj?.status);
          case "Pending":
            return ["Pending", "Booked"]?.includes(obj?.status);
          case "Canceled":
            return ["Canceled", "Cancelled"]?.includes(obj?.status);
          case "Refunded":
            return ["Refunded"]?.includes(obj?.status);
          case "Completed":
            return ["Issued", "Ticket issued"]?.includes(obj?.status);
          default:
            return true;
        }
      }

      return filter;
    });

    const templated = filteredOrders?.map((order) =>
      templateTourOrderData(order)
    );

    const [sortedOrders, sortedIndices] = sortFlightOrders(
      templated,
      selectedTicketsSortHeader,
      filteredOrders
    );

    return [
      sortedOrders,
      sortArrayWithIndices(filteredOrders, sortedIndices) || [],
    ];
  }

  function getInsuranceOrders() {
    const filteredOrders = insuranceOrders?.filter((obj) => {
      // const bookingData = obj?.flightBooking?.at(0);
      let filter = true;

      // search
      try {
        if (ticketSearchValue && filter)
          filter = [
            obj?.bookingId,
            obj?.amount,
            obj?.bookRef,
            obj?.provider,
            obj?.name,
            obj?.title,
          ]
            ?.join(" ")
            ?.toLowerCase()
            ?.includes(ticketSearchValue?.toLowerCase());
      } catch (ex) {}

      if (ticketFilterType && ticketFilterType !== "All") {
        switch (ticketFilterType) {
          case "Paid":
            return ["Paid"]?.includes(obj?.status);
          case "Pending":
            return ["Pending", "Booked"]?.includes(obj?.status);
          case "Canceled":
            return ["Canceled", "Cancelled"]?.includes(obj?.status);
          case "Refunded":
            return ["Refunded"]?.includes(obj?.status);
          case "Completed":
            return ["Issued", "Ticket issued", "Completed"]?.includes(obj?.status);
          default:
            return true;
        }
      }

      return filter;
    });

    const templated = filteredOrders?.map((order) =>
      templateInsuranceOrderData(order)
    );

    const [sortedOrders, sortedIndices] = sortFlightOrders(
      templated,
      selectedTicketsSortHeader,
      filteredOrders
    );

    return [
      sortedOrders,
      sortArrayWithIndices(filteredOrders, sortedIndices) || [],
    ];
  }

  const getOrders = () => {
    switch (ticketType) {
      case "Flights":
        switch (ticketFilterType) {
          case "Void Management":
            return getVoidOrders();
          case "Refund Management":
            return getRefundOrders();
          case "Re-issue Management":
            return getReissueOrders();
          default:
            return getFlightOrders();
        }
      case configurations.stayKeyword:
        return getStaysOrders();
      case configurations.tourKeyword:
        return getTourOrders();
      case configurations.insuranceKeyword:
        return getInsuranceOrders();
      default:
        return getFlightOrders();
    }
    // return [[
    //   exampleOrder,
    //   exampleOrder,
    //   exampleOrder,
    //   exampleOrder,
    //   exampleOrder,
    // ]];
  };

  return {
    getOrdersCount,
    selectOrderType,
    getTicketFilterTypes,
    getTicketStatusFilterTypes,
    getSortHeaderRow,
    selectSortHeader,
    getOrders,
  };
}

// export enum ORDER_STATUS {
//   Approved = "Approved",
//   Paid = "Paid",
//   Denied = "Denied",
//   Pending = "Pending",
//   PendingPayment = "PendingPayment",
//   PendingVoid = "PendingVoid",
//   Success = "Success",
//   Failed = "Failed",
//   Closed = "Closed",
// }
