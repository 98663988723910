import { useDispatch } from "react-redux";
import { URLdecode, URLencode } from "../utils/url";
import { useLocation, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { setInsuranceFilter } from "../redux/reducers/tour/filterSlice";
import moment from "moment";
import { configurations } from "../configurations";

export function useInsuranceActions() {
  const params = URLdecode();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchForInsurance = ({ data }) => {
    if (!data?.departure)
      return enqueueSnackbar("Departure location required", {
        variant: "warning",
      });
    if (!data?.arrival)
      return enqueueSnackbar("Arrival location required", {
        variant: "warning",
      });
    if (!data?.startDate)
      return enqueueSnackbar("Start date required", { variant: "warning" });
    if (!data?.endDate)
      return enqueueSnackbar("End date required", { variant: "warning" });
    navigate(
      `/protection/results?${URLencode({
        ...data,
        departure: JSON.stringify(data?.departure),
        arrival: JSON.stringify(data?.arrival),
        reload: Number(params?.relaod || 0) + 1,
      })}`
    );
  };

  function getTitle(gender) {
    if (gender?.toLowerCase() === "male") return "Mr";
    else return "Ms";
  }

  function getArrivalAndDepartureDateForFirstDirection(data) {
    let flights = data?.flightObj?.flights;
    let initialOfferId = flights?.at(0)?.offerId;
    flights = flights?.filter((flight) => flight?.offerId === initialOfferId);
    if (flights?.length) {
      return {
        startDate:
          moment(flights?.at(0)?.departureDate, "MMM DD YYYY").format(
            "YYYY-MM-DD"
          ) || moment().add(7, "days").format("YYYY-MM-DD"),
        endDate:
          moment(
            flights?.at(flights?.length - 1)?.arrivalDate,
            "MMM DD YYYY"
          ).format("YYYY-MM-DD") ||
          moment().add(14, "days").format("YYYY-MM-DD"),
      };
    }
    return {
      startDate: moment().add(7, "days").format("YYYY-MM-DD"),
      endDate: moment().add(14, "days").format("YYYY-MM-DD"),
    };
  }

  function getFirstFlightDestination(data, type = "arrival") {
    let destination = data?.flightObj?.destinations?.at(0);
    let segment = data?.flightObj?.flights?.find(
      (f) => f?.arrivalLocation === destination?.to
    );
    if (type === "arrival") {
      return segment;
    }
    let departureSegment = data?.flightObj?.flights?.find(
      (f) => f?.arrivalLocation === destination?.from
    );
    return departureSegment;
  }

  const searchForInsuranceFromFlightBooking = ({ data }) => {
    // console.log({ data });
    let passenger = data?.flightObj?.travelers?.at(0);
    const travellerAgeCounter = (age) => {
      let travellers =
        data?.flightObj?.travelers?.filter(
          (traveler) => traveler?.travelerType === age
        ) || [];
      return travellers.length;
    };
    let parameters = {
      birthDate: passenger?.birthDate,
      firstName: passenger?.firstName,
      middleName: passenger?.middleName || "",
      lastName: passenger?.lastName,
      email: passenger?.email,
      phone: passenger?.phone,
      gender: passenger?.gender,
      title: getTitle(passenger?.gender),
      city: passenger?.document?.issuanceCountry,
      state: passenger?.document?.nationality,
      passportNumber: passenger?.document?.number,
      departure: JSON.stringify({
        // country: getFirstFlightDestination(data)?.arrivalLocationName,
        country: "South Africa",
        city: getFirstFlightDestination(data)?.departureLocationName,
      }),
      arrival: JSON.stringify({
        city: getFirstFlightDestination(data)?.arrivalLocationName,
      }),
      startDate: getArrivalAndDepartureDateForFirstDirection().startDate,
      endDate: getArrivalAndDepartureDateForFirstDirection().endDate,
      isRoundTrip: true,
      numberOfAdults: data?.flightObj?.adult?.passengerCount || 0,
      numberOfChildren: data?.flightObj?.child?.passengerCount || 0,
      numberOfInfants: data?.flightObj?.infant?.passengerCount || 0,
      isRoundTrip: true,
      flights:
        JSON.stringify(
          data?.flightObj?.flights?.map((flight) => {
            return {
              departureDate: moment(
                flight?.departureDate,
                "MMM DD YYYY"
              ).format("YYYY-MM-DD"),
              arrivalDate: moment(flight?.arrivalDate, "MMM DD YYYY").format(
                "YYYY-MM-DD"
              ),
              flightNumber: flight?.flightNumber || "2911AAD",
              pnr: data?.flightObj?.pnr || "FEDSAA",
              airline: flight?.airline || "ET",
              departureAirport: flight?.departureLocation || "LOS",
              arrivalAirport: flight?.arrivalLocation || "DXB",
              segmentIndex: parseInt(flight?.segment) || 0,
            };
          })
        ) || undefined,
      flightId: data?._id,
    };

    // console.log({ data });

    navigate(`/protection/results?${URLencode(parameters)}`);
  };

  const getActualMinAndMaxInsurancePrice = (policies) => {
    let minPrice = 0;
    let maxPrice = 0;
    if (policies.length === 0) {
      minPrice = 0;
      maxPrice = 0;
      return;
    }

    minPrice = Number(policies[0]?.amount);
    maxPrice = minPrice;

    for (let policy of policies) {
      let price = Number(policy?.amount);

      if (price < minPrice) {
        minPrice = price;
      }

      if (price > maxPrice) {
        maxPrice = price;
      }
    }
    dispatch(
      setInsuranceFilter({
        actualMaxPrice: maxPrice,
        priceMax: 100,
        actualMinPrice: minPrice,
        priceMin: 0,
      })
    );
  };

  const selectInsurance = ({ policy }) => {
    const parameters = { ...params, policy: policy?.policyId };
    localStorage.setItem(
      `insurance/${policy?.policyId}`,
      JSON.stringify(policy)
    );
    navigate(`/protection/checkout?${URLencode(parameters)}`);
  };
  const saveToRecentSearch = (insurance) => {
    const parameters = URLdecode();
    let savedSearches = localStorage.getItem(
      configurations.insuranceRecentSearchKey
    );
    savedSearches = savedSearches ? JSON.parse(savedSearches) : [];
    let foundIndex = savedSearches?.findIndex(
      (savedSearch) => savedSearch?.params?.policy === insurance?.policyId
    );

    if (foundIndex > -1) {
      const existingInsurance = savedSearches.splice(foundIndex, 1)[0];

      savedSearches.unshift({
        insurance: insurance,
        params: parameters,
      });
    } else {
      savedSearches.unshift({
        insurance: insurance,
        params: params,
      });
    }
    savedSearches = savedSearches.slice(0, 10);
    localStorage.setItem(
      configurations.insuranceRecentSearchKey,
      JSON.stringify(savedSearches)
    );
  };

  const initializeCheckoutData = ({
    setData,
    insurance,
    cart = false,
    flightOrder,
  }) => {
    const parameters = URLdecode();
    const paxMix = {
      adult: Number(
        insurance?.req?.numberOfAdults || parameters?.numberOfAdults || 0
      ),
      child: Number(
        insurance?.req?.numberOfChildren || parameters?.numberOfChildren || 0
      ),
      infant: Number(
        insurance?.req?.numberOfInfants || parameters?.numberOfInfants || 0
      ),
    };

    if (parameters?.sessionID && !cart) {
      let data = localStorage.getItem(`insuranceData/${parameters?.sessionID}`);
      if (data) {
        data = JSON.parse(data);
        setData(data);
        return;
      }
    }

    let travellers = [
      ...Array(paxMix.adult).fill("adult"),
      ...Array(paxMix.child).fill("child"),
      ...Array(paxMix.infant).fill("infant"),
    ];

    let flightTravellerAdult = flightOrder?.flightBooking
      ?.at(0)
      ?.travelers?.filter((traveler) => traveler?.travelerType === "ADT");
    let flightTravellerChild = flightOrder?.flightBooking
      ?.at(0)
      ?.travelers?.filter((traveler) => traveler?.travelerType === "CNN");
    let flightTravellerInfant = flightOrder?.flightBooking
      ?.at(0)
      ?.travelers?.filter(
        (traveler) =>
          traveler?.travelerType === "INF" || traveler?.travelerType === "INS"
      );
    let flightTravellers = [
      ...Array(paxMix.adult)
        ?.fill("")
        ?.map((_, index) => {
          return flightTravellerAdult?.at(index)
            ? { ...flightTravellerAdult[index], ageBand: "adult" }
            : null;
        }),
      ...Array(paxMix.child)
        ?.fill("")
        ?.map((_, index) => {
          return flightTravellerChild?.at(index)
            ? { ...flightTravellerChild[index], ageBand: "child" }
            : null;
        }),
      ...Array(paxMix.infant)
        ?.fill("")
        ?.map((_, index) => {
          return flightTravellerInfant?.at(index)
            ? { ...flightTravellerInfant[index], ageBand: "infant" }
            : null;
        }),
    ];

    const getAgeBandTitle = (traveller) => {
      const titles = {
        adult: "Adult (Over 12 Years)",
        child: "Child (Between 2 and 12 Years)",
        infant: "Infant (Below 2 Years)",
      };
      return titles[traveller] || "";
    };

    const getTravellerTitle = (index) => {
      const titles = [
        "Primary Traveler",
        "Secondary Traveler",
        "Tertiary Traveler",
        "Quaternary Traveler",
        "Quinary Traveler",
        "Senary Traveler",
        "Septenary Traveler",
        "Octonary Traveler",
        "Nonary Traveler",
        "Denary Traveler",
      ];
      return titles[index] || `Traveler ${index + 1}`;
    };

    const noMedicalRequirement =
      configurations.insuranceNoMedicalSuppliers?.includes(insurance?.supplier);
    const noNextOfKinRequirement =
      configurations.insuranceNoMedicalSuppliers?.includes(insurance?.supplier);

    const defaultFields = {
      title: "",
      gender: "",
      firstName: "",
      middleName: "",
      lastName: "",
      nationality: "",
      dateOfBirth: "",
      email: "",
      phoneNumber: "234-",
      passportId: "",
      passportExpiryDate: "",
      issuedCountry: "",
      purposeOfTrip: "",
      nextOfKinType: noNextOfKinRequirement ? "---" : "",
      nextOfKinFullName: noNextOfKinRequirement ? "---" : "",
      nextOfKinPhoneNumber: noNextOfKinRequirement ? "234-NONE" : "234-",
      nextOfKinAddress: noNextOfKinRequirement ? "---" : "",
      medicalIssues: noMedicalRequirement ? "No" : "",
      medicalIssuesDetail: "",
      state: "",
      city: "",
      passportPhoto: "",
    };

    const createErrorState = (fields) =>
      Object.keys(fields).reduce((acc, key) => ({ ...acc, [key]: false }), {});

    travellers = travellers.map((traveller, index) => {
      const medicalQuestions = insurance?.medicalQuestions?.map((question) => ({
        question: question?.question,
        type: question?.type,
        answer: "",
      }));

      const errorState = {
        ...createErrorState(defaultFields),
        medical: medicalQuestions?.map((q) => ({
          question: q?.question,
          value: false,
        })),
      };

      let fields = { ...defaultFields };

      if (
        flightOrder &&
        flightTravellers?.at(index) &&
        flightTravellers?.at(index)?.ageBand === traveller
      ) {
        let flightTraveller = flightTravellers?.at(index);
        fields = {
          ...fields,
          title: flightTraveller?.gender === "Male" ? "Mr" : "Ms",
          gender: flightTraveller?.gender || "",
          firstName: flightTraveller?.firstName || "",
          middleName: flightTraveller?.middleName || "",
          lastName: flightTraveller?.lastName || "",
          nationality: flightTraveller?.document?.nationality || "",
          dateOfBirth: flightTraveller?.birthDate || "",
          email: flightTraveller?.email || "",
          phoneNumber: flightTraveller?.phone || "234-",
          passportId: flightTraveller?.document?.number || "",
          passportExpiryDate: flightTraveller?.document?.expiryDate || "",
          issuedCountry: flightTraveller?.document?.issuanceCountry || "",
        };
      }

      return {
        ageBand: traveller,
        ageBandTitle: getAgeBandTitle(traveller),
        travellerTitle: getTravellerTitle(index),
        ...fields,
        open: index === 0,
        step: 0,
        medical: medicalQuestions,
        errors: errorState,
        touched: { ...errorState },
      };
    });

    let id = moment().format("YYYYMMDDHHmmss");
    const initializedData = { travellers, id };
    setData(initializedData);
    localStorage.setItem(
      `insuranceData/${parameters?.sessionID}`,
      JSON.stringify(initializedData)
    );
    if (!parameters?.sessionID && !cart) {
      navigate(
        `${location.pathname}?${URLencode({ ...parameters, sessionID: id })}`,
        { replace: true }
      );
    }
  };

  const onTravellerFieldChange = ({
    field,
    index,
    value,
    data,
    setData,
    medical,
  }) => {
    const updatedTravellers = [...data?.travellers];
    if (medical) {
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        medical:
          updatedTravellers[index]?.medical?.map((q, idx) => {
            if (idx === field) {
              return { ...q, answer: value };
            } else {
              return q;
            }
          }) || [],
      };
    } else {
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        [field]: value,
      };
    }
    setData({ ...data, travellers: updatedTravellers });
  };

  const validateField = ({ value, field }) => {
    let valid = true;
    if (["middleName"].includes(field)) return true;
    if (!valid) return false;
    if (["phoneNumber", "nextOfKinPhoneNumber"].includes(field)) {
      let val = value.split("-")[1];
      if (!val) return false;
    }
    if (["email"].includes(field)) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      return emailRegex.test(value);
    }
    if (value) return true;
  };

  const onTravellerFieldBlur = ({ field, index, data, setData, medical }) => {
    let error = true;
    const updatedTravellers = [...data?.travellers];
    if (
      validateField({
        field,
        value: medical
          ? updatedTravellers[index]?.medical?.at(field)?.answer
          : updatedTravellers[index][field],
        medical,
      })
    )
      return;
    if (medical) {
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        errors: {
          ...updatedTravellers[index]?.errors,
          medical:
            updatedTravellers[index]?.errors?.medical?.map((q, idx) =>
              idx === field ? { ...q, value: error } : q
            ) || [],
        },
      };
    } else {
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        errors: {
          ...updatedTravellers[index]?.errors,
          [field]: error,
        },
      };
    }

    setData({ ...data, travellers: updatedTravellers });
  };

  const onTravellerFieldFocus = ({ field, index, data, setData, medical }) => {
    const updatedTravellers = [...data?.travellers];
    if (medical) {
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        touched: {
          ...updatedTravellers[index]?.touched,
          medical:
            updatedTravellers[index]?.touched?.medical?.map((q, idx) =>
              idx === field ? { ...q, value: true } : q
            ) || [],
        },
        errors: {
          ...updatedTravellers[index]?.errors,
          medical:
            updatedTravellers[index]?.errors?.medical?.map((q, idx) =>
              idx === field ? { ...q, value: false } : q
            ) || [],
        },
      };
    } else {
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        touched: {
          ...updatedTravellers[index]?.touched,
          [field]: true,
        },
        errors: {
          ...updatedTravellers[index]?.errors,
          [field]: false,
        },
      };
    }
    setData({ ...data, travellers: updatedTravellers });
  };

  const isTravellerStepDataValid = ({ fields, traveller }) => {
    let valid = true;
    fields.forEach((field) => {
      if (field === "medical") {
        traveller?.medical?.forEach((q) => {
          if (!validateField({ field: q.question, value: q.answer }))
            valid = false;
        });
      }
      if (!validateField({ field, value: traveller[field] })) valid = false;
    });
    return valid;
  };

  const insuranceInputFormat = ({ data, setData, insurance }) => {
    let travellers = data?.travellers;

    const onChange = ({ field, index, value }) => {
      const updatedTravellers = [...travellers];
      updatedTravellers[index] = {
        ...updatedTravellers[index],
        [field]: value,
      };
      setData({ ...data, travellers: updatedTravellers });
    };

    return travellers?.map((traveller, index) => {
      const createField = ({
        label,
        type,
        value,
        field,
        width,
        placeholder,
        options,
        maxDate,
        hidden = false,
      }) => ({
        label,
        type,
        value,
        width,
        placeholder,
        options,
        maxDate,
        field,
        hidden,
        error: traveller?.error?.[field],
      });

      let steps = [];
      const personalDetailsStep = {
        title: "Personal details",
        // step: 0,
        // onEdit: () => onChange({ field: "step", index, value: 0 }),
        onDone: () =>
          onChange({ field: "step", index, value: traveller?.step + 1 }),
        fields: [
          "title",
          "gender",
          "firstName",
          "lastName",
          "middleName",
          "nationality",
          "dateOfBirth",
          "email",
          "phoneNumber",
        ],
        items: [
          createField({
            label: "Title",
            type: "dropdown",
            value: traveller?.title,
            field: "title",
            width: 3,
            placeholder: "Select",
            options: ["Mr", "Mrs", "Ms"],
          }),
          createField({
            label: "Gender",
            type: "dropdown",
            value: traveller?.gender,
            field: "gender",
            width: 3,
            placeholder: "Select",
            options: ["Male", "Female"],
          }),
          { type: "openspace", width: 3 },
          createField({
            label: "First Name",
            type: "freetext",
            value: traveller?.firstName,
            field: "firstName",
            width: 3,
            placeholder: "eg. John",
          }),
          createField({
            label: "Middle Name",
            type: "freetext",
            value: traveller?.middleName,
            field: "middleName",
            width: 3,
            placeholder: "eg. Doe",
          }),
          createField({
            label: "Surname",
            type: "freetext",
            value: traveller?.lastName,
            field: "lastName",
            width: 3,
            placeholder: "eg. Ike",
          }),
          createField({
            label: "Nationality",
            type: "country",
            value: traveller?.nationality,
            field: "nationality",
            width: 2,
            placeholder: "Search country",
          }),
          createField({
            label: "Date of Birth",
            type: "date",
            value: traveller?.dateOfBirth,
            field: "dateOfBirth",
            width: 2,
            placeholder: "DD/MM/YYYY",
            maxDate: new Date(),
          }),
          createField({
            label: "Email",
            type: "freetext",
            value: traveller?.email,
            field: "email",
            width: 2,
            placeholder: "Enter email address",
          }),
          createField({
            label: "Phone Number",
            type: "phone",
            value: traveller?.phoneNumber,
            field: "phoneNumber",
            width: 2,
            placeholder: "Enter phone number",
          }),
        ],
      };
      const passportDetailsStep = {
        title: "Passport details",
        // step: 1,
        // onEdit: () => onChange({ field: "step", index, value: 1 }),
        onDone: () =>
          onChange({ field: "step", index, value: traveller?.step + 1 }),
        fields: ["passportId", "purposeOfTrip", "city", "state"],
        items: [
          createField({
            label: "Passport or ID Number",
            type: "freetext",
            value: traveller?.passportId,
            field: "passportId",
            width: 2,
            placeholder: "Enter number",
          }),
          createField({
            label: "Purpose of Trip",
            type: "dropdown",
            value: traveller?.purposeOfTrip,
            field: "purposeOfTrip",
            width: 2,
            placeholder: "Select",
            options: ["Leisure", "Business", "Holiday"],
          }),
          createField({
            label: "City",
            type: "freetext",
            value: traveller?.city,
            field: "city",
            width: 2,
            placeholder: "eg. Lagos",
          }),
          createField({
            label: "State",
            type: "freetext",
            value: traveller?.state,
            field: "state",
            width: 2,
            placeholder: "eg. Abuja",
          }),
        ],
      };
      const nextOfKinStep = {
        title: "Next of kin details",
        // step: 2,
        // onEdit: () => onChange({ field: "step", index, value: 2 }),
        onDone: () =>
          onChange({ field: "step", index, value: traveller?.step + 1 }),
        fields: [
          "nextOfKinType",
          "nextOfKinFullName",
          "nextOfKinPhoneNumber",
          "nextOfKinAddress",
        ],
        items: [
          createField({
            label: "Next of Kin Type",
            type: "dropdown",
            value: traveller?.nextOfKinType,
            field: "nextOfKinType",
            width: 3,
            placeholder: "Select",
            options: ["Wife", "Husband", "Father", "Mother"],
          }),
          { type: "openspace", width: 3 },
          {
            type: "openspace",
            width: 3,
          },
          createField({
            label: "Next of Kin Full Name",
            type: "freetext",
            value: traveller?.nextOfKinFullName,
            field: "nextOfKinFullName",
            width: 2,
            placeholder: "eg. John doe",
          }),
          createField({
            label: "Next of Kin Phone Number",
            type: "phone",
            value: traveller?.nextOfKinPhoneNumber,
            field: "nextOfKinPhoneNumber",
            width: 2,
            placeholder: "Enter phone number",
          }),
          createField({
            label: "Next of Kin Address",
            type: "freetext",
            value: traveller?.nextOfKinAddress,
            field: "nextOfKinAddress",
            width: 1,
            placeholder: "Enter address",
          }),
        ],
      };
      const medicalStep = {
        title: "Medical details",
        // step: 3,
        // onEdit: () => onChange({ field: "step", index, value: 3 }),
        onDone: () =>
          onChange({ field: "step", index, value: traveller?.step + 1 }),
        fields: ["medicalIssues", "medical"],
        items: [
          createField({
            label: "Medical Issues",
            type: "radio",
            value: traveller?.medicalIssues,
            field: "medicalIssues",
            width: 1,
            options: ["Yes", "No"],
          }),
          {
            type: "array",
            width: 1,
            hidden: !Boolean(traveller?.medical?.length),
            questions: traveller?.medical?.map((question, idx) => {
              switch (question.type) {
                case "free_text":
                  return createField({
                    label: question.question,
                    type: "textarea",
                    value: question?.answer,
                    field: idx,
                    width: 1,
                    placeholder: "Enter answer",
                  });
                case "true_false":
                  return createField({
                    label: question.question,
                    type: "radio",
                    value: question?.answer,
                    field: idx,
                    width: 1,
                    options: ["Yes", "No"],
                  });
                default:
                  return <></>;
              }
            }),
          },
        ],
      };
      const passportPhotoStep = {
        title: "Passport photo",
        // step: 4,
        // onEdit: () => onChange({ field: "step", index, value: 4 }),
        onDone: () =>
          onChange({ field: "step", index, value: traveller?.step + 1 }),
        fields: ["passportPhoto"],
        items: [
          createField({
            label: "Passport photo",
            type: "image",
            value: traveller?.passportPhoto,
            field: "passportPhoto",
            width: 1,
            placeholder: "Choose an image",
            hidden: !Boolean(insurance?.passportPhotoRequired),
          }),
        ],
      };
      steps = [personalDetailsStep, passportDetailsStep];
      if (
        !configurations.insuranceNoMedicalSuppliers?.includes(
          insurance?.supplier
        )
      ) {
        steps = [...steps, nextOfKinStep, medicalStep];
      }
      if (insurance?.passportPhotoRequired) {
        steps = [...steps, passportPhotoStep];
      }
      steps = steps.map((step, index) => {
        return {
          ...step,
          step: index,
          onEdit: () => onChange({ field: "step", index, value: index }),
        };
      });

      return {
        title: traveller?.travellerTitle,
        ageBand: traveller?.ageBandTitle,
        open: traveller?.open,
        step: traveller?.step,
        onOpenChange: () => {
          onChange({ field: "open", index, value: !traveller?.open });
        },
        steps,
      };
    });
  };

  const handleCheckout = ({ data, insurance, callback }) => {
    const parameters = URLdecode();
    localStorage.setItem(
      `insuranceData/${parameters?.sessionID}`,
      JSON.stringify(data)
    );
    const payload = parseInsuranceBookingData({ data, insurance });

    callback && callback(payload);
  };

  const parseInsuranceBookingData = ({ data, insurance }) => {
    const ageCategory = (ageBand) => {
      switch (ageBand) {
        case "child":
          return "Child";
        case "infant":
          return "Infant";
        default:
          return "Adult";
      }
    };

    const personsInfo = data?.travellers?.map((traveller) => {
      const travellerInfo = {
        firstName: traveller?.firstName,
        middleName: traveller?.middleName,
        lastName: traveller?.lastName,
        birthDate: moment(traveller?.dateOfBirth).format("YYYY-MM-DD"),
        ageCategory: ageCategory(traveller?.ageBand),
        passportNumber: traveller?.passportId,
        nextOfKinRelationship: traveller?.nextOfKinType,
        nextOfKinAddress: traveller?.nextOfKinAddress,
        nextOfKinName: traveller?.nextOfKinFullName,
        nextOfKinContact: traveller?.nextOfKinPhoneNumber?.includes("NONE")
          ? "---"
          : traveller?.nextOfKinPhoneNumber,
        preMedical: traveller?.medicalIssues === "Yes" ? true : false,
        medical:
          traveller?.medical?.map((q) => {
            return {
              question: q.question,
              answer:
                q?.type === "true_false"
                  ? q.answer === "Yes"
                    ? true
                    : false
                  : q.answer,
            };
          }) || null,
        gender: traveller?.gender,
        title: traveller?.title,
        phone: traveller?.phoneNumber,
        email: traveller?.email,
        // city: traveller?.city || "Lagos",
        // state: traveller?.state || "Lagos",
        passportPhoto: traveller?.passportPhoto,
      };

      if (!insurance?.passportPhotoRequired) {
        delete travellerInfo?.passportPhoto;
      }

      return travellerInfo;
    });

    return {
      personsInfo: personsInfo,
      originalAmount: insurance?.originalAmount,
      amount: insurance?.amount,
      baggageCoverOriginalPrice: 20,
      baggageCoverPrice: 20000,
      policyId: insurance?.policyId,
      policyType: insurance?.policyType,
      address: "Lagos, abebe st 1000",
      destinationCountry: insurance?.req?.destinationCountry || "South Africa",
      flights: insurance?.req?.flights,
      supplier: insurance?.supplier,
      purposeOfTravel:
        data?.travellers?.find((traveller) => traveller?.purposeOfTravel)
          ?.purposeOfTravel || "Leisure",
    };
  };

  const isInsuranceDataValid = ({ data, insurance }) => {
    let valid = true;
    let fields = [
      "title",
      "gender",
      "firstName",
      "middleName",
      "lastName",
      "nationality",
      "dateOfBirth",
      "email",
      "phoneNumber",
      "passportId",
      "purposeOfTrip",
      "nextOfKinType",
      "nextOfKinFullName",
      "nextOfKinPhoneNumber",
      "nextOfKinAddress",
      "medicalIssues",
      "state",
      "city",
      "medical",
      "finished",
    ];

    if (insurance?.passportPhotoRequired) {
      fields.push("passportPhoto");
    }

    data?.travellers?.forEach((traveller) => {
      fields.forEach((field) => {
        if (field === "medical") {
          traveller?.medical?.forEach((q) => {
            if (!validateField({ field: q.question, value: q.answer }))
              valid = false;
          });
        }
        if (!validateField({ field, value: traveller[field] })) valid = false;
      });
    });
    return valid;
  };

  return {
    searchForInsurance,
    getActualMinAndMaxInsurancePrice,
    selectInsurance,
    initializeCheckoutData,
    insuranceInputFormat,
    saveToRecentSearch,
    handleCheckout,
    parseInsuranceBookingData,
    onTravellerFieldBlur,
    onTravellerFieldChange,
    onTravellerFieldFocus,
    isTravellerStepDataValid,
    isInsuranceDataValid,
    searchForInsuranceFromFlightBooking,
  };
}
