import React from "react";
import AccommodationDateRangePicker from "../../accommodation/AccommodationDateRangePicker";
import ModifyHotelBookingModalBlueButton from "./ModifyHotelBookingModalBlueButton";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export default function ModifyHotelBookingSelectRoom() {
  const { data, setData, onHotelDateChange, checkedHotel } =
    useModifyBookingModalContext();

  return (
    <div className="space-y-4">
      <h3 className="font-bold text-lg sm:text-2xl">Add Room</h3>
      <div>
        <AccommodationDateRangePicker
          onDateChange={onHotelDateChange}
          startDate={checkedHotel?.newStartDate}
          endDate={checkedHotel?.newEndDate}
        />
      </div>

      <div className="flex gap-6 items-center mt-4">
        <button
          onClick={() => {
            setData({ ...data, tab: data?.tab - 1 });
          }}
          className="py-3 sm:py-4 px-6 sm:px-[4.6875rem] text-xs whitespace-nowrap sm:text-lg font-bold"
        >
          Go back
        </button>
        <ModifyHotelBookingModalBlueButton>
          Continue
        </ModifyHotelBookingModalBlueButton>
      </div>
    </div>
  );
}
