import React, { useState } from "react";
import { Icon } from "@iconify/react";
import ModalContainer from "../common/ModalContainer";
import moment from "moment";
import { formatMoney } from "../../features/utils/formatMoney";
import { useInsuranceApi } from "../../hooks/useInsuranceApi";
import { useDispatch, useSelector } from "react-redux";
import { setInsuranceOrders } from "../../redux/reducers/orderSlice";
import { templateInsuranceOrderData } from "../../data/order/ordersData";

export const CancelInsuranceBookingModal = ({
  setOpen,
  open,
  booking,
  noSave,
}) => {
  const dispatch = useDispatch();
  const { insuranceOrders } = useSelector((state) => state.order);
  const { cancelAndRefundInsurance } = useInsuranceApi();
  const [loading, setLoading] = useState(false);
  const headerData = [
    { label: "Services", width: "w-4/12", align: "text-left" },
    { label: "Travellers", width: "w-3/12", align: "text-left" },
    { label: "Final Price", width: "w-2/12", align: "text-left" },
    { label: "Cancellation Fees", width: "w-3/12", align: "text-right" },
  ];

  const renderBookingData = ({ data }) => {
    const formatModifyDates = (startDate, endDate) => {
      if (!startDate || !endDate) return "";

      const start = moment(startDate);
      const end = moment(endDate);
      const nights = end.diff(start, "days");

      return `${start.format("DD MMM")} - ${end.format(
        "DD MMM"
      )} (${nights} night${nights > 1 ? "s" : ""})`;
    };

    const details = data?.requestBody?.rooms?.map((room) => {
      let adultCount = 0;
      let childCount = 0;
      room.guests?.forEach((guest) => {
        if (guest.type === "adult") adultCount += 1;
        if (guest.type === "child") childCount += 1;
      });
      return `${room?.name} (${adultCount} Adult${
        adultCount === 1 ? "" : "s"
      } ${childCount}, Child${childCount === 1 ? "" : "ren"})`;
    });

    const cancellation = () => {
      if (!data?.cancellable) return "";
      if (!data?.cancellationAmount) return "Free";
      let calculationAmount = Math.ceil(
        (data?.cancellationAmount / data?.fareTotalPrice) * 100
      );
      return 100 - calculationAmount === 0
        ? "Free"
        : `${100 - calculationAmount}%`;
    };

    let travellerNames =
      data?.orderData?.orderData?.requestBody?.personsInfo?.map((person) => {
        let travellerName = person?.firstName + " " + person?.lastName;

        return travellerName;
      });

    travellerNames = travellerNames?.join(", ");

    let items = [];
    items.push([
      {
        type: "service",
        data: {
          serviceName: data?.orderData?.orderData?.policyType,
          serviceDetails: details,
          serviceDates: formatModifyDates(
            data?.orderData?.orderData?.startDate,
            data?.orderData?.orderData?.endDate
          ),
        },
      },
      {
        type: "traveller",
        data: {
          travellerName: data?.name || travellerNames,
        },
      },
      {
        type: "price",
        data: {
          finalPrice: formatMoney(data?.fareTotalPrice),
        },
      },
      {
        type: "cancellation",
        data: {
          cancellationFees: formatMoney(
            data?.grandTotal - data?.cancellationAmount
          ),
          cancellationDetails: cancellation(),
          cancellationDeadline: `Until ${moment(
            data?.lastCancellationDate
          )?.format("DD MMM YYYY, HH:MM A")}`,
        },
      },
    ]);
    return items;
  };

  const handleCancel = async () => {
    await cancelAndRefundInsurance({
      booking,
      setLoading,
      callback: (cancelledData) => {
        setOpen(false);
        if (noSave) {
          dispatch(
            setInsuranceOrders(
              insuranceOrders?.map((order) =>
                order?._id === booking?._id
                  ? templateInsuranceOrderData(cancelledData)
                  : booking
              )
            )
          );
        }
      },
      noSave,
    });
  };

  return (
    <>
      <ModalContainer
        open={open}
        setOpen={setOpen}
        secondary
        padding="px-4 py-6"
        title="Cancel"
        subTitle="Check the information of the product you want to cancel"
      >
        <div className="w-full flex flex-col">
          <div className="w-full">
            <div className="relative overflow-x-auto flex flex-col scroll-hide">
              <div className="bg-[#F0F6FC] w-full min-w-[1024px] text-xxxs text-primaryText uppercase flex rounded-t-lg">
                {headerData.map((header, index) => (
                  <div
                    key={index}
                    className={`first:px-4 px-2 font-bold py-2 md:py-4 ${header.width} ${header.align}`}
                  >
                    {header.label}
                  </div>
                ))}
              </div>
              <div className="flex flex-col bg-[#F0F6FC] p-[2px] w-full min-w-[1024px] rounded-b-md">
                {renderBookingData({ data: booking })?.map((item, index) => (
                  <div
                    className="flex w-full  last:rounded-b-md rounded-t-md bg-white"
                    key={index}
                  >
                    {item?.map((section) =>
                      section?.type === "service" ? (
                        <Services data={section?.data} />
                      ) : section?.type === "traveller" ? (
                        <Travellers data={section?.data} />
                      ) : section?.type === "price" ? (
                        <FinalPrice data={section?.data} />
                      ) : (
                        <Cancellation data={section?.data} />
                      )
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end gap-1 text-sm md:text-base mt-4 py-3">
            <div className="flex gap-2">
              <span>Final price:</span>
              <span className="font-bold">
                {formatMoney(booking?.cancellationAmount)}
              </span>
            </div>
            <p className="text-darkGray">
              {Math.ceil(
                100 -
                  100 *
                    ((booking?.fareTotalPrice - booking?.cancellationAmount) /
                      booking?.fareTotalPrice)
              )}
              % of final price
            </p>
          </div>
          <div className="border-[#EFECEC] border-t"></div>
          <div className="flex flex-col items-end gap-5 py-3">
            <div className="flex gap-2 text-lg md:text-2xl">
              <span>Total to be refunded:</span>
              <span className="font-bold">
                {formatMoney(booking?.cancellationAmount)}
              </span>
            </div>
            <div className="bg-[#FDF3D7] border-l-[10px] border-[#D9A513] text-[#6B5618] text-xs md:text-sm pr-6 pl-4 py-2 rounded-r-lg mb-4">
              By clicking on “Cancel” you are accepting cancellation charges
            </div>
          </div>
          <div className="flex gap-6 items-center">
            <button onClick={setOpen} className={ClassName.whiteButton}>
              Do not cancel
            </button>
            <button
              className="bg-primary1 flex items-center justify-center text-secondaryflex-1 text-white w-full py-4 rounded-lg text-xs sm:text-lg"
              onClick={() => handleCancel()}
            >
              {loading ? (
                <div className="buttonLoader"></div>
              ) : (
                <span>Cancel</span>
              )}
            </button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

const ClassName = {
  whiteButton: "py-4 px-6 text-xs md:text-lg font-bold w-full max-w-[174px]",
};

const Travellers = ({ data }) => {
  return (
    <div className="flex items-start p-2 w-3/12">
      <div className="flex items-center gap-1 ">
        <Icon icon="solar:user-bold" className="text-base" />
        <span className="text-sm text-gray-400">{data.travellerName}</span>
      </div>
    </div>
  );
};

const FinalPrice = ({ data }) => {
  return <span className="p-2 w-2/12">{data.finalPrice}</span>;
};

const Cancellation = ({ data }) => {
  if (!data.cancellationDetails) return <></>;
  return (
    <div className="flex flex-1 flex-col p-2">
      <span className="text-end text-sm">{data.cancellationFees}</span>
      <div className="text-xxxs md:text-sm text-[#1EA994] flex items-center justify-end gap-2">
        <Icon icon="akar-icons:check" className="text-lg" />
        {data.cancellationDetails === "Free"
          ? "Free cancellation"
          : `${data.cancellationDetails} Cancellation fees`}
      </div>
      <span className="text-xxxs text-end text-gray-400">
        {data.cancellationDeadline}
      </span>
    </div>
  );
};

const Services = ({ data }) => {
  return (
    <div className="flex items-start gap-2 w-4/12 p-2">
      <div className="my-1">
        <img
          src="/IconInsurance2.svg"
          alt="service-icon"
          className="h-5 object-contain"
        />
      </div>
      <div className="flex flex-1 flex-col">
        <span className="text-sm lg:text-base">{data.serviceName}</span>
        {data.serviceDetails?.map((detail, index) => (
          <span className="text-sm text-gray-400" key={index}>
            {detail}
          </span>
        ))}
        <span className="text-sm text-gray-400">{data.serviceDates}</span>
      </div>
    </div>
  );
};
