import React from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment";

function InsuranceConfirmationBookedPolicySectionPDFStatic(booking) {
  if (!booking) return "";

  // Create a static HTML for the hotel section.
  const staticHotelContent = (
    <div className="w-full flex flex-col mt-4">
      <div className="flex items-center gap-1 mb-1">
        <svg
          className="h-5 object-contain"
          width="31"
          height="30"
          viewBox="0 0 31 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7539 27.5C12.8581 26.7708 10.4672 25.1092 8.58141 22.515C6.69557 19.9208 5.75307 17.0408 5.75391 13.875V6.25L15.7539 2.5L25.7539 6.25V13.875C25.7539 17.0417 24.8114 19.9221 22.9264 22.5163C21.0414 25.1104 18.6506 26.7717 15.7539 27.5Z"
            fill="#1E305A"
          />
        </svg>

        <span className="text-xl font-bold">
          {booking?.orderData?.orderData?.requestBody?.policyType}
        </span>
      </div>
      <div className="w-full bg-blue-100 border-t-2 border-blue-600 p-4 flex flex-wrap gap-2 justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order ID</span>
          <span className="text-sm text-blue-600 font-bold">
            {booking?.mainBooking?.bookingId || booking?.bookingId}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order Date</span>
          <span className="text-sm font-bold">
            {moment(booking?.createdAt).format("DD/MM/YYYY HH:mm A")}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Period</span>
          <span className="text-sm font-bold">
            {moment(booking?.startDate).format("DD MMM")} -{" "}
            {moment(booking?.endDate).format("DD MMM")}
          </span>
        </div>
      </div>
    </div>
  );

  return ReactDOMServer.renderToStaticMarkup(staticHotelContent);
}

export default InsuranceConfirmationBookedPolicySectionPDFStatic;
