import { createSlice } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";

const savedCompare = localStorage?.getItem("flightCompare");

let initialState = {
  bookingData: {},
  compareFlights: savedCompare ? JSON.parse(savedCompare) : [],
  shareFlights: [],
  flowType: "Round trip",
  flightCompareDisplay: false,
  flightCompareRoute: 0,
};

const flowType = window.localStorage.getItem("flowType");
const UDStorage = window.localStorage.getItem("bookingData");
if (UDStorage) {
  try {
    initialState = { ...initialState, bookingData: JSON.parse(UDStorage) };
    initialState = { ...initialState, flowType: flowType || "Round trip" };
  } catch (ex) {
    console.log("Failed reading bookingData from storage");
  }
} else console.log("Got none from LS");

export const flightBookingSlice = createSlice({
  name: "flightBooking",
  initialState,
  reducers: {
    setBookingData: (state, action) => {
      try {
        state.bookingData = action.payload;
        window.localStorage.setItem(
          "bookingData",
          JSON.stringify(action.payload)
        );
      } catch (ex) {
        console.log("Failed reading booking data from storage");
      }
    },
    setCompareFlights: (state, action) => {
      let compareData = action?.payload;
      if (compareData?.length > 0) {
        const lastFlight = compareData[compareData.length - 1];

        const haveSameDestinations = (flight1, flight2) => {
          const destinations1 = flight1?.destinations || [];
          const destinations2 = flight2?.destinations || [];

          if (destinations1.length !== destinations2.length) return false;

          return destinations1.every(
            (dest, index) =>
              dest?.from === destinations2[index]?.from &&
              dest?.to === destinations2[index]?.to
          );
        };

        const allHaveSameDestinations = compareData.every((flight) =>
          haveSameDestinations(flight, lastFlight)
        );

        if (!allHaveSameDestinations) {
          enqueueSnackbar("All flights has to be the same to compare", {
            variant: "info",
          });
          compareData = [lastFlight];
        }
      }
      if (compareData?.length <= 4) {
        state.compareFlights = compareData;
        if (state?.flightCompareRoute > compareData[0]?.destinations?.length) {
          state.flightCompareRoute = 0;
        }
        state.flightCompareDisplay = true;
        localStorage.setItem("flightCompare", JSON.stringify(compareData));
      } else {
        enqueueSnackbar("Only 4 flights are allowed to compare", {
          variant: "warning",
        });
      }
    },
    setFlightCompareDisplay: (state, action) => {
      state.flightCompareDisplay = action.payload;
    },
    setFlightCompareRoute: (state, action) => {
      state.flightCompareRoute = action.payload;
    },
    setShareFlights: (state, action) => {
      state.shareFlights = action.payload;
    },
    setFlowType: (state, action) => {
      state.flowType = action.payload || "Round trip";
      window.localStorage.setItem("flowType", state.flowType);
    },
    clearBookingData: (state) => {
      state.bookingData = initialState;
      window.localStorage.removeItem("bookingData");
    },
  },
});

export const {
  setBookingData,
  clearBookingData,
  setCompareFlights,
  setShareFlights,
  setFlowType,
  setFlightCompareDisplay,
  setFlightCompareRoute,
} = flightBookingSlice.actions;

export default flightBookingSlice.reducer;
