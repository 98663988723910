import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import { useHotels } from "../../../hooks/useHotels";
import { useStayUI } from "../../../hooks/useStayUI";
import { formatMoney } from "../../../features/utils/formatMoney";

export default function ModifyHotelBookingNameChangeConfirmation() {
  const { parseBookingTravellerNameChange } = useStayUI();
  const {
    checkedRoom,
    newPriceLoading,
    setNewPriceLoading,
    booking,
    priceDiff,
  } = useModifyBookingModalContext();
  const { processChangeBooking } = useHotels();
  const [newData, setNewData] = useState();

  const loadData = async () => {
    const data = parseBookingTravellerNameChange({
      body: booking?.requestBody,
      checkedRoom,
    });
    setNewPriceLoading(true);
    await processChangeBooking({
      data,
      id: booking?._id,
      callback: (updatedData) => setNewData(updatedData),
    }).finally(() => setNewPriceLoading(false));
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col sm:flex-row gap-6 w-full">
        <div className="border border-[#EFECEC] rounded-lg p-6 w-full">
          <div className="bg-primaryText text-white px-6 py-1.5 text-sm font-semibold rounded-lg max-w-[174px] mb-8">
            Current names
          </div>
          <div className="flex items-center gap-2.5 text-sm sm:text-base">
            <Icon icon="solar:user-bold" />
            <p>{checkedRoom?.guest}</p>
          </div>
        </div>
        <div className="border border-[#FAA108] rounded-lg p-6 w-full">
          <div className="bg-[#FAA108] text-white px-6 py-1.5 text-sm font-semibold rounded-lg max-w-[182px] mb-8">
            Modified names
          </div>
          <div className="flex items-center gap-2.5 text-sm sm:text-base">
            <Icon icon="solar:user-bold" />
            <p>
              {checkedRoom?.firstName} {checkedRoom?.lastName}
            </p>
          </div>
        </div>
      </div>
      {newPriceLoading ? (
        <div className="w-full flex items-center justify-center py-6">
          <div className="primaryLoader"></div>
        </div>
      ) : priceDiff({ data: newData }) ? (
        <div className="flex justify-between items-center text-xl sm:text-2xl font-bold mt-4">
          <div className="text-darkGray">Price Difference</div>
          <div>
            {priceDiff({ data: newData }) < 0 ? "-" : "+"}
            {formatMoney(priceDiff({ data: newData }))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
