import { useDispatch } from "react-redux";
import { setStaysFilter } from "../redux/reducers/tour/filterSlice";
import useDateFormat from "./useDateFormat";
import { enqueueSnackbar } from "notistack";
import { URLdecode, URLencode } from "../utils/url";
import { useNavigate } from "react-router-dom";
import { setConfirmHotelBookingModal } from "../redux/reducers/modalSlice";
import moment from "moment";
import { configurations } from "../configurations";
import { isCustomerSite } from "../utils/getsubDomain";

export function useStayUI() {
  const params = URLdecode();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isPastDate,
    compareDate,
    isSameDate,
    isAllChildrenAgeSelected,
    formatDate,
    capitalizeFirstLetter,
    calculateAge,
  } = useDateFormat();

  const getActualMinAndMaxPrice = (hotels) => {
    let minPrice = 0;
    let maxPrice = 0;
    if (hotels.length === 0) {
      minPrice = 0;
      maxPrice = 0;
      return;
    }

    minPrice = Number(hotels[0]?.price);
    maxPrice = minPrice;

    for (let flight of hotels) {
      let price = Number(flight?.price);

      if (price < minPrice) {
        minPrice = price;
      }

      if (price > maxPrice) {
        maxPrice = price;
      }
    }
    dispatch(
      setStaysFilter({
        actualMaxPrice: maxPrice,
        priceMax: 100,
        actualMinPrice: minPrice,
        priceMin: 0,
      })
    );
  };

  const hotelSearchInputInvalid = (data) => {
    if (data?.searchBy === "location" && !data.latitude && !data.longitude) {
      enqueueSnackbar("Please select a location", { variant: "warning" });
      return true;
    }
    if (data?.searchBy === "hotel" && !data?.hotel) {
      enqueueSnackbar("Please select a hotel from the suggestion", {
        variant: "warning",
      });
      return true;
    }
    if (isPastDate(data?.start_date)) {
      enqueueSnackbar("Start date can't be a past date", {
        variant: "warning",
      });
      return true;
    }
    if (isPastDate(data?.end_date)) {
      enqueueSnackbar("End date can't be a past date", { variant: "warning" });
      return true;
    }
    if (compareDate(data?.end_date, data?.start_date)) {
      enqueueSnackbar("End date can't be before start date", {
        variant: "warning",
      });
      return true;
    }

    if (isSameDate(data?.start_date, data?.end_date)) {
      enqueueSnackbar("Start date and End date can't be the same", {
        variant: "warning",
      });
      return true;
    }
    if (!isAllChildrenAgeSelected(data?.rooms)) {
      return true;
    }
    return false;
  };

  const hotelRating = ({ hotel }) => {
    const reviewRating = hotel?.details?.reviews
      ? hotel?.details?.reviews?.at(0)?.rate
      : null;

    if (reviewRating != null) {
      const numericRating = parseFloat(reviewRating, 10);
      return !isNaN(numericRating) ? numericRating : 0;
    }

    return 0;
  };

  const hotelsSearch = (data, setEdit) => {
    const reload = Number(params?.reload) || 0;
    if (hotelSearchInputInvalid(data)) return;
    setEdit && setEdit(false);
    if (data?.searchBy === "hotel") {
      localStorage.setItem("searchedHotel", JSON.stringify(data?.hotel));
    }
    const parameters = {
      ...params,
      start_date: formatDate(data?.start_date),
      end_date: formatDate(data?.end_date),
      lat:
        data?.searchBy === "hotel"
          ? data?.hotel?.details?.location?.lat
          : data.latitude,
      lng:
        data?.searchBy === "hotel"
          ? data?.hotel?.details?.location?.long
          : data.longitude,
      location: data?.location,
      hotel: data?.hotel?.name || "",
      rooms: JSON.stringify(data?.rooms),
      reload: reload + 1,
      searchBy: data?.searchBy,
    };

    //  navigate(`?${URLencode(parameters)}`) :
    navigate(`/accommodation/results?${URLencode(parameters)}`);
  };

  const saveToRecentSearch = (hotel) => {
    let savedSearches = localStorage.getItem("hotelRecentSearch");
    savedSearches = savedSearches ? JSON.parse(savedSearches) : [];
    let foundIndex = savedSearches?.findIndex(
      (savedSearch) => savedSearch?.params?.name === hotel?.name
    );

    if (foundIndex > -1) {
      const existingHotel = savedSearches.splice(foundIndex, 1)[0];

      savedSearches.unshift({
        hotel: hotel,
        params: params,
      });
    } else {
      savedSearches.unshift({
        hotel: hotel,
        params: params,
      });
    }
    savedSearches = savedSearches.slice(0, 10);
    localStorage.setItem("hotelRecentSearch", JSON.stringify(savedSearches));
  };

  const facilityDatabase = [
    { code: [55], icon: "/IconAmenityTV.svg", name: "TV" },
    { code: [195], icon: "/IconAmenityHeating.svg", name: "Heating" },
    {
      code: [41, 21, 95, 23, 11, 48, 13, 56, 99],
      icon: "/IconAmenitySafe.svg",
      name: "Safe2Stay",
    },
    { code: [296], icon: "/IconAmenitySafe.svg", name: "Fireplace" },
    { code: [305], icon: "/IconAmenityHotTub.svg", name: "Hot Tub" },
    { code: [190], icon: "/IconAmenityGameRoom.svg", name: "Game Room" },
    { code: [573], icon: "/IconAmenityPrivatePool.svg", name: "Private Pool" },
    { code: [500], icon: "/IconAmenityParking.svg", name: "Parking" },
    {
      code: [573, 306],
      icon: "/IconAmenitySwimmingPool.svg",
      name: "Swimming Pool",
    },
    { code: [200], icon: "/IconAmenityRestaurant.svg", name: "Restaurant" },
    { code: [470], icon: "/IconAmenityGym.svg", name: "Gym" },
    {
      code: [295],
      icon: "/IconAmenityWheelchair.svg",
      name: "Adapted for disabled",
    },
  ];

  const hotelFacilities = (data) => {
    if (!data) return [];
    return facilityDatabase.filter((facility) =>
      facility.code.some((code) => data.some((item) => item.code === code))
    );
  };

  const getMinRoomPrice = (availability) => {
    if (!availability?.rooms || availability.rooms.length === 0) return null;

    return availability.rooms
      .map((room) => {
        return isCustomerSite() ? room?.farePrice : room?.price;
      })
      .filter((price) => price !== undefined && price !== null)
      .reduce((min, price) => (price < min ? price : min), Infinity);
  };

  const cheapestRoom = (hotel) => {
    const availabilities = hotel?.availabilities;

    if (!availabilities || availabilities.length === 0) {
      return null;
    }

    const cheapestRoom = availabilities.reduce((cheapest, current) => {
      const currentPrice = parseFloat(current?.convertedPrice);
      const cheapestPrice = parseFloat(cheapest?.convertedPrice);

      return currentPrice < cheapestPrice ? current : cheapest;
    });

    return cheapestRoom;
  };

  const getRoomsForHotelCard = (availability) => {
    if (!availability?.rooms) return [];

    let rooms = [...availability.rooms];
    // rooms = rooms.filter(
    //   (room) => room?.availabilityStatus === "AvailableForSale"
    // );

    const groupByName = rooms.reduce((acc, room) => {
      const roomName = room.name;
      if (!acc[roomName]) {
        acc[roomName] = [];
      }
      acc[roomName].push(room);
      return acc;
    }, {});

    const groupByType = (roomGroup, typeKey = "type") => {
      if (!Array.isArray(roomGroup) || roomGroup.length === 0) return [];

      const grouped = roomGroup.reduce((acc, room) => {
        const type = room[typeKey];
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(room);
        return acc;
      }, {});

      return Object.keys(grouped).map((type) => ({
        type: capitalizeFirstLetter(type),
        rooms: grouped[type],
      }));
    };

    const groupedByName = Object.keys(groupByName).map((name) => {
      const roomGroup = groupByName[name];
      const guestsDescription = roomGroup.map((room) => {
        let adult = rooms?.guests?.adult || room?.adults;
        let child = rooms?.guests?.child || room?.children;
        if (adult && child) {
          return `for ${adult} adult${adult > 1 ? "s" : ""} and ${child} child${
            child > 1 ? "ren" : ""
          }`;
        } else if (adult) {
          return `for ${adult} adult${adult > 1 ? "s" : ""}`;
        } else if (child) {
          return `for ${child} child${child > 1 ? "ren" : ""}`;
        }
        return "for no guests specified";
      })[0];

      return {
        type: capitalizeFirstLetter(name || ""),
        roomTypes: groupByType(roomGroup),
        show: true,
        guests: guestsDescription,
      };
    });

    return groupedByName;
  };

  const getRoomsForHotelPage = (availability) => {
    if (!availability?.rooms) return [];

    let rooms = [...availability.rooms];
    rooms = rooms.map((room, index) => ({ ...room, index }));
    // rooms = rooms.filter(
    //   (room) => room?.availabilityStatus === "AvailableForSale"
    // );

    const groupByName = rooms.reduce((acc, room) => {
      const roomName = room.name;
      if (!acc[roomName]) {
        acc[roomName] = [];
      }
      acc[roomName].push(room);
      return acc;
    }, {});

    const groupByType = (roomGroup, typeKey = "type") => {
      if (!Array.isArray(roomGroup) || roomGroup.length === 0) return [];

      const grouped = roomGroup.reduce((acc, room) => {
        const type = room[typeKey];
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(room);
        return acc;
      }, {});

      return Object.keys(grouped).map((type) => ({
        type: capitalizeFirstLetter(type),
        rooms: grouped[type],
      }));
    };

    const groupedByName = Object.keys(groupByName).map((name) => {
      const roomGroup = groupByName[name];
      const guestsDescription = roomGroup.map((room) => {
        let adult = room?.guests?.adult || room?.adults;
        let child = room?.guests?.child || room?.children;
        let roomCount = 1;

        if (
          configurations.hotelSupplierExceptions.includes(
            availability?.supplier
          ) &&
          params?.rooms
        ) {
          const paramRooms = JSON.parse(params.rooms);

          if (Array.isArray(paramRooms)) {
            roomCount = paramRooms.length;
            adult = paramRooms.reduce((sum, r) => sum + (r.adults || 0), 0);
            child = paramRooms.reduce(
              (sum, r) => sum + (r.children?.length || 0),
              0
            );
          }
        }

        let description = `for ${roomCount} room${roomCount > 1 ? "s" : ""}`;
        if (adult) description += `, ${adult} adult${adult > 1 ? "s" : ""}`;
        if (child) description += `, ${child} child${child > 1 ? "ren" : ""}`;

        return description;
      })[0];

      return {
        type: capitalizeFirstLetter(name || ""),
        roomTypes: groupByType(roomGroup),
        show: true,
        guests: guestsDescription,
      };
    });

    return groupedByName;
  };

  const hotelAmenities = ({ hotel, hotelAvailability }) => {
    let totalAmenities = [];
    if (hotelAvailability || hotel) {
      let facilities =
        hotelAvailability?.details?.facilities || hotel?.details?.facilities;
      if (facilities) {
        let data = facilities || [];
        let dat = data.filter((item) => {
          if (!item?.description && !item?.title) {
            return false;
          } else {
            return true;
          }
        });
        data = dat;

        data = data.map((item) => {
          let name = "";
          if (item?.title) {
            name = item?.title;
          } else {
            name = item?.description;
          }

          name = name.replace(/_/g, " ");
          name = name.replace(",HAC", " ");
          name = name.toLocaleLowerCase();
          name = name.charAt(0).toUpperCase() + name.slice(1);

          let amenity = {
            code: parseInt(item?.code),
            title: name,
          };
          return amenity;
        });
        for (const item of data) {
          totalAmenities.push(item?.title);
        }
      }
    }

    return totalAmenities;
  };

  const parseCompareHotelData = (compare) => {
    let data = [];

    const yearOfRenovationData = () => {
      let items = compare?.map((item) => {
        return {
          type: "text",
          value: "2022",
        };
      });

      return items;
    };

    const yearOfConstruction = () => {
      let items = compare?.map((item) => {
        return {
          type: "text",
          value: "2022",
        };
      });

      return items;
    };

    const numberOfRooms = () => {
      let items = compare?.map((item) => {
        return {
          type: "text",
          value: "600",
        };
      });

      return items;
    };

    const amenityRows = () => {
      const amenities = [
        { label: "Bathtub", code: 30 },
        { label: "Gym", code: 470 },
        { label: "Sauna", code: 420 },
      ];
      let rows = [];

      for (let amenity of amenities) {
        let amenityColumns = compare?.map((hotel) => {
          if (
            hotel?.details?.facilities?.find(
              (amn) => amn?.code === amenity?.code
            )
          ) {
            return {
              type: "available",
              value: "",
            };
          } else {
            return {
              type: "unavailable",
              value: "",
            };
          }
        });
        rows.push({
          show: true,
          label: amenity.label,
          columns: amenityColumns,
        });
      }

      return rows;
    };

    const internetAccessRows = () => {
      const amenities = [{ label: "Wi-Fi", code: 550 }];
      let rows = [];

      for (let amenity of amenities) {
        let amenityColumns = compare?.map((hotel) => {
          if (
            hotel?.details?.facilities?.find(
              (amn) => amn?.code === amenity?.code
            )
          ) {
            return {
              type: "available",
              value: "",
            };
          } else {
            return {
              type: "unavailable",
              value: "",
            };
          }
        });
        rows.push({
          show: true,
          label: amenity.label,
          columns: amenityColumns,
        });
      }

      return rows;
    };

    const restaurantRows = () => {
      const amenities = [{ label: "Restaurant", code: 200 }];
      let rows = [];

      for (let amenity of amenities) {
        let amenityColumns = compare?.map((hotel) => {
          if (
            hotel?.details?.facilities?.find(
              (amn) => amn?.code === amenity?.code
            )
          ) {
            return {
              type: "available",
              value: "",
            };
          } else {
            return {
              type: "unavailable",
              value: "",
            };
          }
        });
        rows.push({
          show: true,
          label: amenity.label,
          columns: amenityColumns,
        });
      }

      return rows;
    };

    const pointOfInterestRows = () => {
      const points = [];
      for (let hotel of compare) {
        if (hotel?.details?.interestPoints?.length > 0) {
          for (let interest of hotel.details?.interestPoints) {
            if (!points?.find((int) => interest === int)) {
              points.push(interest?.name);
            }
          }
        }
      }
      let rows = [];

      for (let interestPoint of points) {
        let pointColumns = compare?.map((hotel) => {
          let foundPoint =
            hotel?.details?.interestPoints?.length > 0 &&
            hotel?.details?.interestPoints?.find(
              (pt) => pt?.name === interestPoint
            );
          if (foundPoint) {
            return {
              type: "available",
              value: foundPoint?.distance ? `${foundPoint?.distance} m` : "",
            };
          } else {
            return {
              type: "unavailable",
              value: "",
            };
          }
        });
        rows.push({
          show: true,
          label: interestPoint,
          columns: pointColumns,
        });
      }

      return rows;
    };

    const hotelLocations = () => {
      let locations = [];

      for (let hotel of compare) {
        locations.push({
          ...hotel,
          position: {
            lat: hotel?.details?.location.lat,
            lng: hotel?.details?.location?.long,
          },
        });
      }

      return locations;
    };

    // The hotel
    data.push({
      show: true,
      title: "The Hotel",
      type: "table",
      icon: "/IconHotel2.svg",
      rows: [
        {
          show: true,
          label: "Year Of Most Recent Renovation",
          columns: yearOfRenovationData(),
        },
        {
          show: true,
          label: "Year Of Construction",
          columns: yearOfConstruction(),
        },
        {
          show: true,
          label: "Total Number Of Rooms",
          columns: numberOfRooms(),
        },
      ],
    });

    // Internet access
    data.push({
      show: true,
      title: "Internet Access",
      type: "table",
      icon: "/IconCompareWifi.svg",
      rows: internetAccessRows(),
    });

    // Amenties and services
    data.push({
      show: true,
      title: "Amenities and Services",
      type: "table",
      icon: "/IconCompareAmenities.svg",
      rows: amenityRows(),
    });

    // Map
    data.push({
      show: true,
      title: "Map",
      type: "map",
      icon: "/IconCompareMap.svg",
      rows: hotelLocations(),
    });

    // Restaurant services
    data.push({
      show: true,
      title: "Restaurant Service",
      type: "table",
      icon: "/IconAmenityRestaurant.svg",
      rows: restaurantRows(),
    });

    // Points of interest
    data.push({
      show: true,
      title: "Points of interest",
      type: "table",
      icon: "/IconAmenityLocation.svg",
      rows: pointOfInterestRows(),
    });

    return data;
  };

  const handleOpenHotel = (hotel) => {
    const availability = hotel.availabilities?.at(0);
    let rooms = [];
    if (!params?.rooms) {
      if (availability?.adults) {
        rooms.push({
          adults: availability?.adults || 0,
          children: availability?.children
            ? Array(availability.children?.length).fill(12)
            : [],
        });
      }
    }
    const parameters = {
      ...params,
      lat: params?.lat,
      lng: params?.lng,
      location: params?.location,
      rooms: params?.rooms || JSON.stringify(rooms),
      start_date: params?.start_date || hotel?.start_date,
      end_date: params?.end_date || hotel?.end_date,
      code: hotel?.code,
      codeContext: hotel?.codeContext,
      name: hotel?.name,
      cityCode: hotel?.cityCode,
      chainCode: hotel?.chainCode,
      chainName: hotel?.chainName,
      areaId: hotel?.areaId || "",
      categoryCode: hotel?.categoryCode,
      address: hotel?.address,
      city: hotel?.city,
      postalCode: hotel?.postalCode,
      countryCode: hotel?.countryCode,
      units: hotel?.units,
      nights: hotel?.nights,
      supplier: hotel?.supplier,
      proximity: false,
    };

    localStorage.setItem(`hotel ${hotel.name}`, JSON.stringify(hotel));

    let url = `/accommodation/hotel?${URLencode(parameters)}`;
    window.open(url, "_blank");
  };

  const bookingInputInvalid = ({ data }) => {
    let notValid = false;
    if (!data?.email) {
      enqueueSnackbar("Email is required", { variant: "warning" });
      notValid = true;
      // return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data?.email)) {
      enqueueSnackbar("Invalid email address", { variant: "warning" });
      notValid = true;
      // return;
    }
    if (!data?.phone) {
      enqueueSnackbar("Phone number is required", { variant: "warning" });
      notValid = true;
      // return;
    }
    if (!data?.phone?.split("-")[1]) {
      enqueueSnackbar("Phone number is required", { variant: "warning" });
      notValid = true;
      // return;
    }
    let rRooms = data?.rooms ? [...data?.rooms] : [];
    rRooms.map((room) => {
      let guests = room?.guests;
      let guest = guests[0];
      if (!guest?.title) {
        enqueueSnackbar("Title is required for guests", {
          variant: "warning",
        });
        notValid = true;
        // return;
      }
      if (!guest?.firstName) {
        enqueueSnackbar("First name is required for guests", {
          variant: "warning",
        });
        notValid = true;
        // return;
      }
      if (!guest?.lastName) {
        enqueueSnackbar("Last name is required for guests", {
          variant: "warning",
        });
        notValid = true;
        // return;
      }
    });
    return notValid;
  };

  const isBookingDataValid = ({ data }) => {
    let notValid = false;
    if (!data?.email) {
      notValid = true;
      // return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(data?.email)) {
      notValid = true;
      // return;
    }
    if (!data?.phone) {
      notValid = true;
      // return;
    }
    if (!data?.phone?.split("-")[1]) {
      notValid = true;
      // return;
    }
    let rRooms = data?.rooms ? [...data?.rooms] : [];
    rRooms.map((room) => {
      let guests = room?.guests;
      let guest = guests[0];
      if (!guest?.title) {
        notValid = true;
        // return;
      }
      if (!guest?.firstName) {
        notValid = true;
        // return;
      }
      if (!guest?.lastName) {
        notValid = true;
        // return;
      }
    });
    return !notValid;
  };

  const handleBookHotel = ({ bookingData, payOption, callback }) => {
    if (bookingInputInvalid({ data: bookingData })) return;
    const updatedRooms =
      bookingData?.rooms?.map((room) => ({
        ...room,
        guests: room?.guests?.map((guest, index) =>
          index === 0
            ? {
                ...guest,
                email: bookingData?.email,
                phoneNumber: bookingData?.phone,
              }
            : guest
        ),
      })) || [];

    const parameters = {
      ...params,
      reservedRooms: JSON.stringify(updatedRooms),
      contactEmail: bookingData?.email,
      payOption: payOption,
    };

    if (callback) {
      navigate(`/accommodation/checkout?${URLencode(parameters)}`);
      callback();
    } else {
      navigate(`/accommodation/userinfo?${URLencode(parameters)}`);
      dispatch(setConfirmHotelBookingModal(true));
    }
  };

  const bookingHotelsAndRooms = (booking) => {
    if (
      !booking?.requestBody?.rooms ||
      !Array.isArray(booking.requestBody.rooms)
    ) {
      return [];
    }

    const calculateGuests = (guests) => {
      if (!guests || !Array.isArray(guests)) return { adults: 0, children: [] };

      const adults = guests.filter((guest) => guest.type === "adult").length;
      const children = guests
        .filter((guest) => guest.type === "child")
        .map((child) => calculateAge(child?.birthdate || ""));

      return { adults, children };
    };

    const { adults: hotelAdults, children: hotelChildren } = calculateGuests(
      booking.requestBody.rooms[0]?.guests || []
    );

    const rooms = booking.requestBody.rooms.map((room) => {
      const { adults, children } = calculateGuests(room.guests);
      const guest = room.guests?.[0] || {};

      return {
        id: room.bookingCode,
        label: room.name,
        guest: `${guest.firstName || ""} ${guest.lastName || ""}`.trim(),
        firstName: guest.firstName || "",
        lastName: guest.lastName || "",
        guests: `${
          adults > 0 ? `${adults} Adult${adults > 1 ? "s" : ""}` : ""
        }${
          children.length > 0
            ? `${adults > 0 ? " and " : ""}${children.length} Child${
                children.length > 1 ? "ren" : ""
              }`
            : ""
        }`,
        checked: false,
        adults,
        children,
        startDate: booking.requestBody.start_date,
        newStartDate: booking.requestBody.start_date,
        endDate: booking.requestBody.end_date,
        newEndDate: booking.requestBody.end_date,
        room,
        requests: [],
        requestComment: "",
      };
    });

    return [
      {
        id: booking.requestBody.name,
        label: booking.requestBody.name,
        startDate: booking.requestBody.start_date,
        newStartDate: booking.requestBody.start_date,
        endDate: booking.requestBody.end_date,
        newEndDate: booking.requestBody.end_date,
        adults: hotelAdults,
        children: hotelChildren,
        rooms,
      },
    ];
  };

  const parseBookingToModifyHotel = ({ booking }) => {
    const data = {
      tab: 0,
      tabType: "main",
      services: bookingHotelsAndRooms(booking),
    };

    return data;
  };

  const isHotelSaved = ({ hotel, saved }) => {
    return saved;
  };

  const saveHotel = ({ hotel }) => {};

  const modifyBookingContinueButtonValidate = ({ data }) => {
    // Checking if service is selected in step 1
    if (
      data?.tab === 0 &&
      !data?.services?.some((hotel) =>
        hotel?.rooms?.some((room) => room?.checked)
      )
    ) {
      enqueueSnackbar("Please select a service first", { variant: "warning" });
      return true;
    }

    // Checking if type is selected in step 2 for main route
    if (data?.tab === 1 && data?.tabType === "main" && !data?.type) {
      enqueueSnackbar("Please select a a type to modify first", {
        variant: "warning",
      });
      return true;
    }

    const checkedRoom = data?.services
      ?.flatMap((hotel) => hotel?.rooms || [])
      ?.find((room) => room?.checked);

    if (data?.tab === 2 && data?.tabType === "main") {
      switch (data?.type) {
        // Checking if traveller names are filled and a room is selected in step 3 for the main route
        case "traveler-names":
          if (!checkedRoom) {
            enqueueSnackbar("Please select a room first", {
              variant: "warning",
            });
            return true;
          }

          if (!checkedRoom.firstName || !checkedRoom.lastName) {
            enqueueSnackbar(
              "Please fill in the traveller's first and last names",
              {
                variant: "warning",
              }
            );
            return true;
          }
          break;
        // Checking if requests and request comments are filled and a room is selected in step 3 for the main route
        case "requests":
          if (!checkedRoom) {
            enqueueSnackbar("Please select a room first", {
              variant: "warning",
            });
            return true;
          }

          if (!checkedRoom?.requests || checkedRoom?.requests?.length === 0) {
            enqueueSnackbar("Please select at least one request", {
              variant: "warning",
            });
            return true;
          }

          if (!checkedRoom?.requestComment) {
            enqueueSnackbar("Please type in a request comment first", {
              variant: "warning",
            });
            return true;
          }
          break;
        // Checking if start date and end date are filled and a room is selected in step 3 for the main route
        case "dates":
          if (!checkedRoom) {
            enqueueSnackbar("Please select a room first", {
              variant: "warning",
            });
            return true;
          }

          if (!checkedRoom?.newStartDate || !checkedRoom?.newEndDate) {
            enqueueSnackbar("Please select both start date and end date", {
              variant: "warning",
            });
            return true;
          }
          break;

        default:
          break;
      }
    }
    return false;
  };

  const amenitiesSubCategories = [
    "commonAreas",
    "handicap",
    "top-family-friendly",
    "popular",
    "guestService",
    "todo",
    "parking",
    "convenience",
    "access",
    "foodAndDrink",
    "rooms",
    "internet",
    "room",
    "info",
    "policy",
    "children",
    "check-in",
    "check-out",
    "special-check-in",
    "pets",
    "optional-extras",
    "need-to-know",
    "should-mention",
  ];

  const filterAmenityBySubCategories = ({ hotel, subCategory, fullData }) => {
    const amenities = fullData
      ? new Map(
          hotel?.details?.facilities
            ?.filter((facility) =>
              facility?.subCategories?.some((subC) => subC === subCategory)
            )
            ?.map((facility) => [facility.description, facility]) || []
        )
      : new Set(
          hotel?.details?.facilities
            ?.filter((facility) =>
              facility?.subCategories?.some((subC) => subC === subCategory)
            )
            ?.map((facility) => facility?.description) || []
        );

    return fullData ? Array.from(amenities.values()) : Array.from(amenities);
  };

  const parseHotelAccessibilityDetail = ({ hotel, hotelAvailability }) => {
    const categories = [
      { title: "Common Areas", subCategory: "commonAreas", type: "half" },
      { title: "Rooms", subCategory: "room", type: "half" },
    ];

    return categories
      .map(({ title, subCategory, type }) => {
        const list = filterAmenityBySubCategories({ hotel, subCategory });
        return list?.length ? { title, list, type } : null;
      })
      .filter(Boolean);
  };

  const parseHotelPolicyDetail = ({ hotel, hotelAvailability }) => {
    const categories = [
      { title: "Check-in", subCategory: "check-in", type: "half" },
      { title: "Check-out", subCategory: "check-out", type: "half" },
      {
        title: "Special check-in instructions",
        subCategory: "special-check-in",
        type: "full",
      },
      { title: "Access methods", subCategory: "access", type: "half" },
      { title: "Pets", subCategory: "pets", type: "half" },
      {
        title: "Children and extra beds",
        subCategory: "children",
        type: "full",
      },
    ];

    return categories
      .map(({ title, subCategory, type }) => {
        const list = filterAmenityBySubCategories({ hotel, subCategory });
        return list?.length ? { title, list, type } : null;
      })
      .filter(Boolean);
  };

  const parseHotelImportantInfoDetail = ({ hotel, hotelAvailability }) => {
    const categories = [
      {
        title: "Optional extras",
        subCategory: "optional-extras",
        type: "full",
      },
      {
        title: "You need to know",
        subCategory: "need-to-know",
        type: "full",
      },
      {
        title: "We should mention",
        subCategory: "should-mention",
        type: "full",
      },
    ];

    return categories
      .map(({ title, subCategory, type }) => {
        const list = filterAmenityBySubCategories({ hotel, subCategory });
        return list?.length ? { title, list, type } : null;
      })
      .filter(Boolean);
  };

  const parseHotelFAQ = ({ hotel, hotelAvailability }) => {
    let data = [
      {
        question: "Does Four Points by Sheraton Lagos have a pool?",
        answer: "No, pets are not allowed at this property.",
        opened: false,
      },
      {
        question: "Does Four Points by Sheraton Lagos have a pool?",
        answer: "No, pets are not allowed at this property.",
        opened: false,
      },
      {
        question: "Does Four Points by Sheraton Lagos have a pool?",
        answer: "No, pets are not allowed at this property.",
        opened: false,
      },
      {
        question: "Does Four Points by Sheraton Lagos have a pool?",
        answer: "No, pets are not allowed at this property.",
        opened: false,
      },
    ];

    return data;
  };

  const parseHotelAmentiesTop = ({ hotel, hotelAvailability }) => {
    let data = filterAmenityBySubCategories({
      hotel,
      subCategory: "top-family-friendly",
      fullData: true,
    });

    return data;
  };

  const parseHotelAmentiesPopular = ({ hotel, hotelAvailability }) => {
    let data = filterAmenityBySubCategories({
      hotel,
      subCategory: "popular",
      fullData: true,
    });

    return data;
  };

  const parseHotelAmentiesRest = ({ hotel, hotelAvailability }) => {
    const categories = [
      {
        title: "Parking and transportation",
        subCategory: "parking",
      },
      {
        title: "Food and drink",
        subCategory: "foodAndDrink",
      },
      {
        title: "Internet",
        subCategory: "internet",
      },
      {
        title: "Internet",
        subCategory: "todo",
      },
      {
        title: "Conveniences",
        subCategory: "convenience",
      },
      {
        title: "Guest services",
        subCategory: "guestService",
      },
    ];

    return categories
      .map(({ title, subCategory }) => {
        const list = filterAmenityBySubCategories({ hotel, subCategory });
        return list?.length ? { title, list } : null;
      })
      .filter(Boolean);
  };

  const parseBookingTravellerNameChange = ({ body, checkedRoom }) => {
    const updatedRooms = body?.rooms?.map((room) => {
      if (room?.bookingCode !== checkedRoom?.id) {
        return room;
      }
      const [firstName, lastName] = checkedRoom?.guest?.split(" ");

      const updatedGuests = room?.guests?.map((guest) =>
        guest?.type === "adult" &&
        guest?.firstName === firstName &&
        guest?.lastName === lastName
          ? {
              ...guest,
              firstName: checkedRoom?.firstName,
              lastName: checkedRoom?.lastName,
            }
          : guest
      );

      return { ...room, guests: updatedGuests };
    });

    return { ...body, rooms: updatedRooms };
  };

  const parseBookingDateChange = ({ body, checkedRoom }) => {
    return {
      ...body,
      start_date: moment(checkedRoom?.newStartDate).format("YYYY-MM-DD"),
      end_date: moment(checkedRoom?.newEndDate).format("YYYY-MM-DD"),
    };
  };

  return {
    getActualMinAndMaxPrice,
    hotelSearchInputInvalid,
    hotelsSearch,
    saveToRecentSearch,
    hotelFacilities,
    getMinRoomPrice,
    getRoomsForHotelCard,
    parseCompareHotelData,
    handleOpenHotel,
    bookingInputInvalid,
    handleBookHotel,
    parseBookingToModifyHotel,
    modifyBookingContinueButtonValidate,
    hotelRating,
    cheapestRoom,
    isHotelSaved,
    saveHotel,
    hotelAmenities,
    parseHotelAccessibilityDetail,
    parseHotelPolicyDetail,
    parseHotelImportantInfoDetail,
    parseHotelFAQ,
    parseHotelAmentiesTop,
    parseHotelAmentiesPopular,
    parseHotelAmentiesRest,
    parseBookingTravellerNameChange,
    parseBookingDateChange,
    getRoomsForHotelPage,
    isBookingDataValid,
  };
}
