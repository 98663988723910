import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { flightStatusMap } from "../../../pages/Orders/OrdersData";
import { isGDSSupplier } from "../../../data/flight/supplier/getSupplierName";
import { getTestLevel } from "../../../utils/testLevel";
import { ActionContext } from "../OrderList";

export default function FlightMenu({ og }) {
  const [openPayment, setOpenPayment] = useState(false);
  const bookingData = og?.flightBooking.at(0);
  const { ticketFilterType } = useSelector((state) => state.order);

  const voidTime = new Date(bookingData?.voidDeadline || null);

  const navigate = useNavigate();

  if (getTestLevel() === getTestLevel("dev")) console.log("og: ", og);

  return (
    <div
      className="absolute p-2 flex flex-col z-10 bg-white rounded-md right-2 top-full shadow-xl min-w-[200px]"
      style={{
        boxShadow: "0 5px 15px #999",
      }}
    >
      <ActionContext.Consumer>
        {(value) => {
          const {
            reload,
            setOpenPayment,
            setOpenCancelOrder,
            setOpenIssueTicket,
            setOpenRebook,
            setOpenVoidTicket,
            setOpenCancelTicket,
            refreshTicket,
            issue,
            manualIssue,
            approve,
            deny,
            requestInfo,
            approveVoid,
            denyVoid,
            approveRefund,
            denyRefund,
            setOpenEditRefund,
            refundTicket,
            approveChange,
            denyChange,
            setOpenEditChange,
            setOpenViewDetail,
            assignChangeTask,
            assignTicket,
            assignVoid,
            setOpenUpdateChangeRequest,
            setOpenChangeQuote,
            setOpenRefundQuote,
            setOpenManualUpdate,
            setOpenManualRefund,
            setOpenManualVoid,
          } = value || {};
          // Ticketed:
          // x View order
          // x Add a bag
          // x Change date
          // x Change name
          // Void
          // x Refund (if refundable)

          // Pending Payment:
          // View order
          // Make payment
          // Hold booking
          // Add hotel
          // Add tour
          // Add insurance
          // Add bag
          // Add a seat
          // Cancel order

          // PAID:
          // View order
          // Issue ticket
          // Add hotel
          // Add tour
          // Add insurance
          // Add bag
          // Add a seat
          // Cancel order

          // Cancelled:
          // View order
          // Rebook order

          // Share
          // Share pdf
          // Share via email

          const postTicketing =
            og?.fee !== undefined || og?.ticketNo || og?.changeRequest;

          const menus = [
            {
              label: postTicketing ? "View Request" : "View order",
              action: () =>
                setOpenViewDetail({ id: og?._id, page: ticketFilterType }),
              // action: () => navigate("/order/flight/ticket/detail/"+og?._id+'?page='+ticketFilterType),
            },
            {
              label: "Refresh Status",
              action: () => refreshTicket(bookingData?._id),
              showFor: ["Completed", "PendingVoid", "WaitingConfirmation"],
            },
          ];

          if (ticketFilterType === "Tickets") {
            menus?.push(
              ...[
                {
                  label: "Issue Ticket",
                  action: () => issue(og?._id),
                  showFor: ["Pending", "RequestedInfo"],
                },
                {
                  label: "Manual Issue",
                  action: () => manualIssue(og),
                  // showFor: ['Pending']
                },
                {
                  label: "Cancel Ticket",
                  action: () => setOpenCancelTicket(bookingData),
                  showFor: ["Ticket issued", "issued", "Completed"],
                },
                {
                  label: "Approve",
                  action: () => approve(og?._id),
                  showFor: ["Open"],
                },
                {
                  label: "Deny",
                  action: () => deny(og),
                  showFor: ["Open"],
                },
                {
                  label: "Request more info",
                  action: () => requestInfo(og),
                  showFor: ["Open"],
                },
                {
                  label: "Assign",
                  action: () => assignTicket(og),
                  showFor: ["Approved", "Pending"],
                },
              ]
            );
          } else if (ticketFilterType === "Void Management") {
            menus?.push(
              ...[
                {
                  label: "Approve",
                  action: () => approveVoid(og?._id),
                  showFor: ["Pending"],
                },
                {
                  label: "Deny",
                  action: () => denyVoid(og),
                  showFor: ["Pending"],
                },
                {
                  label: "Void Ticket",
                  action: () => setOpenVoidTicket(og?._id),
                  showFor: ["Approved"],
                },
                {
                  label: "Manual Void",
                  action: () => setOpenManualVoid(og),
                  showFor: ["Approved"],
                },
                {
                  label: "Assign",
                  action: () => assignVoid(og),
                  showFor: ["Approved", "Pending"],
                },
              ]
            );
          } else if (ticketFilterType === "Refund Management") {
            menus?.push(
              ...[
                {
                  label: "Approve",
                  action: () => approveRefund(og?._id),
                  showFor: ["Pending"],
                  // hide: !og?.isSubmitted,
                },
                {
                  label: "Edit Refund",
                  action: () => setOpenEditRefund(og),
                  showFor: ["Approved"],
                  hide: og?.confirmed === true,
                },
                {
                  label: "Quote",
                  action: () => setOpenRefundQuote(og),
                  showFor: ["Pending"],
                },
                {
                  label: "Manual Refund",
                  action: () => setOpenManualRefund(og),
                  showFor: ["Approved"],
                },
                {
                  label: "Assign",
                  action: () => refundTicket(og),
                  showFor: ["Approved", "Pending"],
                },
                {
                  label: "Deny",
                  action: () => denyRefund(og),
                  showFor: ["Pending"],
                },
              ]
            );
          } else if (ticketFilterType === "Re-issue Management") {
            menus?.push(
              ...[
                {
                  label: "Approve",
                  action: () => approveChange(og?._id),
                  showFor: ["Pending"],
                },
                {
                  label: "Update Request",
                  action: () => setOpenUpdateChangeRequest(og),
                  showFor: ["Pending"],
                },
                {
                  label: "Edit Change Fee",
                  action: () => setOpenEditChange(og),
                  showFor: ["Pending"],
                },
                {
                  label: "Create a quote",
                  action: () => setOpenChangeQuote(og),
                  showFor: ["Pending", "Approved"],
                },
                // {
                //   label: 'Reissue Ticket',
                //   action: () => assignChangeTask(og),
                //   showFor: ['Approved']
                // },
                {
                  label: "Manual Change",
                  action: () => setOpenManualUpdate(og),
                  showFor: ["Approved"],
                },
                {
                  label: "Assign Task",
                  action: () => assignChangeTask(og),
                  showFor: ["Pending", "Approved"],
                },
                {
                  label: "Decline",
                  action: () => denyChange(og),
                  showFor: ["Pending"],
                },
              ]
            );
          }

          if (!postTicketing)
            menus?.push(
              ...[
                {
                  label: "Make payment",
                  action: () => setOpenPayment(og?.flightObj?._id),
                  showFor: ["Not Paid", "booked"],
                },
                {
                  label: "Issue Ticket",
                  action: () => setOpenIssueTicket(bookingData),
                  showFor: ["Paid", "issuable"],
                },
                {
                  label: "Hold booking",
                  action: () => {},
                  showFor: ["Not Paid", "booked"],
                },
                {
                  label: "Add a bag",
                  action: () => {},
                  showFor: [
                    "Not Paid",
                    "booked",
                    "Ticket issued",
                    "issued",
                    "Paid",
                    "issuable",
                  ],
                },
                {
                  label: "Add a seat",
                  action: () => {},
                  showFor: [
                    "Not Paid",
                    "booked",
                    "Ticket issued",
                    "issued",
                    "Paid",
                    "issuable",
                  ],
                },
                {
                  label: "Add hotel",
                  action: () => {},
                  showFor: ["Not Paid", "booked", "Paid", "issuable"],
                },
                {
                  label: "Add tour",
                  action: () => {},
                  showFor: ["Not Paid", "booked", "Paid", "issuable"],
                },
                {
                  label: "Add insurance",
                  action: () => {},
                  showFor: ["Not Paid", "booked", "Paid", "issuable"],
                },
                {
                  label: "Rebook",
                  action: () => setOpenRebook(og?.flightObj),
                  showFor: ["Expired", "Canceled", "Ticket Canceled"],
                },
                // {
                //   label: 'Cancel Ticket',
                //   action: () => setOpenCancelTicket(bookingData),
                //   showFor: ['Ticket issued','issued','Completed']
                // },
                {
                  label: "Change date",
                  action: () =>
                    navigate("/order/flight/ticket/changedate/" + og?._id),
                  showFor: ["Ticket issued", "issued"],
                },
                {
                  label: "Change name",
                  action: () =>
                    navigate("/order/flight/ticket/changeinfo/" + og?._id),
                  showFor: ["Ticket issued", "issued"],
                },
                {
                  label: "Refund",
                  action: () =>
                    navigate("/order/flight/ticket/refund/" + og?._id),
                  showFor: ["Ticket issued", "issued"],
                  hide: og?.status === "Approved", //bookingData?.isRefundable
                },
                {
                  label: "Download pdf",
                  action: () => {},
                },
                {
                  label: "Share order",
                  action: () => {},
                },
                {
                  label: "Share pnr",
                  action: () => {},
                  hide: !isGDSSupplier(bookingData?.supplier),
                },
                {
                  label: "Issue via intraverse",
                  action: () => {},
                  hide: !isGDSSupplier(bookingData?.supplier),
                },
                {
                  label: "Cancel order",
                  action: () => setOpenCancelOrder(og?.flightObj?._id),
                  showFor: ["Not Paid", "booked", "Paid", "issuable"],
                },
                {
                  label: "Void",
                  action: () =>
                    navigate("/order/flight/ticket/void/" + og?._id),
                  hide: voidTime - new Date() <= 0,
                  showFor: ["Ticket issued", "issued", "Denied"],
                },
              ]
            );

          let items = menus?.filter((obj) => {
            const bookingData = og?.flightBooking?.at(0);
            const status = flightStatusMap(og?.status || bookingData?.status);
            console.log(status, obj?.showFor);
            return (
              (obj?.showFor ? obj?.showFor?.includes(status) : true) &&
              !obj?.hide
            );
          });

          return (items || [])?.map((item, index) => (
            <div
              key={index}
              onClick={() => item?.action()}
              className="font-bold text-sm text-[#444] px-2 py-2 hover:bg-[#818083]/10 border-b"
            >
              {item?.label}
            </div>
          ));
        }}
      </ActionContext.Consumer>
    </div>
  );
}
// Flight menu
