import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export const ModifyHotelBookingRequestModification = () => {
  const { setData, checkedRoom } = useModifyBookingModalContext();

  const requestOptions = [
    {
      group: ["Adjacent rooms", "Double bed", "Honeymoon", "Smoking room"],
    },
    {
      group: [
        "Client without voucher",
        "Except ground floor",
        "Late arrival",
        "Two beds",
      ],
    },
    {
      group: ["Cot", "Ground floor", "Non-smoking room"],
    },
  ];

  const handleRequestChange = (request) => {
    const updatedRequests = checkedRoom?.requests || [];
    const isRequestSelected = updatedRequests.includes(request);

    const newRequests = isRequestSelected
      ? updatedRequests.filter((item) => item !== request)
      : [...updatedRequests, request];

    setData((prevData) => ({
      ...prevData,
      services: prevData.services.map((hotel) => ({
        ...hotel,
        rooms: hotel.rooms.map((room) =>
          room.id === checkedRoom.id ? { ...room, requests: newRequests } : room
        ),
      })),
    }));
  };

  const handleCommentChange = (comment) => {
    setData((prevData) => ({
      ...prevData,
      services: prevData.services.map((hotel) => ({
        ...hotel,
        rooms: hotel.rooms.map((room) =>
          room.id === checkedRoom.id
            ? { ...room, requestComment: comment }
            : room
        ),
      })),
    }));
  };

  return (
    <div className="w-full text-sm sm:text-base">
      <h3 className="font-bold text-lg sm:text-2xl mb-2">Add your requests</h3>
      <p>For reference only, Intraverse cannot guarantee them</p>
      <div className="my-6 grid grid-cols-1 sm:grid-cols-12 gap-4">
        {requestOptions.map((column, colIdx) => (
          <div key={colIdx} className="col-span-1 sm:col-span-4">
            {column.group.map((option, idx) => (
              <div key={idx} className="flex items-center mb-4">
                <input
                  id={`request-${colIdx}-${idx}`}
                  type="checkbox"
                  checked={checkedRoom?.requests?.includes(option) || false}
                  onChange={() => handleRequestChange(option)}
                  className="w-3 h-3 rounded-sm bg-[#BCC7D3] text-primaryText checked:text-white checked:bg-blue-600 checked:border-blue-600 border border-[#BCC7D3] accent-blue-600"
                />
                <label
                  htmlFor={`request-${colIdx}-${idx}`}
                  className="ms-2 text-sm font-medium text-gray-900"
                >
                  {option}
                </label>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div>
        <p className="text-darkGray">Comments</p>
        <textarea
          className="w-full border border-[#A4B1C1] rounded-md p-4 my-1.5 placeholder:text-darkGray"
          placeholder="Write your comments here"
          rows={4}
          maxLength={1000}
          value={checkedRoom?.requestComment || ""}
          onChange={(e) => handleCommentChange(e.target.value)}
        />
        <p className="text-darkGray text-sm">
          Characters remaining:{" "}
          {1000 - (checkedRoom?.requestComment?.length || 0)}
        </p>
      </div>
    </div>
  );
};
