import React from "react";
import { generateTourPDFCSS } from "../../../controllers/cssController";
import TourConfirmationDetailSectionPDFStatic from "./TourConfirmationDetailSectionPDFStatic";
import TourConfirmationBookedTourSectionPDFStatic from "./TourConfirmationBookedTourSectionPDFStatic";
import TourConfirmationTravellerPDFStatic from "./TourConfirmationTravellerPDFStatic";

function TourConfirmationPDFSection({ booking }) {
  const tourSectionStatic = TourConfirmationBookedTourSectionPDFStatic(booking);
  const tourDetailSectionStatic =
    TourConfirmationDetailSectionPDFStatic(booking);
  const travellerSectionStatic = TourConfirmationTravellerPDFStatic(booking);

  const combinedStaticMarkup = `
      <div class="flex flex-col gap-4">
        ${tourSectionStatic}
        ${tourDetailSectionStatic}
        ${travellerSectionStatic}
      </div>
    `;

  return (
    <div className="tour-doc-template">
      <div dangerouslySetInnerHTML={{ __html: combinedStaticMarkup }} />
      <style>{generateTourPDFCSS()}</style>
    </div>
  );
}

export default TourConfirmationPDFSection;
