import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export default function ModifyHotelBookingRequestChangeConfirmation() {
  const { checkedRoom } = useModifyBookingModalContext();

  return (
    <div className="border border-[#FAA108] rounded-lg p-6 w-full">
      <div className="flex justify-between items-center mb-6">
        <span className="text-darkGray text-sm sm:text-base">
          For reference only, Intraverse cannot guarantee them
        </span>
        <div className="bg-[#FAA108] text-white px-6 py-1.5 text-sm font-semibold rounded-lg">
          Selected requests
        </div>
      </div>
      <div className="flex flex-wrap gap-y-3 gap-x-6 w-full max-w-xl mb-4">
        {checkedRoom?.requests?.map((request, idx) => (
          <div key={idx} className="flex items-center">
            <input
              id={`request-${idx}`}
              type="checkbox"
              checked={true}
              readOnly
              className="w-3 h-3 rounded-sm bg-[#BCC7D3] text-primaryText checked:text-white checked:bg-blue-600 checked:border-blue-600 border border-[#BCC7D3] accent-blue-600"
            />
            <label
              htmlFor={`request-${idx}`}
              className="ms-2 text-sm sm:text-base font-medium text-gray-900"
            >
              {request}
            </label>
          </div>
        ))}
      </div>
      <div>
        <span className="mb-2 text-darkGray text-sm">Remark:</span>
        <span className="block sm:text-lg text-gray-500">
          {checkedRoom?.requestComment || "No remarks provided."}
        </span>
      </div>
    </div>
  );
}
