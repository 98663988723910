import { useState } from "react";
import Modal1 from "../../../../components/DIsplay/Modal/Modal1";
import AddFlightBaggage from "../../../../components/flight/Baggage";
import CancelFlightOrder from "../../../../components/flight/CancelFlightOrder";
import AddFlightSeats from "../../../../components/flight/Seats";
import Button1 from "../../../../components/form/Button1";
import { alertType } from "../../../../data/constants";
import moment from "moment";
import { flightStatusMap } from "../../OrdersData";
import { def } from "../../../../config";
import CancelOrder from "../../cancelOrder";
import PriceSummary from "./PriceSummary";
import { getsubDomain } from "../../../../utils/getsubDomain";
import Icon from "../../../../components/HOC/Icon";
import { Modal3 } from "../../../../components/DIsplay/Modal/Modal3";
import ServiceStandard from "../../../../components/flight/Price/ServiceStandard";
import PriceBreakdownDetail from "../../../../components/flight/Price/PriceBreakdownDetail";
import { formatMoney } from "../../../../features/utils/formatMoney";
import Collapse from "../../../../components/mini/Collapse";

export default function OrderStatusBar({ data,page,needsReview,changeable, cancelOrder }) {
  const [openBaggage, setOpenBaggage] = useState(false);
  const [openSeats, setOpenSeats] = useState(false);
  // const [cancelBooking, setCancelBooking] = useState(false);

  const orderData = data?.booking?.flightBooking?.at(0);
  const status = orderData?.status;
  const convertedStatus = flightStatusMap(status)

  const siteName = getsubDomain() || 'Intraverse';

  const StatusDisplay = () => (
    <div className="flex flex-col gap-2">
      <p>Status</p>
      <div>
        <button className={`btn ${alertType[status?.toLowerCase()]} p-3`}>
          {convertedStatus}
        </button>
      </div>
    </div>
  )

  const ImportantInfo = () => page !== 'Ticket' ? 
    <div className="max-w-[360px] text-xs">
      <b>Important info</b>
      <br /><br />

      <p>
        On rare occasions, ticketing of paid order may not be successful for many expected reasons such as the variety of booking class,
        <span className="uppercase"> {siteName}</span> may offer full refund of payments for the failure of issuance within 3 hours of payment received.
        <br />
        <br />
        Please make sure the passport number is correct and the validity should be at least six month from date of departure of the last journey.
        <br />
        <br />
        <span className="uppercase"> {siteName}</span> strongly recommends that duplicate reservations are avoided. 
        Merging and cancellation may occur within the airline system without prior notification. 
        <span className="uppercase"> {siteName}</span> is not liable for any loss or damage.
      </p>
    </div>
  :null

  
  return (
    <div className="border rounded-md p-4 flex flex-col gap-6 sm:w-[350px]">
      <StatusDisplay />

      {/* <div className="flex gap-2 justify-between flex-wrap">
        <div className="flex flex-col gap-2 flex-1">
          <p>Order Id:</p>
          <b>{data?.booking?.bookingId || orderData?.bookingId}</b>
        </div>

        <div className="flex flex-col gap-2">
          <div className="flex justify-between gap-4">
            <p>Airline</p>
            <p>Order Date</p>
          </div>
          <div className="flex justify-between gap-4">
            <div className="flex gap-2 ">
              <img
                src={`https://pics.avs.io/200/200/${data?.booking?.flightBooking?.at(0)?.airlines?.at(0)}@2x.png`}
                alt=""
                className="w-4 h-4"
              />
              <b>{orderData?.airlines?.at(0)} Airline</b>
            </div>
            <div>
              {moment(orderData?.createdAt).format(
                "MMM DD YYYY, HH:mm A",
              )}
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="flex flex-col gap-2 flex-1">
        <p>Ticket No:</p>
        <b className="flex flex-col ">
          {orderData?.ticketNumber?.map((val,i) => 
            <span key={i}>{val}</span>
          )}
          {data?.ticketNo || (!orderData?.ticketNumber?.length ? 
            'NA'
            :null)}
        </b>
      </div> */}


      {/* {needsReview ? 
      <div className="flex flex-col gap-5">
        <Button1
          variant="outlined"
          className="!border-primary !text-primary"
          onClick={() => setOpenBaggage(true)}
        >
          Add extra baggage
        </Button1>
        <Button1
          variant="outlined"
          className="!border-primary !text-primary"
          onClick={() => setOpenSeats(true)}
        >
          Seat selection
        </Button1>
        <Button1
          variant="outlined"
          className="!border-primary !text-primary"
          onClick={() => cancelOrder(data?.booking?.bookingId)}
        >
          Cancel booking
        </Button1>
        <Button1>Issue ticket</Button1>
      </div>
      :
      <div className="flex flex-col gap-5">
        <Button1
          disabled={status !== 'Not Paid' || status !== 'booked'}
          variant="outlined"
          className="!border-primary !text-primary"
          onClick={() => cancelOrder(data?.booking?.bookingId)}
        >
          Request Cancellation
        </Button1>
        <Button1 disabled={!changeable || !def?.devTest ? true:false}
          to={`/order/flight/change/${data?.booking?._id}`}
          variant="outlined"
          className="!border-primary !text-primary"
        >
          Request Change
        </Button1>
        // <Button1>Accept Changes</Button1>
      </div>
      } */}

      <div className="hidden sm:flex flex-col gap-6">
        <PriceBreakdown data={orderData} />
        <ImportantInfo />
      </div>

      <div className="flex sm:hidden">
        <Collapse label={'Price breakdown'}>
          <div className="flex flex-col gap-6">
            <PriceBreakdown data={orderData} />
            <ImportantInfo />
          </div>
        </Collapse>
      </div>

      <Modal1 open={openBaggage} setOpen={setOpenBaggage}>
        <div className="card p-10 flex flex-col gap-4">
          <b>Add Bag</b>
          <AddFlightBaggage data={{}} cancel={() => setOpenBaggage(false)} />
        </div>
      </Modal1>
      <Modal1 open={openSeats} setOpen={setOpenSeats}>
        <div className="card p-10 flex flex-col gap-4">
          <b>Add Seat</b>
          <AddFlightSeats data={{}} cancel={() => setOpenSeats(false)} />
        </div>
      </Modal1>
      {/* <CancelOrder open={cancelBooking} setOpen={() => setCancelBooking(false)}  /> */}
      {/* <Modal1 open={cancelBooking} setOpen={setCancelBooking}>
        <div className="card p-10 flex flex-col gap-4">
          <CancelFlightOrder data={{}} cancel={() => setCancelBooking(false)} />
        </div>
      </Modal1> */}
    </div>
  );
}


const PriceBreakdown = ({data}) => {
  console.log(data)
  const [openPriceBreakdown,setOpenPriceBreakdown] = useState(false);

  
  const breakdown = {
    total: "606.17 USD",
    detail: [
      {
        quantity: 1,
        feeName: "Adult",
        total: "606.17 USD",
        items: [
          { name: "Fare per adult", price: "534.1 USD" },
          { name: "Taxes per adult", price: "71.07 USD" },
        ],
      },
      { quantity: 1, feeName: "Ticketing fee", total: "1 USD" },
    ],
  };

  const fee = data?.fee || 0;
  
  return (
    <div className="w-full bg-theme1/5 rounded-md flex flex-col gap-4 p-4 mt-4">
      <div className="flex justify-end">
        <button onClick={() => setOpenPriceBreakdown(true)} className="text-sm flex gap-1 items-center text-theme1">Price breakdown <Icon icon='cuida:open-in-new-tab-outline' className='!w-4 !h-4' /></button>
      </div>
      <div>
        {data?.adult ? 
          <div>
              <div className="flex gap-4 justify-between font-bold">
                <div>{(data?.adult?.passengerCount||0)}x Adult</div>
                <div>{data?.adult?.totalPrice}</div>
              </div>
              <div className="pl-6 py-4 text-primary/60">
                <div className="flex justify-between gap-4 items-center">
                  <div>Fare per adult</div>
                  {data?.adult?.basePrice}
                </div>
                <div className="flex justify-between gap-4 items-center">
                  <div>Taxes per adult</div>
                  <div>{data?.adult?.taxes}</div>
                </div>
              </div>
          </div>
        :null}
      </div>
      <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
        <span className="">
          {data?.travelers?.length}x Ticketing Fee
        </span>
        <span className="">{formatMoney(data?.ticketingFee)}</span>
      </div>
      {/* {breakdown.detail.map((item, index) => (
        <div key={index} className="flex flex-col mb-2">
          <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
            <span className="">
              {item.quantity} x {item.feeName}
            </span>
            <span className="">{item.total}</span>
          </div>
          {item.items &&
            item.items?.map((subItem, subIndex) => (
              <div
                key={subIndex}
                className="flex items-center gap-2 justify-between text-gray-600 ml-2"
              >
                <span className="">{subItem.name}</span>
                <span className="">{subItem.price}</span>
              </div>
            ))}
        </div>
      ))} */}
      <div className="w-full border-b border-gray-300 mb-2"></div>
      <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
        <span className="">Total</span>
        <span className="">{formatMoney(data?.payable)}</span>
      </div>
      <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
        <span className="">Customer Payable</span>
        <span className="">{formatMoney(data?.customerPayable)}</span>
      </div>
      {/* <div className="flex font-bold w-full justify-end text-end text-primary1 text-sm lg:text-base mt-2">
        <span className="">Customer Payable</span>
        {data?.customerPayable}
      </div> */}
      {/* <div className="flex font-bold w-full justify-end text-end text-[#6D7C94] text-sm mt-2">
        NOT including payment fee
      </div> */}
      <Modal3 open={openPriceBreakdown} setOpen={setOpenPriceBreakdown}
        title='Price breakdown'>
          <div className="p-10">
            <PriceBreakdownDetail data={data} />
          </div>
      </Modal3>

    </div>
  );
};
