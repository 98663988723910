import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export const ModifyHotelBookingModalSteps = () => {
  const { data, tabs } = useModifyBookingModalContext();
  return (
    <div className="w-full mx-auto px-4">
      <div className="flex items-center gap-2 max-lg:overflow-x-auto justify-between">
        {tabs.map((step, index) => (
          <React.Fragment key={index}>
            <Step
              number={index + 1}
              title={step}
              active={index <= data?.tab}
              // activetab={() => setData({ ...data, tab: index })}
            />
            {index < tabs.length - 1 && (
              <>
                <img
                  src={step.id < data?.tab ? "/Line-blue.svg" : "/Line.svg"}
                  alt="range"
                  className="flex-shrink w-[40px] h-[4px] sm:w-[106px] sm:h-[6px] my-2"
                />
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

const Step = ({ number, title, active, activetab }) => {
  return (
    <button onClick={activetab} className="flex flex-col items-center">
      <div
        className={`text-sm lg:text-2xl h-10 w-10 flex items-center justify-center rounded-lg ${
          active
            ? "bg-primary1 text-secondary"
            : "bg-[#E8F4FF] text-primaryText "
        }`}
      >
        {number}
      </div>
      <span
        className={`text-xxxs lg:text-sm ${
          active ? "text-primary1" : "text-darkGray"
        }`}
      >
        {title}
      </span>
    </button>
  );
};
