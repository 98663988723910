import React from "react";
import ReactDOMServer from "react-dom/server";
import { tourBookingTravellers } from "../../../controllers/tourUIController";

function TourConfirmationTravellerPDFStatic(booking) {
  if (!booking) return "";

  const travellers = tourBookingTravellers({ booking }) || [];

  // Function to render a single traveller's section
  const renderTraveller = (traveller, travelerIndex) => {
    if (!traveller) return "";

    // Static HTML for the traveller section
    const travellerContent = (
      <div
        className="w-full rounded-md flex flex-col border border-gray-300 bg-white mb-4"
        key={travelerIndex}
      >
        <div className="w-full px-4 py-2 flex items-center gap-3 border-b border-gray-300">
          <div className="w-6 h-6 text-white bg-blue-600 font-bold text-sm rounded-md flex items-center justify-center">
            {travelerIndex + 1}
          </div>
          <span className="font-bold text-blue-600 text-sm lg:text-base">
            {traveller?.name}
          </span>
        </div>
        <div className="flex flex-wrap justify-between gap-2 lg:gap-4 py-2 px-4">
          {traveller?.travellerData.map((item, index) => (
            <div className="flex flex-col" key={index}>
              <span className="text-sm text-gray-500">{item?.label}</span>
              <span className="text-sm">{item?.value}</span>
            </div>
          ))}
        </div>
      </div>
    );

    return ReactDOMServer.renderToStaticMarkup(travellerContent);
  };

  let staticTravellerSection = travellers.map((traveller, travellerIndex) =>
    renderTraveller(traveller, travellerIndex)
  );
  staticTravellerSection = [
    ReactDOMServer.renderToStaticMarkup(
      <span className="text-base font-bold lg:text-xl mb-2 mt-4">
        Travellers
      </span>
    ),
    ...staticTravellerSection,
  ].join(" ");

  return staticTravellerSection; // Return the static HTML string
}

export default TourConfirmationTravellerPDFStatic;
