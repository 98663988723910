import { useSnackbar } from "notistack";

export default function useDateFormat() {
  const { enqueueSnackbar } = useSnackbar();
  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const selectedDate = date ? new Date(date) : today;
    selectedDate.setHours(0, 0, 0, 0);

    return selectedDate < today;
  };

  const compareDate = (date1, date2) => {
    const today = new Date();
    const selectedDate1 = date1 ? new Date(date1) : today;
    const selectedDate2 = date2 ? new Date(date2) : today;

    if (selectedDate1 < selectedDate2) {
      return true;
    }
    return false;
  };

  const isSameDate = (date1, date2) => {
    const today = new Date();
    const selectedDate1 = date1 ? new Date(date1) : today;
    const selectedDate2 = date2 ? new Date(date2) : today;
    if (selectedDate1 === selectedDate2) {
      return true;
    }
    return false;
  };

  const formatDate = (date, onlyDayAndMonth) => {
    const today = new Date();
    const selectedDate = date ? new Date(date) : today;

    const year = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = selectedDate.getDate().toString().padStart(2, "0");
    const formattedDate = onlyDayAndMonth
      ? `${month}-${day}`
      : `${year}-${month}-${day}`;

    return formattedDate;
  };

  const formatDate2 = (date, onlyDayAndMonth) => {
    const today = new Date();
    const selectedDate = date ? new Date(date) : today;

    const year = selectedDate.getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[selectedDate.getMonth()];

    const formattedDate = `${month} ${year}`;
    return formattedDate;
  };

  const formatTime = (date) => {
    const today = new Date();
    const selectedDate = date ? new Date(date) : today;

    const hours = selectedDate.getHours().toString().padStart(2, "0");
    const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  };

  function capitalizeFirstLetter(str, trim) {
    if (typeof str !== "string") {
      return str;
    }

    str = str.replace(/_/g, " ");

    let finalString = str
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
    if (trim) {
      if (finalString?.length > 18) {
        finalString = finalString.slice(0, 18) + "...";
      }
    }

    return finalString;
  }

  function calculateAge(dateString) {
    const birthDate = new Date(dateString);

    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    const dayDiff = currentDate.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    return age;
  }

  const isAllChildrenAgeSelected = (rooms) => {
    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i];
      for (let j = 0; j < room.children.length; j++) {
        const childAge = room.children[j];
        if (childAge === null || childAge === "") {
          enqueueSnackbar(`Please select children age in room ${i + 1}`, {
            variant: "warning",
          });
          return false;
        }
      }
    }
    return true;
  };

  return {
    isPastDate,
    isSameDate,
    formatDate,
    formatDate2,
    compareDate,
    formatTime,
    capitalizeFirstLetter,
    calculateAge,
    isAllChildrenAgeSelected,
  };
}
