import React from "react";
import ModalContainer from "../../common/ModalContainer";
import TourConfirmationBookedTourSection from "./TourConfirmationBookedTourSection";
import TourConfirmationTourDetailSection from "./TourConfirmationTourDetailSection";
import TourConfirmationTravelerSection from "./TourConfirmationTravelerSection";

export function TourOrderDetailModal({ open, setOpen, booking }) {
  return (
    <ModalContainer open={open} setOpen={setOpen} title="Tour order detail">
      <TourConfirmationBookedTourSection booking={booking} />
      <TourConfirmationTourDetailSection booking={booking} />
      <TourConfirmationTravelerSection booking={booking} />

      {/* <div className="w-full flex flex-col" >
        
      </div> */}
    </ModalContainer>
  );
}
