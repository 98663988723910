import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useDateFormat from "./useDateFormat";
import { enqueueSnackbar } from "notistack";
import { URLdecode, URLencode } from "../utils/url";
import { setToursFilter } from "../redux/reducers/tour/filterSlice";
import { setTourActivity } from "../redux/reducers/tour/tourDataSlice";
import ISO6391 from "iso-639-1";
import { formatMoney } from "../features/utils/formatMoney";
import moment from "moment";

export default function useToursUI() {
  const { user } = useSelector((state) => state.user.userData);
  const params = URLdecode();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatDate, isPastDate, capitalizeFirstLetter } = useDateFormat();

  const handleTourSearch = ({ data }) => {
    if (!data?.destination)
      return enqueueSnackbar("Location is required", { variant: "warning" });
    if (isPastDate(data?.date)) {
      enqueueSnackbar("Departure date can't be a past date", {
        variant: "warning",
      });
      return;
    }

    const parameters = {
      location: data?.country,
      destinationId: data?.destination,
      date: formatDate(data?.date),
      tag: data?.tag,
      reload: params?.reload ? Number(params?.reload) + 1 : 0,
    };
    navigate(`/tour/result?${URLencode(parameters)}`);
  };

  const getActualMinAndMaxPrice = (activities) => {
    let minPrice = 0;
    let maxPrice = 0;
    if (activities.length === 0) {
      minPrice = 0;
      maxPrice = 0;
      return;
    }

    minPrice = Number(activities[0]?.convertedPrice);
    maxPrice = minPrice;

    for (let tour of activities) {
      let price = Number(tour?.convertedPrice);

      if (price < minPrice) {
        minPrice = price;
      }

      if (price > maxPrice) {
        maxPrice = price;
      }
    }
    dispatch(
      setToursFilter({
        actualMaxPrice: maxPrice,
        priceMax: 100,
        actualMinPrice: minPrice,
        priceMin: 0,
      })
    );
  };

  const parsedAvailabilities = ({ tour, availability }) => {
    let items = [];
    if (tour) {
      let productOptions = tour?.productOptions;
      if (productOptions) {
        let packages = availability?.bookableItems?.filter(
          (pkg) => pkg.available
        );
        if (packages) {
          productOptions = productOptions.map((option) => ({
            ...option,
            options: packages.filter(
              (pkg) => pkg?.productOptionCode === option?.productOptionCode
            ),
          }));
        }
      } else if (!productOptions && availability?.bookableItems?.length > 0) {
        let packages = availability?.bookableItems?.filter(
          (pkg) => pkg.available
        );

        if (packages?.length > 0) {
          productOptions = [
            {
              title: packages[0]?.title || tour?.title,
              productOptionCode: packages[0]?.productOptionCode,
              options: packages,
            },
          ];
        }
      }
      items = productOptions?.filter((item) => item.options?.length > 0);
    }
    return items;
  };

  const packagePriceDetail = ({ item, pkg }) => {
    let detail = {
      price: "",
      detail: "",
      available: "",
      reason: "",
      title: item?.productOptionCode,
      description: item?.description,
      title: item?.title,
    };
    let selectedPackage = item;

    if (selectedPackage) {
      let selectedTime = selectedPackage?.options?.[pkg?.time];
      if (selectedTime) {
        detail.available = selectedTime?.available;
        let reason = selectedTime?.unavailableReason;
        if (reason) {
          reason = reason.replace(/_/g, " ").toLowerCase();
          detail.reason = reason.charAt(0).toUpperCase() + reason.slice(1);
        }
        let farePrice = selectedTime?.totalPrice?.farePrice;
        if (farePrice) {
          detail.price = Number(farePrice?.total?.toFixed(2));
        }
        let lineItems = selectedTime?.lineItems;
        if (lineItems) {
          let formattedItems = lineItems.map((line) => {
            let ageBand = line.ageBand.toLowerCase();
            ageBand = ageBand.charAt(0).toUpperCase() + ageBand.slice(1);
            if (ageBand === "Child" && line?.numberOfTravelers !== 1)
              ageBand += "ren";
            if (ageBand !== "Child" && line?.numberOfTravelers !== 1)
              ageBand += "s";
            if (line?.numberOfTravelers > 0) {
              return `${line.numberOfTravelers}${ageBand} x ${formatMoney(
                line?.subtotalPrice?.price?.partnerNetPrice
              )}`;
            }
          });

          detail.detail = formattedItems.join(", ");
        }
      }
    }

    return detail;
  };

  const handleBookTour = ({ tour, availability, pkg }) => {
    let selectedPackage = parsedAvailabilities({ tour, availability })?.find(
      (pkage) => pkage?.productOptionCode === pkg?.code
    );
    let selectedOption = selectedPackage?.options[pkg?.time];

    let parameters = {
      ...params,
      productOptionCode: pkg?.code,
      startTime: selectedOption?.startTime,
      newSession: true,
      sessionID: moment().format("YYYYMMDDHHmmss"),
    };

    if (tour?.languageGuides || selectedPackage?.languageGuides) {
      parameters = {
        ...parameters,
        languageGuides: selectedPackage?.languageGuides
          ? JSON.stringify(selectedPackage?.languageGuides)
          : JSON.stringify(tour?.languageGuides),
      };
    }

    navigate(`/tour/checkout?${URLencode(parameters)}`);
  };

  const groupQuestionTypes = {
    TRANSFER_ARRIVAL_MODE: {
      AIR: [
        "TRANSFER_AIR_ARRIVAL_AIRLINE",
        "TRANSFER_AIR_ARRIVAL_FLIGHT_NO",
        "TRANSFER_ARRIVAL_TIME",
        "TRANSFER_ARRIVAL_DROP_OFF",
        "PICKUP_POINT",
      ],
      RAIL: [
        "TRANSFER_RAIL_ARRIVAL_LINE",
        "TRANSFER_RAIL_ARRIVAL_STATION",
        "TRANSFER_ARRIVAL_TIME",
        "TRANSFER_ARRIVAL_DROP_OFF",
      ],
      SEA: [
        "TRANSFER_PORT_ARRIVAL_TIME",
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_ARRIVAL_DROP_OFF",
        "PICKUP_POINT",
      ],
      OTHER: ["PICKUP_POINT"],
      types: [
        "TRANSFER_AIR_ARRIVAL_AIRLINE",
        "TRANSFER_AIR_ARRIVAL_FLIGHT_NO",
        "TRANSFER_RAIL_ARRIVAL_LINE",
        "TRANSFER_RAIL_ARRIVAL_STATION",
        "TRANSFER_PORT_ARRIVAL_TIME",
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_ARRIVAL_TIME",
        "TRANSFER_ARRIVAL_DROP_OFF",
        "PICKUP_POINT",
      ],
    },
    TRANSFER_DEPARTURE_MODE: {
      AIR: [
        "TRANSFER_AIR_DEPARTURE_AIRLINE",
        "TRANSFER_AIR_DEPARTURE_FLIGHT_NO",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
      RAIL: [
        "TRANSFER_RAIL_DEPARTURE_LINE",
        "TRANSFER_RAIL_DEPARTURE_STATION",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
      SEA: [
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_PORT_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
      OTHER: [],
      types: [
        "TRANSFER_AIR_DEPARTURE_AIRLINE",
        "TRANSFER_AIR_DEPARTURE_FLIGHT_NO",
        "TRANSFER_RAIL_DEPARTURE_LINE",
        "TRANSFER_RAIL_DEPARTURE_STATION",
        "TRANSFER_PORT_CRUISE_SHIP",
        "TRANSFER_PORT_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_DATE",
        "TRANSFER_DEPARTURE_TIME",
        "TRANSFER_DEPARTURE_PICKUP",
      ],
    },
  };

  const isTourSaved = ({ tour, saved }) => {
    return saved;
  };

  const saveTour = ({ tour }) => {};

  const getTourLocation = ({ tour }) => {
    let start = tour?.logistics?.start?.at(0)?.location?.ref;
    let location =
      tour?.locations?.find((loc) => loc?.reference === start) ||
      tour?.locations?.at(0);

    if (location) {
      return location?.name || location?.address?.street;
    }

    return "Location not available";
  };

  const getTourDuration = ({ tour }) => {
    let time = tour?.itinerary?.duration?.fixedDurationInMinutes;

    const convertMinutes = (totalMinutes) => {
      let timeString = "";
      const hours = Math.floor(totalMinutes / 60);
      if (hours) {
        timeString += `${hours} hour(s)`;
      }
      const minutes = totalMinutes % 60;
      if (minutes) {
        timeString += ` ${minutes} minute(s)`;
      }
      return timeString;
    };

    if (time !== undefined && time !== null) {
      return convertMinutes(time);
    }

    return "Duration not available";
  };

  const handleOpenTour = ({ tour }) => {
    const params = URLdecode();
    dispatch(setTourActivity(tour));

    const parameters = {
      ...params,
      productCode: tour?.productCode,
      supplier: tour?.package_supplier,
      tourDuration: getTourDuration({ tour }),
    };
    let url = `/tour/singleTour?${URLencode(parameters)}`;
    localStorage.setItem(`tour ${tour?.productCode}`, JSON.stringify(tour));

    window.open(url, "_blank");
  };

  const parseTags = ({ tour }) => {
    let allTags = [];
    if (tour?.tags) {
      let displayableTags = [21972, 21873, 22083, 11940, 21074];
      let availableTags = tour?.tags;
      availableTags = availableTags?.filter((tag) => {
        let available = displayableTags.find((tg) => tg === tag?.tagId);
        if (available) return true;
        return false;
      });

      allTags = availableTags;
    }

    return allTags;
  };

  const coverTourImage = ({ tour }) => {
    let cover = "";
    if (tour) {
      if (tour?.images) {
        tour?.images?.map((image, index) => {
          if (image?.isCover === true) {
            cover = image?.variants?.reduce((maxVariant, currentVariant) => {
              return currentVariant?.height > (maxVariant?.height || 0)
                ? currentVariant
                : maxVariant;
            }, null)?.url;
          }
        });
        if (!cover) {
          cover = tour?.images[0]?.variants?.reduce(
            (maxVariant, currentVariant) => {
              return currentVariant?.height > (maxVariant?.height || 0)
                ? currentVariant
                : maxVariant;
            },
            null
          )?.url;
        }
      }
    }
    return cover;
  };

  const tourRating = ({ num = true, tour }) => {
    if (tour?.reviews) {
      if (tour?.reviews?.sources) {
        if (num) {
          return tour?.reviews?.sources[0]?.averageRating;
        } else {
          return `${tour?.reviews?.sources[0]?.averageRating} (${tour?.reviews?.sources[0]?.reviewCounts?.length}) reviews`;
        }
      }
    }

    return "";
  };

  const tourLanguages = ({ tour }) => {
    let languageString = "";

    let availableLanguages = tour?.languageGuides || [];
    if (availableLanguages && Array.isArray(availableLanguages)) {
      availableLanguages = [
        ...new Set(availableLanguages?.map((guide) => guide?.language)),
      ];

      for (let i = 0; i < availableLanguages.length; i++) {
        languageString +=
          i !== 0
            ? `, ${ISO6391.getName(availableLanguages[i]) || "Unkown language"}`
            : ISO6391.getName(availableLanguages[i]) || "Unkown language";
      }
    }

    return languageString;
  };

  const saveToRecentSearch = (tour) => {
    const parameters = URLdecode();
    let savedSearches = localStorage.getItem("tourRecentSearch");
    savedSearches = savedSearches ? JSON.parse(savedSearches) : [];
    let foundIndex = savedSearches?.findIndex(
      (savedSearch) => savedSearch?.params?.productCode === tour?.productCode
    );

    if (foundIndex > -1) {
      const existingTour = savedSearches.splice(foundIndex, 1)[0];

      savedSearches.unshift({
        tour: tour,
        params: parameters,
      });
    } else {
      savedSearches.unshift({
        tour: tour,
        params: params,
      });
    }
    savedSearches = savedSearches.slice(0, 10);
    localStorage.setItem("tourRecentSearch", JSON.stringify(savedSearches));
  };

  const parseTourDetail = ({ tour }) => {
    let data = [
      {
        title: "About this tour",
        sectionRef: "overview",
        opened: true,
        detail: [
          { title: "Overview", content: tour?.description, type: "full" },
        ].filter((item) => item.content),
      },
      {
        title: "Full description",
        sectionRef: "about",
        opened: true,
        detail: [
          {
            content:
              tour?.itinerary?.activityInfo?.description ||
              tour?.itinerary?.unstructuredDescription,
            type: "full",
          },
        ].filter((item) => item.content),
      },
      {
        title: "Includes",
        sectionRef: "about",
        opened: true,
        detail: [
          {
            list: tour?.inclusions?.map((include) =>
              include?.type === "OTHER"
                ? include?.otherDescription
                : `${include?.categoryDescription} - ${include?.typeDescription}`
            ),
            type: "full",
          },
        ].filter((item) => item.list?.length > 0),
      },
      {
        title: "Excludes",
        sectionRef: "about",
        opened: true,
        detail: [
          {
            list: tour?.exclusions?.map((exclude) =>
              exclude?.type === "OTHER"
                ? exclude?.otherDescription
                : `${exclude?.categoryDescription} - ${exclude?.typeDescription}`
            ),
            type: "full",
          },
        ].filter((item) => item.list?.length > 0),
      },
      {
        title: "Meeting & Pickup Point",
        sectionRef: "about",
        opened: true,
        detail: [
          {
            title: "Meeting Point",
            content: tour.logistics?.start?.at(0)?.description,
            type: "full",
          },
          {
            title: "Pickup Point",
            content: tour.logistics?.end?.at(0)?.description,
            type: "full",
          },
        ].filter((item) => item.content),
      },
      {
        title: "Important Information",
        sectionRef: "about",
        opened: true,
        detail: [
          {
            list: tour.additionalInfo
              ?.map((item) => item?.description || "")
              ?.filter(Boolean),
            type: "full",
          },
        ].filter((item) => item.list?.length > 0),
      },
    ];

    return data;
  };

  const sortBookingQuestions = (questions) => {
    return questions
      .sort((a, b) => {
        const aIsArray = Array.isArray(a?.units);
        const bIsArray = Array.isArray(b?.units);

        if (!aIsArray && bIsArray) return -1;
        if (aIsArray && !bIsArray) return 1;
        return 0;
      })
      .sort((a, b) => a.legacyBookingQuestionId - b.legacyBookingQuestionId)
      .sort((a, b) => {
        // Priority: FULL_NAMES_FIRST first
        if (a.id === "FULL_NAMES_FIRST") return -1;
        if (b.id === "FULL_NAMES_FIRST") return 1;

        // // Priority: FULL_NAMES_LAST second
        if (a.id === "FULL_NAMES_LAST") return -1;
        if (b.id === "FULL_NAMES_LAST") return 1;

        // // Priority: FULL_NAMES_LAST second
        if (a.id === "TRANSFER_ARRIVAL_MODE") return -1;
        if (b.id === "TRANSFER_ARRIVAL_MODE") return 1;

        // // Priority: TRANSFER_ARRIVAL_MODE third
        if (a.id === "TRANSFER_ARRIVAL_MODE") return -1;
        if (b.id === "TRANSFER_ARRIVAL_MODE") return 1;

        // // Priority: TRANSFER_DEPARTURE_MODE forth
        if (a.id === "TRANSFER_DEPARTURE_MODE") return -1;
        if (b.id === "TRANSFER_DEPARTURE_MODE") return 1;
        return 0;
      })
      .sort((a, b) => {
        if (a.id === "TRANSFER_ARRIVAL_DROP_OFF") return 1;
        if (b.id === "TRANSFER_ARRIVAL_DROP_OFF") return -1;
        return 0;
      })
      .sort((a, b) => {
        if (a.id === "TRANSFER_DEPARTURE_PICKUP") return 1;
        if (b.id === "TRANSFER_DEPARTURE_PICKUP") return -1;
        return 0;
      });
  };

  const parseSingleQuestion = ({ question, item }) => {
    let questionType = question?.allowedAnswers ? "dropdown" : "input";
    let unit = question?.units?.length ? true : false;
    let parsedQuestion = {
      ...question,
      questionType,
      unitEnabled: unit,
      full: unit,
      focused: false,
      error: false,
      scrollToView: false,
    };
    if (unit) {
      parsedQuestion = {
        ...parsedQuestion,
        unit: question?.units[0],
      };
    }
    if (question?.id === "AGEBAND") {
      parsedQuestion = {
        ...parsedQuestion,
        answer: item?.ageBand,
        hide: true,
        full: true,
      };
    }

    if (
      ["TRANSFER_DEPARTURE_MODE", "TRANSFER_ARRIVAL_MODE"]?.includes(
        question?.id
      )
    ) {
      // To make the options natural language as per supplier suggestion
      let newAllowedAnswers = question?.allowedAnswers.map((answer) => {
        let description =
          answer === "AIR"
            ? "Airport"
            : answer === "RAIL"
            ? "Train station"
            : answer === "SEA"
            ? "Port"
            : answer === "OTHER"
            ? "Hotel / Specific address"
            : capitalizeFirstLetter(answer);
        return {
          label: description,
          value: answer,
        };
      });
      if (question?.id === "TRANSFER_DEPARTURE_MODE") {
        newAllowedAnswers = newAllowedAnswers?.filter(
          (answer) => answer.value !== "OTHER"
        );
      }
      parsedQuestion = {
        ...parsedQuestion,
        full: true,
        parsedAllowedAnswers: newAllowedAnswers,
      };
    }

    if (question?.id === "TRANSFER_DEPARTURE_PICKUP") {
      parsedQuestion = {
        ...parsedQuestion,
        label: "Where would you like to be picked up from?",
      };
    }

    if (question?.id === "TRANSFER_DEPARTURE_PICKUP") {
      parsedQuestion = {
        ...parsedQuestion,
        label: "Where would you like to be picked up from?",
      };
    }

    if (question?.id === "PICKUP_POINT") {
      parsedQuestion = {
        ...parsedQuestion,
        label: "Where would you like to be picked up from?",
      };
    }
    if (question?.id === "TRANSFER_ARRIVAL_DROP_OFF") {
      parsedQuestion = {
        ...parsedQuestion,
        label: "Where would you like to be dropped off?",
      };
    }

    return parsedQuestion;
  };

  const parseTourPersonalBookingQuestions = ({
    tour,
    availability,
    productOptionCode,
  }) => {
    const availableItems = parsedAvailabilities({ tour, availability });
    const selectedItem = availableItems?.find(
      (item) => item.productOptionCode === productOptionCode
    );
    let bookingQuestions = tour?.bookingQuestions || [];

    bookingQuestions = sortBookingQuestions(
      bookingQuestions.filter((question) => question.group === "PER_TRAVELER")
    );

    if (!bookingQuestions?.length) return [];

    const lineItems = selectedItem?.options?.at(0)?.lineItems || [];
    const travellers = [];
    for (let item of lineItems) {
      if (item?.numberOfTravelers) {
        for (let i = 0; i < item.numberOfTravelers; i++) {
          travellers.push({
            ageBand: item?.ageBand,
            title: `${capitalizeFirstLetter(item?.ageBand)} ${i + 1}`,
            questions: bookingQuestions?.map((question) => {
              return parseSingleQuestion({ question, item });
            }),
          });
        }
      }
    }

    return travellers;
  };

  const selectedPackageIsShared = ({ tour, productOptionCode }) => {
    const selectedItem = tour?.productOptions?.find(
      (item) => item.productOptionCode === productOptionCode
    );
    if (selectedItem) {
      let isShared =
        selectedItem?.description?.includes("share Group") ||
        selectedItem?.title?.includes("Share Group Tour");
      if (isShared) {
        return true;
      }
    }
    return false;
  };

  const parseTourDepartureBookingQuestions = ({ tour, productOptionCode }) => {
    let bookingQuestions = tour?.bookingQuestions || [];

    // Filtering questions by booking only
    bookingQuestions = bookingQuestions
      .filter((question) => question.group === "PER_BOOKING")
      .sort((a, b) => a.legacyBookingQuestionId - b.legacyBookingQuestionId);

    // Listing possible departure question types
    const departureQuestionTypes = [
      "TRANSFER_DEPARTURE_MODE",
      ...groupQuestionTypes?.TRANSFER_DEPARTURE_MODE?.types,
    ];

    // Parsing each question to properly display it
    bookingQuestions = sortBookingQuestions(
      bookingQuestions?.filter((question) =>
        departureQuestionTypes.includes(question.id)
      )
    ).map((question) => {
      return parseSingleQuestion({ question, item: null });
    });

    // Hiding departure questions untill TRANSFER_DEPARTURE_MODE is selected
    if (
      bookingQuestions?.find(
        (question) => question.id === "TRANSFER_DEPARTURE_MODE"
      )
    ) {
      bookingQuestions = bookingQuestions?.map((question) =>
        question.id === "TRANSFER_DEPARTURE_MODE"
          ? question
          : { ...question, hide: true }
      );
    }

    if (!bookingQuestions?.length) return [];

    // Used to determine if the selected package is a shared package so that people won't be asked questions about departure
    if (selectedPackageIsShared({ tour, productOptionCode })) {
      bookingQuestions = bookingQuestions?.map((question) =>
        question?.id === "TRANSFER_DEPARTURE_MODE"
          ? { ...question, answer: "OTHER", hide: true }
          : { ...question, hide: true }
      );
    }
    return bookingQuestions;
  };

  const parseTourArrivalBookingQuestions = ({ tour, productOptionCode }) => {
    let bookingQuestions = tour?.bookingQuestions || [];

    // Filtering questions by booking only
    bookingQuestions = bookingQuestions
      .filter((question) => question.group === "PER_BOOKING")
      .sort((a, b) => a.legacyBookingQuestionId - b.legacyBookingQuestionId);

    // Listing possible arrival question types
    const departureQuestionTypes = [
      "TRANSFER_ARRIVAL_MODE",
      ...groupQuestionTypes?.TRANSFER_ARRIVAL_MODE?.types,
    ];

    // Parsing each question to properly display it
    bookingQuestions = sortBookingQuestions(
      bookingQuestions?.filter((question) =>
        departureQuestionTypes.includes(question.id)
      )
    ).map((question) => {
      return parseSingleQuestion({ question, item: null });
    });

    // Hiding arrival questions untill TRANSFER_ARRIVAL_MODE is selected
    if (
      bookingQuestions?.find(
        (question) => question.id === "TRANSFER_ARRIVAL_MODE"
      )
    ) {
      bookingQuestions = bookingQuestions?.map((question) =>
        question.id === "TRANSFER_ARRIVAL_MODE"
          ? question
          : { ...question, hide: true }
      );
    }

    if (!bookingQuestions?.length) return [];

    // Used to determine if the selected package is a shared package so that people won't be asked questions about pickup but mmandatory fields will be populated
    if (selectedPackageIsShared({ tour, productOptionCode })) {
      bookingQuestions = bookingQuestions?.map((question) =>
        question?.id === "TRANSFER_ARRIVAL_MODE"
          ? { ...question, answer: "OTHER", hide: true }
          : question?.id === "PICKUP_POINT"
          ? { ...question, answer: "MEET_AT_DEPARTURE_POINT", hide: true }
          : { ...question, hide: true }
      );
    }

    return bookingQuestions;
  };

  const parseTourMeetingPoint = ({ tour, productOptionCode }) => {
    const startPoint = tour?.logistics?.start?.at(0);
    const pickupOptionType = tour?.logistics?.travelerPickup?.pickupOptionType;

    // Used to determine if the selected package is a shared package so that people won't be asked questions about pickup
    if (selectedPackageIsShared({ tour, productOptionCode })) {
      let location = tour?.locations?.find(
        (location) => location?.reference === startPoint?.location?.ref
      )?.name;
      if (location) {
        return {
          pickupOptionType: "SHARED",
          location,
        };
      }
      return null;
    }

    if (pickupOptionType === "MEET_EVERYONE_AT_START_POINT") {
      let location = tour?.locations?.find(
        (location) => location?.reference === startPoint?.location?.ref
      )?.name;

      if (location) {
        return {
          pickupOptionType,
          location,
        };
      }
    } else if (
      pickupOptionType === "PICKUP_AND_MEET_AT_START_POINT" ||
      pickupOptionType === "PICKUP_EVERYONE"
    ) {
      const locations = tour?.logistics?.travelerPickup?.locations;
      const options = [
        {
          value: "PICK_UP",
          label: "I’d like to be picked up",
        },
      ];
      if (locations && locations.length > 0) {
        if (
          locations?.find(
            (location) => location.location?.ref === "MEET_AT_DEPARTURE_POINT"
          )
        ) {
          const start = tour?.locations?.find(
            (location) => location?.reference === startPoint?.location?.ref
          );
          let meetingLocation = start?.name || "";
          if (startPoint?.description) {
            meetingLocation = startPoint?.description + ", " + meetingLocation;
          }
          options.push({
            value: "MEET_AT_DEPARTURE_POINT",
            label: "I’ll make my own way to the meeting point",
            location: meetingLocation,
          });
        }
        if (
          locations?.find(
            (location) => location.location?.ref === "CONTACT_SUPPLIER_LATER"
          )
        ) {
          options.push({
            value: "CONTACT_SUPPLIER_LATER",
            label: "I’ll decide later",
          });
        }
      }
      return {
        pickupOptionType: "PICKUP_AND_MEET_AT_START_POINT",
        // pickupOptionType: pickupOptionType,
        toggle: true,
        selected: "PICK_UP",
        options: options,
      };
    }

    return null;
  };

  const parseTourDeparture = ({ tour }) => {
    const options = [
      {
        value: "DEPARTURE",
        label: "My departure info",
      },
      {
        value: "OTHER",
        label: "Doesn't apply to me",
      },
    ];

    return {
      selected: "DEPARTURE",
      options: options,
    };
  };

  const parseTourOptionalBookingQuestions = ({ tour }) => {
    let bookingQuestions = tour?.bookingQuestions || [];

    bookingQuestions = bookingQuestions
      .filter((question) => question.group === "PER_BOOKING")
      .filter((question) => question.required === "OPTIONAL")
      .sort((a, b) => a.legacyBookingQuestionId - b.legacyBookingQuestionId);

    bookingQuestions = sortBookingQuestions(bookingQuestions).map(
      (question) => {
        let questionType = question?.allowedAnswers ? "dropdown" : "input";
        let unit = question?.units?.length ? true : false;
        let parsedQuestion = {
          ...question,
          questionType,
          unitEnabled: unit,
          full: unit,
        };
        if (unit) {
          parsedQuestion = { ...parsedQuestion, unit: question?.units[0] };
        }

        if (question?.id === "PICKUP") {
          return {
            ...parsedQuestion,
            label: "Where would you like to be picked up from?",
          };
        }
        if (question?.id === "TRANSFER_ARRIVAL_DROP_OFF") {
          return {
            ...parsedQuestion,
            label: "Where would you like to be dropped off?",
          };
        }

        return parsedQuestion;
      }
    );

    if (!bookingQuestions?.length) return [];

    return bookingQuestions;
  };

  const parseTourLanguageOptions = ({
    tour,
    availability,
    productOptionCode,
  }) => {
    const availableItems = parsedAvailabilities({ tour, availability });
    const selectedItem = availableItems?.find(
      (item) => item.productOptionCode === productOptionCode
    );

    let languageGuides = selectedItem?.languageGuides || tour?.languageGuides;
    if (languageGuides && languageGuides?.length > 0) {
      languageGuides = languageGuides.map((guide) => {
        return {
          ...guide,
          label: `${
            ISO6391.getName(guide?.language) || "Unknown language"
          } - ${capitalizeFirstLetter(guide?.type)}`,
        };
      });

      return {
        selected: languageGuides[0]?.label,
        options: languageGuides,
      };
    }

    return null;
  };

  const parseTourBookingQuestions = ({
    tour,
    setData,
    availability,
    productOptionCode,
    savedPaxMix,
    cart,
    startTime,
    travelDate,
    productCode,
  }) => {
    const parameters = URLdecode();
    const userPhone = user?.phone?.split("-");
    let phoneNumber = "";
    let countryCode = "";
    if (userPhone?.length > 0) {
      phoneNumber = userPhone[1];
      countryCode = userPhone[0];
    } else {
      phoneNumber = "";
      countryCode = "234";
    }

    if (!cart) {
      const savedData = localStorage.getItem(
        `tourCheckout ${parameters?.sessionID}`
      );
      if (savedData) {
        setData(JSON.parse(savedData));
        return;
      }
    }

    const personalQuestions = parseTourPersonalBookingQuestions({
      tour,
      availability,
      productOptionCode: productOptionCode || parameters?.productOptionCode,
    });

    const paxMix =
      savedPaxMix || (parameters?.paxMix ? JSON.parse(parameters?.paxMix) : []);

    const details = {
      id: parameters?.sessionID || moment().format("YYYYMMDDHHmmss"),
      email: user?.email || "",
      phone: phoneNumber || "",
      countryCode: countryCode || "",
      step: 0,
      packageOpened: false,
      startTime: startTime || parameters?.startTime,
      productOptionCode:
        productOptionCode || parameters?.productOptionCode || undefined,
      productCode: productCode || parameters?.productCode,
      date: travelDate || parameters?.date,
      payOption: "now",
      paxMix,
    };
    let data = {
      ...details,
      personalQuestions,
      arrivalQuestions: parseTourArrivalBookingQuestions({
        tour,
        productOptionCode: parameters?.productOptionCode,
      }),
      departureQuestions: parseTourDepartureBookingQuestions({
        tour,
        productOptionCode: parameters?.productOptionCode,
      }),
      additionalQuestions: parseTourOptionalBookingQuestions({ tour }),
      meetingPoint: parseTourMeetingPoint({
        tour,
        productOptionCode: parameters?.productOptionCode,
      }),
      departure: parseTourDeparture({ tour }),
      languageGuide: parseTourLanguageOptions({
        tour,
        availability,
        productOptionCode: parameters?.productOptionCode,
      }),
    };
    // Removing redundant questions
    data = {
      ...data,
      departureQuestions: data?.departureQuestions?.filter(
        (question) =>
          !data?.arrivalQuestions?.some((aq) => aq?.id === question?.id)
      ),
    };
    setData(data);
    if (parameters?.sessionID && !cart) {
      navigate(
        `${location.pathname}?${URLencode({
          ...parameters,
          sessionID: details?.id,
        })}`,
        { replace: true }
      );
    }
  };

  const bookingQuestionsArrivalModeChange = ({
    tour,
    data,
    setData,
    question,
    value,
  }) => {
    let arrivalQuestions = data?.arrivalQuestions;
    let selectedQuestionTypes =
      groupQuestionTypes?.TRANSFER_ARRIVAL_MODE[value];
    arrivalQuestions = arrivalQuestions?.map((aQuestion) => {
      if (aQuestion?.id === question?.id)
        return {
          ...aQuestion,
          answer: value,
          error: false,
          scrollToView: false,
        };
      if (selectedQuestionTypes.includes(aQuestion.id))
        return { ...aQuestion, hide: false, answer: "" };
      return { ...aQuestion, hide: true, answer: "" };
    });

    // This logic is to filter out locations to pick for the selected travel mode if PICKUP_POINT or TRANSFER_ARRIVAL_DROP_OFF is available or custom pickup is allowed
    if (
      arrivalQuestions
        ?.filter((question) => !question?.hide)
        ?.find(
          (question) =>
            question?.id === "PICKUP_POINT" ||
            question?.id === "TRANSFER_ARRIVAL_DROP_OFF"
        )
    ) {
      let customLocation =
        tour?.logistics.travelerPickup.allowCustomTravelerPickup;

      let pickupType =
        value === "AIR"
          ? ["AIRPORT"]
          : value === "SEA"
          ? ["PORT"]
          : value === "RAIL"
          ? []
          : ["LOCATION", "HOTEL", "OTHER"];
      const locationOptions =
        tour?.logistics?.travelerPickup?.locations?.filter((location) =>
          pickupType?.includes(location?.pickupType)
        ) || [];
      let locations = [];

      if (locationOptions?.length > 0 || customLocation) {
        locations = locationOptions
          .map((location) => {
            let fetchedLocation = tour?.locations?.find(
              (loc) => location.location?.ref === loc?.reference
            );
            if (fetchedLocation) {
              return {
                label: fetchedLocation?.name,
                reference: fetchedLocation?.reference,
                address: fetchedLocation?.address?.street,
                type: location?.pickupType,
              };
            }

            return false;
          })
          ?.filter(Boolean);
        if (locations?.length > 0) {
          // Making PICKUP_POINT and TRANSFER_ARRIVAL_DROP_OFF a selectable locations because there are available locations to select from
          arrivalQuestions = arrivalQuestions?.map((question) => {
            if (
              (question?.id === "PICKUP_POINT" ||
                question?.id === "TRANSFER_ARRIVAL_DROP_OFF") &&
              question?.type === "LOCATION_REF_OR_FREE_TEXT"
            ) {
              return {
                ...question,
                unit: "LOCATION_REFERENCE",
                unitEnabled: false,
                locationSelect: true,
              };
            } else {
              return question;
            }
          });
        } else if (customLocation) {
          // Making PICKUP_POINT and TRANSFER_ARRIVAL_DROP_OFF a free text when only custom locations is allowed because there are no available locations to select from
          arrivalQuestions = arrivalQuestions?.map((question) => {
            if (
              (question?.id === "PICKUP_POINT" ||
                question?.id === "TRANSFER_ARRIVAL_DROP_OFF") &&
              question?.type === "LOCATION_REF_OR_FREE_TEXT"
            ) {
              return {
                ...question,
                unit: "FREETEXT",
                unitEnabled: false,
                locationSelect: false,
              };
            } else {
              return question;
            }
          });

          console.log({ arrivalQuestions });
        } else {
          // Hiding PICKUP_POINT and TRANSFER_ARRIVAL_DROP_OFF because there are no locations to select and custom pickup is not allowed
          arrivalQuestions = arrivalQuestions?.map((question) => {
            if (
              question?.id === "PICKUP_POINT" ||
              question?.id === "TRANSFER_ARRIVAL_DROP_OFF"
            ) {
              return {
                ...question,
                hide: true,
                answer: "",
              };
            } else {
              return question;
            }
          });
        }
      } else {
        // Clearing PICKUP_POINT and TRANSFER_ARRIVAL_DROP_OFF incase they were filled by a another travel mode
        arrivalQuestions = arrivalQuestions?.map((question) => {
          if (
            question?.id === "PICKUP_POINT" ||
            question?.id === "TRANSFER_ARRIVAL_DROP_OFF"
          ) {
            // Since rail has no location condition according to provider TRANSFER_ARRIVAL_DROP_OFF will be treated as free text
            if (
              value === "RAIL" &&
              question?.id === "TRANSFER_ARRIVAL_DROP_OFF" &&
              question?.type === "LOCATION_REF_OR_FREE_TEXT"
            ) {
              return {
                ...question,
                unit: "FREETEXT",
                hide: false,
                unitEnabled: false,
                locationSelect: false,
              };
            }
            return {
              ...question,
              hide: true,
              answer: "",
              unitEnabled: false,
              locationSelect: false,
            };
          } else {
            return question;
          }
        });
      }

      setData({ ...data, arrivalLocations: locations, arrivalQuestions });
      return;
    }
    setData({ ...data, arrivalQuestions, arrivalLocations: [] });
  };

  const bookingQuestionsDepartureModeChange = ({
    tour,
    data,
    setData,
    question,
    value,
  }) => {
    let departureQuestions = data?.departureQuestions;
    let selectedQuestionTypes =
      groupQuestionTypes?.TRANSFER_DEPARTURE_MODE[value];

    departureQuestions = departureQuestions?.map((dQuestion) => {
      if (dQuestion?.id === question?.id) {
        return {
          ...dQuestion,
          answer: value,
          error: false,
          scrollToView: false,
        };
      }
      if (selectedQuestionTypes.includes(dQuestion.id)) {
        return { ...dQuestion, hide: false, answer: "" };
      }
      return { ...dQuestion, hide: true, answer: "" };
    });

    // Making all departure question free text because there are no required implementations or guide lines for location selection
    departureQuestions = departureQuestions?.map((question) => {
      let questionsToCheck = ["TRANSFER_DEPARTURE_PICKUP"];
      if (
        questionsToCheck.includes(question.id) &&
        question?.type === "LOCATION_REF_OR_FREE_TEXT"
      ) {
        return {
          ...question,
          unit: "FREETEXT",
        };
      } else {
        return question;
      }
    });

    setData({ ...data, departureQuestions });
  };

  const bookingQuestionsMeetingOptionChange = ({
    tour,
    data,
    value,
    setData,
  }) => {
    let arrivalQuestions = data?.arrivalQuestions;
    if (data?.meetingPoint?.selected === value) return;
    if (value === "PICK_UP") {
      arrivalQuestions = arrivalQuestions?.map((question) => {
        if (question?.id === "TRANSFER_ARRIVAL_MODE") {
          return { ...question, answer: "", hide: false };
        } else {
          return { ...question, answer: "", hide: true };
        }
      });
    } else {
      // If the choice is decide later or meeting point, setting TRANSFER_ARRIVAL_MODE to Other and PICKUP_POINT to MEET_AT_DEPARTURE_POINT or CONTACT_SUPPLIER_LATER
      arrivalQuestions = arrivalQuestions?.map((question) => {
        if (question?.id === "TRANSFER_ARRIVAL_MODE") {
          return { ...question, answer: "OTHER", hide: false };
        } else if (question?.id === "PICKUP_POINT") {
          return { ...question, answer: value, hide: false };
        } else {
          return { ...question, answer: "", hide: true };
        }
      });
    }

    setData({
      ...data,
      arrivalQuestions,
      meetingPoint: { ...data?.meetingPoint, selected: value },
    });
  };

  const bookingQuestionsDepartureOptionChange = ({
    tour,
    data,
    value,
    setData,
  }) => {
    let departureQuestions = data?.departureQuestions;
    if (data?.meetingPoint?.selected === value) return;
    if (value === "DEPARTURE") {
      departureQuestions = departureQuestions?.map((question) => {
        if (
          !departureQuestions?.find((q) => q?.id === "TRANSFER_DEPARTURE_MODE")
        ) {
          return { ...question, answer: "", hide: false };
        }
        if (question?.id === "TRANSFER_DEPARTURE_MODE") {
          return { ...question, answer: "", hide: false };
        } else {
          return { ...question, answer: "", hide: true };
        }
      });
    } else {
      // If the choise is decide later or meeting point, setting TRANSFER_DEPARTURE_MODE to Other
      departureQuestions = departureQuestions?.map((question) => {
        if (question?.id === "TRANSFER_DEPARTURE_MODE") {
          return { ...question, answer: "OTHER", hide: false };
        } else {
          return { ...question, answer: "", hide: true };
        }
      });
    }

    setData({
      ...data,
      departureQuestions,
      departure: { ...data?.departure, selected: value },
    });
  };

  const isBookingDataValid = ({ data, setData, cart }) => {
    let allQuestions = [
      ...data?.personalQuestions
        ?.map((person, index) =>
          person?.questions?.map((question) => ({
            ...question,
            id: `PERSON_${index}-${question?.id}`,
          }))
        )
        ?.flatMap((q) => q),
      ...data?.arrivalQuestions,
      ...data?.departureQuestions,
      ...data?.additionalQuestions,
    ]?.filter(
      (q) =>
        !q?.hide &&
        !q?.answer &&
        ["MANDATORY", "CONDITIONAL"].includes(q?.required)
    );

    if (allQuestions?.length > 0) {
      let scrollToViewId = allQuestions[0]?.id;
      const validateQuestion = ({ question }) => {
        if (
          !question?.hide &&
          !question?.answer &&
          ["MANDATORY", "CONDITIONAL"]?.includes(question?.required)
        ) {
          let updatedQuestion = {
            ...question,
            error: true,
            focused: true,
          };
          if (scrollToViewId === question?.id) {
            updatedQuestion = {
              ...updatedQuestion,
              scrollToView: true,
            };
          }
          return updatedQuestion;
        }
        return {
          ...question,
        };
      };
      if (!cart) {
        setData({
          ...data,
          personalQuestions: data?.personalQuestions?.map(
            (person, personIndex) => {
              return {
                ...person,
                questions: person?.questions?.map((question) => {
                  if (
                    !question?.hide &&
                    !question?.answer &&
                    question?.required === "MANDATORY"
                  ) {
                    let updatedQuestion = {
                      ...question,
                      error: true,
                      focused: true,
                    };
                    if (scrollToViewId?.startsWith("PERSON")) {
                      let index = Number(
                        scrollToViewId?.split("-")[0]?.split("_")[1]
                      );
                      let id = scrollToViewId?.split("-")[1];
                      if (index === personIndex && question?.id === id) {
                        updatedQuestion = {
                          ...updatedQuestion,
                          scrollToView: true,
                        };
                      }
                    }
                    return updatedQuestion;
                  }
                  return {
                    ...question,
                  };
                }),
              };
            }
          ),
          arrivalQuestions: data?.arrivalQuestions?.map((question) => {
            return validateQuestion({ question });
          }),
          departureQuestions: data?.departureQuestions?.map((question) => {
            return validateQuestion({ question });
          }),
          additionalQuestions: data?.additionalQuestions?.map((question) => {
            return validateQuestion({ question });
          }),
        });
      }
      return false;
    }

    return true;
  };

  const parseBookingPayload = ({ tour, payload, supplier }) => {
    const parameters = URLdecode();
    let item = {
      paxMix: payload?.paxMix?.map((mix) => ({
        ageBand: mix.ageBand,
        numberOfTravelers: mix.numberOfTravelers,
      })),
      startTime: payload?.startTime,
      productCode: payload?.productCode,
      productOptionCode: payload?.productOptionCode,
      travelDate: payload?.date,
    };
    let personalQuestions = payload?.personalQuestions
      ?.map((person, personIndex) => {
        return {
          ...person,
          questions: person?.questions?.map((question) => {
            let updatedQuestion = {
              question: question?.id,
              answer: question?.answer,
              travelerNum: personIndex + 1,
            };
            if (question?.unit) {
              updatedQuestion = { ...updatedQuestion, unit: question.unit };
            }
            return updatedQuestion;
          }),
        };
      })
      ?.flatMap((q) => q?.questions);
    let groupQuestions = [
      ...payload?.arrivalQuestions,
      ...payload?.departureQuestions,
      ...payload?.additionalQuestions,
    ];
    groupQuestions = Array.from(
      new Map(
        groupQuestions.map((question) => [question?.id, question])
      ).values()
    );
    groupQuestions = groupQuestions
      ?.filter((question) => question?.answer)
      ?.map((question) => {
        let updatedQuestion = {
          question: question?.id,
          answer: question?.answer,
        };
        if (question?.unit) {
          updatedQuestion = { ...updatedQuestion, unit: question.unit };
        }
        return updatedQuestion;
      });
    let bookingQuestionAnswers = [...personalQuestions, ...groupQuestions];
    item = { ...item, bookingQuestionAnswers };
    if (payload?.languageGuide) {
      let languageGuide = payload?.languageGuide?.options?.find(
        (guide) => guide?.label === payload?.languageGuide?.selected
      );
      if (languageGuide) {
        delete languageGuide?.label;
        item = { ...item, languageGuide };
      }
    }

    const data = {
      items: [item],
      supplier:
        supplier || tour?.tour?.package_supplier || parameters?.supplier,
      contactEmail: payload?.email,
      showPrice: true,
    };

    return data;
  };

  return {
    handleTourSearch,
    getActualMinAndMaxPrice,
    parsedAvailabilities,
    packagePriceDetail,
    handleBookTour,
    isTourSaved,
    saveTour,
    handleOpenTour,
    parseTags,
    coverTourImage,
    tourRating,
    tourLanguages,
    getTourDuration,
    saveToRecentSearch,
    parseTourDetail,
    parseTourBookingQuestions,
    parseBookingPayload,
    bookingQuestionsArrivalModeChange,
    bookingQuestionsDepartureModeChange,
    bookingQuestionsMeetingOptionChange,
    bookingQuestionsDepartureOptionChange,
    isBookingDataValid,
    getTourLocation,
  };
}
