import React from "react";
import moment from "moment";

function TourConfirmationBookedTourSection({ booking }) {
  if (!booking) return <></>;

  return (
    <div className="w-full flex flex-col mt-4">
      <div className="flex items-center gap-1 mb-1">
        <img src="/IconTour2.svg" alt="" className="h-5 object-contain" />
        <span className="text-xl font-bold">
          {booking?.extraDetails?.productInfo?.title}
        </span>
      </div>
      <div className="w-full bg-[#F3F8FF] border-t-2 border-primary1 p-4 flex flex-wrap gap-2 justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order ID</span>
          <span className="text-sm text-primary1 font-bold">
            {booking?.itemRef}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order Date</span>
          <span className="text-sm font-bold">
            {moment(booking?.createdAt).format("DD/MM/YYYY HH:mm A")}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Start time</span>
          <span className="text-sm font-bold">
            {moment(booking?.travelDate).format("DD MMM")}
            {booking?.startTime
              ? `, ${moment(booking?.startTime, "HH:mm").format("h:mm A")}`
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TourConfirmationBookedTourSection;
