import React from "react";
import { generateHotelPDFCSS } from "../../../controllers/cssController";
import AccommodationConfirmationRoomsSectionPDFStatic from "./AccommodationConfirmationRoomsSectionPDFStatic";
import AccommodationConfirmationBookedHotelSectionPDFStatic from "./AccommodationConfirmationBookedHotelSectionPDFStatic";
import AccommodationConfirmationTravellerPDFStatic from "./AccommodationConfirmationTravellerPDFStatic";

function AccommodationConfirmationPDFSection({ booking }) {
  const hotelSectionStatic =
    AccommodationConfirmationBookedHotelSectionPDFStatic(booking);
  const roomsSectionStatic =
    AccommodationConfirmationRoomsSectionPDFStatic(booking);
  const travellerSectionStatic =
    AccommodationConfirmationTravellerPDFStatic(booking);

  const combinedStaticMarkup = `
      <div class="flex flex-col gap-4">
        ${hotelSectionStatic}
        ${roomsSectionStatic}
        ${travellerSectionStatic}
      </div>
    `;

  return (
    <div className="hotel-doc-template">
      <div dangerouslySetInnerHTML={{ __html: combinedStaticMarkup }} />
      <style>{generateHotelPDFCSS()}</style>
    </div>
  );
}

export default AccommodationConfirmationPDFSection;
