import {
  Check,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import TicketManagementFlightOrderDetailStatusContainer from "../../../components/TicketManagement/TicketManagementFlightOrderDetailStatusContainer";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import { useDispatch } from "react-redux";
import { setConfirmFlightRefundRequestModal } from "../../../redux/reducers/modalSlice";
import ConfirmFlightRefundRequestModal from "../../../components/modal/ConfirmFlightRefundRequestModal";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import { URLdecode } from "../../../utils/url";
import { useNavigate, useParams } from "react-router-dom";
import getBooking from "../../../controllers/booking/getBooking";
import getRefundTicketRequest from "../../../controllers/booking/postTicketing/refund/getRefundTicketRequest";
import Modal1 from "../../../components/DIsplay/Modal/Modal1";
import { formatMoney } from "../../../features/utils/formatMoney";
import Button1 from "../../../components/form/Button1";
import confirmRefundFee from "../../../controllers/booking/postTicketing/refund/confirmRefundFee";
import getVoidTicketRequest from "../../../controllers/booking/postTicketing/void/getVoidTicketRequest";
import getTicketRequest from "../../../controllers/booking/getTicketRequest";
import getChangeRequest from "../../../controllers/booking/postTicketing/change/getChangeRequest";
import getBookingById from "../../../controllers/booking/getBookingById";
import cancelChangeRequest from "../../../controllers/booking/postTicketing/change/cancelChangeRequest";
import PostTicketPaymentMethod from "../../../components/flight/PostTicketPaymentMethod";
import cancelRefundRequest from "../../../controllers/booking/postTicketing/refund/cancelRefundRequest";
import cancelVoidRequest from "../../../controllers/booking/postTicketing/void/cancelVoidRequest";
import { Contact } from "./Contact";
import { Modal3 } from "../../../components/DIsplay/Modal/Modal3";
import OrderStatusBar from "../../Orders/Flight/Booked/OrderStatusBar";
import Remark from "./Remark";
import { OrderDetail } from "./OrderDetail/OrderDetail";
import { FlightDetails } from "./OrderDetail/FlightDetails";
import { Passengers } from "./OrderDetail/Passengers";
import { Baggage } from "./OrderDetail/Baggage";
import { FareRules } from "./OrderDetail/FareRules";
import { PriceBreakdown } from "../../../components/TicketManagement/PirceBreakdown";

function TicketManagementFlightOrderDetail({fetchId,page:defPage}) {
  const {id} = useParams()
  const [order, setOrder] = useState();
  const [loading,setLoading] = useState(true);
  const params = URLdecode();
  const [page,setPage] = useState('')
  const [bookingData,setBookingData] = useState({});

  const fetchBooking = async (id) => {
    const res = await getBookingById(id)
    if(res?.return) {
      setBookingData(res?.data)
    }
  }
  
  useEffect(() => {
    fetch();
    //eslint-disable-next-line
  }, [id,fetchId]);

  const fetch = async () => {
    const fetcherId = fetchId || id;
    
    if(!fetcherId) return setLoading(false);

    let page = defPage || params?.page || '';

    setLoading(true);
    let res = {return:0,data: []}
    const mockPrice = {
      // supplierRefundAmount: 5000,
      // passengerPrice: [
      //   {travelerType: 'ADT',amount: 4444,serviceFee: 5},
      //   {travelerType: 'ADT',amount: 4444,serviceFee: 5},
      // ]
    }
    if(page === 'Refund Management') {
      page = ('refund')
      res = await getRefundTicketRequest(fetcherId);
      res.data = {...res.data,booking: {...res.data,flightBooking: [res?.data?.flightBooking]}}
    }
    else if(page === 'Void Management') {
      page = ('void')
      res = await getVoidTicketRequest(fetcherId);
      res.data = {...res.data,booking: {...res.data,flightBooking: [res?.data?.flightBooking]}}
    }
    else if(page === 'Tickets') {
      page = ('Ticket')
      res = await getTicketRequest(fetcherId);
      res.data = {...res.data,booking: {...res.data,flightBooking: [res?.data?.flightBooking]}}
    }
    else if(page === 'Re-issue Management') {
      page = ('Change')
      res = await getChangeRequest(fetcherId);
      res.data = {...res.data,...mockPrice,booking: {...res.data,...mockPrice,flightBooking: [res?.data?.flightBooking]}}
    }
    else {
      page = ('')
      res = await getBooking(fetcherId);
    }
    setPage(page);
    setLoading(false);
    if (res.return) {
      setOrder(res.data);
      if(page)
        fetchBooking(res?.data?.bookingId || res?.data?.booking?.flightBooking?.at(0)?.bookingId)
    }
  };

  console.log(order)

  const preTicketing = (!page||page === 'Ticket') && ['Booked','Paid','Pending']?.includes(order?.booking?.flightBooking?.at(0)?.status)

  return !loading ? (
    <div className="flex flex-col w-full p-4 bg-[#F8F8F8]">
      <TicketManagementHeader />
      <TicketManagementLoadingModal />
      <div className="flex flex-col lg:flex-row gap-4">
        <div></div>
        <div className="flex flex-col flex-1">
          {!preTicketing && page !== 'Ticket' && order?.status !== 'Canceled' ? 
            <TicketUpdateStatusHeader ogData={bookingData} order={order} page={page} reload={() => fetch()} />
          :null}
          {!loading && (!page || page === 'Ticket' || order?.status === 'Canceled') && !preTicketing ? 
            <TicketActionChoices order={order} bookingData={bookingData} />
          :null}
          <OrderDetail bookingData={bookingData} order={order} reload={fetch} page={page} />
          <div className="sm:hidden my-4">
            {preTicketing || page === 'Ticket' || !page ? 
              <div className="self-start bg-secondary w-full sm:w-auto">
                <OrderStatusBar page={page}
                  // changeable={changable}
                  needsReview={order?.booking?.flightBooking?.at(0)?.needsReview}
                  data={order}
                  // cancelOrder={() => setOpenCancelOrder(orderData?._id)}
                  />
              </div>
            :
              // <TicketManagementFlightOrderDetailStatusContainer order={order} page={page} callback={fetch} />
              <div className="bg-secondary p-4 rounded-md shadow-sm flex flex-col">
                <b>Price</b>
                <PriceBreakdown data={order} />
              </div>
            }
          </div>
          <FlightDetails order={order} />
          <Passengers order={order} page={page} />
          <Baggage order={order} />
          <FareRules order={order} />
          <Contact />
          {!preTicketing && page !== 'Ticket' && page ? 
            <div className="flex flex-col gap-3">
              <h5 className="capitalize">{page} Request</h5>
              <Remark data={order} />
            </div>
          :null}
        </div>
        <div className="hidden sm:block">
          {preTicketing || page === 'Ticket' || !page ? 
            <div className="self-start bg-secondary w-full sm:w-auto">
              <OrderStatusBar page={page}
                // changeable={changable}
                needsReview={order?.booking?.flightBooking?.at(0)?.needsReview}
                data={order}
                // cancelOrder={() => setOpenCancelOrder(orderData?._id)}
                />
            </div>
          :
            <TicketManagementFlightOrderDetailStatusContainer order={order} page={page} callback={fetch} />
          }
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center p-4 py-10">
      <CircularProgress />
    </div>
  );
}

export default TicketManagementFlightOrderDetail;

const TicketActionChoices = ({order,bookingData:defBookingData}) => {
  const bookingData = order?.booking?.flightBooking?.at(0);
  const voidTime = new Date(bookingData?.voidDeadline || null)

  const navigate = useNavigate();
  const params = URLdecode();
  const choices = [
    {
      name: "Add bag",
      onClick: () => {
        navigate("/order/flight/ticket/addbag");
      },
      state: {
        disabled: !["Ticket issued", "Issued"]?.includes(bookingData?.status)
      }
    },
    {
      name: "Change Date",
      onClick: () => {
        navigate("/order/flight/ticket/changedate/"+(defBookingData?._id || order?.booking?._id));
      },
      state: {
        disabled: !["Ticket issued", "Issued"]?.includes(bookingData?.status)
      }
    },
    {
      name: "Change Name",
      onClick: () => {
        navigate("/order/flight/ticket/changeinfo/"+(defBookingData?._id || order?.booking?._id));
      },
      state: {
        disabled: !["Ticket issued", "Issued"]?.includes(bookingData?.status)
      }
    },
    {
      name: "Refund",
      onClick: () => {
        navigate("/order/flight/ticket/refund/"+(defBookingData?._id || order?.booking?._id));
      },
      state: {
        disabled: !["Ticket issued", "Issued"]?.includes(bookingData?.status)
      }
    },
    {
      name: "Void",
      onClick: () => {
        navigate("/order/flight/ticket/void/"+(defBookingData?._id || order?.booking?._id));
      },
      state: {
        disabled: (voidTime - new Date() <= 0) || !["Ticket issued", "Issued","Denied"]?.includes(bookingData?.status),
      }
    },
  ];
  if (params?.status) return <></>;

  return (
    <div className={(choices?.every(obj => obj?.state?.disabled) ? 'hidden':'flex')+" gap-2 lg:gap-4 p-2 lg:p-4 bg-white border-[2px] rounded-md flex-wrap"}>
      {choices.map(({ name, onClick, state }, index) => !state?.disabled && (
        <button disabled={state?.disabled}
          key={index}
          className={"flex flex-1 py-2 lg:py-3 px-2 rounded-md border-[2px] font-bold text-[#71717E] border-[#71717E] items-center justify-center text-xs lg:text-base "+(state?.disabled ? 'cursor-not-allowed':'')}
          onClick={onClick}
        >
          {name}
        </button>
      ))}
    </div>
  );
};

const TicketUpdateStatusHeader = ({order,page,reload,ogData}) => {
  const {id} = useParams()
  const params = URLdecode();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openCancelRequest,setOpenCancelRequest] = useState(false);
  const [loadings,setLoadings] = useState({cancel: false})


  let pageName = page;
  if(order?.changeRequest === 'Flight')
    pageName = 'date';
  else if(order?.changeRequest === 'PassengerInfo')
    pageName = 'name';

  // const status = "datePending"
  // const status = "dateDenied"
  // const status = "dateRejected"
  // const status = "datePaid"
  // const status = "dateIssuedSuccess"
  // const status = "dateApproved"
  const status = page ? (pageName||'')+order?.status : ''
  // console.log(order,page,status)

  const bookingData = order?.booking?.flightBooking?.at(0);
  const voidTime = new Date(bookingData?.voidDeadline || null)


  if (!status) return <></>;

  const DateChangeReviewStatus = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
          <img
            src="/IconTicketStatus.svg"
            alt=""
            className="w-3 object-contain"
          />
        </div>
        <span>Date change request is under review</span>
      </div>
    );
  };
  const NameChangeReviewStatus = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
          <img
            src="/IconTicketStatus.svg"
            alt=""
            className="w-3 object-contain"
          />
        </div>
        <span>Name change request is under review</span>
      </div>
    );
  };
  const RefundReviewStatus = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
          <img
            src="/IconTicketStatus.svg"
            alt=""
            className="w-3 object-contain"
          />
        </div>
        <span>Refund request is under review</span>
      </div>
    );
  };
  const RefundPriceReview = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm lg:text-base py-3">
        {`The Intraverse system has pre-assessed that this ticket is refundable and the refund amount is displayed below for your reference. 
        Please note that the final refund price will be calculated by Airline when processing the refund. 
        If you have any questions about the price, feel free to request refund pricing for manual verification.)`}
      </div>
    );
  };
  const ReIssueRejected = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <span>Re-issue rejected</span>
        </div>
        <p className="text-gray-500 font-normal self-start">
          The status has been changed to Reissue Rejected. The reason:
          {order?.rejectReason ? ' '+order?.rejectReason : '---'}
        </p>
      </div>
    ); 
  };
  const NameChangeRejected = () => {
    return (
      <div className="flex flex-col gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <span>Name change rejected</span>
        </div>
        <p className="text-gray-500 font-normal self-start">
          The status has been changed to name change Rejected. The reason:
          {order?.rejectReason ? ' '+order?.rejectReason : '---'}
        </p>
      </div>
    );
  };
  const RefundRejected = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <span>Refund requested was rejected</span>
        </div>
        <p className="text-gray-500 font-normal self-start">
          The status has been changed to Refund Rejected. The reason: 
          {order?.rejectReason ? ' '+order?.rejectReason : '---'}
        </p>
      </div>
    );
  };
  const VoidRejected = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-[#B52026]">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>
          <div>
            <span>Void was rejected</span>
            <p className="text-gray-500 font-normal">
              {order?.rejectReason ? ' '+order?.rejectReason : '---'}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const ChangeOrderProcessing = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3 text-primary1">
        <img
          src="/IconFlightTicket.svg"
          alt=""
          className="w-7 object-contain"
        />

        <span>Change order processing</span>
      </div>
    );
  };
  const RefundProcessing = () => {
    return (
      <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base py-3 text-primary1">
        <img
          src="/IconFlightTicket.svg"
          alt=""
          className="w-7 object-contain"
        />

        <span>Refund processing</span>
      </div>
    );
  };
  const VoidProcessing = () => {
    return (
      <div className="flex flex-col gap-2 py-3 text-primary1">
        <div className="flex flex-row items-center gap-2 text-sm font-bold lg:text-base text-primary1">
          <div className="w-7 h-7 rounded-full flex items-center justify-center bg-primary1">
            <img
              src="/IconTicketStatus.svg"
              alt=""
              className="w-3 object-contain"
            />
          </div>

          <span>Void processing</span>
        </div>
        <p className="text-gray-500 font-normal ml-10">
          We are currently processing your void request. The final decision is
          dependent on the ticket fare rules and airline policy
        </p>
      </div>
    );
  };
  const ChangeDateIssuedStatus = () => {
    const actionButtons = [
      {
        name: "Change",
        onClick: () => {
          navigate("/order/flight/ticket/changeinfo/"+ogData?._id);
        },
      },
      {
        name: "Refund",
        onClick: () => {
          navigate("/order/flight/ticket/refund/"+ogData?._id);
        },
      },
      {
        name: "Void",
        onClick: () => {
          navigate("/order/flight/ticket/void/"+ogData?._id);
        },
        state: {
          disabled: voidTime - new Date() <= 0,
        }  
      },
    ];
    return (
      <div className="flex flex-col lg:flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-8 h-8 rounded-sm text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <span>Ticket Issued</span>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          {actionButtons.map(({ name, onClick, state }, index) => (
            <button disabled={state?.disabled}
              key={index}
              className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
              onClick={onClick}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    );
  };
  const ChangeNameIssuedStatus = () => {
    const actionButtons = [
      {
        name: "Change",
        onClick: () => {
          navigate("/order/flight/ticket/changeinfo/"+ogData?._id);
        },
      },
      {
        name: "Refund",
        onClick: () => {
          navigate("/order/flight/ticket/refund/"+ogData?._id);
        },
      },
      {
        name: "Void",
        onClick: () => {
          navigate("/order/flight/ticket/void/"+ogData?._id);
        },
        state: {
          disabled: voidTime - new Date() <= 0,
        }  
      },
    ];
    return (
      <div className="flex flex-col lg:flex-row items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex items-center gap-2">
          <div className="w-8 h-8 rounded-sm text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <span>Name change completed</span>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          {actionButtons.map(({ name, onClick, state }, index) => (
            <button disabled={state?.disabled}
              key={index}
              className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
              onClick={onClick}
            >
              {name}
            </button>
          ))}
        </div>
      </div>
    );
  };
  const ChangeApprovedStatus = ({ targetDate }) => {
    const [time, setTime] = useState("0h 0m 0s");
    const [openPayment,setOpenPayment] = useState(false);
    const [loadings,setLoadings] = useState({cancel: false})
    
    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date();
        const tg = new Date(targetDate);
        const difference = tg.setHours(tg.getHours() + 2) - now;

        if (difference <= 0) {
          clearInterval(timerId);
          setTime("0h 0m 0s");
          return;
        }

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTime(`${hours}h ${minutes}m ${seconds}s`);
      };

      const timerId = setInterval(calculateTimeLeft, 1000);

      return () => clearInterval(timerId);
    }, [targetDate]);


      const handlePay = async () => {
      setOpenPayment(order?._id)
      // const res = await payforChangeRequest({
      //   "changeOrderId": order?._id,
      //   "paymentMode": "Card", //Wallet, SavedCard, Bank, Card, Ussd, Qr, MobileMoney, BankTransfer
      //   "callback": "http://localhost:3000",
      //   "saveCard": false
      //   // "savedCardId":"64f484acfc77bd4e1d4f8384"
      // })
    };
    return (
      <div className="flex flex-col lg:flex-row items-center gap-6 text-sm lg:text-base py-3">
        <div className="w-full flex flex-1 flex-col gap-2">
          <div className="w-full flex justify-between gap-3">
            <div className="text-[#0B662C] bg-[#0B662C]/10 px-5 py-1 rounded-md w-fit h-fit">
              Change request approved
            </div>
            <div className="flex flex-col items-end">
              <span className="text-gray-500 text-xs lg:text-sm font">
                Time left to pay
              </span>
              <div className="flex gap-1 items-center">
                <img
                  src="/IconClock1.svg"
                  alt=""
                  className="w-3 object-contain"
                />
                <span className="font-bold text-[#F0373C]">{time}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <img src="/IconMoney.svg" alt="" className="w-6 object-contain" />
            <span className="font-bold">Fee to be paid: {formatMoney(order?.changeFee)}</span>
          </div>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          <button
            className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
            onClick={() => setOpenCancelRequest(true)}
          >
            Cancel
          </button>
          <button disabled={time === '0h 0m 0s'}
            className={`flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-white ${time === '0h 0m 0s' ? 'bg-gray-400' : 'bg-primary1'} justify-center`}
            onClick={handlePay}
          >
            Pay
          </button>
        </div>
        <Modal1 open={openPayment} setOpen={setOpenPayment}>
          <PostTicketPaymentMethod
            callback={() => {
              reload && reload();
              setOpenPayment(false);
            }}
            close={() => setOpenPayment(false)}
            changeOrderId={openPayment}
            expand
          />
        </Modal1>
      </div>
    );
  };
  const AwaitingRefundConfirmation = ({ targetDate }) => {
    const [time, setTime] = useState("0h 0m 0s");
    const [openFeeConfirm,setOpenFeeConfirm] = useState(false)
    const [loading,setLoading] = useState({confirmFee: false});


    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date();
        const tg = new Date(targetDate);
        const difference = tg - now;

        if (difference <= 0) {
          clearInterval(timerId);
          setTime("0h 0m 0s");
          return;
        }

        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTime(`${hours}h ${minutes}m ${seconds}s`);
      };

      const timerId = setInterval(calculateTimeLeft, 1000);

      return () => clearInterval(timerId);
    }, [targetDate]);
    const handleCancel = () => {};
    const handleConfirm = () => {
      dispatch(setConfirmFlightRefundRequestModal(true));
    };
    
  async function handleConfirmFee() {
    setLoading({...loading,confirmFee: true})
    const res = await confirmRefundFee(order?._id);
    setLoading({...loading,confirmFee: false})
    if(res.return) {
      reload && reload()
      // navigate('/order/flight/ticket/detail/'+(res?.data?._id || id)+'?page=refund Mangement')
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

    return (
      <div className="flex flex-col lg:flex-row items-center gap-6 text-sm lg:text-base py-3">
        <div className="w-full flex flex-1 flex-col gap-2">
          <div className="w-full flex justify-between gap-3">
            <div className="text-[#D9A513] bg-[#D9A513]/10 border border-[#D9A513] px-5 py-1 rounded-md w-fit h-fit">
              Awaiting your confirmation
            </div>
            <div className="flex flex-col items-end">
              <span className="text-gray-500 text-xs lg:text-sm font">
                Time left to confirm
              </span>
              <div className="flex gap-1 items-center">
                <img
                  src="/IconClock1.svg"
                  alt=""
                  className="w-3 object-contain"
                />
                <span className="font-bold text-[#F0373C]">{time}</span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <img src="/IconMoney.svg" alt="" className="w-6 object-contain" />
            <span className="font-bold">Refund amount: {formatMoney(order?.payable)}</span>
          </div>
        </div>
        <div className="flex items-center gap-4 w-full lg:w-fit">
          <button
            className="flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-[#2E2E32] justify-center"
            onClick={() => setOpenCancelRequest(true)}
          >
            Cancel
          </button>
          <button disabled={time === "0h 0m 0s"}
            className={`flex items-center px-3 lg:px-8 py-2 lg:py-3 rounded-md border text-xs flex-1 lg:flex-none lg:text-base font-bold text-white ${time === "0h 0m 0s" ? 'bg-gray-400':'bg-primary1'}  justify-center`}
            onClick={() => handleConfirm()}
          >
            Confirm
          </button>
        </div>
        <ConfirmFlightRefundRequestModal callback={handleConfirmFee} />
      </div>
    );
  };
  const RefundedStatus = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex  gap-3">
          <div className="w-6 lg:w-8 h-6 lg:h-8 rounded-sm flex-shrink-0 text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <div className="flex flex-col mt-1">
            <span>Refunded, to re-imbursed</span>
            <p className="text-gray-500 font-normal">
              We will credit your Intraverse wallet once we receive the refund
              from airline
            </p>
          </div>
        </div>
      </div>
    );
  };
  const VoidedStatus = () => {
    return (
      <div className="flex flex-col items-center gap-2 text-sm font-bold lg:text-base py-3">
        <div className="w-full flex  gap-3">
          <div className="w-6 lg:w-8 h-6 lg:h-8 rounded-sm flex-shrink-0 text-white text-sm flex items-center justify-center bg-[#34C759]">
            <Check color="inherit" fontSize="inherit" />
          </div>
          <div className="flex flex-col mt-1">
            <span>Voided, to be re-imbursed</span>
            <p className="text-gray-500 font-normal">
              We will credit your Intraverse wallet once completed
            </p>
          </div>
        </div>
      </div>
    );
  };

  const StatusContent = () => {
    switch (status) {
      case "datePending":
        return <DateChangeReviewStatus />;
      case "namePending":
        return <NameChangeReviewStatus />;
      case "refundPendingRefund":
      case "refundPending":
          return <RefundReviewStatus />
      case "dateDenied":
      case "dateRejected":
        return <ReIssueRejected />;
      case "nameDenied":
      case "nameRejected":
        return <NameChangeRejected />;
      case "refundDenied":
      case "refundRejected":
        return <RefundRejected />;
      case "voidDenied":
      case "voidRejected":
        return <VoidRejected />;
      case "changeProcessing":
      case "datePaid":
        return <ChangeOrderProcessing />;
      case "refundProcessing":
        return <RefundProcessing />;
      case "voidProcessing":
      case "voidPending":
      case "voidPendingVoid":
      case "voidApproved":
        return <VoidProcessing />;
      case "dateIssuedSuccess":
        return <ChangeDateIssuedStatus />;
      case "nameIssued":
        return <ChangeNameIssuedStatus />;
      case "dateApproved":
        return <ChangeApprovedStatus targetDate={order?.updatedAt || order?.createdAt} />;
      case "refundApproved":
        return order?.confirmed ? 
          <RefundReviewStatus />
          :
          <AwaitingRefundConfirmation targetDate={order?.requestExpirationTime || order?.updatedAt || order?.createdAt} />
      case "refundSuccess":
        return <RefundedStatus />;
      case "voidSuccess":
        return <VoidedStatus />;

      default:
        return <></>;
    }
  };


  const handleCancel = async () => {
    setLoadings({...loadings,cancel: true})
    let res = {return: 0,msg: 'Error 0xCERNF'}
    if(page === 'Change')
      res = await cancelChangeRequest(id)
    else if(page === 'refund')
      res = await cancelRefundRequest(id);
    else if(page === 'Void')
      res = await cancelVoidRequest(id);
    setLoadings({...loadings,cancel: false})
    if(res.return) {
      enqueueSnackbar('Request Cancelled',{variant: 'success'})
      reload && reload();
    } else
      enqueueSnackbar(res.msg,{variant: 'error'})
  };


  return (
    <div className="w-full bg-white mb-4 border p-4">
      <StatusContent />
      <Modal3 open={openCancelRequest} setOpen={setOpenCancelRequest} title='Cancel Request'>
          <div className="card p-10 flex flex-col gap-4">
            <h5>Are you sure you want to cancel this request?</h5>
            <div className="flex gap-4 items-center">
              <div>
                <Button1 variant='outlined' className='whitespace-nowrap' onClick={() => setOpenCancelRequest(false)}>No, Close</Button1>
              </div>
              <Button1 loading={loadings?.cancel} onClick={() => handleCancel()}>Yes, Cancel Request</Button1>
            </div>
          </div>
        </Modal3>

    </div>
  );
};