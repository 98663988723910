import React from "react";
import { Icon } from "@iconify/react";

const ModalContainer = ({
  open,
  setOpen,
  children,
  title,
  subTitle,
  secondary,
  padding,
}) => {
  return (
    <div
      className={`fixed z-[51] top-0 left-0 w-full h-full items-center justify-center bg-gray-500 bg-opacity-75 ${
        open ? "flex" : "hidden"
      }`}
      style={{
        transform: "translateZ(0)",
      }}
    >
      <div className="bg-white w-full max-w-[887px] rounded-b-lg shadow-md max-h-[90vh] mx-4 overflow-scroll scroll-hide">
        <div
          className={`${
            secondary ? "bg-[#F0F6FF]" : "bg-primary1"
          } z-20 px-2.5 py-1 sm:px-6 sm:py-5 flex justify-between items-center top-0 sticky`}
        >
          <div className="flex-col">
            <h2
              className={`font-bold ${
                secondary ? "text-black" : "text-secondary"
              } text-xs sm:text-2xl`}
            >
              {title}
            </h2>
            {subTitle && (
              <span
                className={`${
                  secondary ? "text-black" : "text-secondary"
                } text-xs sm:text-sm`}
              >
                {subTitle}
              </span>
            )}
          </div>
          <button onClick={() => setOpen(false)}>
            <Icon
              icon="material-symbols:close"
              className={`${
                secondary ? "text-black" : "text-secondary"
              } text-3xl`}
            />
          </button>
        </div>
        <div
          className={`${
            padding || "pt-6 px-4 pb-4 sm:p-16"
          } z-10 flex flex-col gap-4 sm:gap-6 relative`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalContainer;
