export const configurations = {
  hotelSuppliers: ["StayX2", "StayX3"],
  // hotelSuppliers: ["StayX2"],
  hotelSupplierExceptions: ["StayX3"],
  insuranceRecentSearchKey: "insuranceRecentSearch",
  insuranceNoMedicalSuppliers: ["Airhelp Plus"],
  insuranceKeyword: "Protection",
  stayKeyword: "Stays",
  tourKeyword: "Tour",
};
