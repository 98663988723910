import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import ModifyHotelBookingNameChangeConfirmation from "./ModifyHotelBookingNameChangeConfirmation";
import ModifyHotelBookingAllocationChangeConfirmation from "./ModifyHotelBookingAllocationChangeConfirmation";
import ModifyHotelBookingRequestChangeConfirmation from "./ModifyHotelBookingRequestChangeConfirmation";
import ModifyHotelBookingDateChangeConfirmation from "./ModifyHotelBookingDateChangeConfirmation";
import ModifyHotelBookingRoomAdditionConfirmation from "./ModifyHotelBookingRoomAdditionConfirmation";

export const ModifyHotelBookingConfirmation = () => {
  const { data } = useModifyBookingModalContext();

  const renderConfirmationContent = () => {
    if (data?.tabType === "room")
      return <ModifyHotelBookingRoomAdditionConfirmation />;
    switch (data?.type) {
      case "traveler-names":
        return <ModifyHotelBookingNameChangeConfirmation />;
      case "allocation":
        return <ModifyHotelBookingAllocationChangeConfirmation />;
      case "board":
        return <div className="text-gray-700 text-sm sm:text-base"></div>;
      case "requests":
        return <ModifyHotelBookingRequestChangeConfirmation />;
      case "dates":
        return <ModifyHotelBookingDateChangeConfirmation />;
      default:
        return (
          <p className="text-gray-500 text-sm sm:text-base">
            No option selected.
          </p>
        );
    }
  };

  return (
    <div className="flex flex-col items-center">
      {renderConfirmationContent()}
    </div>
  );
};
