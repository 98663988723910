import { Icon } from "@iconify/react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import Dropdown from "../../common/Dropdown";
import { enqueueSnackbar } from "notistack";

function ModifyHotelAllocationTravellerSelection() {
  const { setData, checkedRoom, data, checkedHotel } =
    useModifyBookingModalContext();

  const isRoomTab = data?.tabType === "room";
  const target = isRoomTab ? checkedHotel : checkedRoom;

  const adults = target?.adults || 1;
  const childrenAges = target?.children || [];

  const updateTarget = (updateFn) => {
    setData((prevData) => ({
      ...prevData,
      services: prevData.services.map((hotel) =>
        hotel.id === checkedHotel?.id
          ? !isRoomTab
            ? {
                ...hotel,
                rooms: hotel.rooms.map((room) =>
                  room.id === checkedRoom?.id ? updateFn(room) : room
                ),
              }
            : updateFn(hotel)
          : hotel
      ),
    }));
  };

  const handleAdultChange = (newAdultCount) => {
    updateTarget((target) => ({
      ...target,
      adults: newAdultCount,
    }));
  };

  const handleChildrenChange = (newChildrenAges) => {
    updateTarget((target) => ({
      ...target,
      children: newChildrenAges,
    }));
  };

  const handleAddAdult = () => {
    if (isRoomTab) {
      handleAdultChange(adults + 1);
    } else {
      if (adults < checkedRoom?.room?.allowedGuests?.adult) {
        handleAdultChange(adults + 1);
      } else {
        enqueueSnackbar(
          `Only ${checkedRoom?.room?.allowedGuests?.adult} adult${
            checkedRoom?.room?.allowedGuests?.adult === 1 ? "" : "s"
          } allowed in this room`
        );
      }
    }
  };

  const handleSubtractAdult = () => {
    if (adults > 1) {
      handleAdultChange(adults - 1);
    }
  };

  const handleAddChild = () => {
    if (isRoomTab) {
      handleChildrenChange([...childrenAges, 1]);
    } else {
      if (childrenAges?.length < checkedRoom?.room?.allowedGuests?.child) {
        handleChildrenChange([...childrenAges, 1]);
      } else {
        enqueueSnackbar(
          !checkedRoom?.room?.allowedGuests?.child
            ? "Children are not allowed in this room"
            : `Only ${checkedRoom?.room?.allowedGuests?.child} child${
                checkedRoom?.room?.allowedGuests?.child === 1 ? "" : "ren"
              } allowed in this room`
        );
      }
    }
  };

  const handleSubtractChild = () => {
    if (childrenAges.length > 0) {
      handleChildrenChange(childrenAges.slice(0, -1));
    }
  };

  const handleChildAgeChange = (index, age) => {
    const updatedAges = [...childrenAges];
    updatedAges[index] = age;
    handleChildrenChange(updatedAges);
  };

  return (
    <div className="flex flex-col sm:flex-row gap-4 items-start text-sm sm:text-base">
      <div className="bg-[#F8F8F8] px-4 py-2 w-full rounded-lg flex justify-between">
        <div className="flex items-center gap-0.5">
          <Icon icon="solar:user-bold" />
          <p>Adult</p>
        </div>
        <div className="flex items-center">
          <button onClick={handleSubtractAdult} className="px-4 py-3">
            -
          </button>
          <p className="px-4 py-3">{adults}</p>
          <button onClick={handleAddAdult} className="px-4 py-3">
            +
          </button>
        </div>
      </div>

      <div className="bg-[#F8F8F8] px-4 py-2 w-full rounded-lg text-sm sm:text-base">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-0.5">
            <Icon icon="solar:user-bold" />
            <p>Children</p>
          </div>
          <div className="flex items-center">
            <button onClick={handleSubtractChild} className="px-4 py-3">
              -
            </button>
            <p className="px-4 py-3">{childrenAges.length}</p>
            <button onClick={handleAddChild} className="px-4 py-3">
              +
            </button>
          </div>
        </div>
        <div>
          <div className="max-w-40 w-full flex flex-col gap-2 z-10">
            {childrenAges.map((age, index) => (
              <div key={index} style={{ zIndex: 50 - index }}>
                <Dropdown
                  label={`Child ${index + 1} Age`}
                  selectedValue={age}
                  onSelect={(value) => handleChildAgeChange(index, value)}
                  options={Array.from({ length: 17 }, (_, i) => ({
                    label: `${i + 1} years`,
                    value: i + 1,
                  }))}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModifyHotelAllocationTravellerSelection;
