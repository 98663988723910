import React, { createContext, useEffect, useState } from 'react'
import CustomTable from '../../../../components/Table/CustomTable'
import TextInput from '../../../../components/form/TextInput'
import { MenuItem } from '@mui/material'
import Modal1 from '../../../../components/DIsplay/Modal/Modal1'
import Icon from '../../../../components/HOC/Icon'
import CreateMarkup from './CreateMarkup'
import { Link } from 'react-router-dom'
import Button1 from '../../../../components/form/Button1'
import getOrderPriceAdjustments from '../../../../controllers/orderPriceAdjustment/getOrderPriceAdjustment'
import MarkupDelete from './MarkupDelete'


const ActionContext = createContext();

export default function ToursMarkup() {
  const [open,setOpen] = useState(false);
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState([
    // {id: 1,name: 'Default Markup',type: 'value',figure: '5000',status: 'active'},
    // {id: 2,name: 'Default Markup',type: 'Percentage',figure: '0',status: 'active'}
  ])
  const [editObj,setEditObj] = useState();
  const [deleteObj, setDeleteObj] = useState();


   useEffect(() => {
      load();
      //eslint-disable-next-line
    }, []);
  
    async function load() {
      setEditObj()
      if (open) setOpen(false);
      const filter = {
        filterBy: 'appliedTo',
        filterValue: 'Package',
      }
      
      setEditObj();
      setLoading(true);
      const res = await getOrderPriceAdjustments(new URLSearchParams(filter));
      setLoading(false);
      if (res.return) {
        if (Array.isArray(res.data?.data?.data)) {
          setData(res.data?.data?.data);
        }
      }
    }

  async function changeStatus() {
    // activate deactivate logic
    load();
  }

  const columns = [
    { field: "name", headerName: "Mark-up name", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "method", headerName: "Method", flex: 1 },
    {
      field: "status",
      headerName: "Action",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <ActionContext.Provider
            value={{
              setEditObj,
              setDeleteObj,
              changeStatus,
            }}
          >
            <ActionCol params={params.row} />
          </ActionContext.Provider>
        );
      },
    },
  ];
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex justify-between flex-wrap items-center gap-4'>
        <div className='flex gap-2'>
          <Link to='?type=Flights' className={'btn-theme-light rounded-md'}>Flights</Link>
          <Link to='?type=Stays' className={'btn-theme-light rounded-md'}>Stays</Link>
          <Link to='?type=Tours' className={'btn'}>Tours</Link>
        </div>
        {/* <h5 className='text-primary/70'>Created Mark ups</h5> */}
        <div>
          <Button1 onClick={() => setOpen(true)}>Create new markup</Button1>
          <Modal1 open={open} setOpen={setOpen}>
            <CreateMarkup 
              forType={'Tours'} 
              reload={load}
              hideMoreOptions={true}
              footer={
                <div>
                  <Button1 onClick={() => setOpen(false)} className='btn-theme-light'>Cancel</Button1>
                </div>
              }
            />
          </Modal1>
        </div>

      </div>

      <CustomTable rows={data} columns={columns} />


      <Modal1 open={deleteObj} setOpen={() => setDeleteObj()}>
        <div className="card p-10">
          <MarkupDelete forType='Tours'
            data={deleteObj}
            cancel={() => setDeleteObj()}
            reload={load}
          />
        </div>
      </Modal1>

      <Modal1 open={editObj} setOpen={setEditObj}>
        <div className='p-6'>
          <CreateMarkup update data={editObj} reload={load} hideMoreOptions={true} forType={'Tours'} />
        </div>
      </Modal1>
    </div>
  )
}

function ActionCol({ params }) {
  return (
    <ActionContext.Consumer>
      {(value) => {
        const { setEditObj, changeStatus, setDeleteObj } = value || {};
        return (
          <div className="flex gap-2">
            {/* <Switch
              checked={params.status === "Active"}
              onChange={(ev) => changeStatus(ev.target.checked, params)}
            /> */}
            <label
              className="bg-primary/10 rounded-md cursor-pointer p-2 text-primary/30"
              onClick={() => setEditObj(params)}
            >
              <Icon icon="tabler:edit" />
            </label>
            <span
              className="p-2 bg-red-100 text-red-500 rounded-md cursor-pointer"
              onClick={() => setDeleteObj(params)}
            >
              <Icon icon="material-symbols-light:delete" />
            </span>
          </div>
        );
      }}
    </ActionContext.Consumer>
  );
}
