import React, { useState } from "react";
import useDateFormat from "../../../hooks/useDateFormat";
import { parseRateComments } from "../../../controllers/hotelUIController";

function AccommodationConfirmationRoomsSection({ booking }) {
  if (!booking) return <></>;

  return (
    <div className="w-full flex flex-col mt-4 mb-4 lg:mb-10 gap-2">
      {booking?.requestBody?.rooms?.map((room, index) => (
        <ConfirmationRoom
          room={room}
          key={index}
          index={index}
          booking={booking}
        />
      ))}
    </div>
  );
}

export default AccommodationConfirmationRoomsSection;

const ConfirmationRoom = ({ booking, room, index }) => {
  const [details, setDetails] = useState(false);
  const { capitalizeFirstLetter } = useDateFormat();

  const detailData = [
    {
      label: "Accommodation type",
      value: booking?.holdBookingRes?.details?.accomodationType,
    },
    {
      label: "Category type",
      value: booking?.holdBookingRes?.details?.categoryDescription,
    },
  ];

  return (
    <div className="w-full p-4 bg-white border flex-wrap border-gray-300 items-center flex gap-2 justify-between">
      <div className="w-6 h-6 text-white bg-primary1 font-bold text-sm rounded-md flex items-center justify-center">
        {index + 1}
      </div>
      <div className="flex flex-col items-start w-[350px] flex-1">
        <div className="flex items-center gap-1">
          <img src="/IconHotel2.svg" alt="" className="h-5 object-contain" />
          <span className=" font-bold">
            {booking?.holdBookingRes?.name || booking?.requestBody?.name}{" "}
          </span>
        </div>
        <div className="flex items-center gap-1 -ml-1">
          <img
            src="/IconLocationPrimary.svg"
            alt=""
            className="w-5 object-contain"
          />
          <span className=" text-sm text-[#6D7C94]">
            {booking?.holdBookingRes?.address || booking?.requestBody?.address}
          </span>
        </div>
      </div>
      <div className="flex lg:flex-1 w-full lg:w-fit justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Room</span>
          <span className="text-sm font-bold">
            {capitalizeFirstLetter(room?.name)}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Board</span>
          <span className="text-sm font-bold">
            {capitalizeFirstLetter(room?.type)}
          </span>
        </div>
        <div className="flex flex-col">
          <button
            className="flex items-center"
            onClick={() => setDetails(!details)}
          >
            <span className="text-sm text-gray-500">Details</span>
            <img
              src="/IconChevronDown.svg"
              alt=""
              className={`w-4 object-contain ${details && "rotate-180"}`}
            />
          </button>
          <div></div>
        </div>
      </div>
      {details && (
        <div className="w-full flex justify-between flex-wrap gap-2 border-t pt-2 border-gray-300 ">
          {detailData?.map((item, index) => (
            <div className="flex flex-col" key={index}>
              <span className="text-sm text-gray-500">{item.label}</span>
              <span className="text-sm font-bold">{item.value}</span>
            </div>
          ))}

          {room?.rateComments ? (
            <div className="w-full flex gap-2 flex-wrap mt-2">
              {parseRateComments({ rateComments: room.rateComments })?.map(
                (comment) => (
                  <div className="text-[10px] lg:text-xs rounded-md p-3 bg-blue-500/10 w-fit">
                    {comment}
                  </div>
                )
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
