import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import ModifyHotelAllocationTravellerSelection from "./ModifyHotelAllocationTravellerSelection";

export const ModifyHotelBookingAllocationModification = () => {
  const { checkedRoom } = useModifyBookingModalContext();

  const guests = (room) => {
    if (!room?.guests || !Array.isArray(room.guests)) return "";

    let adultsCount = 0;
    let childrenCount = 0;

    room.guests.forEach((guest) => {
      if (guest.type === "adult") {
        adultsCount += 1;
      } else if (guest.type === "child") {
        childrenCount += 1;
      }
    });

    let result = [];
    if (adultsCount > 0) {
      result.push(`${adultsCount} Adult${adultsCount > 1 ? "s" : ""}`);
    }
    if (childrenCount > 0) {
      result.push(`${childrenCount} Child${childrenCount > 1 ? "ren" : ""}`);
    }

    return result.join(" and ");
  };

  return (
    <div className="space-y-6">
      <div className="rounded-lg border border-[#EFECEC] p-6 flex justify-between items-center text-sm sm:text-base">
        <div>
          <div>{checkedRoom?.room?.name}</div>
          <div className="text-darkGray mt-2">{guests(checkedRoom?.room)}</div>
        </div>
        <div>
          <button className="bg-primaryText text-white text-xxxs sm:text-sm px-6 py-1.5 rounded-lg">
            Current allocation
          </button>
        </div>
      </div>
      <ModifyHotelAllocationTravellerSelection />
    </div>
  );
};
