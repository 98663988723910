import React from "react";
import ReactDOMServer from "react-dom/server";
import useDateFormat from "../../../hooks/useDateFormat";

function AccommodationConfirmationTravellerPDFStatic(booking) {
  const { capitalizeFirstLetter } = useDateFormat();
  if (!booking) return "";

  const rooms = booking?.requestBody?.rooms || [];

  // Function to render a single traveller's section
  const renderTraveller = (room, roomIndex) => {
    const traveller = room?.guests?.at(0);
    if (!traveller) return "";

    const travellerData = [
      { label: "Email", value: traveller.email },
      { label: "Phone number", value: traveller.phoneNumber },
      {
        label: "Type",
        value: capitalizeFirstLetter(traveller?.type || ""),
      },
    ];

    // Static HTML for the traveller section
    const travellerContent = (
      <div
        className="w-full rounded-md flex flex-col border border-gray-300 bg-white mb-4"
        key={roomIndex}
      >
        <div className="w-full px-4 py-2 flex items-center gap-3 border-b border-gray-300">
          <div className="w-6 h-6 text-white bg-blue-600 font-bold text-sm rounded-md flex items-center justify-center">
            {roomIndex + 1}
          </div>
          <span className="font-bold text-blue-600 text-sm lg:text-base">
            {traveller?.lastName}/{traveller?.firstName}
          </span>
        </div>
        <div className="flex flex-wrap justify-between gap-2 lg:gap-4 py-2 px-4">
          {travellerData.map((item, index) => (
            <div className="flex flex-col" key={index}>
              <span className="text-sm text-gray-500">{item?.label}</span>
              <span className="text-sm">{item?.value}</span>
            </div>
          ))}
        </div>
      </div>
    );

    return ReactDOMServer.renderToStaticMarkup(travellerContent); // Convert to static HTML
  };

  // Generate the static HTML for all rooms
  const staticTravellerSection = rooms
    .map((room, roomIndex) => renderTraveller(room, roomIndex))
    .join(""); // Join all room HTML strings

  return staticTravellerSection; // Return the static HTML string
}

export default AccommodationConfirmationTravellerPDFStatic;
