import React from "react";

import { Icon } from "@iconify/react";

export default function ModifyHotelBookingRoomAdditionConfirmation() {
  return (
    <div className="border border-[#FAA108] rounded-lg p-6 w-full">
      <div className="flex justify-between items-center mb-6 gap-2">
        <div className="text-sm sm:text-base">Room Only: 2 Adults</div>
        <div className="bg-[#FAA108] text-white px-6 py-1.5 text-sm font-semibold rounded-lg">
          New Room
        </div>
      </div>
      <div>
        <p className="text-sm sm:text-lg text-darkGray">
          New price:
          <span className="font-bold text-primaryText"> ₦530,000</span>
        </p>
        <div className="flex items-center gap-2 text-[#1EA994]">
          <Icon icon="material-symbols:check" className="text-xl" />
          <span className="text-xs sm:text-sm">Free cancelation</span>
        </div>
      </div>
    </div>
  );
}
