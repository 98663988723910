import React, { useRef } from "react";
import { allStatuses } from "../OrderList";
import usePDF from "../../../hooks/flight/usePDF";
import InsuranceConfirmationPDFSection from "../../pdf/insurancePDF/InsuranceConfirmationPDFSection";
import MenuOptions from "./MenuOptions";

export default function InsuranceMenu({
  order,
  mobile,
  setCancelInsuranceOrder,
  setViewInsuranceOrder,
  setCancelPendingInsuranceOrder,
  setPayInsuranceOrder,
  setLoading,
}) {
  const { handlePDFDownload } = usePDF();
  const pdfRef = useRef(null);

  const handleExport = async () => {
    // dispatch(setHotelConfirmationPdfModal(true));
    if (pdfRef?.current) {
      let string = pdfRef.current.outerHTML;
      setLoading && setLoading(true);
      await handlePDFDownload(string, {
        fileName: order?.mainBooking?.bookingId || order?.bookingId || "",
      }).finally(() => setLoading && setLoading(false));
    }
  };
  const isVisible = (type) => {
    switch (type) {
      case "View Booking":
        return true;
      // return allStatuses
      //   .filter(
      //     (status) =>
      //       ![
      //         "Pending",
      //         "Not Paid",
      //         "Pending",
      //         // "Paid",
      //         "Booked",
      //         "Failed",
      //       ].includes(status)
      //   )
      //   ?.includes(order?.status);
      case "PDF":
        return true;
      case "Pay Booking":
        return ["Booked", "Pending", "Not Paid"]?.includes(order?.status);
      case "Cancel Booking":
        return allStatuses?.includes(order?.status);
      case "Cancel Pending Booking":
        return ["Not Paid", "Pending"]?.includes(order?.status);
      case "Send Invoice":
        return allStatuses?.includes(order?.status);
      case "Price Details":
        return allStatuses?.includes(order?.status);
      // case "Modify Reference":
      //   return allStatuses?.includes(order?.status);
      default:
        return false;
    }
  };

  const menu = [
    {
      label: "View Booking",
      action: () => {
        setViewInsuranceOrder && setViewInsuranceOrder(true);
      },
      visible: isVisible("View Booking"),
    },
    {
      label: "Pay Booking",
      action: () => {
        setPayInsuranceOrder && setPayInsuranceOrder(true);
      },
      visible: isVisible("Pay Booking"),
    },
    {
      label: "Cancel Booking",
      action: () => {
        setCancelInsuranceOrder(true);
      },
      visible: isVisible("Cancel Booking"),
    },
    {
      label: "Download PDF",
      action: () => {
        handleExport();
      },
      visible: isVisible("PDF"),
    },
    {
      label: "Cancel Booking",
      action: () => {
        setCancelPendingInsuranceOrder && setCancelPendingInsuranceOrder(true);
      },
      visible: isVisible("Cancel Pending Booking"),
    },
  ];

  return (
    <>
      <div className="hidden">
        <div className="" ref={pdfRef}>
          <InsuranceConfirmationPDFSection booking={order} />
        </div>
      </div>
      <MenuOptions mobile={mobile} menu={menu} />
    </>
  );
}
// Insurance menu
