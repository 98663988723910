import React, { useState } from "react";
import ModalContainer from "../common/ModalContainer";
import moment from "moment";
import { formatMoney } from "../../features/utils/formatMoney";
import { useHotels } from "../../hooks/useHotels";
import { useDispatch, useSelector } from "react-redux";
import { setHotelOrders } from "../../redux/reducers/orderSlice";

export const PayAccommodationBookingModal = ({ setOpen, open, booking }) => {
  const dispatch = useDispatch();
  const { hotelOrders } = useSelector((state) => state.order);
  const { initializePayment } = useHotels();
  const [loading, setLoading] = useState(false);

  const renderBookingData = ({ data }) => {
    const formatModifyDates = (startDate, endDate) => {
      if (!startDate || !endDate) return "";

      const start = moment(startDate);
      const end = moment(endDate);
      const nights = end.diff(start, "days");

      return `${start.format("DD MMM")} - ${end.format(
        "DD MMM"
      )} (${nights} night${nights > 1 ? "s" : ""})`;
    };

    const details = data?.requestBody?.rooms?.map((room) => {
      let adultCount = 0;
      let childCount = 0;
      room.guests?.forEach((guest) => {
        if (guest.type === "adult") adultCount += 1;
        if (guest.type === "child") childCount += 1;
      });
      return `${room?.name} (${adultCount} Adult${
        adultCount === 1 ? "" : "s"
      } ${childCount}, Child${childCount === 1 ? "" : "ren"})`;
    });

    // console.log({ data });
    let travellerNames = data?.requestBody?.rooms?.map((room) => {
      let travellerName = "";
      room.guests?.slice(0, 1)?.forEach((guest) => {
        travellerName += `${guest.firstName} ${guest.lastName}`;
      });
      return travellerName;
    });

    travellerNames = travellerNames?.join(", ");

    let items = [];
    items.push([
      {
        type: "service",
        data: {
          serviceName: data?.requestBody?.name,
          serviceDetails: details,
          serviceDates: formatModifyDates(
            data?.requestBody?.start_date,
            data?.requestBody?.end_date
          ),
        },
      },
    ]);
    return items;
  };

  const handlePay = async () => {
    await initializePayment({
      booking,
      setLoading,
      callback: (data) => {
        dispatch(
          setHotelOrders(
            hotelOrders?.map((item) => (item?._id === data?._id ? data : item))
          )
        );
        setOpen(false);
      },
    });
  };

  return (
    <>
      <ModalContainer
        open={open}
        setOpen={setOpen}
        secondary
        padding="px-4 py-6"
        title="Pay booking"
        subTitle="Check the information of the product you want to pay for"
      >
        <div className="w-full flex flex-col">
          <Services
            data={renderBookingData({ data: booking })?.at(0)?.at(0)?.data}
          />

          <div className="border-[#EFECEC] mb-10"></div>

          <div className="flex gap-6 items-center">
            <button onClick={setOpen} className={ClassName.whiteButton}>
              Cancel
            </button>
            <button
              className="bg-primary1 flex items-center justify-center text-secondaryflex-1 text-white w-full py-4 rounded-lg text-xs sm:text-lg"
              onClick={() => handlePay()}
            >
              {loading ? (
                <div className="buttonLoader"></div>
              ) : (
                <span>Pay {formatMoney(booking?.grandTotal)}</span>
              )}
            </button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

const ClassName = {
  whiteButton: "py-4 px-6 text-xs md:text-lg font-bold w-full max-w-[174px]",
};

const Services = ({ data }) => {
  return (
    <div className="flex items-start gap-2 w-full p-2">
      <div className="my-1">
        <img
          src="/IconHotel2.svg"
          alt="service-icon"
          className="h-5 object-contain"
        />
      </div>
      <div className="flex flex-1 flex-col">
        <span className="text-sm lg:text-base">{data.serviceName}</span>
        {data.serviceDetails?.map((detail, index) => (
          <span className="text-sm text-gray-400" key={index}>
            {detail}
          </span>
        ))}
        <span className="text-sm text-gray-400">{data.serviceDates}</span>
      </div>
    </div>
  );
};
