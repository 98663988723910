import React, { useState } from 'react'
import CustomTable from '../../../../components/Table/CustomTable'
import ToggleSwitch from '../../../../components/Settings/suppliers/ToggleSwitch'
import updateHotelSettings from '../../../../controllers/hotelSettings/updateHotelSettings'
import { enqueueSnackbar } from 'notistack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import updateTourSuppliersImage from '../../../../controllers/hotelSettings/updateTourSuppliersImage';
import { Modal3 } from '../../../../components/DIsplay/Modal/Modal3';
import Button1 from '../../../../components/form/Button1';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import Icon from '../../../../components/HOC/Icon';

export default function ManageTourSuppliers({data,loading,reload}) {
    const [openLogoUpdate,setOpenLogoUpdate] = useState();
    const [loadings,setLoadings] = useState({updateImage: false})

    async function handleEnable(newStatus,name) {
        let enabledPackageSuppliers = data?.filter(obj => obj?.status && (obj?.name !== name))?.map(obj => obj?.name);
        if(newStatus) 
            enabledPackageSuppliers.push(name);

        const res = await updateHotelSettings({
            enabledPackageSuppliers
        })
        if(res.return) {
            enqueueSnackbar(name+' supplier updated',{variant: 'success'})
        } else enqueueSnackbar(res.msg,{variant: 'error'})
        reload && reload();
    }
    async function handleUpdateImage() {
        const formData = new FormData();
        formData.append("supplier",openLogoUpdate?.name);
        formData.append('image',openLogoUpdate?.file);

        setLoadings({...loadings,updateImage: true})
        const res = await updateTourSuppliersImage(formData)
        setLoadings({...loadings,updateImage: false})
        if(res.return) {
            setOpenLogoUpdate();
            enqueueSnackbar('Image Updated',{variant: 'success'})
            reload && reload();
        } else enqueueSnackbar(res.msg,{variant: 'error'})
    }
    
    
    const columns = [
        {field: 'name',headerName: 'Supplier', flex: 1,
            renderCell: (params) => (
                <div className='flex items-center gap-2'>
                    <img src={params.row?.image} className='!w-5 !h-5' alt='' />
                    {params.value}
                </div>
            )
        },
        {field: 'status',headerName: 'Status',
            renderCell: (params) => (
                <div>
                   <ToggleSwitch toggleValue={params.value} callback={(val) => handleEnable(val,params?.row?.name)} />            
                </div>
            )
        },
        {field: 'actions', headerName: 'Actions',
            renderCell: (params) => (
                <MenuOption openLogoModal={() => setOpenLogoUpdate(params.row)} />

            )
        }
    ]
    
  return (
    <div className='bg-secondary'>
      <CustomTable rows={data} columns={columns} loading={loading} />
      
      <Modal3 open={openLogoUpdate} setOpen={() => setOpenLogoUpdate(false)} title='Update Supplier Logo'>
            <div className='flex flex-col gap-4 p-4'>
                <input
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    onChange={(ev) => setOpenLogoUpdate({...(openLogoUpdate||{}),file: ev.target.files[0]})}
                    id="uploadInput"
                    multiple
                />
                <div className='flex gap-4 justify-between items-center'>
                    <div>
                        <Button1 variant='outlined' onClick={() => setOpenLogoUpdate(false)}>Close</Button1>
                    </div>
                    <Button1 loading={loadings.updateImage} onClick={handleUpdateImage}>Update</Button1>
                </div>
            </div>
        </Modal3>
    </div>
  )
}


const MenuOption = ({ openLogoModal }) => {
    const [anchorEl, setAnchorEl] = useState(null);
  
    return (
      <div>
        <MoreVertIcon
          onClick={(ev) => setAnchorEl(ev.currentTarget)}
          style={{ cursor: 'pointer' }}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={(ev) => setAnchorEl(null)}
          sx={{ boxShadow: 'none' }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => {setAnchorEl(null);openLogoModal()}}>
            <ListItemIcon>
              <Icon icon='material-symbols:image' fontSize="small" />
            </ListItemIcon>
            <ListItemText>Upload Logo</ListItemText>
          </MenuItem>
        </Menu>
      </div>
    )
  } 