import { formatMoney } from "../../features/utils/formatMoney";

export const PriceBreakdown = ({data}) => {
    const breakdown = {
      total: "606.17 USD",
      detail: [
        {
          quantity: 1,
          feeName: "Adult",
          total: "606.17 USD",
          items: [
            { name: "Fare per adult", price: "534.1 USD" },
            { name: "Taxes per adult", price: "71.07 USD" },
          ],
        },
        { quantity: 1, feeName: "Ticketing fee", total: "1 USD" },
      ],
    };

    const fee = data?.status === 'Success' ? data?.fee || 0 : data?.fee || '-';
    const payable = data?.status === 'Success' ? data?.payable || 0 : data?.payable || '-';
    
    const passengerPrice = groupPrice(data?.passengerPrice);

    const count = data?.passengerIds?.length || ((data?.adult||0) + (data?.child||0) + (data?.infant||0)) || 1
    
    return (
      <div className="w-full bg-[#F1F1F1] flex flex-col p-4 mt-4 gap-2">
        {passengerPrice?.adult?.count ? 
        <div>
          <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
            <span>
              {passengerPrice?.adult?.count}x Adult
            </span>
            <span>
              {formatMoney(passengerPrice?.adult?.amount)}
            </span>
          </div>
          <div className="flex flex-col gap-2 pl-4 text-primary/50">
            <span className="flex gap-4 justify-between items-center">fee per Adult
              <span>{passengerPrice?.adult?.serviceFee}</span>
            </span>
            <span className="flex gap-4 justify-between items-center">Ticket difference per Adult
              <span>{passengerPrice?.adult?.amount/(passengerPrice?.adult?.count||1)}</span>
            </span>
          </div>
        </div>
        :null}
        {passengerPrice?.child?.count ? 
        <div>
          <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
            <span>
              {passengerPrice?.child?.count}x Child
            </span>
            <span>
              {formatMoney(passengerPrice?.child?.amount)}
            </span>
          </div>
          <div className="flex flex-col gap-2 pl-4 text-primary/50">
            <span className="flex gap-4 justify-between items-center">Change fee per Child
              <span>{passengerPrice?.child?.serviceFee}</span>
            </span>
            <span className="flex gap-4 justify-between items-center">Ticket difference per Child
              <span>{passengerPrice?.child?.amount/(passengerPrice?.child?.count||1)}</span>
            </span>
          </div>
        </div>
        :null}
        {passengerPrice?.infant?.count ? 
        <div>
          <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
            <span>
              {passengerPrice?.infant?.count}x Infant
            </span>
            <span>
              {formatMoney(passengerPrice?.infant?.amount)}
            </span>
          </div>
          <div className="flex flex-col gap-2 pl-4 text-primary/50">
            <span className="flex gap-4 justify-between items-center">Change fee per Infant
              <span>{passengerPrice?.infant?.serviceFee}</span>
            </span>
            <span className="flex gap-4 justify-between items-center">Ticket difference per Infant
              <span>{passengerPrice?.infant?.amount/(passengerPrice?.infant?.count||1)}</span>
            </span>
          </div>
        </div>
        :null}
        <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
          <span className="">
            {count||1}x Service Fee
          </span>
          <span className="">{fee}</span>
        </div>
        {/* {breakdown.detail.map((item, index) => (
          <div key={index} className="flex flex-col mb-2">
            <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
              <span className="">
                {item.quantity} x {item.feeName}
              </span>
              <span className="">{item.total}</span>
            </div>
            {item.items &&
              item.items?.map((subItem, subIndex) => (
                <div
                  key={subIndex}
                  className="flex items-center gap-2 justify-between text-gray-600 ml-2"
                >
                  <span className="">{subItem.name}</span>
                  <span className="">{subItem.price}</span>
                </div>
              ))}
          </div>
        ))} */}
        <div className="w-full border-b border-gray-300 mb-2"></div>
        <div className="flex items-center gap-2 font-bold justify-between text-sm lg:text-base">
          <span className="">Total</span>
          <span className="">-{payable}</span>
        </div>
        <div className="flex font-bold w-full justify-end text-end text-primary1 text-sm lg:text-base mt-2">
          -{payable}
        </div>
        {/* <div className="flex font-bold w-full justify-end text-end text-[#6D7C94] text-sm lg:text-base mt-2">
          NOT including payment fee
        </div> */}
      </div>
    );
  };
  
export function groupPrice(priceObj) {

    const adult = priceObj?.reduce((acc,cur) => {
      if(cur.travelerType === 'ADT') {
        return ({...cur,
          amount: acc.amount+(cur?.amount ||0),
          serviceFee: acc.serviceFee+(cur?.serviceFee||0),
          count: (acc?.count || 0) + 1,
        })
      }
      return acc
    },{travelerType: 'ADT',amount: 0,serviceFee: 0})
    const child = priceObj?.reduce((acc,cur) => {
      if(cur.travelerType === 'CNN') {
        return ({...cur,
          amount: acc.amount+(cur?.amount ||0),
          serviceFee: acc.serviceFee+(cur?.serviceFee||0),
          count: (acc?.count || 0) + 1,
        })
      }
      return acc
    },{travelerType: 'CNN',amount: 0,serviceFee: 0})
    const infant = priceObj?.reduce((acc,cur) => {
      if(cur.travelerType === 'INF') {
        return ({...cur,
          amount: acc.amount+(cur?.amount ||0),
          serviceFee: acc.serviceFee+(cur?.serviceFee||0),
          count: (acc?.count || 0) + 1,
        })
      }
      return acc
    },{travelerType: 'INF',amount: 0,serviceFee: 0})

    return ({adult,child,infant})

  }