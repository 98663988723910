import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icon from "../../HOC/Icon";
import { SwipeableDrawer } from "@mui/material";
import SM from "../../DIsplay/Screen/SM";
import { ModifyHotelBookingModal } from "../../modal/modifyHotelBookingModal/ModifyHotelBookingModal";
import { CancelAccommodationBookingModal } from "../../modal/CancelAccommodationBookingModal";
import { AccommodationOrderPriceDetailModal } from "../../modal/AccommodationOrderPriceDetailModal";
import { AccommodationOrderDetailsModal } from "../../modal/accommodationDetails/AccommodationOrderDetailsModal";
import { configurations } from "../../../configurations";
import { TourOrderDetailModal } from "../../modal/tourOrderDetails/TourOrderDetailModal";
import { CancelInsuranceBookingModal } from "../../modal/CancelInsuranceBookingModal";
import { InsuranceOrderDetailsModal } from "../../modal/insuranceOrderDetails/InsuranceOrderDetailsModal";
import FlightMenu from "./FlightMenu";
import StayMenu from "./StayMenu";
import TourMenu from "./TourMenu";
import InsuranceMenu from "./InsuranceMenu";
import { CancelPendingAccommodationBookingModal } from "../../modal/CancelPendingAccommodationBookingModal";
import { CancelPendingTourBookingModal } from "../../modal/CancelPendingTourBookingModal";
import { CancelPendingInsuranceBookingModal } from "../../modal/CancelPendingInsuranceBookingModal";
import { PayAccommodationBookingModal } from "../../modal/PayAccommodationBookingModal";
import { PayTourBookingModal } from "../../modal/PayTourBookingModal";
import { PayInsuranceBookingModal } from "../../modal/PayInsuranceBookingModal";

const OrderMenu = ({ order, og }) => {
  const [open, setOpen] = useState(false);
  const [viewHotelOrder, setViewHotelOrder] = useState(false);
  const [modifyHotelOrder, setModifyHotelOrder] = useState(false);
  const [cancelHotelOrder, setCancelHotelOrder] = useState(false);
  const [cancelPendingHotelOrder, setCancelPendingHotelOrder] = useState(false);
  const [payHotelOrder, setPayHotelOrder] = useState(false);
  const [hotelPriceDetailModal, setHotelPriceDetailModal] = useState(false);
  const [viewTourOrder, setViewTourOrder] = useState(false);
  const [cancelPendingTourOrder, setCancelPendingTourOrder] = useState(false);
  const [payTourOrder, setPayTourOrder] = useState(false);
  const [viewInsuranceOrder, setViewInsuranceOrder] = useState(false);
  const [cancelInsuranceOrder, setCancelInsuranceOrder] = useState(false);
  const [cancelPendingInsuranceOrder, setCancelPendingInsuranceOrder] =
    useState(false);
  const [payInsuranceOrder, setPayInsuranceOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const { ticketType } = useSelector((state) => state.order);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open) {
        setTimeout(() => {
          setOpen(false);
        }, 400);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const menu = () => {
    let menus = ["Cancel order", "Open order", "Refund"];
    return menus;
  };

  const MenuType = ({ mobile }) => {
    switch (ticketType) {
      case "Flights":
        return <FlightMenu og={og} />;
      case configurations.stayKeyword:
        return (
          <StayMenu
            order={og}
            {...{ mobile }}
            setModifyHotelOrder={setModifyHotelOrder}
            setCancelHotelOrder={setCancelHotelOrder}
            setCancelPendingHotelOrder={setCancelPendingHotelOrder}
            setHotelPriceDetailModal={setHotelPriceDetailModal}
            setViewHotelOrder={setViewHotelOrder}
            setPayHotelOrder={setPayHotelOrder}
            setLoading={setLoading}
          />
        );
      case configurations.tourKeyword:
        return (
          <TourMenu
            order={og}
            {...{ mobile }}
            setViewTourOrder={setViewTourOrder}
            setCancelPendingTourOrder={setCancelPendingTourOrder}
            setPayTourOrder={setPayTourOrder}
            setLoading={setLoading}
          />
        );
      case configurations.insuranceKeyword:
        return (
          <InsuranceMenu
            order={og}
            {...{ mobile }}
            setCancelInsuranceOrder={setCancelInsuranceOrder}
            setViewInsuranceOrder={setViewInsuranceOrder}
            setCancelPendingInsuranceOrder={setCancelPendingInsuranceOrder}
            setPayInsuranceOrder={setPayInsuranceOrder}
            setLoading={setLoading}
          />
        );
      default:
        <div className="absolute py-2 flex flex-col bg-white rounded-md right-6 top-4 shadow-md min-w-[200px]">
          {menu()?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>;
    }
  };

  return (
    <>
      {ticketType === configurations.stayKeyword && (
        <>
          <AccommodationOrderDetailsModal
            open={viewHotelOrder}
            booking={og}
            setOpen={setViewHotelOrder}
          />
          <ModifyHotelBookingModal
            open={modifyHotelOrder}
            booking={og}
            setOpen={setModifyHotelOrder}
          />
          <CancelAccommodationBookingModal
            open={cancelHotelOrder}
            booking={og}
            setOpen={setCancelHotelOrder}
          />
          <CancelPendingAccommodationBookingModal
            open={cancelPendingHotelOrder}
            booking={og}
            setOpen={setCancelPendingHotelOrder}
          />
          <PayAccommodationBookingModal
            open={payHotelOrder}
            booking={og}
            setOpen={setPayHotelOrder}
          />
          <AccommodationOrderPriceDetailModal
            open={hotelPriceDetailModal}
            booking={og}
            setOpen={setHotelPriceDetailModal}
          />
        </>
      )}
      {ticketType === configurations.tourKeyword && (
        <>
          <TourOrderDetailModal
            open={viewTourOrder}
            booking={og}
            setOpen={setViewTourOrder}
          />
          <CancelPendingTourBookingModal
            open={cancelPendingTourOrder}
            booking={og}
            setOpen={setCancelPendingTourOrder}
          />
          <PayTourBookingModal
            open={payTourOrder}
            booking={og}
            setOpen={setPayTourOrder}
          />
        </>
      )}
      {ticketType === configurations.insuranceKeyword && (
        <>
          <CancelInsuranceBookingModal
            open={cancelInsuranceOrder}
            booking={og}
            setOpen={setCancelInsuranceOrder}
            noSave
          />
          <CancelPendingInsuranceBookingModal
            open={cancelPendingInsuranceOrder}
            booking={og}
            setOpen={setCancelPendingInsuranceOrder}
            noSave
          />
          <InsuranceOrderDetailsModal
            open={viewInsuranceOrder}
            booking={og}
            setOpen={setViewInsuranceOrder}
            noSave
          />
          <PayInsuranceBookingModal
            open={payInsuranceOrder}
            booking={og}
            setOpen={setPayInsuranceOrder}
          />
        </>
      )}
      <button
        className="cursor-pointer p-2 relative "
        disabled={loading}
        onClick={() => setOpen(true)}
      >
        {loading ? (
          <div className="primaryLoader"></div>
        ) : (
          <img
            src="/IconMore.svg"
            alt=""
            className="h-5 object-contain relative"
          />
        )}

        <SM className="">{open && <MenuType />}</SM>
        <SM lt>
          <SwipeableDrawer
            anchor="bottom"
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            SlideProps={{ className: "max-h-[50vh]" }}
          >
            <div className="flex flex-col h-full flex-1 gap-2 relative">
              <div
                className="flex gap-4 justify-between items-center shadow p-4"
                onClick={() => setOpen(false)}
              >
                <div className="flex items-center gap-2">
                  <Icon icon="mingcute:left-line" /> Order Menus
                </div>
                <button>
                  <Icon icon="mdi:close" />
                </button>
              </div>
              <div className="overflow-y-auto">
                <MenuType mobile />
              </div>
            </div>
          </SwipeableDrawer>
        </SM>
      </button>
    </>
  );
};

export default OrderMenu;
// Order menu
