import React, { useState } from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import { useHotels } from "../../../hooks/useHotels";
import { enqueueSnackbar } from "notistack";

const ModifyHotelBookingModalConfirmButton = () => {
  const { changeBookingReconcilation, confirmChangeBooking } = useHotels();
  const { setOpen, booking, newPriceLoading, page } =
    useModifyBookingModalContext();
  const [loading, setLoading] = useState();

  const processTravellerNameChangeRequest = async () => {
    setLoading(true);
    await changeBookingReconcilation({
      id: booking?._id,
      page: page,
      callback: async (response) => {
        if (response?.data?.authorization_url) {
          window.location.replace(response?.data?.authorization_url);
        } else {
          await confirmChangeBooking({
            id: booking?._id,
            callback: (data) => {
              if (page) {
                localStorage.setItem(
                  `booking/${data?.bookingId || data?._id}`,
                  JSON.stringify(data)
                );
              }
              setOpen(false);
              enqueueSnackbar("Booking modification successfull", {
                variant: "success",
              });
            },
          });
        }
      },
    }).finally(() => setLoading(false));
  };

  const handleConfirm = async () => {
    await processTravellerNameChangeRequest();
  };

  return (
    <button
      onClick={handleConfirm}
      disabled={newPriceLoading}
      className={`${
        newPriceLoading ? "bg-gray-300 cursor-not-allowed" : "bg-primary1"
      }  text-secondaryflex-1 flex items-center justify-center text-white w-full py-4 rounded-lg text-xs sm:text-lg`}
    >
      {loading ? (
        <div className="buttonLoader"></div>
      ) : (
        <span>Confirm booking</span>
      )}
    </button>
  );
};

export default ModifyHotelBookingModalConfirmButton;
