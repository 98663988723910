const OrderStatus = ({ value, size = "small" }) => {
  const getStatusStyles = (status) => {
    switch (status) {
      case "Pending":
        return {
          bg: "bg-[#FE9F29]/10",
          text: "text-[#FE9F29]",
          border: "border-[#FE9F29]",
          label: "Pending",
        };
      case "Processing":
        return {
          bg: "bg-[#FE9F29]/10",
          text: "text-[#FE9F29]",
          border: "border-[#FE9F29]",
          label: "Processing",
        };
      case "Awaiting Payment Confirmation":
      case "Awaiting ticketing":
      case "Awaiting Void":
      case "Awaiting Refund":
      case "Awaiting Change":
      case "Awaiting Cancellation":
      case "Not Paid":
        return {
          bg: "bg-[#FE9F29]/10",
          text: "text-[#FE9F29]",
          border: "border-[#FE9F29]",
          label: value,
        };
      case "Paid":
        return {
          bg: "bg-[#32CD32]/10",
          text: "text-[#32CD32]",
          border: "border-[#32CD32]",
          label: "Paid",
        };
      case "Failed":
        return {
          bg: "bg-[#FF0000]/10",
          text: "text-[#FF0000]",
          border: "border-[#FF0000]",
          label: "Failed",
        };
      case "Expired":
      case "Rejected":
        return {
          bg: "bg-[#FF0000]/10",
          text: "text-[#FF0000]",
          border: "border-[#FF0000]",
          label: value,
        };
      case "Booked":
        return {
          bg: "bg-[#1E61DC]/10",
          text: "text-[#1E61DC]",
          border: "border-[#1E61DC]",
          label: "Booked",
        };
      case "Issued":
      case "Ticket issued":
        return {
          bg: "bg-[#009688]/10",
          text: "text-[#009688]",
          border: "border-[#009688]",
          label: "Ticket Issued",
        };
      case "PendingTicketIssue":
        return {
          bg: "bg-[#ABAB01]/10",
          text: "text-[#ABAB01]",
          border: "border-[#ABAB01]",
          label: "Pending Ticket Issue",
        };
      case "AwaitingAdmin":
        return {
          bg: "bg-[#4B0082]/10",
          text: "text-[#4B0082]",
          border: "border-[#4B0082]",
          label: "Awaiting Admin",
        };
      case "Expired":
        return {
          bg: "bg-[#333333]/10",
          text: "text-[#333333]",
          border: "border-[#333333]",
          label: "Expired",
        };
      case "AutoCanceled":
        return {
          bg: "bg-[#795548]/10",
          text: "text-[#795548]",
          border: "border-[#795548]",
          label: "Auto Cancelled",
        };
      case "Denied":
        return {
          bg: "bg-[#D32F2F]/10",
          text: "text-[#D32F2F]",
          border: "border-[#D32F2F]",
          label: "Denied",
        };
      case "Canceled":
      case "Cancelled":
        return {
          bg: "bg-[#D32F2F]/10",
          text: "text-[#D32F2F]",
          border: "border-[#D32F2F]",
          label: "Cancelled",
        };
      case "CustomerPaid":
        return {
          bg: "bg-[#00FF00]/10",
          text: "text-[#00FF00]",
          border: "border-[#00FF00]",
          label: "Customer Paid",
        };
      case "TicketCanceled":
        return {
          bg: "bg-[#E57373]/10",
          text: "text-[#E57373]",
          border: "border-[#E57373]",
          label: "Ticket Cancelled",
        };
      case "Refunded":
        return {
          bg: "bg-[#00BCD4]/10",
          text: "text-[#00BCD4]",
          border: "border-[#00BCD4]",
          label: "Refunded",
        };
      case "TicketCancelationRequested":
        return {
          bg: "bg-[#FF5722]/10",
          text: "text-[#FF5722]",
          border: "border-[#FF5722]",
          label: "TC Requested",
        };
      default:
        return {
          bg: "bg-gray-200",
          text: "text-gray-700",
          border: "border-gray-700",
          label: value || "Unknown Status",
        };
    }
  };

  const { bg, text, border, label } = getStatusStyles(value);

  const sizeClasses =
    size === "large" ? `text-sm px-4 py-2` : "text-xs px-2 py-1";

  return (
    <div
      className={`rounded-md mb-3 w-fit pb-1 text-center ${bg} ${
        size === "large" ? `border py-2 pb-[7px] px-2 ${border}` : ""
      }`}
    >
      <span className={`font-bold rounded-md ${text} ${sizeClasses}`}>
        {label}
      </span>
    </div>
  );
};

export default OrderStatus;
