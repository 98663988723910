import React from "react";
import { tourBookingTravellers } from "../../../controllers/tourUIController";

function TourConfirmationTravelerSection({ booking }) {
  if (!booking) return <></>;

  const travellers = tourBookingTravellers({ booking }) || [];

  return (
    <div className="w-full flex flex-col">
      <span className="text-base font-bold lg:text-xl mb-2">Travellers</span>
      {travellers.map((traveller, travelerIndex) => {
        return (
          <div
            className="w-full rounded-md flex flex-col border border-gray-300 bg-white mb-4"
            key={travelerIndex}
          >
            <div className="w-full px-4 py-2 flex items-center gap-3 border-b border-gray-300">
              <div className="w-6 h-6 text-white bg-primary1 font-bold text-sm rounded-md flex items-center justify-center">
                {travelerIndex + 1}
              </div>
              <span className="font-bold text-primary1 text-sm lg:text-base">
                {traveller?.name}
              </span>
            </div>
            <div className="flex flex-wrap justify-between gap-2 lg:gap-4 py-2 px-4">
              {traveller?.travellerData.map((item, index) => (
                <div className="flex flex-col" key={index}>
                  <span className="text-sm text-gray-500">{item?.label}</span>
                  <span className="text-sm">{item?.value}</span>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TourConfirmationTravelerSection;
