import React, { useCallback, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ToggleSwitch from './ToggleSwitch';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Modal from './Modal';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Icon from '../../HOC/Icon';
import addDealCode from '../../../controllers/settings/dealCodes/addDealCode';
import getDealCodes from '../../../controllers/settings/dealCodes/getDealCodes';
import deleteDealCode from '../../../controllers/settings/dealCodes/deleteDealCode';
import { Link } from 'react-router-dom';
import getSuppliersName from '../../../controllers/settings/supplier/getFlightSetting';
import Loading from '../../animation/loading';
import DateTimeFormatter from '../../formatDate/FormatDate';
import { FamilyRestroomTwoTone, IndeterminateCheckBox } from '@mui/icons-material';
import LoadingBar from '../../animation/LoadingBar';
import updateFlightSetting from '../../../controllers/settings/supplier/updateFlightSetting';
import { CircularProgress } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import TextInput from '../../form/TextInput';
import { convertMinutes } from '../../../utils/time/time';
import Button1 from '../../form/Button1';
import updateDealCode from '../../../controllers/settings/dealCodes/updateDealCode';
import AirlinesInput from '../../form/AirlinesInput';


const SupplierSection = ({ allSuppliers, localFlights, settings, reload, loading: listLoading }) => {
	const [selectedProvider, setSelectedProvider] = useState(null);
	const [isDealCodeModalOpen, setIsDealCodeModalOpen] = useState(false);
	const [isPaymentTimeLimitModalOpen, setIsPaymentTimeLimitModalOpen] = useState(false);
	const [loading, setLoading] = useState(false)
	const [code, setCode] = useState("")
	const [dealCodes, setDealCodes] = useState([])
	const [openAirlines,setOpenAirlines] = useState();
	const [loadings, setLoadings] = useState({})


	const toggleValue = async () => await allSuppliers && allSuppliers?.lenght > 0 ? true : false

	const handleEnable = async (val, code) => {
		const enabledSuppliers = settings?.enabledSuppliers?.filter(obj => obj !== code)
		if (val)
			enabledSuppliers.push(code);
		setLoadings({ ...loadings, [code]: true })
		const res = await updateFlightSetting({ enabledSuppliers });
		setTimeout(() => setLoadings({ ...loadings, [code]: false }), 1000)
		if (res.return) {
			enqueueSnackbar('Successful', { variant: 'success' })
			reload && reload()
		} else enqueueSnackbar(res.msg, { variant: 'error' })
	}

	const handleEnableAutoTicketing = async (val, code) => {
		const autoTicketingEnabledSuppliers = settings?.autoTicketingEnabledSuppliers?.filter(obj => obj !== code)
		if (val)
			autoTicketingEnabledSuppliers.push(code);
		setLoadings({ ...loadings, [code+'AT']: true })
		const res = await updateFlightSetting({ autoTicketingEnabledSuppliers });
		setTimeout(() => setLoadings({ ...loadings, [code+'AT']: false }), 1000)
		if (res.return) {
			enqueueSnackbar('Successful', { variant: 'success' })
			reload && reload()
		} else enqueueSnackbar(res.msg, { variant: 'error' })
	}
	const handleAirlineUpdate = async () => {
		const autoTicketingEnabledAirlines = openAirlines?.list?.map(obj => obj?.id || obj);

		setLoadings({ ...loadings, airlinesUpdate: true })
		const res = await updateFlightSetting({ autoTicketingEnabledAirlines });
		setTimeout(() => setLoadings({ ...loadings, airlinesUpdate: false }), 1000)
		if (res.return) {
			enqueueSnackbar('Successful', { variant: 'success' })
			reload && reload()
		} else enqueueSnackbar(res.msg, { variant: 'error' })
	}

	const MenuOption = ({ item }) => {
		const [anchorEl, setAnchorEl] = useState(null);

		const handleMenuClick = (event) => {
			setAnchorEl(event.currentTarget);
		};

		const handleMenuClose = () => {
			setAnchorEl(null);
		};

		const handleDealCodeClick = (item) => {
			setIsDealCodeModalOpen(item);
			handleMenuClose();
		};

		const handlePaymentTimeLimitClick = (item) => {
			setIsPaymentTimeLimitModalOpen(item);
			handleMenuClose();
		};

		return (
			<div>
				<MoreVertIcon
					onClick={handleMenuClick}
					style={{ cursor: 'pointer' }}
				/>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					sx={{ boxShadow: 'none' }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<MenuItem onClick={() => handlePaymentTimeLimitClick(item)}>
						<ListItemIcon>
							<AccessTimeIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Payment Time Limit</ListItemText>
					</MenuItem>
					<MenuItem onClick={() => handleDealCodeClick(item)}>
						<ListItemIcon>
							<ConfirmationNumberIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Deal Code</ListItemText>
					</MenuItem>
					{item?.code === 'Intra1A' ? 
						<MenuItem onClick={() => setOpenAirlines({...(item||{}),list: item.autoTicketingEnabledAirlines?.map(airline => ({id: airline}))})}>
							<ListItemIcon>
								<Icon icon='material-symbols:airlines' fontSize="small" />
							</ListItemIcon>
							<ListItemText>Airlines</ListItemText>
						</MenuItem>
					:null}
				</Menu>
			</div>
		)
	}

	function handleAirlineSelection(airlines) {
		let list = airlines;
		if(!Array.isArray(list))
			list = [list]

		setOpenAirlines({...openAirlines,list: [...(openAirlines?.list||[]),...list]})
	}

	function handleAirlineDelete(airline) {
		setOpenAirlines({...openAirlines,list: (openAirlines?.list||[])?.filter(obj => obj?.id !== airline)})
	}

	console.log(allSuppliers)

	return (
		<div className="mb-6">

			<div>{listLoading ? <div className='flex justify-center items-center'><Loading /> </div> : (<div>


				{allSuppliers && allSuppliers.length > 0 ? (
					<TableContainer component={Paper} elevation={0}>

						<Table sx={{ minWidth: 650 }} aria-label="supplier table">
							<TableHead>

								<TableRow>
									<TableCell  className='!w-[40%] bg-[#F0F6FC] text-gray-700 !font-semibold px-4'>
										{'All Suppliers'}
									</TableCell>
									<TableCell className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Status
									</TableCell>
									<TableCell className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Auto Ticketing
									</TableCell>
									<TableCell className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Payment Time Limit
									</TableCell>
									<TableCell align="right" className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{allSuppliers?.map((item, index) => (
									<TableRow
										key={index}
										//  key={IndeterminateCheckBox}
										sx={{
											cursor: 'pointer',
											'&:hover': { backgroundColor: '' },
											'& .MuiTableCell-root': { padding: '10px 8px' },
										}}
									>
										<TableCell component="th" scope="row" className='!w-[40%]'>
											<div className="flex items-center gap-2">
												<p className="h-10 w-10 rounded-full bg-blue-50 flex justify-center items-center text-sm">
													{item.icon ?
														<img src={item.icon} alt='' />
														:
														item.name.slice(0, 2)
													}
												</p>
												<p className="text-sm">{item.name}</p>
											</div>
										</TableCell>

										<TableCell className='!px-4'>
											{loadings[item?.code] ?
													<CircularProgress className='!w-4 !h-4' />
													:
													<ToggleSwitch toggleValue={item.enabled} callback={(val) => handleEnable(val, item?.code)} />
												}
										</TableCell>
										<TableCell className='!px-4'>
											{loadings[item?.code+'AT'] ?
													<CircularProgress className='!w-4 !h-4' />
													:
													<ToggleSwitch toggleValue={item.autoTicketing} callback={(val) => handleEnableAutoTicketing(val, item?.code)} />
												}
										</TableCell>
										<TableCell className='!px-4'>
											{item?.expiration ?
												<div className="flex gap-1 items-center">
													<p>
														<Icon icon={'stash:clock-light'} />
													</p>
													<p>{item.expiration}m</p>
												</div>
											: null}
										</TableCell>
										<TableCell className='!px-4' align="right">
											<div className="flex justify-end gap-4 items-center">
												<MenuOption item={item} />
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : <p className='text-center'>No Data</p>}


				<div className="!my-5"></div>

				{/* Local Flights Table */}

				{localFlights && localFlights.length > 0 ? (
					<TableContainer component={Paper} elevation={0}>
						<Table sx={{ minWidth: 650 }} aria-label="local flights table">
							<TableHead >
								<TableRow>
									<TableCell  className='!w-[40%] bg-[#F0F6FC] text-gray-700 !font-semibold px-4'>
										{'Local Flights'}
									</TableCell>
									<TableCell className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Status
									</TableCell>
									<TableCell className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Auto Ticketing
									</TableCell>
									<TableCell className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Payment Time Limit
									</TableCell>
									<TableCell align="right" className="bg-[#F0F6FC] text-gray-700 !font-semibold ">
										Actions
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{localFlights?.map((item, index) => (
									<TableRow key={index} sx={{ cursor: 'pointer' }}>
										<TableCell component="th" scope="row" className='!w-[40%]'>
											<div className="flex items-center gap-2">
												<p className="h-10 w-10 rounded-full bg-blue-50 flex justify-center items-center text-sm">
													{item.icon ?
														<img src={item.icon} alt='' />
														:
														item.name.slice(0, 2)
													}
												</p>
												<p className="text-sm">{item.name}</p>
											</div>
										</TableCell>

										<TableCell className='!px-4'>
											{loadings[item?.code] ?
													<CircularProgress className='!w-4 !h-4' />
													:
													<ToggleSwitch toggleValue={item.enabled} callback={(val) => handleEnable(val, item?.code)} />
												}
										</TableCell>
										<TableCell className='!px-4'>
											{loadings[item?.code+'AT'] ?
													<CircularProgress className='!w-4 !h-4' />
													:
													<ToggleSwitch toggleValue={item.autoTicketing} callback={(val) => handleEnableAutoTicketing(val, item?.code)} />
												}
										</TableCell>
										<TableCell className='!px-4'>
											{item?.expiration ?
												<div className="flex gap-1 items-center">
													<p>
														<Icon icon={'stash:clock-light'} />
													</p>
													<p>{item.expiration}m</p>
												</div>
											: null}
										</TableCell>

										<TableCell align="right">
											<div className="flex justify-end gap-4 items-center">

												<MenuOption item={item} />

												{/* <MoreVertIcon onClick={handleMenuClick} style={{ cursor: 'pointer' }} /> */}
											</div>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>) : <p> </p>}



				{/* Modal for Deal Code */}
				<Modal isOpen={isDealCodeModalOpen} onClose={() => setIsDealCodeModalOpen(false)} title={'Deal Codes'}>
					<DealCodeForm {...{settings,reload}} close={() => setIsDealCodeModalOpen(false)} />
				</Modal>

				{/* Modal for Payment Time Limit */}
				<Modal isOpen={isPaymentTimeLimitModalOpen} onClose={() => setIsPaymentTimeLimitModalOpen(false)} title={'Payment Time Limit'}>
					<PaymentTimeLimitForm data={isPaymentTimeLimitModalOpen} {...{settings,reload}} close={() => setIsPaymentTimeLimitModalOpen(false)} />
				</Modal>

				<Modal isOpen={openAirlines} onClose={() => setOpenAirlines(false)} title='Specified Airlines'>
					<div className='flex flex-col gap-4'>
						<div>Airlines that support automatic ticketing</div>
						<div className='flex gap-4 items-center'>
							{(openAirlines?.list||[])?.map(obj => 
								<button key={obj?.id} className='border rounded-md p-1 px-2 flex items-center gap-2'>
									<span>{obj?.id}</span>
									<button onClick={() => handleAirlineDelete(obj?.id)}><Icon icon='mdi:delete' className='text-red-500' /></button>
								</button>
							)}
						</div>
						<AirlinesInput multiple={true} returnData={(airlines) => handleAirlineSelection(airlines)} />
						<div className='flex gap-4 justify-between'>
							<div>
								<Button1 variant='outlined' onClick={() => setOpenAirlines(false)}>Cancel</Button1>
							</div>
							<Button1 loading={loadings?.airlinesUpdate} onClick={() => handleAirlineUpdate()}>Update</Button1>
						</div>
					</div>
				</Modal>

			</div>)}</div>


		</div>
	);
};

function DealCodeForm({close}) {
	const [code,setCode] = useState()
	const [loading,setLoading] = useState(false);
	const [loadings,setLoadings] = useState({});
	const [dealCodes,setDealCodes] = useState([])
	
	const loadDealCodes = useCallback(async () => {
		setLoading(true);
		try {
			const res = await getDealCodes();
			if (res?.return === 1) {
				// console.log('thisres', res)
				setDealCodes(res.data.data);

			}
		} catch (error) {
			enqueueSnackbar("Error loading deal codes.", { variant: "error" });
		}
		setLoading(false);
	}, [enqueueSnackbar]);

	useEffect(() => {
		loadDealCodes();
	}, [loadDealCodes]);

	const handleCreateDealCode = async () => {
		setLoading(true);
		try {
			const data = { airline: 'vs',code };
			const res = await addDealCode(data);

			if (res?.return === 1) {
				setCode(code => code = "")

				enqueueSnackbar("Deal Code Created Successfully.", { variant: "success" });
				await loadDealCodes();

			} else {
				enqueueSnackbar("Failed to create deal code.", { variant: "error" });
			}
		} catch (error) {
			enqueueSnackbar("Error creating deal code.", { variant: "error" });
		}
		setLoading(false);
		loadDealCodes();
		// close && close();
	};

	const handleDeleteDealCode = async (id) => {
		setLoading(true);
		try {
			const res = await deleteDealCode(id);
			if (res?.return === 1) {
				enqueueSnackbar("Deal Code Deleted Successfully.", { variant: "success" });
				await loadDealCodes();
			} else {
				enqueueSnackbar("Failed to delete deal code.", { variant: "error" });
			}
		} catch (error) {
			enqueueSnackbar("Error deleting deal code.", { variant: "error" });
		}
		setLoading(false);
	};

	const handleEnable = async ({_id:id,...data},isActive) => {
		setLoadings({...loadings,[id]: true})
		const res = await updateDealCode(id,{...data,isActive});
		setLoadings({...loadings,[id]: false})
		if(res?.return) {
			enqueueSnackbar("Successful",{variant: 'success'})
			loadDealCodes();
		} else enqueueSnackbar(res.msg,{variant: 'error'})
	}

	return (
		<div>
			<div className="flex items-center flex-wrap ">
				<div className='w-full sm:w-[85%]'>
					<p>Add a Code</p>
					<input
						type="text"
						className="border-2 rounded p-1  px-2 w-full !border-gray-300"
						value={code}
						onChange={(e) => setCode(e.target.value)}
					/>
				</div>
				<button className="bg-[#0067FF] text-white sm:w-[13%] w-full sm:ml-2 !mt-5 sm:mt-0 px-4 py-1 rounded flex items-center justify-center" onClick={handleCreateDealCode}>
					Add
				</button>
			</div>

			<div className="mt-4">

				<p className="text-gray-500 mb-2">Current Codes</p>
				{loading && <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-30 z-10">
					<Loading />
				</div>}
				{dealCodes.map((item, index) => (
					<div key={index} className="flex items-center justify-between mb-2 py-3  border-b">
						<p className='!text-gray-900'>{item.code}</p>
						<div className="flex items-center gap-2">
							<DateTimeFormatter dateString={item.createdAt} className={'text-gray-600 text-sm font-main'} />
							{loadings[item?._id] ? 
								<CircularProgress className='!w-4 !h-4' />
							:
								<ToggleSwitch toggleValue={item.isActive} callback={(val) => handleEnable(item,val)} />
							}
							<button onClick={() => handleDeleteDealCode(item._id)}><Icon icon={'material-symbols-light:delete-outline'} /></button>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}


function PaymentTimeLimitForm({data,close,settings,reload}) {
	const {hours:h,minutes:m} = convertMinutes(data?.expiration||0)
	const [hours,setHours] = useState(h);
	const [minutes,setMinutes] = useState(m);
	
	const [loadings,setLoadings] = useState({});

	async function handleSubmit() {
		const bookingExpiration = settings?.bookingExpiration

		const modMin = Number(hours*60) + Number(minutes)
		bookingExpiration[data?.code] = modMin;

		setLoadings({ ...loadings, submit: true })
		const res = await updateFlightSetting({ bookingExpiration });
		setTimeout(() => setLoadings({ ...loadings, submit: false }), 1000)
		if (res.return) {
			enqueueSnackbar('Successful', { variant: 'success' })
			close && close();
			reload && reload();
		} else enqueueSnackbar(res.msg, { variant: 'error' })
	}


	return (
		<div>
			<div className="flex items-center justify-between flex-wrap gap-2">
				<div className='w-full'>
					<div className='flex gap-4'>
						<div>
							<p>Hours</p>
							<TextInput label='' type='number'
								value={hours}
								onChange={(ev) => setHours((ev.target.value))}
							 />
						</div>
						<div>
							<p>Minutes</p>
							<TextInput label='' type='number'
								value={minutes}
								onChange={(ev) => setMinutes(ev.target.value)}
							 />
						</div>
					</div>
				</div>
			</div>
			<div className="mt-4">

				<p className="!text-gray-800 mb-2">Current Time Limit</p>
				{data?.expiration ?
					<div className="flex gap-1 items-center">
						<p>
							<Icon icon={'stash:clock-light'} />
						</p>
						<p>{data.expiration}m</p>
					</div>
				: null}
			</div>
			<div className="flex items-center justify-between mt-4">
				<button className=" text-black rounded w-[20%] font-bold" onClick={() => close && close()}>
					Go back
				</button>
				<Button1 loading={loadings?.submit} className="!bg-[#0067FF] !text-white !px-4 !py-2 !rounded !w-[75%] " onClick={() => handleSubmit()}>
					Confirm
				</Button1>
			</div>
		</div>
	)
}

export default SupplierSection;