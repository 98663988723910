import { useMediaQuery } from '@mui/material';
import React from 'react'

export default function SM(props) {
  const display = props.display || 'flex';
  const direction = props?.lt ? `sm:hidden` : `hidden sm:${display}`;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  
  return (props?.lt ? isMobile : !isMobile) ? (
    <div className={`${direction} ${props.className}`}>{props.children}</div>
  ):null
}
