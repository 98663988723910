import React from 'react'
import { formatMoney } from '../../../features/utils/formatMoney'

export default function PriceBreakdownDetail({data}) {
    console.log(data)
  return (
    <div className='flex flex-col gap-4'>
        <div className='flex justify-between gap-4'>
            <span>Passenger <span className='text-theme1'>x{data?.travelers?.length}</span></span>
            <span className='text-theme1'>{formatMoney(data?.grandTotal)} </span>
        </div>
        <table>
            <tr className='!bg-theme1/5 !text-sm'>
                <th>PASSENGER TYPE</th>
                <th>FARE</th>
                <th>TAXES</th>
                <th>GTP SERVICE FEE</th>
                <th>PASSENGER</th>
                <th>TOTAL</th>
            </tr>
            {data?.adult ? 
                <tr>
                    <td>Adult</td>
                    <td>{data?.adult?.basePrice}</td>
                    <td>{data?.adult?.taxes}</td>
                    <td>{data?.adult?.gtpFee}</td>
                    <td>x{(data?.adult?.passengerCount||0)}</td>
                    <td>{data?.adult?.totalPrice}</td>
                </tr>
            :null}
            {data?.child ? 
                <tr>
                    <td>Child</td>
                    <td>{data?.child?.basePrice}</td>
                    <td>{data?.child?.taxes}</td>
                    <td>{data?.child?.gtpFee}</td>
                    <td>x{(data?.child?.passengerCount||0)}</td>
                    <td>{data?.child?.totalPrice}</td>
                </tr>
            :null}
            {data?.infant ? 
                <tr>
                    <td>Infant</td>
                    <td>{data?.infant?.basePrice}</td>
                    <td>{data?.infant?.taxes}</td>
                    <td>{data?.infant?.gtpFee}</td>
                    <td>x{(data?.infant?.passengerCount||0)}</td>
                    <td>{data?.infant?.totalPrice}</td>
                </tr>
            :null}
        </table>      
        <div className='flex flex-col gap-1'>
            <div className='flex items-center justify-between py-4 border-b border-primary/20'>
                <b>Total</b>
                <b className='text-theme1'>{formatMoney(data?.grandTotal)}</b>
            </div>
            <div className='flex items-center justify-between py-4 border-b border-primary/20'>
                <b>Payment Fee</b>
                <b className='text-theme1'>{formatMoney(data?.ticketingFee)}</b>
            </div>
            <div className='flex justify-end'>
                <h3 className='text-theme1'>{formatMoney(data?.payable)}</h3>
            </div>
        </div>
    </div>
  )
}
