import React from 'react'
import ToolTip from '../../../components/DIsplay/ToolTip'
import Button1 from '../../../components/form/Button1'
import Icon from '../../../components/HOC/Icon'
import { getAgeType } from '../../../utils/booking/getAgeType'

export default function Remark({data}) {
    console.log(data)

    const remarkData = {
        userRemark: data?.remark,
    }

    const passengerData = data?.flightBooking?.travelers?.find(p => data?.passengerIds?.includes(p?.id))
    
  return (
    <div className='card p-4 rounded-md shadow-md flex flex-col gap-7'>
      <PassengerDisplay data={passengerData} />
      <RemarkDisplay data={remarkData} />
      {/* <Certification /> */}
    </div>
  )
}

function PassengerDisplay({data}) {
    // const data = {
    //     name: 'Chinedu John Ike',
    //     gender: 'Male',
    //     type: 'Adult',
    // }
    return (
        <div className='flex flex-wrap gap-4 items-center'>
            <div className='bg-[#FDF3D7] p-2 px-4'>Passenger</div>
            <div className='flex-1 flex items-center gap-4'>
                <div>{data?.firstName} {data?.middleName || ''} {data?.lastName}</div>
                <p>{getAgeType(data?.travelerType)}</p>
                <p>{data?.gender}</p>
            </div>
            <div>
                <small className='text-blue-500'>View Image</small>
            </div>
        </div>
    )
}

function RemarkDisplay({data}) {
    const list = [
        {key: 'Me', value: data?.userRemark},
        {key: 'CS', value: data?.adminRemark},
    ]
    return (
        <div>
            <b>Remark</b>
            <div className='flex flex-col gap-2'>
                {list?.map((obj,i) => (
                    <span key={i}>{obj.key}: {obj.value}</span>
                ))}
            </div>
        </div>
    )
}

function Certification() {
    return (
        <div className='flex flex-col gap-2'>
            <b>Certificate File</b>
            <ToolTip>
                If the uploaded files change, 
                please inform online customer service to pay attention to this so that Intraverse can deal with it as soon as possible.
            </ToolTip>
            <textarea className='w-full h-32 bg-primary/10 p-4'></textarea>
            <div className='flex flex-wrap gap-4 items-center'>
                <div>
                    <Button1>Reply</Button1>
                </div>
                <div className='flex items-center gap-4 '>
                    <div>
                        <Button1 className='!bg-primary !text-secondary'><Icon icon='ph:upload-fill' /></Button1>
                    </div>
                    Jpeg, png, doc, pdf, zip, rar, Size not more than 2M
                </div>
            </div>
        </div>
    )
}