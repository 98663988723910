import React from "react";
import moment from "moment";

function InsuranceConfirmationBookedPolicySection({ booking }) {
  if (!booking) return <></>;

  return (
    <div className="w-full flex flex-col mt-4">
      <div className="flex items-center gap-1 mb-1">
        <img src="/IconInsurance2.svg" alt="" className="h-5 object-contain" />
        <span className="text-xl font-bold">
          {booking?.orderData?.orderData?.requestBody?.policyType}
        </span>
      </div>
      <div className="w-full bg-[#F3F8FF] border-t-2 border-primary1 p-4 flex flex-wrap gap-2 justify-between">
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order ID</span>
          <span className="text-sm text-primary1 font-bold">
            {booking?.mainBooking?.bookingId || booking?.bookingId}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Order Date</span>
          <span className="text-sm font-bold">
            {moment(booking?.createdAt).format("DD/MM/YYYY HH:mm A")}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="text-sm text-gray-500">Period</span>
          <span className="text-sm font-bold">
            {moment(booking?.startDate).format("DD MMM")} -{" "}
            {moment(booking?.endDate).format("DD MMM")}
          </span>
        </div>
      </div>
    </div>
  );
}

export default InsuranceConfirmationBookedPolicySection;
