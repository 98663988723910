import React from "react";
import ModalContainer from "../../common/ModalContainer";
import InsuranceConfirmationBookedPolicySection from "./InsuranceConfirmationBookedPolicySection";
import InsuranceConfirmationTravellerSection from "./InsuranceConfirmationTravellerSection";

export function InsuranceOrderDetailsModal({ open, setOpen, booking }) {
  return (
    <ModalContainer
      open={open}
      setOpen={setOpen}
      title="Insurance order detail"
    >
      <InsuranceConfirmationBookedPolicySection booking={booking} />
      <InsuranceConfirmationTravellerSection booking={booking} />
      {/* <div className="w-full flex flex-col" >
        
      </div> */}
    </ModalContainer>
  );
}
