import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export const ModifyHotelBookingTypeSelection = () => {
  const { data, setData, checkedRoom } = useModifyBookingModalContext();

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setData((prevData) => ({ ...prevData, type: value }));
  };

  const options = [
    {
      id: "traveler-names-radio",
      value: "traveler-names",
      label: "Traveler Names",
      disabled: false,
    },
    {
      id: "allocation-radio",
      value: "allocation",
      label: "Allocation",
      // disabled: checkedRoom?.room?.allowedGuests ? false : true,
      disabled: true,
      subtext: checkedRoom?.room?.allowedGuests
        ? undefined
        : "Allocation change is not available",
    },
    {
      id: "board-radio",
      value: "board",
      label: "Board",
      disabled: true,
      subtext: "There are no alternative boards",
    },
    {
      id: "requests-radio",
      value: "requests",
      label: "Requests",
      disabled: false,
    },
    {
      id: "dates-radio",
      value: "dates",
      label: "Dates",
      disabled: false,
    },
  ];

  return (
    <div className="flex flex-col w-full text-xs sm:text-base">
      {options.map((option) => (
        <div
          key={option.id}
          className={`flex items-center mb-4 cursor-pointer ${
            option?.disabled && "opacity-60"
          }`}
        >
          <input
            id={option.id}
            type="radio"
            value={option.value}
            name="modification-radio"
            className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 ${
              option.disabled ? "cursor-not-allowed" : ""
            }`}
            disabled={option.disabled}
            checked={!option.disabled && data?.type === option.value}
            onChange={handleOptionChange}
          />
          <label
            htmlFor={option.id}
            className={`ms-2 ${
              option.disabled ? "text-gray-400" : "text-black"
            }`}
          >
            <div className="flex flex-col">
              <span
                className={`${
                  option.disabled ? "text-gray-400" : "text-black"
                }`}
              >
                {option.label}
              </span>
              {option.subtext && (
                <span className="text-gray-400 text-sm">{option.subtext}</span>
              )}
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};

export default ModifyHotelBookingTypeSelection;
