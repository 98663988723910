import React from "react";
import { Icon } from "@iconify/react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

export const ModifyHotelBookingNameModification = () => {
  const { checkedRoom, setData } = useModifyBookingModalContext();

  const handleNameChange = (field, value) => {
    if (!checkedRoom) return;

    setData((prevData) => ({
      ...prevData,
      services: prevData.services.map((hotel) => ({
        ...hotel,
        rooms: hotel.rooms.map((room) =>
          room.id === checkedRoom.id ? { ...room, [field]: value } : room
        ),
      })),
    }));
  };

  return (
    <div className="text-sm sm:text-base">
      <h3 className="font-bold text-lg sm:text-2xl mb-2">Traveller names</h3>
      {checkedRoom ? (
        <div>
          <p className="">Adult 1:</p>
          <div className="flex gap-2 items-center mb-4">
            <Icon icon="mage:information-circle" className="text-primary1" />
            <p className="text-darkGray">Booking.traveller_name_lead_info</p>
          </div>
          <div className="flex flex-col sm:flex-row items-center gap-4">
            <TextInput2
              placeholder="Enter Name"
              className="w-full"
              label="Name"
              value={checkedRoom.firstName || ""}
              onChange={(e) => handleNameChange("firstName", e.target.value)}
            />
            <TextInput2
              placeholder="Enter Surname"
              className="w-full"
              label="Surname"
              value={checkedRoom.lastName || ""}
              onChange={(e) => handleNameChange("lastName", e.target.value)}
            />
          </div>
        </div>
      ) : (
        <p className="text-red-500">
          No room selected. Please select a room first.
        </p>
      )}
    </div>
  );
};

const TextInput2 = ({
  placeholder = "Type something...",
  className = "",
  label,
  value,
  onChange,
}) => {
  return (
    <div className={`relative flex flex-col justify-center ${className}`}>
      <p className="text-darkGray mb-1.5">{label}</p>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="w-full p-4 border rounded-lg focus:outline-none border-gray-300"
      />
    </div>
  );
};
