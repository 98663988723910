import { useDispatch } from "react-redux";
import { URLdecode, URLencode } from "../utils/url";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useRef } from "react";
import axios from "axios";
import fetchServer from "../controllers/fetchServer";
import { setPolicies } from "../redux/reducers/tour/insuranceSlice";
import { useInsuranceActions } from "./useInsuranceActions";
import { setLoadingModal } from "../redux/reducers/modalSlice";
import { configurations } from "../configurations";

export function useInsuranceApi() {
  const params = URLdecode();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getActualMinAndMaxInsurancePrice, parseInsuranceBookingData } =
    useInsuranceActions();
  const cancelGetFlightPoliciesToken = useRef(null);

  const parseFlightPolicyPayload = ({ data }) => {
    return {
      personInfo: {
        birthDate: data?.birthDate || "1990-10-20",
        firstName: data?.firstName || "yared",
        middleName: data?.middleName || "fire",
        lastName: data?.lastName || "terefe",
        email: data?.email || "admin-dembe@yopmail.com",
        phone: data?.phone || "08034635316",
        gender: data?.gender || "Male",
        title: data?.title || "Mr",
        city: data?.city || "Lagos",
        state: data?.state || "Lagos",
        passportNumber: data?.passportNumber || "18277872A4",
      },
      destinationCountry: data?.departure?.country,
      startDate: data?.startDate,
      endDate: data?.endDate,
      purposeOfTravel: data?.purposeOfTravel || "Leisure",
      isRoundTrip: data?.isRoundTrip || false,
      numberOfAdults: Number(data?.numberOfAdults) || 1,
      numberOfChildren: Number(data?.numberOfChildren) || 0,
      numberOfInfants: Number(data?.numberOfInfants) || 0,
      flights: data?.flights || [
        {
          departureDate: data?.startDate,
          arrivalDate: data?.endDate,
          flightNumber: data?.flightNumber || "2992AAD",
          pnr: data?.pnr || "FEDSAA",
          airline: data?.airline || "ET",
          departureAirport: data?.departure?.iata,
          arrivalAirport: data?.arrival?.iata,
          segmentIndex: 0,
        },
      ],
    };
  };

  const getFlightPolicies = async ({ data, setLoading, callback }) => {
    const payload = parseFlightPolicyPayload({ data });
    setLoading && setLoading(true);
    let cancellationMessage = "Operation canceled due to new request.";

    if (cancelGetFlightPoliciesToken.current) {
      cancelGetFlightPoliciesToken.current.cancel(cancellationMessage);
    }

    cancelGetFlightPoliciesToken.current = axios.CancelToken.source();

    await fetchServer({
      method: "POST",
      url: `/product/v1/insurance/flight-policies`,
      data: payload,
      cancelToken: cancelGetFlightPoliciesToken.current.token,
    })
      .then((res) => {
        if (!res?.cancelled) setLoading && setLoading(false);
        if (res) {
          if (res.status === 200) {
            const policies = res?.data?.data?.data;
            if (callback) {
              callback(policies);
            } else {
              dispatch(setPolicies(policies));
              getActualMinAndMaxInsurancePrice(policies);
            }
          }
        }
      })
      .catch((err) => {});
  };

  const bookInsurance = async ({ data, setLoading, insurance, callback }) => {
    const payload = parseInsuranceBookingData({ data, insurance });

    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/insurance/flight-purchase`,
      data: payload,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            enqueueSnackbar("Protection booking successful", {
              variant: "success",
            });
            if (callback) callback(res?.data?.data);
            else
              navigate(
                `/protection/payment?${URLencode({
                  id: res?.data?.data?.purchasedInsurance?._id,
                  policy: insurance?.policyId,
                })}`
              );
          } else {
            enqueueSnackbar(
              `Protection booking failed: ${
                res.data.message || "Unknown error"
              }`,
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Insurance booking failed: Network error", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const initializeInsurancePayment = async ({
    setLoading,
    callback,
    booking,
  }) => {
    const data = {
      insurancePurchaseId: booking?._id,
      paymentMode: "Wallet",
      type: "admin",
    };
    setLoading && setLoading(true);

    await fetchServer({
      method: "POST",
      url: `/payment/v1/payment/insurance`,
      data,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data?.data;
            // callback(res);
            callback(bookingData || booking);
          } else {
            enqueueSnackbar(
              res?.data?.error ||
                res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const getInsuranceBooking = async (setReload) => {
    const params = URLdecode();

    dispatch(setLoadingModal(true));

    await fetchServer({
      method: "GET",
      url: `/product/v1/insurance/${params?.id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data;
            let parameters = {
              id: booking?._id,
              status: booking?.status,
            };
            localStorage.setItem(
              `booking/${booking?._id}`,
              JSON.stringify(booking)
            );
            let url = `/protection/confirmation?${URLencode(parameters)}`;
            window.location.replace(url);
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        }
      })
      .catch((err) => {
        // console.log("Error: ", err);
        // enqueueSnackbar("Something went wrong. please try again later.", {
        //   variant: "error",
        // });
      })
      .finally(() => {
        setReload && setReload((prev) => !prev);
        dispatch(setLoadingModal(false));
      });
  };

  const cancelAndRefundInsurance = async ({
    booking,
    setLoading,
    callback,
    noSave = false,
  }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/insurance/cancel-confirmed/${booking?._id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data;
            enqueueSnackbar("Ticket cancelled successfully", {
              variant: "success",
            });
            if (callback) {
              callback(bookingData);
            }
            if (!noSave) {
              localStorage.setItem(
                `booking/${booking?._id}`,
                JSON.stringify(bookingData)
              );
            }
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  const getInsuranceBookings = async (setLoading) => {
    setLoading && setLoading(true);

    const response = await fetchServer({
      method: "GET",
      url: `/product/v1/insurance`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            return res.data.data;
          }
        }
      })
      .catch((err) => {});

    setLoading && setLoading(false);
    return response || [];
  };

  const getFlightOrderForInsurance = async (setLoading) => {
    const params = URLdecode();
    const savedBooking = localStorage.getItem(
      configurations.flightOrderSaveKey?.replace(":id", params?.flightId)
    );

    if (!params?.flightId) return;
    if (savedBooking) return;

    setLoading && setLoading(true);

    await fetchServer({
      method: "GET",
      url: `/product/v1/book/${params?.flightId}?populate=flightBooking`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const booking = res?.data?.data?.booking;
            localStorage.setItem(
              configurations.flightOrderSaveKey?.replace(":id", booking?._id),
              JSON.stringify(booking)
            );
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading && setLoading(false);
      });
  };

  const cancelPendingInsuranceOrder = async ({
    booking,
    setLoading,
    callbackUrl,
  }) => {
    setLoading && setLoading(true);
    await fetchServer({
      method: "POST",
      url: `/product/v1/insurance/cancel-purchase/${booking?._id}`,
    })
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const bookingData = res?.data?.data;
            callbackUrl && callbackUrl(bookingData);
            enqueueSnackbar("Booking cancelled successfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar(
              res?.data?.message ||
                "Something went wrong. please try again later.",
              {
                variant: "error",
              }
            );
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong. please try again later.", {
          variant: "error",
        });
      });
    setLoading && setLoading(false);
  };

  return {
    getFlightPolicies,
    bookInsurance,
    initializeInsurancePayment,
    getInsuranceBooking,
    cancelAndRefundInsurance,
    getInsuranceBookings,
    getFlightOrderForInsurance,
    cancelPendingInsuranceOrder,
  };
}
