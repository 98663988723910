import moment from "moment";
import { formatMoney } from "../../../features/utils/formatMoney";
import { getSupplierName } from "../../flight/supplier/getSupplierName";
import { getSupplierClass } from "../../../components/flight/FlightOfferDisplay";
import { flightStatusMap } from "../../../pages/Orders/OrdersData";
import Icon from "../../../components/HOC/Icon";

export function templateInsuranceOrderData(data) {
  const insuranceData = data;
  // debugger
  let flightType = "One Way";
  if (insuranceData?.destinations?.length) {
    flightType = "Multi City";
    if (
      insuranceData?.destinations?.at(-1)?.to ===
      insuranceData?.destinations?.at(0)?.from
    )
      flightType = "Return";
  }
  let travellers =
    insuranceData?.orderData?.orderData?.requestBody?.personsInfo?.map(
      (person) => {
        return {
          type: "text",
          value: person?.firstName + " " + person?.lastName,
        };
      }
    ) || [];

  return [
    {
      elements: [
        {
          type: "title",
          value: moment(data?.createdAt)?.format("DD-MM-YYYY HH:mm [GMT]Z"),
        },
        {
          type: "copy",
          value: insuranceData?.bookingId,
          renderValue: () => insuranceData?.bookingId,
        },
        {
          type: "text",
          value: (
            <span className="text-sm text-primary1">
              <span>Order by: </span>
              <span>{insuranceData?.account?.detail?.agencyName}</span>
            </span>
          ),
        },
        {
          type: "tag",
          value: getSupplierName(insuranceData?.supplier),
          className: getSupplierClass(insuranceData?.supplier),
          color: "text-[#BD002A]",
          background: "bg-[#BD002A]/20",
        },
      ],
      value: moment(data?.createdAt)?.format("YYYY-MM-DD HH:mm"),
      width: "md:w-[25%]",
    },
    {
      elements: [
        {
          type: "title",
          value: "Type",
        },
        {
          type: "text",
          value: insuranceData?.orderData?.orderData?.requestBody?.policyType,
        },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Duration" },
        {
          type: "text",
          value:
            moment(insuranceData?.startDate)?.format("DD MMM") +
            " - " +
            moment(insuranceData?.endDate)?.format("DD MMM"),
        },
      ],
      value: moment(insuranceData?.travelDate)?.format("YYYY-MM-DD"),
      width: "md:w-[15%]",
    },
    {
      elements: [{ type: "title", value: "Travellers" }, ...travellers],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Agent Price" },
        { type: "text", value: formatMoney(insuranceData?.amount) },
      ],
      value: insuranceData?.payable,
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Status" },
        { type: "status", value: insuranceData?.status },
        {
          type: "time",
          render: () => {
            const diffInSeconds = moment
              .utc(insuranceData?.ticketingExpiry)
              .diff(moment.utc(), "seconds");

            // Format the difference in the "5h 10m 5s" format
            const hours = Math.floor(diffInSeconds / 3600);
            const minutes = Math.floor((diffInSeconds % 3600) / 60);
            // const seconds = diffInSeconds % 60;
            const onStage = ["Paid", "Pending", "Pendingpayment"]?.includes(
              insuranceData?.status
            );

            return (hours || minutes) > 0 && onStage ? (
              <div
                className="flex gap-1 items-center"
                title="Ticket Expiry Time"
              >
                <Icon icon="mdi:clock-outline" className="!w-4 !h-4" />
                {hours || minutes} {hours ? "Hrs" : "Mins"}
              </div>
            ) : null;
          },
        },
      ],
      value: (function () {
        return [
          "Not Paid",
          "Paid",
          "Expired",
          "Awaiting ticketing",
          "Ticket issued",
          "Denied",
          "Canceled",
          "Cancelation Requested",
          "Ticket Canceled",
        ]?.findIndex((val) => val === flightStatusMap(insuranceData?.status));
      })(),
      width: "md:w-[15%]",
    },
  ];
}
