import { formatMoney } from "../features/utils/formatMoney";
import ISO6391 from "iso-639-1";

function capitalizeFirstLetter(str, trim) {
  if (typeof str !== "string") {
    return str;
  }

  str = str.replace(/_/g, " ");

  let finalString = str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
  if (trim) {
    if (finalString?.length > 18) {
      finalString = finalString.slice(0, 18) + "...";
    }
  }

  return finalString;
}

export const tourCheckoutPriceBreakdown = ({
  tourAvailability,
  productOptionCode,
}) => {
  let data = [];
  if (!tourAvailability) return data;
  const chosenPackage = tourAvailability?.bookableItems?.find(
    (item) => item.productOptionCode === productOptionCode
  );
  if (chosenPackage) {
    let lineItems = chosenPackage.lineItems;
    if (lineItems && lineItems?.length > 0) {
      lineItems.map((item, index) => {
        let label = `${capitalizeFirstLetter(item?.ageBand)} (${
          item?.numberOfTravelers
        })`;
        let price = item?.subtotalPrice?.price?.partnerNetPrice || 0;
        price = formatMoney(price);
        data.push({
          label,
          price,
        });
      });
    }
  }
  return data;
};

export const tourCheckoutTotalPrice = ({
  tourAvailability,
  productOptionCode,
}) => {
  if (!tourAvailability) return "";
  const chosenPakcage = tourAvailability?.bookableItems?.find(
    (item) => item.productOptionCode === productOptionCode
  );
  const priceItem = chosenPakcage?.totalPrice?.farePrice;

  if (!priceItem) return "";

  return formatMoney(priceItem?.total);
};

export const tourPassengerMix = ({ item }) => {
  if (!item || !item.lineItems || !Array.isArray(item.lineItems)) {
    return "";
  }

  const passengerCounts = item.lineItems.map((lineItem) => {
    const ageBand = capitalizeFirstLetter(lineItem.ageBand);
    const count = lineItem.numberOfTravelers || 0;

    return `${count} ${ageBand}`;
  });

  return passengerCounts.join(", ");
};

export const tourPassengerMixCart = ({ pkg, items, itm }) => {
  let item = null;
  let selectedPackage = items?.find(
    (itm) => itm.productOptionCode === pkg?.code
  );
  item = selectedPackage?.options?.find((_, idx) => idx === pkg?.time);
  if (itm) {
    item = itm;
  }
  if (!item || !item.lineItems || !Array.isArray(item.lineItems)) {
    return "";
  }

  const passengerCounts = item.lineItems.map((lineItem) => {
    const ageBand = capitalizeFirstLetter(lineItem.ageBand);
    const count = lineItem.numberOfTravelers || 0;

    return `${count} ${ageBand}`;
  });

  return passengerCounts.join(", ");
};

export const tourBookingTravellers = ({ booking }) => {
  if (!booking || !Array.isArray(booking.bookingQuestionAnswers)) {
    return [{ name: "", travellerData: [] }];
  }

  // Group answers by travelerNum
  const travelersMap = booking.bookingQuestionAnswers.reduce(
    (acc, question) => {
      const { travelerNum, question: questionKey, answer } = question;

      if (travelerNum) {
        if (!acc[travelerNum]) {
          acc[travelerNum] = {
            name: "",
            travellerData: [
              {
                label: "Email",
                value: booking?.contactEmail || "",
              },
              {
                label: "Phone number",
                value: "",
              },
              {
                label: "Type",
                value: "",
              },
            ],
          };
        }

        if (questionKey === "FULL_NAMES_FIRST") {
          acc[travelerNum].firstName = answer;
        } else if (questionKey === "FULL_NAMES_LAST") {
          acc[travelerNum].lastName = answer;
        } else if (questionKey === "AGEBAND") {
          const typeEntry = acc[travelerNum].travellerData.find(
            (entry) => entry.label === "Type"
          );
          if (typeEntry) {
            typeEntry.value = capitalizeFirstLetter(answer);
          }
        }

        if (acc[travelerNum].firstName && acc[travelerNum].lastName) {
          acc[
            travelerNum
          ].name = `${acc[travelerNum].lastName}/${acc[travelerNum].firstName}`;
        }
      }

      return acc;
    },
    {}
  );

  // Convert the map to an array
  const travelersArray = Object.values(travelersMap).map(
    ({ firstName, lastName, ...rest }) => rest
  );

  // If no travelerNum fields exist, return a default object
  if (travelersArray.length === 0) {
    return [
      {
        name: "",
        travellerData: [
          {
            label: "Email",
            value: booking?.contactEmail || "",
          },
          {
            label: "Phone number",
            value: "",
          },
          {
            label: "Type",
            value: "Traveler",
          },
        ],
      },
    ];
  }

  return travelersArray;
};

export const tourLanguages = ({ tour }) => {
  let languageString = "";

  let availableLanguages = tour?.languageGuides || [];
  if (availableLanguages && Array.isArray(availableLanguages)) {
    availableLanguages = [
      ...new Set(availableLanguages?.map((guide) => guide?.language)),
    ];
  }

  for (let i = 0; i < availableLanguages.length; i++) {
    languageString +=
      i !== 0
        ? `, ${ISO6391.getName(availableLanguages[i]) || "Unkown language"}`
        : ISO6391.getName(availableLanguages[i]) || "Unkown language";
  }

  return languageString;
};

export const tourLocation = ({ tour }) => {
  let start = tour?.logistics?.start?.at(0)?.location?.ref;
  let location =
    tour?.locations?.find((loc) => loc?.reference === start) ||
    tour?.locations?.at(0);

  if (location) {
    return location?.name || location?.address?.street;
  }

  return "Location not available";
};

export const tourDuration = ({ tour }) => {
  let time = tour?.itinerary?.duration?.fixedDurationInMinutes;

  const convertMinutes = (totalMinutes) => {
    let timeString = "";
    const hours = Math.floor(totalMinutes / 60);
    if (hours) {
      timeString += `${hours} hour(s)`;
    }
    const minutes = totalMinutes % 60;
    if (minutes) {
      timeString += ` ${minutes} minute(s)`;
    }
    return timeString;
  };

  if (time !== undefined && time !== null) {
    return convertMinutes(time);
  }

  return "Duration not available";
};
