import moment from "moment";
import { formatMoney } from "../../../features/utils/formatMoney";
import { getSupplierName } from "../../flight/supplier/getSupplierName";
import { getSupplierClass } from "../../../components/flight/FlightOfferDisplay";
import { flightStatusMap } from "../../../pages/Orders/OrdersData";
import Icon from "../../../components/HOC/Icon";

export function templateTourOrderData(data) {
  const tourData = data;
  // debugger
  let flightType = "One Way";
  if (tourData?.destinations?.length) {
    flightType = "Multi City";
    if (
      tourData?.destinations?.at(-1)?.to === tourData?.destinations?.at(0)?.from
    )
      flightType = "Return";
  }

  return [
    {
      elements: [
        {
          type: "title",
          value: moment(data?.createdAt)?.format("DD-MM-YYYY HH:mm [GMT]Z"),
        },
        {
          type: "copy",
          value: tourData?.bookingId,
          renderValue: () => tourData?.bookingId,
        },
        {
          type: "text",
          value: (
            <span className="text-sm text-primary1">
              <span>Order by: </span>
              <span>{tourData?.account?.detail?.agencyName}</span>
            </span>
          ),
        },
        {
          type: "tag",
          value: getSupplierName(tourData?.supplier),
          className: getSupplierClass(tourData?.supplier),
          color: "text-[#BD002A]",
          background: "bg-[#BD002A]/20",
        },
      ],
      value: moment(data?.createdAt)?.format("YYYY-MM-DD HH:mm"),
      width: "md:w-[25%]",
    },
    {
      elements: [
        // { type: "title", value: flightType },
        {
          type: "text",
          value: (
            <div>
              {tourData?.destinations
                ?.slice(0, 1)
                ?.map((obj) => `${obj?.from} - ${obj?.to}`)
                ?.join(",") || ""}
              <div>
                <span className="">
                  {tourData?.extraDetails?.productInfo?.title}
                </span>
              </div>
              <small>{tourData?.holdBookingRes?.name}</small>
            </div>
          ),
        },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Travel Time" },
        {
          type: "text",
          value: moment(tourData?.travelDate)?.format("YYYY-MM-DD") + " ",
        },
        {
          type: "text",
          value: (
            <span className="text-blue-500">
              {tourData?.startTime
                ? moment(tourData?.startTime, "HH:mm").format("h:mm A")
                : ""}
            </span>
          ),
        },
      ],
      value: moment(tourData?.travelDate)?.format("YYYY-MM-DD"),
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Passenger" },
        { type: "text", value: tourData?.name },
      ],
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Agent Price" },
        { type: "text", value: formatMoney(tourData?.amount) },
      ],
      value: tourData?.payable,
      width: "md:w-[15%]",
    },
    {
      elements: [
        { type: "title", value: "Status" },
        { type: "status", value: tourData?.status },
        {
          type: "time",
          render: () => {
            const diffInSeconds = moment
              .utc(tourData?.ticketingExpiry)
              .diff(moment.utc(), "seconds");

            // Format the difference in the "5h 10m 5s" format
            const hours = Math.floor(diffInSeconds / 3600);
            const minutes = Math.floor((diffInSeconds % 3600) / 60);
            // const seconds = diffInSeconds % 60;
            const onStage = ["Paid", "Pending", "Pendingpayment"]?.includes(
              tourData?.status
            );

            return (hours || minutes) > 0 && onStage ? (
              <div
                className="flex gap-1 items-center"
                title="Ticket Expiry Time"
              >
                <Icon icon="mdi:clock-outline" className="!w-4 !h-4" />
                {hours || minutes} {hours ? "Hrs" : "Mins"}
              </div>
            ) : null;
          },
        },
      ],
      value: (function () {
        return [
          "Not Paid",
          "Paid",
          "Expired",
          "Awaiting ticketing",
          "Ticket issued",
          "Denied",
          "Canceled",
          "Cancelation Requested",
          "Ticket Canceled",
        ]?.findIndex((val) => val === flightStatusMap(tourData?.status));
      })(),
      width: "md:w-[15%]",
    },
  ];
}
