import React, { useState } from "react";
import ModalContainer from "../common/ModalContainer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setInsuranceOrders } from "../../redux/reducers/orderSlice";
import useDateFormat from "../../hooks/useDateFormat";
import { useInsuranceApi } from "../../hooks/useInsuranceApi";
import { formatMoney } from "../../features/utils/formatMoney";

export const PayInsuranceBookingModal = ({ setOpen, open, booking }) => {
  const dispatch = useDispatch();
  const { capitalizeFirstLetter } = useDateFormat();
  const { insuranceOrders } = useSelector((state) => state.order);
  const { initializeInsurancePayment } = useInsuranceApi();
  const [loading, setLoading] = useState(false);

  const paxMix = () => {
    const pax = booking?.orderData?.orderData?.requestBody?.personsInfo;
    let adults = pax?.filter(
      (person) => person?.ageCategory === "Adult"
    ).length;
    let children = pax?.filter(
      (person) => person?.ageCategory === "Child"
    ).length;
    let infants = pax?.filter(
      (person) => person?.ageCategory === "Infant"
    ).length;
    let paxMix = [
      { ageBand: "Adult", numberOfTravelers: adults || 0 },
      { ageBand: "Child", numberOfTravelers: children || 0 },
      { ageBand: "Infant", numberOfTravelers: infants || 0 },
    ];

    paxMix = paxMix.filter((person) => person?.numberOfTravelers > 0);
    return paxMix
      ?.map((item) => {
        return `${item?.numberOfTravelers} ${capitalizeFirstLetter(
          item?.ageBand
        )}`;
      })
      .join(", ");
  };

  const handlePay = async () => {
    await initializeInsurancePayment({
      booking,
      setLoading,
      callback: (data) => {
        dispatch(
          setInsuranceOrders(
            insuranceOrders?.map((item) =>
              item?._id === data?._id ? data : item
            )
          )
        );
        setOpen(false);
      },
    });
  };

  return (
    <>
      <ModalContainer
        open={open}
        setOpen={setOpen}
        secondary
        padding="px-4 py-6"
        title="Cancel"
        subTitle="Check the information of the product you want to cancel"
      >
        <div className="w-full flex flex-col">
          <div className="flex items-start gap-2 w-full p-2">
            <div className="my-1">
              <img
                src="/IconInsurance2.svg"
                alt="service-icon"
                className="h-5 object-contain"
              />
            </div>
            <div className="flex flex-1 flex-col">
              <span className="text-sm lg:text-base">
                {booking?.orderData?.orderData?.requestBody?.policyType}
              </span>
              {paxMix()}
              <span className="text-sm text-gray-400">
                {moment(booking?.startDate).format("ddd DD MMM")} -{" "}
                {moment(booking?.endDate).format("ddd DD MMM")}
              </span>
            </div>
          </div>

          <div className="border-[#EFECEC] mb-10"></div>

          <div className="flex gap-6 items-center">
            <button onClick={setOpen} className={ClassName.whiteButton}>
              Cancel
            </button>
            <button
              className="bg-primary1 flex items-center justify-center text-secondaryflex-1 text-white w-full py-4 rounded-lg text-xs sm:text-lg"
              onClick={() => handlePay()}
            >
              {loading ? (
                <div className="buttonLoader"></div>
              ) : (
                <span>Pay {formatMoney(booking?.grandTotal)}</span>
              )}
            </button>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

const ClassName = {
  whiteButton: "py-4 px-6 text-xs md:text-lg font-bold w-full max-w-[174px]",
};
