import React from "react";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";
import { useStayUI } from "../../../hooks/useStayUI";

const ModifyHotelBookingModalBlueButton = ({ children, onClick }) => {
  const { modifyBookingContinueButtonValidate } = useStayUI();
  const { data, setData, tabs } = useModifyBookingModalContext();

  const goToNextStep = () => {
    if (modifyBookingContinueButtonValidate({ data })) return;
    if (onClick) {
      onClick();
    } else {
      if (data?.tab < tabs.length) {
        setData({ ...data, tab: data?.tab + 1 });
      }
    }
  };

  return (
    <button
      onClick={goToNextStep}
      className="bg-primary1 text-secondaryflex-1 text-white w-full py-4 rounded-lg text-xs sm:text-lg"
    >
      {children}
    </button>
  );
};

export default ModifyHotelBookingModalBlueButton;
