import React, { useEffect, useState } from "react";
import OrderTypeSelection from "../../components/order/OrderTypeSelection";
import OrderFilterTypeSelection from "../../components/order/OrderFilterTypeSelection";
import OrderStatusFilterSelection from "../../components/order/OrderStatusFilterSelection";
import OrderSortHeader from "../../components/order/OrderSortHeader";
import OrderList from "../../components/order/OrderList";
import OrderPagination from "../../components/order/OrderPagination";
import { useDispatch, useSelector } from "react-redux";
import {
  setFlightOrders,
  setHotelOrders,
  setInsuranceOrders,
  setRefundOrders,
  setReissueOrders,
  setTicketsPage,
  setTourOrders,
  setVoidOrders,
} from "../../redux/reducers/orderSlice";
import { CircularProgress } from "@mui/material";
import getBookings from "../../controllers/booking/getBookings";
import useOrders from "../../hooks/useOrders";
import getVoidTicketRequests from "../../controllers/booking/postTicketing/void/getVoidTicketRequests";
import getRefundTicketRequests from "../../controllers/booking/postTicketing/refund/getRefundTicketRequests";
import getTicketRequests from "../../controllers/booking/getTicketRequests";
import getChangeRequests from "../../controllers/booking/postTicketing/change/getChangeRequests";
import IOSSwitch from "../../components/form/IOSSwitch";
import getSuppliersName from "../../controllers/settings/supplier/getFlightSetting";
import updateFlightSetting from "../../controllers/settings/supplier/updateFlightSetting";
import { enqueueSnackbar } from "notistack";
import { useHotels } from "../../hooks/useHotels";
import { useTours } from "../../hooks/useTours";
import {
  templateHotelOrderData,
  templateInsuranceOrderData,
  templateTourOrderData,
} from "../../data/order/ordersData";
import { useInsuranceApi } from "../../hooks/useInsuranceApi";
import { configurations } from "../../configurations";

function NewOrders() {
  const {
    ticketFilterType,
    ticketType,
    ticketsPage: page,
    ticketsPerPage: perPage,
    ticketSearchValue,
  } = useSelector((state) => state.order);
  const { getOrders } = useOrders();
  const { getHotelBookings } = useHotels();
  const { getTourBookings } = useTours();
  const { getInsuranceBookings } = useInsuranceApi();
  const [ordersList, ogData] = getOrders() || [];
  const [settings, setSettings] = useState();
  const [loading, setLoading] = useState(true);

  const offset = ((page || 1) - 1) * perPage;

  const searchQuery = {};
  if (ticketSearchValue) {
    searchQuery.searchBy = "bookingId";
    searchQuery.keyword = ticketSearchValue;
  }

  useEffect(() => {
    loadData();
    loadSettings();
    //eslint-disable-next-line
  }, [ticketFilterType, ticketType, page, perPage, ticketSearchValue]);

  const dispatch = useDispatch();

  const loadData = async () => {
    setLoading(true);
    let promises = [loadCase(), loadTour(), loadHotel(), loadInsurance()];
    let responses = await Promise.all(promises);
    setLoading(false);
    responses = responses.flat();
    responses = responses.filter((response) => response);
    if (responses) {
      responses.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      // setData(responses);
    }
  };

  async function loadCase() {
    switch (ticketFilterType) {
      case "Void Management":
        return loadVoidOrders();
      case "Refund Management":
        return loadRefundOrders();
      case "Re-issue Management":
        return loadChangeRequests();
      case "Tickets":
        return loadTicketRequests();
      default:
        return load();
    }
  }

  async function loadChangeRequests() {
    const res = await getChangeRequests({
      limit: perPage,
      offset,
      ...searchQuery,
    });
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({
          ...obj,
          flightBooking: [obj?.flightBooking],
          flightObj: obj?.flightBooking,
          type: "Flight",
        });
        // );
        return true;
      });
      dispatch(
        setReissueOrders([
          ...data,
          ...Array((res?.data?.meta?.total || 0) - (data?.length || 0)),
        ])
      );
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  async function loadTicketRequests() {
    const res = await getTicketRequests({
      limit: perPage,
      offset,
      ...searchQuery,
    });
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({
          ...obj,
          flightBooking: [obj?.flightBooking],
          flightObj: obj?.flightBooking,
          type: "Flight",
        });
        // );
        return true;
      });
      dispatch(
        setFlightOrders([
          ...data,
          ...Array((res?.data?.meta?.total || 0) - (data?.length || 0)),
        ])
      );
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  async function loadRefundOrders() {
    const res = await getRefundTicketRequests({
      limit: perPage,
      offset,
      ...searchQuery,
    });
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({
          ...obj,
          flightBooking: [obj?.flightBooking],
          flightObj: obj?.flightBooking,
          type: "Flight",
        });
        // );
        return true;
      });
      dispatch(
        setRefundOrders([
          ...data,
          ...Array((res?.data?.meta?.total || 0) - (data?.length || 0)),
        ])
      );
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  async function loadVoidOrders() {
    const res = await getVoidTicketRequests({
      limit: perPage,
      offset,
      ...searchQuery,
    });
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        // obj?.flightBooking?.map((flightObj) =>
        data.push({
          ...obj,
          flightBooking: [obj?.flightBooking],
          flightObj: obj?.flightBooking,
          type: "Flight",
        });
        // );
        return true;
      });
      dispatch(
        setVoidOrders([
          ...data,
          ...Array((res?.data?.meta?.total || 0) - (data?.length || 0)),
        ])
      );
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  async function load() {
    if (
      ticketType === configurations.stayKeyword ||
      ticketType === configurations.tourKeyword ||
      ticketType === configurations.insuranceKeyword
    )
      return [];

    const res = await getBookings({ limit: perPage, offset, ...searchQuery });
    let data = [];
    if (res.return) {
      res.data?.data?.map((obj) => {
        obj?.flightBooking?.map((flightObj) =>
          data.push({ ...obj, flightObj, type: "Flight" })
        );
        return true;
      });
      dispatch(
        setFlightOrders([
          ...data,
          ...Array((res?.data?.meta?.total || 0) - (data?.length || 0)),
        ])
      );
      // data = data?.map((obj) => templateFlightOrderData(obj));
    }
    return data;
  }

  const loadSettings = async () => {
    try {
      setLoading(true); // Set loading to true before fetching
      const res = await getSuppliersName();
      setSettings({ ...res.data });
    } catch (error) {
      console.error("Error fetching suppliers", error);
    } finally {
      setLoading(false); // Set loading to false after fetching completes
    }
  };

  // const loadTour = async () => {
  //   let response = await getTourBookings();
  //   response = response?.map((obj) => templateTourOrderData(obj));
  //   return response;
  // };

  // const loadHotel = async () => {
  //   let response = await getHotelBookings();
  //   response = response?.map((obj) => templateHotelOrderData(obj));
  //   return response;
  // };
  console.log(settings);

  async function handleAutoTicket(autoTicket) {
    const res = await updateFlightSetting({ autoTicket });
    if (res?.return) {
      loadSettings();
    } else enqueueSnackbar(res.msg, { variant: "error" });
  }

  const loadTour = async () => {
    // if (ticketType !== "Tour") return [];

    let response = await getTourBookings({
      limit: perPage,
      offset,
      ...searchQuery,
    });
    response = response?.map((obj) => templateTourOrderData(obj));
    dispatch(setTourOrders(response));
    return response;
  };

  const loadHotel = async () => {
    // if (ticketType !== "Stays") return [];

    let response = await getHotelBookings({
      limit: perPage,
      offset,
      ...searchQuery,
    });
    response = response?.map((obj) => templateHotelOrderData(obj));
    dispatch(setHotelOrders(response));
    return response;
  };

  const loadInsurance = async () => {
    // if (ticketType !== configurations.insuranceKeyword) return [];

    let response = await getInsuranceBookings();
    response = response?.map((obj) => templateInsuranceOrderData(obj));
    dispatch(setInsuranceOrders(response));
    return response;
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        <div className="flex gap-4 justify-between items-center">
          <OrderTypeSelection />
          <label className="flex  items-center justify-end sm:whitespace-nowrap">
            Auto Ticketing
            <IOSSwitch
              checked={settings?.autoTicket}
              onChange={(ev) => handleAutoTicket(ev.target.checked)}
            />
          </label>
        </div>
        <OrderFilterTypeSelection />
        <OrderStatusFilterSelection />
        <OrderSortHeader />
        {loading ? (
          <div className="p-4 w-full flex items-center justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="z-10">
            <OrderList
              reload={loadData}
              // ogData={ogData} orders={ordersList}
              perPage={perPage}
            />
          </div>
        )}
        <div>
          <OrderPagination
            perPage={perPage}
            // count={parseInt(Math.max(ordersList?.length,perPage)/perPage)}
          />
        </div>
      </div>
    </div>
  );
}

export default NewOrders;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 pb-96",
};
