import React, { useState } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import { useModifyBookingModalContext } from "./ModifyHotelBookingModal";

// Helper Functions
const formatRoomDates = (startDate, endDate) => {
  if (!startDate || !endDate) return "";

  const start = moment(startDate);
  const end = moment(endDate);
  const nights = end.diff(start, "days");

  return `${start.format("DD MMM")} - ${end.format("DD MMM")} (${nights} night${
    nights > 1 ? "s" : ""
  })`;
};

const updateHotelRooms = (hotels, hotelId, roomId) =>
  hotels.map((hotel) =>
    hotel.id === hotelId
      ? {
          ...hotel,
          rooms: hotel.rooms.map((room) => ({
            ...room,
            checked: room.id === roomId,
          })),
        }
      : {
          ...hotel,
          rooms: hotel.rooms.map((room) => ({ ...room, checked: false })),
        }
  );

const RoomOption = ({ room, checked, onChange }) => (
  <div className="flex justify-between p-4 border-b border-gray-200">
    <div className="flex gap-4 w-full items-start">
      <div className="flex items-center mt-2">
        <input
          id={room.id}
          type="radio"
          name="room"
          checked={checked}
          onChange={onChange}
          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
        />
      </div>

      <div className="flex-1">
        <span className="block font-medium text-sm sm:text-base">
          {room.label}
        </span>
        <span className="block text-sm text-gray-500 mt-1">
          {formatRoomDates(room.startDate, room.endDate)}
        </span>
        <span className="block text-sm text-gray-500 mt-1">{room.guests}</span>
      </div>
    </div>

    <div className="flex items-center gap-2 text-lg text-gray-500">
      <Icon icon="solar:user-bold" />
      <span className="text-sm lg:whitespace-nowrap">{room.guest}</span>
    </div>
  </div>
);

const HotelOption = ({
  hotel,
  isSelected,
  onSelectHotel,
  onSelectRoom,
  addRoom,
}) => (
  <div className="flex flex-col border border-gray-200 rounded-lg overflow-hidden mb-4">
    <div className="flex items-center p-4 border-b border-gray-200">
      <input
        id={hotel.id}
        type="radio"
        name="hotel"
        checked={isSelected}
        onChange={onSelectHotel}
        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
      />
      <label
        htmlFor={hotel.id}
        className="ml-2 text-sm sm:text-lg font-bold flex items-center gap-2"
      >
        {hotel.image && (
          <img
            src={hotel.image}
            alt={hotel.label}
            className="h-5 object-contain"
          />
        )}
        <span>{hotel.label}</span>
      </label>
    </div>
    <div className="flex flex-col">
      {hotel.rooms.map((room) => (
        <RoomOption
          key={room.id}
          room={room}
          checked={room.checked}
          onChange={() => onSelectRoom(room.id)}
        />
      ))}
    </div>
    {/* <div className="p-4">
      <button
        className="flex items-center gap-2 text-blue-600"
        onClick={() => addRoom(hotel.id)}
      >
        <Icon icon="ic:baseline-add" className="text-lg" />
        <span>Add Room</span>
      </button>
    </div> */}
  </div>
);

export const ModifyHotelBookingServiceSelection = () => {
  const { data, setData, setTabs, roomTabs } = useModifyBookingModalContext();
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);

  const handleSelectHotel = (hotelId) => {
    setSelectedHotel(hotelId);
    setSelectedRoom(null);
    setData((prevData) => ({
      ...prevData,
      services: prevData.services.map((hotel) => ({
        ...hotel,
        rooms: hotel.rooms.map((room) => ({ ...room, checked: false })),
      })),
    }));
  };

  const handleSelectRoom = (hotelId, roomId) => {
    setSelectedHotel(hotelId);
    setSelectedRoom(roomId);
    setData((prevData) => ({
      ...prevData,
      services: updateHotelRooms(prevData.services, hotelId, roomId),
      hotelId,
    }));
  };

  const handleAddRoom = (hotelId) => {
    setData({ ...data, tabType: "room", tab: 1, hotelId });
    setTabs(roomTabs);
  };

  const hotels = data?.services || [];

  return (
    <div className="flex flex-col">
      <h3 className="text-lg sm:text-2xl font-bold mb-4">Select Service</h3>
      {hotels.map((hotel) => (
        <HotelOption
          key={hotel.id}
          hotel={hotel}
          isSelected={selectedHotel === hotel.id}
          onSelectHotel={() => handleSelectHotel(hotel.id)}
          onSelectRoom={(roomId) => handleSelectRoom(hotel.id, roomId)}
          addRoom={() => handleAddRoom(hotel.id)}
        />
      ))}
      <div className="flex items-center gap-4 mt-4">
        <button className={ClassName.whiteButton}>
          <span>Add Products</span>
          <Icon icon="ic:baseline-add" className="text-base" />
        </button>
        <button className={ClassName.whiteButton}>
          <span>Cancellation Options</span>
          <Icon icon="zondicons:close-outline" className="text-base" />
        </button>
      </div>
    </div>
  );
};

// Styles
const ClassName = {
  whiteButton:
    "px-6 py-2 text-xxxs sm:text-sm font-semibold text-primary1 border border-primary1 rounded-lg flex items-center gap-2",
};
