import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import downlaodPDF from "../../controllers/Misc/downloadPDF";

export default function usePDF() {
  const viewPDF = (base64) => {
    // Step 1: Convert Base64 to binary
    const byteCharacters = atob(base64);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Step 2: Create a Blob
    const blob = new Blob([byteNumbers], { type: "application/pdf" });

    // Step 3: Generate a URL
    const url = URL.createObjectURL(blob);

    return url;
  };

  function handlePDFExport(
    callback,
    { fileName, component: defComponent, returnResult }
  ) {
    const component = defComponent || document.getElementById("flightDoc");

    const desiredWidth = 795; // Set your desired width here

    const scaleFactor = desiredWidth / component.offsetWidth;

    const desiredHeight = component.offsetHeight * scaleFactor;

    component.style.width = `${desiredWidth}px`;
    // component.style.height = `${desiredHeight}px`;

    const segments = Array.from(
      { length: Math.ceil(component?.offsetHeight / desiredHeight) + 1 },
      (_, index) => index * desiredHeight
    );
    console.log(segments, component?.offsetHeight, desiredHeight);
    const pdf = new jsPDF();

    function captureSegmentScreenshot(index) {
      if (index >= segments.length) {
        if (callback) callback(pdf.output("blob"));
        else pdf.save(fileName + ".pdf");
        returnResult && returnResult(pdf.output("blob"));

        component.style.width = "auto";
        component.style.height = "auto";

        return;
      }

      if (index) pdf.addPage();

      setTimeout(
        () =>
          html2canvas(component, { useCORS: true, allowTaint: false }).then(
            (canvas) => {
              const imgData = canvas.toDataURL("image/png");

              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

              const xPos =
                (pdfWidth - canvas.width * (pdfHeight / canvas.height)) / 2;
              const yPos = index * (desiredWidth - 500);

              pdf.addImage(
                imgData,
                "PNG",
                xPos,
                -yPos,
                canvas.width * (pdfHeight / canvas.height),
                pdfHeight
              );

              captureSegmentScreenshot(index + 1);
            }
          ),
        10
      );
    }

    captureSegmentScreenshot(0);
  }

  async function handlePDFDownload(string, { fileName }) {
    const res = await downlaodPDF({
      html: `
            <html>
                <head>
                    <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
                </head>
                <body>${string}</body>
            </html>
        `,
    });
    if (res.return) {
      const url = viewPDF(res.data);

      // Create an anchor element for download
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName ? fileName + ".pdf" : "document.pdf"; // Specify the filename
      document.body.appendChild(a); // Append the anchor to the body
      a.click(); // Trigger the download
      document.body.removeChild(a); // Clean up
      URL.revokeObjectURL(url); // Release the Blob URL
    }
    console.log(res);
  }

  return {
    handlePDFExport,
    handlePDFDownload,
  };
}
