import React, { useState, createContext, useContext, useEffect } from "react";
import ModalContainer from "../../common/ModalContainer";
import { ModifyHotelBookingModalSteps } from "./ModifyHotelBookingModalSteps";
import { ModifyHotelBookingModalContent } from "./ModifyHotelBookingModalContent";
import ModifyHotelBookingModalBlueButton from "./ModifyHotelBookingModalBlueButton";
import { useStayUI } from "../../../hooks/useStayUI";
import moment from "moment";
import ModifyHotelBookingModalConfirmButton from "./ModifyHotelBookingModalConfirmButton";

const ModifyBookingModalContext = createContext();

export const useModifyBookingModalContext = () => {
  const context = useContext(ModifyBookingModalContext);
  if (!context) {
    throw new Error("useModalContext must be used within a ModalProvider");
  }
  return context;
};

const ModifyBookingModalProvider = ({
  children,
  booking,
  setOpen,
  open,
  page,
}) => {
  const { parseBookingToModifyHotel } = useStayUI();
  const mainTabs = ["Service", "Type", "Modification", "Confirmation"];
  const roomTabs = ["Service", "Travellers", "Modification", "Confirmation"];
  const [data, setData] = useState(parseBookingToModifyHotel({ booking }));
  const [tabs, setTabs] = useState(mainTabs);
  const [newPriceLoading, setNewPriceLoading] = useState(false);

  useEffect(() => {
    if (booking) {
      setData(parseBookingToModifyHotel({ booking }));
    }
  }, [booking, open]);

  const formatModifyDates = (startDate, endDate) => {
    if (!startDate || !endDate) return "";

    const start = moment(startDate);
    const end = moment(endDate);
    const nights = end.diff(start, "days");

    return `${start.format("ddd, DD MMM, YYYY")} - ${end.format(
      "ddd, DD MMM, YYYY"
    )} (${nights} night${nights > 1 ? "s" : ""})`;
  };

  const onHotelDateChange = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }) => {
    setData((prevData) => ({
      ...prevData,
      services: prevData.services.map((hotel) =>
        hotel.id === checkedHotel?.id
          ? {
              ...hotel,
              newStartDate,
              newEndDate,
            }
          : hotel
      ),
    }));
  };

  const onRoomDateChange = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }) => {
    if (!newStartDate || !newEndDate) return;

    setData((prevData) => ({
      ...prevData,
      services: prevData.services.map((hotel) => ({
        ...hotel,
        rooms: hotel.rooms.map((room) =>
          room.id === checkedRoom.id
            ? { ...room, newStartDate, newEndDate }
            : room
        ),
      })),
    }));
  };

  const priceDiff = ({ data }) => {
    if (!data) return null;
    const diff = Math.floor(data?.newGrandTotal) - Math.floor(data?.grandTotal);
    if (!diff) return null;
    return diff;
  };

  const checkedRoom = data?.services
    ?.flatMap((hotel) => hotel?.rooms || [])
    ?.find((room) => room?.checked);

  const checkedHotel = data?.services?.find(
    (hotel) => hotel?.id === data?.hotelId
  );

  const value = {
    data,
    setData,
    tabs,
    booking,
    setTabs,
    mainTabs,
    roomTabs,
    checkedRoom,
    checkedHotel,
    formatModifyDates,
    onHotelDateChange,
    onRoomDateChange,
    setOpen,
    newPriceLoading,
    setNewPriceLoading,
    priceDiff,
    page,
  };

  const subTitle = () => {
    let text = "";
    if (data?.tabType === "main" && data?.tab > 0) {
      text = checkedRoom?.room?.name;
    } else {
      if (data?.tab > 0) {
        text = "Add room";
      }
    }
    return text;
  };

  return (
    <ModifyBookingModalContext.Provider value={value}>
      <ModalContainer
        open={open}
        setOpen={setOpen}
        title={
          data?.tab > 0
            ? data?.hotelId || "Modify your booking"
            : "Modify your booking"
        }
        subTitle={subTitle()}
        secondary
      >
        <>
          {children}
          {!(
            data?.tabType === "room" &&
            (data?.tab === 1 || data?.tab === 2)
          ) && (
            <div className="flex gap-6 items-center">
              <button
                onClick={() =>
                  data?.tab === 0
                    ? setOpen()
                    : setData({ ...data, tab: data?.tab - 1 })
                }
                className={ClassName.whiteButton}
              >
                {data?.tab === 0 ? "Close" : "Go back"}
              </button>
              {data?.tab === 3 ? (
                <ModifyHotelBookingModalConfirmButton />
              ) : (
                <ModifyHotelBookingModalBlueButton>
                  Continue
                </ModifyHotelBookingModalBlueButton>
              )}
            </div>
          )}
        </>
      </ModalContainer>
    </ModifyBookingModalContext.Provider>
  );
};

// Main Component
export const ModifyHotelBookingModal = ({ setOpen, open, booking, page }) => {
  return (
    <ModifyBookingModalProvider
      booking={booking}
      setOpen={setOpen}
      open={open}
      page={page}
    >
      <div className="space-y-6">
        <ModifyHotelBookingModalSteps />
        <ModifyHotelBookingModalContent />
      </div>
    </ModifyBookingModalProvider>
  );
};

const ClassName = {
  whiteButton:
    "py-3 sm:py-4 px-6 sm:px-[4.6875rem] text-xs whitespace-nowrap sm:text-lg font-bold",
};
